import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const branchAction = {
  listBranchAll,

  updateBranchEatin,
  getBranchEatinId,
  createBranchEatin,
  deleteBranchEatin,
  activateBranchEatin,
  getBranchEatinAll,


  updateBranchPreorder,
  getBranchPreorderId,
  createBranchPreorder,
  deleteBranchPreorder,
  activateBranchPreorder,
  listBranchOrderOnline,

};

function listBranchAll() {
  return axios.get(`${Base_API.branch}/branch/api/branch_all`, "").then(res => {
    return res.data;
  });
}

function getBranchEatinId(branch_id) {
  return axios.get(`${Base_API.branch}/branch/${branch_id}`, "").then(res => {
    return res.data;
  });
}

function getBranchEatinAll() {
  return axios.get(`${Base_API.branch}/branch`, "").then(res => {
    return res.data;
  });
}

function updateBranchEatin(formData, branch_id) {
  return axios.put(`${Base_API.branch}/branch/${branch_id}`, formData).then(res => {
    return res.data;
  });
}

function createBranchEatin(formData) {
  return axios.post(`${Base_API.branch}/branch`, formData).then(res => {
    return res.data;
  });
}

function deleteBranchEatin(branch_id) {
  return axios.delete(`${Base_API.branch}/branch/${branch_id}`).then(res => {
    return res.data;
  });
}

function activateBranchEatin(formData, branch_id) {
  return axios.put(`${Base_API.branch}/branch/${branch_id}`, formData).then(res => {
    return res.data;
  });
}


function getBranchPreorderId(branch_id) {
  return axios.get(`${Base_API.branch}/branch_preorder/${branch_id}`, "").then(res => {
    return res.data;
  });
}

function updateBranchPreorder(formData, branch_id) {
  return axios.put(`${Base_API.branch}/branch_preorder/${branch_id}`, formData).then(res => {
    return res.data;
  });
}

function createBranchPreorder(formData) {
  return axios.post(`${Base_API.branch}/branch_preorder`, formData).then(res => {
    return res.data;
  });
}

function deleteBranchPreorder(branch_id) {
  return axios.delete(`${Base_API.branch}/branch_preorder/${branch_id}`).then(res => {
    return res.data;
  });
}

function activateBranchPreorder(formData, branch_id) {
  return axios.put(`${Base_API.branch}/branch_orderonline/${branch_id}`, formData).then(res => {
    return res.data;
  });
}

function listBranchOrderOnline() {
  return axios.get(`${Base_API.branch}/branch/api/branch_all`, "").then(res => {
    return res.data;
  });
}
