import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const bannerAction = {
  // deleteBanner,
  getBannerByID,
  updateBanner,
  // createBanner
};

function getBannerByID(banner_id) {
  return axios.get(`${Base_API.banner}/banner/${banner_id}`).then(res => {
    return res.data;
  });
}

function updateBanner(inputValue, banner_id) {
  return axios.put(`${Base_API.banner}/banner/${banner_id}`, inputValue).then(res => {
    return res.data;
  });
}

// function createBanner(inputValue) {
//   return axios.post(`${Base_API.banner}/images`, inputValue).then(res => {
//     return res.data;
//   });
// }

// function deleteBanner(bid) {
//   return axios({
//     method: "DELETE",
//     url: `${Base_API.banner}/banners/${bid}`
//   })
//     .then(function(response) {
//       return response.data;
//     })
//     .catch(function(response) {
//       console.log(response);
//     });
// }
