import React from "react";
import $ from "jquery";
import { newsAction } from "../../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { Base_API } from "../../../../../_constants/matcher";
import Select from 'react-select';
import "../promotion.css";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState,convertToRaw,convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let modalAlert;

export class NewsPromotionBanner_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      modalManageContent: null,
      messageCreate: "",
      fields: {
        "content": []
      },
      fieldsget: {},
      errors: {},
      typeProgram: null,
      selectType: null,
      isStatus: '',
      status: '',
      nameImage: '-',
      editorState: EditorState.createEmpty(),
      editorStateDes: EditorState.createEmpty(),
      length_image: 0,
      length_image_video: 0
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAddImage = this.handleSubmitAddImage.bind(this);
    this.handleSubmitAddVideo = this.handleSubmitAddVideo.bind(this);
    this.handleSubmitEditImage = this.handleSubmitEditImage.bind(this);
    this.handleSubmitEditVideo = this.handleSubmitEditVideo.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmManageContent = this.onConfirmManageContent.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onConfirmCreateContent = this.onConfirmCreateContent.bind(this);
    this.onCancel = this.onCancel.bind(this);

  }




  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getPromotionByID(params.news_id);
  }

  convertHTMLtoEditor(data) {
    const contentBlock = htmlToDraft(data);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState
  }

  getPromotionByID(news_id) {

    newsAction.getPromotionId(news_id).then(e => {
      if (e.isSuccess === true) {
        // var news_desc = this.convertHTMLtoEditor(e.data.news_description);
        var news_desc_sub = this.convertHTMLtoEditor(e.data.news_image_sub)

        this.setState({
          fields: {
            ...this.state.fields,
            newsTitle: e.data.news_title,
            newsDescription: e.data.news_description,
            newsTitle_en: e.data.news_title_en,
            newsDescription_en: e.data.news_description_en,
            newsStart: e.data.news_start,
            newsEnd: e.data.news_end,
            newsImage: e.data.news_image,
            newsImage_sub: news_desc_sub,
            isStatus: e.data.news_active,
            content: e.data.content,
            news_id: news_id,
            updated_at: e.data.updated_at,
          }
        });

        let length_image = 0
        let length_image_video = 0

        for(var i in e.data.content){
          if(e.data.content[i].promotion_type == "image"){
            length_image += 1
          }else if(e.data.content[i].promotion_type == "video"){
            length_image_video += 1
          }
        }

        this.setState({isStatus: String(this.state.fields["isStatus"]) == "1"});
        this.setState({
          status: this.state.fields["isStatus"],
          length_image: length_image,
          length_image_video: length_image_video
        });


        if(news_desc_sub != null || news_desc_sub != ""){
          var rawHtml = draftToHtml(convertToRaw(news_desc_sub.getCurrentContent()));
          this.setState({ news_desc_sub, newsImgSub: rawHtml });

          let { fields } = this.state;
          fields['newsImage_sub'] = news_desc_sub
          this.setState({ news_desc_sub, fields });
        }

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalSuccessManageContent(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirmManageContent}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestionAddContent(head, body, type) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreateContent(type)}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestionEditContent(head, body, type) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmUpdateContent(type)}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการเพิ่มข้อมูล ?");
    }
  }

  handleSubmitAddImage(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateFormAddImage('image')) {
      this.onModalQuestionAddContent("Confirm", "คุณต้องการเพิ่มข้อมูล ?", 'image');
    }
  }

  handleSubmitAddVideo(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateFormAddImage('video')) {
      this.onModalQuestionAddContent("Confirm", "คุณต้องการเพิ่มข้อมูล ?", 'video');
    }
  }

  handleSubmitEditImage(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateFormAddImage('image')) {
      this.onModalQuestionEditContent("Confirm", "คุณต้องการแก้ไขข้อมูล ?", 'image');
    }
  }

  handleSubmitEditVideo(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateFormAddImage('video')) {
      this.onModalQuestionEditContent("Confirm", "คุณต้องการแก้ไขข้อมูล ?", 'video');
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/news/promotion";
  }

  onConfirmManageContent() {
    this.setState({ modal: null });
    window.location.href = `/backoffice/news/promotion/edit/${this.state.fields['news_id']}`;
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // if (!fields["newsTitle"]) {
    //   formIsValid = false;
    //   errors["newsTitle"] = "*กรุณากรอกหัวข้อข่าวสาร (TH)";
    // }

    if (!fields["newsDescription"]) {
      formIsValid = false;
      errors["newsDescription"] = "*กรุณากรอกคำอธิบายภาพ (TH)";
    }

    // if (!fields["newsTitle_en"]) {
    //   formIsValid = false;
    //   errors["newsTitle_en"] = "*กรุณากรอกหัวข้อข่าวสาร (EN)";
    // }

    if (!fields["newsDescription_en"]) {
      formIsValid = false;
      errors["newsDescription_en"] = "*กรุณากรอกคำอธิบายภาพ (EN)";
    }

    if (!fields["newsImage"]) {
      formIsValid = false;
      errors["newsImage"] = "*กรุณาแนบไฟล์";
    }

    if (!fields["newsStart"]) {
      formIsValid = false;
      errors["newsStart"] = "*กรุณากรอกวันที่เริ่มต้นข่าวสาร";
    }

    if (!fields["newsEnd"]) {
      formIsValid = false;
      errors["newsEnd"] = "*กรุณากรอกวันที่สิ้นสุดข่าวสาร";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  validateFormAddImage(type) {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if(type == "image"){
      if (!fields["promotion_images"]) {
        formIsValid = false;
        errors["promotion_images"] = "*กรุณาแนบรูปภาพโปรโมชัน";
      }
  
      if (!fields["promotion_sort"]) {
        formIsValid = false;
        errors["promotion_sort"] = "*กรุณากรอกลำดับการแสดง";
      }
    }else if(type == "video"){
      if (!fields["video_promotion_images"]) {
        formIsValid = false;
        errors["video_promotion_images"] = "*กรุณาแนบวีดีโอโปรโมชัน";
      }

      if (!fields["video_promotion_image_video"]) {
        formIsValid = false;
        errors["video_promotion_image_video"] = "*กรุณาแนบรูปหน้าปกวีดีโอ";
      }
  
      if (!fields["video_promotion_sort"]) {
        formIsValid = false;
        errors["video_promotion_sort"] = "*กรุณากรอกลำดับการแสดง";
      }
    }


    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  validateFormAddVideo() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["video_promotion_image_video"]) {
      formIsValid = false;
      errors["video_promotion_image_video"] = "*กรุณาแนบวีดีโอโปรโมชัน";
    }

    if (!fields["video_promotion_images"]) {
      formIsValid = false;
      errors["video_promotion_images"] = "*กรุณาแนบรูปภาพโปรโมชัน";
    }

    if (!fields["video_promotion_sort"]) {
      formIsValid = false;
      errors["video_promotion_sort"] = "*กรุณากรอกลำดับการแสดง";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });
    
    var formData = new FormData();
    formData.append("news_title", this.state.fields["newsTitle"]);
    formData.append("news_description", this.state.fields["newsDescription"]);
    formData.append("news_title_en", this.state.fields["newsTitle_en"]);
    formData.append("news_description_en", this.state.fields["newsDescription_en"]);
    // formData.append("news_image", this.state.fields["newsImage"]);
    // formData.append("news_image_sub", this.state.newsImgSub);
    formData.append("news_start", this.state.fields["newsStart"]);
    formData.append("news_end", this.state.fields["newsEnd"]);
    formData.append("news_active", this.state.status);

    newsAction.updatePromotion(formData, this.state.fields["news_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onConfirmCreateContent(type) {
    this.setState({ modal: null });
    
    var formData = new FormData();
    if(type == "image"){
      var promotion_image_video = this.state.fields["promotion_image_video"]
      var promotion_images = this.state.fields["promotion_images"]
      var promotion_sort = this.state.fields["promotion_sort"]
    }else if(type == "video"){
      var promotion_image_video = this.state.fields["video_promotion_image_video"]
      var promotion_images = this.state.fields["video_promotion_images"]
      var promotion_sort = this.state.fields["video_promotion_sort"]

    }
    formData.append("news_id", this.state.fields["news_id"]);
    formData.append("promotion_image_video", promotion_image_video);
    formData.append("promotion_images", promotion_images);
    formData.append("promotion_type", type);
    formData.append("promotion_sort", promotion_sort);

    newsAction.insertContent(formData)
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccessManageContent("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onConfirmUpdateContent(type) {
    this.setState({ modal: null });
    
    var formData = new FormData();
    if(type == "image"){
      var promotion_image_video = this.state.fields["promotion_image_video"]
      var promotion_images = this.state.fields["promotion_images"]
      var promotion_sort = this.state.fields["promotion_sort"]
    }else if(type == "video"){
      var promotion_image_video = this.state.fields["video_promotion_image_video"]
      var promotion_images = this.state.fields["video_promotion_images"]
      var promotion_sort = this.state.fields["video_promotion_sort"]

    }
    formData.append("promotion_image_video", promotion_image_video);
    formData.append("promotion_images", promotion_images);
    formData.append("promotion_sort", promotion_sort);

    console.log(this.state.fields);

    newsAction.updateContent(this.state.fields['promotion_content_id'], formData)
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccessManageContent("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  handleClickDelete = (id) => {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title="Delete"
        onConfirm={() => this.onConfirmDelete(id)}
        onCancel={() => this.onCancel()}
      >
        คุณต้องการลบข้อมูลนี้หรือไม่ ?
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
      let { fields, errors } = this.state;
      fields[e.target.name] = e.target.value;
      this.state.errors[e.target.name] = null;
      this.setState({ fields, errors });
  };

  onChangeUploadHandler = e => {

    
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleInputChange = e => {

  const target = e.target;
  const name = target.name;

  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })

    if(this.state.isStatus == true){
      this.setState({ status: '0' })
    }else{
      this.setState({ status: '1' })
    }

  }
}

handleClickAdd = (type) => {
  this.setState({
    typeManage:"add",
    fields: {
      ...this.state.fields,
      promotion_sort: "",
      video_promotion_sort: "",
      promotion_image_video: "",
      promotion_images: ""
    }
  })
  if(type == 'image'){
    window.$('#ModalAddImage').modal('show')
  }else if(type == 'video'){
    window.$('#ModalAddvideo').modal('show')
  }
};

handleClickEdit = (content_id, type) => {
  newsAction.getContent(content_id)
  .then(e => {

    if (e.isSuccess === true) {
      this.setState({
        typeManage:"edit",
        fields: {
          ...this.state.fields,
          promotion_content_id: e.data[0].promotion_content_id,
          news_id: e.data[0].news_id,
          promotion_image_video: e.data[0].promotion_image_video,
          promotion_images: e.data[0].promotion_images,
          promotion_type: e.data[0].promotion_type,
          promotion_sort: e.data[0].promotion_sort,
          video_promotion_images: e.data[0].promotion_images,
          video_promotion_sort: e.data[0].promotion_sort,
          promotion_images_old: e.data[0].promotion_images,
          video_promotion_image_video: e.data[0].promotion_image_video,
          promotion_image_video_old: e.data[0].promotion_image_video
        }
      });


      if(type == 'image'){
        window.$('#ModalAddImage').modal('show')
      }else if(type == 'video'){
        window.$('#ModalAddvideo').modal('show')
      }


    } else {
      this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
    }
  });
};

onConfirmDelete = (content_id) => {
  newsAction.deleteContent(content_id)
    .then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccessManageContent("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
};


  onEditorStateChange = editorStateDes => {
    var rawHtml = draftToHtml(convertToRaw(editorStateDes.getCurrentContent()));
    this.setState({ editorStateDes, newsDescription: rawHtml });
    let { fields } = this.state;
    fields['newsDescription'] = editorStateDes
    this.setState({ editorStateDes, fields });
  };

  onSubEditorStateChange = (editorState) => {
    var rawHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({ editorState, newsImgSub: rawHtml });

    let { fields } = this.state;
    fields['newsImage_sub'] = editorState
    this.setState({ editorState, fields });
  };

  render() {
    function uploadImageCallBack(file) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
        xhr.open("POST", `${Base_API.upload_img}/image/uploads`);
        const data = new FormData(); // eslint-disable-line no-undef
        data.append("image", file);
        xhr.send(data);
        xhr.addEventListener("load", () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener("error", () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      });
    }

    let listContent_image = this.state.fields["content"].map(( item, content ) => {
      return (
        (item.promotion_type == "image" 
          ? 
            <div className="row">
              <div className="col-lg-6 col-md-6 my-3 text-right">
                <img
                  width="100" height="100"
                  className="preview-img-promotion"
                  src={item.promotion_images}
                />
              </div>
              <div className="col-lg-6 content-button text-left">
              <input
                type="button"
                className={`btn btn-warning`}
                onClick={()=>this.handleClickEdit(item.promotion_content_id,'image')}
                value="แก้ไข"
              />
              &nbsp;&nbsp;
              <input
                type="button"
                className={`btn btn-danget`}
                onClick={()=>this.handleClickDelete(item.promotion_content_id)}
                value="ลบ"
              />
              </div>
            </div>
          : ""
          )
      );
    })

    let listContent_image_video = this.state.fields["content"].map(( item, content ) => {
      return (
        (item.promotion_type == "video" 
          ? 
          <div>
            <div className="row">
              <div className="col-lg-6 col-md-6 my-3 text-right">
                <video className="preview-img-promotion" controls="controls" poster="image" preload="true">
                  <source src={item.promotion_images} type="video/mp4" />
                </video>
              </div>
              <div className="col-lg-6 content-button text-left">
                <input
                  type="button"
                  className={`btn btn-warning`}
                  onClick={()=>this.handleClickEdit(item.promotion_content_id,'video')}
                  value="แก้ไข"
                />
                &nbsp;&nbsp;
                <input
                  type="button"
                  className={`btn btn-danget`}
                  onClick={()=>this.handleClickDelete(item.promotion_content_id)}
                  value="ลบ"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 my-3 text-right">
                <img
                  width="100" height="100"
                  className="preview-img-promotion"
                  src={item.promotion_image_video}
                />
              </div>
            </div>
          </div>
            
          : ""
          )
      );
    })

    console.log(this.state);
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขข่าวสารโปรโมชั่น</h4>
          </div>

          <div className="panel-body">            
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                
                <div className="col-lg-12 text-center">
                  รูปภาพ <span className="text-danger">(.jpg สูงสุด 4 รูป)</span>
                </div>
                {listContent_image}
                <div className="text-center my-4">
                  <input
                    type="button"
                    className={`btn btn-default btn-add-image` + (this.state.length_image >= 4 ? "btn-disabled disabled" : "")}
                    value="เพิ่มรูปภาพโปรโมชัน"
                    onClick={()=>this.handleClickAdd('image')}
                  />
                </div>
                <div className="col-lg-12 text-center">
                  วีดีโอ <span className="text-danger">(.mp4 สูงสุด 1 คลิป)</span>
                </div>
                {listContent_image_video}
                <div className="text-center my-4">
                  <input
                    type="button"
                    className={`btn btn-default btn-add-image` + (this.state.length_image_video >= 1 ? "btn-disabled disabled" : "")}
                    value="เพิ่มวีดีโอข่าวสาร"
                    onClick={()=>this.handleClickAdd('video')}
                  />
                </div>
                {/* <div className="form-group">
                  <label className="control-label col-lg-2">หัวข้อข่าวสาร (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="newsTitle"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหัวข้อข่าวสาร (TH)"
                      value={this.state.fields["newsTitle"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsTitle}</div>
                  </div>
                </div> */}

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">หัวข้อข่าวสาร (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="newsTitle_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหัวข้อข่าวสาร (EN)"
                      value={this.state.fields["newsTitle_en"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsTitle_en}</div>
                  </div>
                </div> */}

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">รูปภาพโปรโมชัน
                    <div className="text-danger"> (500 x 500 px)</div>
                  </label>
                  <div className="col-lg-10">
                    <input
                      type="file"
                      className="file-input"
                      name="newsImage"
                      onChange={this.onChangeUploadHandler}
                      data-allowed-file-extensions='["jpg", "jpeg", "png"]'
                    />
                    <div className="errorMsg">
                      {this.state.errors.newsImage}
                    </div>
                  </div>
                </div> */}

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">รูปภาพข่าวสาร</label>
                  <div className="col-lg-10">
                  <input
                    type="file"
                    className="file-input"
                    name="newsImage"
                    onChange={this.onChangeUploadHandler}/>
                  <div className="errorMsg">{this.state.errors.newsImage}</div>
                  </div>
                </div> */}

                <hr />

                <div className="form-group">
                  <label className="control-label col-lg-2">คำอธิบายภาพ (TH)</label>
                  <div className="col-lg-10">

                  <textarea
                  name="newsDescription"
                  rows={6}
                  className="form-control"
                  placeholder="กรอกคำอธิบายภาพ (TH)"
                  value={this.state.fields["newsDescription"] || ""}
                  onChange={this.handleChange}
                  />

                  {/*
                    <Editor
                      editorState={this.state.fields['newsDescription']}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                          uploadCallback: uploadImageCallBack,
                          previewImage: true,
                          alt: { present: true, mandatory: true }
                        }
                      }}
                      onEditorStateChange={this.onEditorStateChange}
                    />

                  */}
                    <div className="errorMsg">{this.state.errors.newsDescription}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">คำอธิบายภาพ (EN)</label>
                  <div className="col-lg-10">
                  <textarea
                      className="form-control"
                      rows={6}
                      name="newsDescription_en"
                      placeholder="คำอธิบายภาพ (EN)"
                      value={this.state.fields["newsDescription_en"] || ""}
                      onChange={this.handleChange}
                  >
                  </textarea>
                    <div className="errorMsg">{this.state.errors.newsDescription_en}</div>
                  </div>
                </div>

                {/* <div className="form-group">
                <label className="control-label col-lg-2">รูปรายละเอียดข่าวสารโปรโมชั่น</label>
                  <div className="col-lg-10">
                  <Editor
                      editorState={this.state.fields['newsImage_sub']}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'image', 'remove', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                          uploadCallback: uploadImageCallBack,
                          previewImage: true,
                          alt: { present: true, mandatory: true }
                        }
                      }}
                      onEditorStateChange={this.onSubEditorStateChange}
                    />
                  <div className="errorMsg">{this.state.errors.newsImage_sub}</div>
                  </div>
                </div> */}

                <div className="form-group">
                <label className="control-label col-lg-2">รูปรายละเอียดข่าวสารโปรโมชั่น</label>
                  <div className="col-lg-10">
                  <Editor
                      editorState={this.state.fields['newsImage_sub']}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'image', 'remove', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                          uploadCallback: uploadImageCallBack,
                          previewImage: true,
                          alt: { present: true, mandatory: true }
                        }
                      }}
                      onEditorStateChange={this.onSubEditorStateChange}
                    />
                  <div className="errorMsg">{this.state.errors.newsImage_sub}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">วันที่เริ่มต้น (เดือน/วัน/ปี)</label>
                  <div className="col-lg-4">
                  <input
                      name="newsStart"
                      type="date"
                      data-date-format="DD-MM-YYYY"
                      className="form-control"
                      value={this.state.fields["newsStart"] || ""}
                      placeholder="Please input Date Start"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsStart}</div>
                  </div>

                  <label className="control-label col-lg-2">วันที่สิ้นสุด (เดือน/วัน/ปี)</label>
                  <div className="col-lg-4">
                  <input
                      name="newsEnd"
                      type="date"
                      data-date-format="DD-MM-YYYY"
                      className="form-control"
                      value={this.state.fields["newsEnd"] || ""}
                      placeholder="Please input Date End"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsEnd}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">อัพเดตล่าสุดเมื่อ </label>
                  <label className="control-label col-lg-5">{this.state.fields.updated_at} </label>
                </div>


                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}

{/* modelAddImage */}
        <div class="modal fade" id="ModalAddImage" tabindex="-1" role="dialog" aria-labelledby="ModalAddImageLabel">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="ModalAddImageLabel">เพิ่มรูปภาพโปรโมชัน</h4>
              </div>
              <div class="modal-body">
                {this.state.typeManage == "edit" ?
                  <div className="row">
                  <div class="col-md-12 text-center mb-3">
                    <img
                      width="100" height="100"
                      className="preview-img-promotion"
                      src={this.state.fields['promotion_images_old']}
                    />
                  </div>
                </div> :
                ""
                }
                
                <div className="row mb-3">

                  <div class="col-md-3">
                    รูปภาพ <span className="text-danger">(.jpg) (500 x 500 px)</span>
                  </div>
                  <div class="col-md-8">
                    <input
                      type="file"
                      className="file-input"
                      name="promotion_images"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">
                      {this.state.errors.promotion_images}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-md-3">
                    ลำดับการแสดงผล
                  </div>
                  <div class="col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      name="promotion_sort"
                      onChange={this.handleChange}
                      pattern="[0-9]*"
                      value={this.state.fields['promotion_sort']}
                    />
                    <div className="errorMsg">
                      {this.state.errors.promotion_sort}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                {this.state.typeManage == "add"
                  ? <button type="button" class="btn btn-primary" onClick={this.handleSubmitAddImage}>บันทึก</button>
                  : <button type="button" class="btn btn-primary" onClick={this.handleSubmitEditImage}>อัพเดต</button>
                  }
              </div>
            </div>
          </div>
        </div>

{/* modelAddVideo */}
        <div class="modal fade" id="ModalAddvideo" tabindex="-1" role="dialog" aria-labelledby="areaModalAddvideo">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="areaModalAddvideo">เพิ่มวีดีโอโปรโมชัน</h4>
              </div>
              <div class="modal-body">
                {this.state.typeManage == "edit" ?
                    <div className="row">
                    <div class="col-md-12 text-center mb-3">
                      <video className="preview-img-promotion" controls="controls" poster="image" preload="true">
                        <source src={this.state.fields["promotion_images"]} type="video/mp4" />
                      </video>
                    </div>
                  </div> :
                  ""
                }
                <div className="row mb-3">
                  <div class="col-md-3">
                    วีดีโอ <span className="text-danger">(.mp4) (500 x 500 px)</span>
                  </div>
                  <div class="col-md-8">
                    <input
                      type="file"
                      className="file-input"
                      name="video_promotion_images"
                      onChange={this.onChangeUploadHandler}

                    />
                    <div className="errorMsg">
                      {this.state.errors.video_promotion_images}
                    </div>
                  </div>
                </div>

                {this.state.typeManage == "edit" ?
                  <div className="row">
                    <div class="col-md-12 text-center mb-3">
                      <img
                        width="100" height="100"
                        className="preview-img-promotion"
                        src={this.state.fields['promotion_image_video_old']}
                      />
                    </div>
                  </div> :
                  ""
                }

                <div className="row mb-3">
                  <div class="col-md-3">
                    รูปภาพหน้าปกวีดีโอสารโปรโมชั่น <span className="text-danger">(.jpg) (500 x 500 px)</span>
                  </div>
                  <div class="col-md-8">
                    <input
                      type="file"
                      className="file-input"
                      name="video_promotion_image_video"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">
                      {this.state.errors.video_promotion_image_video}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-md-3">
                    ลำดับการแสดงผล
                  </div>
                  <div class="col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      name="video_promotion_sort"
                      onChange={this.handleChange}
                      pattern="[0-9]*"
                      value={this.state.fields["video_promotion_sort"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors.video_promotion_sort}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              {this.state.typeManage == "add"
                  ? <button type="button" class="btn btn-primary" onClick={this.handleSubmitAddVideo}>บันทึก</button>
                  : <button type="button" class="btn btn-primary" onClick={this.handleSubmitEditVideo}>อัพเดต</button>
                  }              
              </div>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}
