import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../ewallet.css";

$.DataTable = require("datatables.net");

export class List_Problem_Ewallet_Title extends React.Component {
  constructor(props, context) {
    super(props, context);


    this.state = {

    };
  }

  componentDidMount() {
    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "problem_ewallet_title_id"
      },
      {
        title: "หัวข้อ (TH)",
        class: "text-center",
        data: "problem_ewallet_title_name_th"
      },
      {
        title: "หัวข้อ (EN)",
        class: "text-center",
        data: "problem_ewallet_title_name_en"
      },
      {
        title: "วันที่แก้ไข",
        class: "text-center",
        data: "updated_at"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "problem_ewallet_title_id"
      }

    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          },
          orderable: false
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-left">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
             .html(`<div class="text-left">${cellData}</div>`);
            },
          orderable: true
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                    <div class="list-icons">
                      <div class="dropdown">
                        <a href="#" class="list-icons-item" data-toggle="dropdown">
                          <i class="icon-menu9"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="/backoffice/ewallet/problem/title/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                        </div>
                      </div>
                    </div>
                  `);

                    // <div class="text-center" width="20%">
                    // <a href="#"><button type='button' name='sent_btn' data-content='${cellData}' class='btn btn-success sent-massage'> <img src="../images/bell_icon.png" class="news_bell" title="คลิกเพื่อส่งข่าวสารนี้"/> แจ้งเตือน</button></a>
                    // <a href="/news/news/edit/${cellData}"><button type='button' name='edit_btn' data-content='' class='btn btn-edit'>แก้ไข</button></a>
                    //

                    // <button type='button' name='delete_btn' data-content='${cellData}' class='btn btn-danger delete_btn'>ลบ</button></div>
          },
          orderable: false
        }
    
      ],
      ajax: {
        url: `${Base_API.ewallet}/api/v1/problem_ewallet_title`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[3, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

  }


  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการหัวข้อแจ้งปัญหาการใช้งาน</h4>
            <div className="heading-elements">
              <a href="/backoffice/ewallet/problem/title/create">
                <button
                  type="button"
                  name="add_btn"
                  data-content=""
                  className="btn btn-add"
                >
                  เพิ่มหัวข้อแจ้งปัญหาการใช้งาน
                </button>
              </a>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
