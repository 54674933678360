import React from "react";
import "./home.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { adminAction } from "../../_actions";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import BootstrapTable from 'react-bootstrap-table-next';
// import 'react-tabs/style/react-tabs.css';

let modalAlert;
var displayChart = ''

var newDate = new Date()
var month_short = newDate.getMonth() + 1
var month = (month_short <= 9 ? "0"+(month_short) : month_short);
var year = newDate.getFullYear();

var datenow = `${year}-${month}`

const columns = [
  {
    dataField: 'id',
    text: 'จัดอันดับ',
    headerAlign: 'center',
    align: 'center'
  }, {
    dataField: 'pr_name',
    text: 'ชื่อสินค้า',
    headerAlign: 'center'
  }, {
    dataField: 'product_count',
    text: 'จำนวนยอดขาย (ชิ้น)',
    headerAlign: 'center',
    align: 'center'
  }
];

const month_name = ['มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน','กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤษจิกายน','ธันวาคม']

const productsAll = [
  {
    id: '1',
    pr_name: 'Boba Caramel Cream Tea',
    sum_product: 1332
  },{
    id: '2',
    pr_name: 'AfterU Drink',
    sum_product: 1109
  },{
    id: '3',
    pr_name: 'Shibuya Honey Syrup',
    sum_product: 980
  },{
    id: '4',
    pr_name: 'Fresh Strawberry',
    sum_product: 567
  },
  {
    id: '5',
    pr_name: 'Banana Pancake',
    sum_product: 489
  },{
    id: '6',
    pr_name: 'Baby horlicks kakigori',
    sum_product: 457
  },{
    id: '7',
    pr_name: 'Figgy Cake',
    sum_product: 411
  },{
    id: '8',
    pr_name: 'Baby milo kakigori',
    sum_product: 387
  },{
    id: '9',
    pr_name: 'Strawberry Cheesecake Kakigori',
    sum_product: 299
  },{
    id: '10',
    pr_name: 'Thai Tea Kakigori',
    sum_product: 212
  }
];


class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,

      show: false,
      modal: null,
      modalOption: {},
      reload:true,
      topEatin: ""
    };
    

  }

  componentDidMount() {

    adminAction.getTop10(datenow).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          topEatin: e.data.topEatin,
          topTakehome: e.data.topTakehome,
          topOnline: e.data.topOnline,

        });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });

    this.getCalc();
    this.getTop10(datenow);
    // document.body.style.backgroundColor = "#000000";
    // document.body.style.backgroundImage = 'linear-gradient(rgba(0,0,0), rgba(0,0,0,.5)),url(../../images/afteryou_bg.png)';
    // document.body.style.backgroundRepeat = 'no-repeat';
    // document.body.style.backgroundSize = '100%';
    // document.body.style.backgroundPosition = 'center';
  }

  getCalc() {
    adminAction.getCalc().then(e => {
      if (e.isSuccess === true) {
        this.setState({
          countOrderAll_count: e.data.countOrderAll.count,
          countOrderAll_total_price: e.data.countOrderAll.total_price,
          countOnline_count: e.data.countPreorder.count,
          countOnline_total_price: e.data.countPreorder.total_price,
          countEatin_count: e.data.countEatin.count,
          countEatin_total_price: e.data.countEatin.total_price,
          countTakehome_count: e.data.countTakehome.count,
          countTakehome_total_price: e.data.countTakehome.total_price,
          countLike_count_percent: e.data.countLike.count_percent,
          countLike_count_total: e.data.countLike.count_total,
        });
        

        // this.setState({isStatus: String(this.state.fields["isStatus"]) == "1"});
        // this.setState({status: this.state.fields["isStatus"]});

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  getTop10(date_now) {
    adminAction.getTop10(date_now).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          topEatin: e.data.topEatin,
          topTakehome: e.data.topTakehome,
          topOnline: e.data.topOnline,

        });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }



  render() {
    console.log(this.state.topEatin);

    const CustomTab = ({ children }) => (
      <Tab>
        <h1>{children}</h1>
      </Tab>
    );

    CustomTab.tabsRole = 'Tab'; // Required field to use your custom Tab

    return (
      <div className="content-home">

      {/* content 1 */}
        <div className="panel panel-default panel-graph">
          <div className="row row-graph p-5">

            {/* graph */}
            <div className="col-md-6 col-graph-left">
              <div className="card">
                <div className="card-header header-elements-inline">
                  <h3 className="card-title">กราฟแสดงสถิติคำสั่งซื้อ ประจำปี {year}</h3>
                  <div className="header-elements">
                    <div className="list-icons">
                        <a className="list-icons-item" data-action="collapse"></a>
                        <a className="list-icons-item" data-action="reload"></a>
                        {/* <a className="list-icons-item" data-action="remove"></a> */}
                      </div>
                    </div>
                </div>
                <div className="card-body">
                  <div className="chart-container">
                    <div className="chart has-fixed-height" id="line_values"></div>
                  </div>
                </div>
              </div>

            </div>

            {/* ตารางสถิติคำสั่งซื้อ */}
            <div className="col-md-6 col-graph-right">
              <div className="panel panel-flat">
                <div className="row mb-5">
                  <div className="col-md-12 text-center">
                    <input className="btn btn-home" type="button" value={"สถิติคำสั่งซื้อประจำเดือน "+month_name[month_short - 1] }  />
                  </div>
                </div>
                <div className="row p-2">
                  <div className="col-md-6 col-sm-6">
                    <div className="text-white">รายการทั้งหมด</div>
                    <div className="text-after">{this.state.countOrderAll_count}</div>
                    <div className="text-white">ยอดขายรวม</div>
                    <div className="text-white lh-20">{this.state.countOrderAll_total_price}</div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                      <div className="text-white">รายการสั่งซื้อออนไลน์</div>
                      <div className="text-after">{this.state.countOnline_count}</div>
                      <div className="text-white">ยอดขายรวม</div>
                      <div className="text-white lh-20">{this.state.countOnline_total_price}</div>
                    </div>
                </div>
                <hr />
                <div className="row div-2">
                  <div className="col-md-6 col-sm-6">
                    <div className="text-white">รายการสั่งซื้อ Eat-in</div>
                    <div className="text-after">{this.state.countEatin_count}</div>
                    <div className="text-white">ยอดขายรวม</div>
                    <div className="text-white lh-20">{this.state.countEatin_total_price}</div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="text-white">รายการสั่งซื้อ Takehome</div>
                    <div className="text-after">{this.state.countTakehome_count}</div>
                    <div className="text-white">ยอดขายรวม</div>
                    <div className="text-white lh-20">{this.state.countTakehome_total_price}</div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
  
      {/* content 2 */}
        <div className="panel panel-default panel-report">
          {/* ภาพรวมสินค้าทั้งหมด */}
          <div>
            <div className="row p-title">
              <div className="col-md-6">
                <input className="btn btn-home" type="button" value="ภาพรวมสินค้าทั้งหมด" />
              </div>
              <div className="col-md-6 text-right">
                <input className="btn btn-home" type="button" value={"ประจำเดือน "+month_name[month_short - 1] } />
              </div>
            </div>
            <div className="row pt-2 pl-5 pb-2">
              <div className="sub-title col-md-5ths">การเข้าชม</div>
              <div className="col-md-5ths">
                <div>ยอดรวมถูกใจ</div>
                <div className="text-after">{this.state.countLike_count_percent} %</div>
                <div className="text-gray">{this.state.countLike_count_total} คน</div>
              </div>
            </div>
            <hr />
            <div className="row pt-2 pl-5 pb-2">
              <div className="sub-title col-md-5ths">การสั่งซื้อสินค้า</div>
              <div className="col-md-5ths">
                <div>ยอดสั่งซื้อสินค้ารวม</div>
                <div className="text-after">{this.state.countOrderAll_count}</div>
                <div className="text-gray">ยอดรวม {this.state.countOrderAll_total_price} บาท</div>
              </div>
              <div className="col-md-5ths">
                <div>ยอดสั่งซื้อออนไลน์</div>
                <div className="text-after">{this.state.countOnline_count}</div>
                <div className="text-gray">ยอดรวม {this.state.countOnline_total_price} บาท</div>
              </div>
              <div className="col-md-5ths">
                <div>ยอดสั่งซื้อ Eat-in</div>
                <div className="text-after">{this.state.countEatin_count}</div>
                <div className="text-gray">ยอดรวม {this.state.countEatin_total_price} บาท</div>
              </div>
              <div className="col-md-5ths">
                <div>ยอดสั่งซื้อ Takehome</div>
                <div className="text-after">{this.state.countTakehome_count}</div>
                <div className="text-gray">ยอดรวม {this.state.countTakehome_total_price} บาท</div>
              </div>
            </div>
          </div>

          {/* การจัดอันดับสินค้า */}
          <div>
            <div className="row p-title">
              <div className="col-md-6">
                <input className="btn btn-home" type="button" value="การจัดอันดับสินค้า (10 อันดับ)" />
              </div>
            </div>
            <div className="row pl-5 pr-5 pt-2 pb-2">
            <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
              <TabList>
                {/* <Tab>จากคำสั่งซื้อทั้งหมด</Tab> */}
                <Tab>จากคำสั่งชื้อพรีออเดอร์ (Eat in)</Tab>
                <Tab>จากคำสั่งชื้อพรีออเดอร์ (Takehome)</Tab>
                <Tab>จากคำสั่งชื้อออนไลน์</Tab>
              </TabList>
              {/* <TabPanel>
                <BootstrapTable
                  keyField='id'
                  data={ productsAll }
                  columns={ columns }
                  bordered={ false }
                  headerClasses="header-class"
                  striped
                  hover
                />
              </TabPanel> */}
              <TabPanel>
                <BootstrapTable
                    keyField='id'
                    data={ this.state.topEatin }
                    columns={ columns }
                    bordered={ false }
                    headerClasses="header-class"
                    striped
                    hover
                  />
              </TabPanel>
              <TabPanel>
              <BootstrapTable
                  keyField='id'
                  data={ this.state.topTakehome }
                  columns={ columns }
                  bordered={ false }
                  headerClasses="header-class"
                  striped
                  hover
                />
              </TabPanel>
              <TabPanel>
                <BootstrapTable
                  keyField='id'
                  data={ this.state.topOnline }
                  columns={ columns }
                  bordered={ false }
                  headerClasses="header-class"
                  striped
                  hover
                  />
              </TabPanel>
            </Tabs>

            </div>
          </div>

        </div>
      
      </div>
    );
  }
}

export default Home;
