import React from "react";
import { bannerAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../banner.css";

let modalAlert;

export class Banner_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      active: 'General',
      subactive: 'G-Banner',
      menu_general: 1,
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      isStatus: '',
      status: '',

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getBannerByID(params.banner_id);
  }

  getBannerByID(banner_id) {

    bannerAction.getBannerByID(banner_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            banner_name: e.data.banner_name,
            banner_image: e.data.banner_image,
            banner_image_old: e.data.banner_image,
            banner_size: e.data.banner_size,
            banner_id: banner_id
          }
        });

        // this.setState({isStatus: String(this.state.fields["isStatus"]) == "1"});
        // this.setState({status: this.state.fields["isStatus"]});


      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/banner";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["banner_name"]) {
      formIsValid = false;
      errors["banner_name"] = "*กรุณากรอกชื่อแบนเนอร์";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    formData.append("banner_name", this.state.fields["banner_name"]);
    formData.append("banner_image", this.state.fields["banner_image"]);

    bannerAction.updateBanner(formData, this.state.fields["banner_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
      let { fields, errors } = this.state;

      // if(typeof selectedOption.value != 'undefined'){
      //   fields["cate_id"] = selectedOption.value;
      //   this.state.errors["cate_id"] = null;

      //   this.setState({
      //     defaultValue:
      //       {
      //         label: selectedOption.label,
      //         value: selectedOption.value
      //       }
      //   })
      // }else {
        fields[e.target.name] = e.target.value;
        this.state.errors[e.target.name] = null;
      // }
      this.setState({ fields, errors });

  };

  handleInputChange = e => {

  const target = e.target;
  const name = target.name;

  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })

    if(this.state.isStatus == true){
      this.setState({ status: '2' })
    }else{
      this.setState({ status: '1' })
    }

  }
}

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขแบนเนอร์</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="text-center my-3">
                <img
                  className="preview-img"
                  src={this.state.fields["banner_image_old"] || ""}
                  alt={this.state.fields["banner_name"] || ""}
                />
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อแบนเนอร์</label>
                  <div className="col-lg-10">
                  <input
                      name="banner_name"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อแบนเนอร์"
                      onChange={this.handleChange}
                      value={this.state.fields["banner_name"] || ""}
                      readOnly
                    />
                    <div className="errorMsg">{this.state.errors.banner_name}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รูปภาพแบนเนอร์
                    <div className="text-danger">({this.state.fields.banner_size} px)</div>
                  </label>
                  <div className="col-lg-10">
                    <input type="file" className="file-input" name="banner_image" onChange={this.onChangeUploadHandler}/>
                    <div className="errorMsg">{this.state.errors.banner_image}</div>
                  
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
