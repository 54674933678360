import React from "react";
import { newsAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import "../news.css";


let modalAlert;
export class News_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: '',
      fields: {},
      errors: {},
      isStatus: true,
      isAutoNoti: false,
      status: 2,
      autoNotiStatus: 0,
      delete: 2,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/news/news"
  }

  validateForm() {

    let fields = this.state.fields;
    let { isAutoNoti } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["newsTitle"]) {
      formIsValid = false;
      errors["newsTitle"] = "*กรุณากรอกรายละเอียดข่าวสาร";
    }

    // if (!fields["newsDescription"]) {
    //   formIsValid = false;
    //   errors["newsDescription"] = "*กรุณากรอกรายละเอียดข่าวสาร";
    // }

    if (!fields["newsTitle_en"]) {
      formIsValid = false;
      errors["newsTitle_en"] = "*กรุณากรอกรายละเอียดข่าวสาร (EN)";
    }

    // if (!fields["newsLink"]) {
    //   formIsValid = false;
    //   errors["newsLink"] = "*กรุณากรอก Link ข่าวสาร";
    // }

    // if (!fields["newsDescription_en"]) {
    //   formIsValid = false;
    //   errors["newsDescription_en"] = "*กรุณากรอกรายละเอียดข่าวสาร (EN)";
    // }

    // if (!fields["newsStart"]) {
    //   formIsValid = false;
    //   errors["newsStart"] = "*กรุณาเลือกวันที่แจ้งเตือน";
    // }

    // if (!fields["news_image"]) {
    //   formIsValid = false;
    //   errors["news_image"] = "*กรุณาแนบไฟล์รูปภาพ";
    // }
    if (isAutoNoti) {
      if(!fields["autonoti_datetime"])
      {
        formIsValid = false;
        errors["autonoti_datetime"] = "*กรุณาระบุวันและเวลาแจ้งเตืือนอัตโนมัติ";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    var formData = new FormData();
    formData.append("news_title", this.state.fields["newsTitle"]);
    formData.append("news_description", this.state.fields["newsDescription"]);
    formData.append("news_title_en", this.state.fields["newsTitle_en"]);
    formData.append("news_link", (this.state.fields["newsLink"] ? this.state.fields["newsLink"] : ""));
    formData.append("news_description_en", this.state.fields["newsDescription_en"]);
    formData.append("news_image", this.state.fields["news_image"]);
    formData.append("news_open_type", "home");
    // formData.append("news_start", this.state.fields["newsStart"]);
    formData.append("news_active", this.state["status"]);
    formData.append("news_delete", this.state["delete"]);
    formData.append("is_autonoti", this.state.autoNotiStatus);
    formData.append("autonoti_datetime", this.state.fields["autonoti_datetime"]);

    newsAction.createNews(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
      let { fields, errors } = this.state;

      fields[e.target.name] = e.target.value;
      this.state.errors[e.target.name] = null;

      this.setState({ fields, errors });
  };

  handleInputChange = e => {
    const target = e.target;
    const name = target.name;
    let status;

    if (name == "isStatus") {
      this.setState({ isStatus: !this.state.isStatus });
      if (this.state.isStatus == true) {
        this.setState({ status: "1" });
      } else {
        this.setState({ status: "2" });
      }
    }
    if(name == "isAutoNoti"){
      this.setState({ isAutoNoti: !this.state.isAutoNoti })

      if(this.state.isAutoNoti == true){
        this.setState({ autoNotiStatus: '0' })
      }else{
        this.setState({ autoNotiStatus: '1' })
      }
    }
  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };


  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แจ้งเตือนข่าวสารทั่วไป</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2 col-md-2">
                    รูปภาพข่าวสาร
                    <div className="text-danger">(500 x 500 px)</div>
                  </label>
                  <div className="col-lg-10 col-md-10">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <input
                          type="file"
                          className="file-input"
                          name="news_image"
                          onChange={this.onChangeUploadHandler}
                        />
                        <div className="errorMsg">
                          {this.state.errors.news_image_1}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียดข่าวสาร (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="newsTitle"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหัวข้อข่าวสาร"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsTitle}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียดข่าวสาร (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="newsTitle_en"
                      type="text"
                      className="form-control"
                      placeholder="หัวข้อข่าวสาร (EN)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsTitle_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียดข่าวสาร (TH)</label>
                  <div className="col-lg-10">
                  <textarea
                      className="form-control"
                      rows={6}
                      name="newsDescription"
                      placeholder="รายละเอียดข่าวสาร (TH)"
                      onChange={this.handleChange}
                  >
                  </textarea>
                    <div className="errorMsg">{this.state.errors.newsDescription}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียดข่าวสาร (EN)</label>
                  <div className="col-lg-10">
                  <textarea
                      className="form-control"
                      rows={6}
                      name="newsDescription_en"
                      placeholder="รายละเอียดข่าวสาร (EN)"
                      onChange={this.handleChange}
                  >
                  </textarea>
                    <div className="errorMsg">{this.state.errors.newsDescription_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">หน้าแอพที่ต้องการเปิด</label>
                  <div className="col-lg-10">
                    
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">Link ข่าวสาร (ถ้ามี)<br /><span className="text-gray">(ขึ้นต้นด้วย http:// หรือ https:// Ex.https://afteryou.com)</span></label>
                  <div className="col-lg-10">
                  <input
                      name="newsLink"
                      type="text"
                      className="form-control"
                      placeholder="Link ข่าวสาร"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsLink}</div>
                  </div>
                </div>

                {/*<div className="form-group">
                  <label className="control-label col-lg-2">วันที่แจ้งเตือน (เดือน/วัน/ปี)</label>
                  <div className="col-lg-4">
                  <input
                      name="newsStart"
                      type="date"
                      data-date-format="DD-MM-YYYY"
                      className="form-control"
                      placeholder="Please input Date Start"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsStart}</div>
                  </div>
                </div>

                */}

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">
                    รูปภาพข่าวสาร
                  </label>
                  <div className="col-lg-10">
                    <input
                      type="file"
                      className="file-input"
                      name="news_image"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">
                      {this.state.errors.news_image}
                    </div>
                  </div>
                </div> */}

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="isStatus" type="checkbox" onChange={this.handleInputChange}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการแจ้งเตือนอัตโนมัติ</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isAutoNoti" type="checkbox" onChange={this.handleInputChange}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2">วันและเวลาที่แจ้งเตือน</label>
                  <div className="col-md-3">
                    <input
                      name="autonoti_datetime"
                      type="datetime-local"
                      className="form-control"
                      placeholder="วันและเวลาที่แจ้งเตือน"
                      value={ this.state.fields.autonoti_datetime}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.autonoti_datetime}</div>
                  </div>
                </div>


                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
