import React from "react";
import { Base_API } from "../../../../_constants/matcher";
import { memberAction, rewardAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../reward.css";

let modalAlert;

export class Reward_Burn_Info extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      typeProgram: null,
      selectType: null,
      inputBody: {},
      rewardid: 0,
      ddProvince: [],
      ddAmphoe: [],
      ddDistrict: [],
      ddZipcode: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);

  }

  componentDidMount() {
    var params = this.props.match.params;
    this.setState({ rewardid: params.logsid });
    this.getRewardDataByID(params.logsid);
  }

  handleChange = e => {
    let { inputBody } = this.state;
    inputBody[e.target.name] = e.target.value;
    console.log(e.target.name);
    this.setState({ inputBody });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });
    this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    const inputBody = {
      rw_tracking: this.state.inputBody["rw_tracking"],
      rw_rewardstatus: this.state.inputBody["rw_rewardstatus"]
    }

    rewardAction.updateTransaction(inputBody, this.state.inputBody["rw_transection_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onConfirm(ev) {
    this.setState({ modal: null });
    window.location.href = `/backoffice/reward/burn/info/${this.state.rewardid}`;
  }


  getRewardDataByID(id) {
    rewardAction.getLogsHistoryByID(id).then(e => {
      if (e.isSuccess === true) {
        var newObject = Object.assign(e.data, this.state.inputBody);
        // if(newObject['rw_rewardstatus'] === 'N') newObject['rw_rewardstatus'] = <strong className="text-warning">ยังไม่ได้ใช้งาน</strong>
        // else if(newObject['rw_rewardstatus'] === 'Y') newObject['rw_rewardstatus'] = <strong className="text-success">ใช้งานแล้ว</strong>
        // else if(newObject['rw_rewardstatus'] === 'C') newObject['rw_rewardstatus'] = <strong className="text-danger">ยกเลิก</strong>

        if(newObject['rw_type_receive'] === 1) newObject['rw_type_receive'] = <strong className="text-primary">แลกของรางวัล</strong>
        else if(newObject['rw_type_receive'] === 2) newObject['rw_type_receive'] = <strong className="text-primary">รับสินค้าที่ร้าน</strong>
        else if(newObject['rw_type_receive'] === 3) newObject['rw_type_receive'] = <strong className="text-primary">จัดส่งสินค้าทางไปรษณีย์</strong>
        
        console.log(newObject);

        this.setState({ inputBody: newObject });
        this.getDropdownAddress(newObject.rw_member_address, newObject.rw_member_province, newObject.rw_member_amphoe, newObject.rw_member_district, newObject.rw_member_zipcode);
      } else {
        this.onModalError("ผิดพลาด", e.message);
      }
    });
  }

  getDropdownAddress(address, province, amphoe, district, zipcode)
  {
    if(!!address)
    {
      // Get Province
      this.provinceInit()
      // Get Amphoe
      this.amphoeInit(province)
      // Get District
      this.districtInit(amphoe)
      // Get Zipcode
      this.zipcodeInit(district)
    }else
    {
      // Get Province
      this.provinceInit()
    }
  }

  provinceInit()
  {
    memberAction.getProvince()
    .then(results => {
      console.log('Province: ', results);
      this.setState({
        ddProvince: results.data
      });
    })
  }
  amphoeInit(province)
  {
    memberAction.getAmphoe(province)
    .then(results => {
      console.log('Amphoe: ', results);
      this.setState({
        ddAmphoe: results.data
      });
    })
  }
  districtInit(amphoe)
  {
    memberAction.getDistrict(amphoe)
    .then(results => {
      console.log('District: ', results);
      this.setState({
        ddDistrict: results.data
      });
    })
  }
  zipcodeInit(district)
  {
    memberAction.getZipcode(district)
    .then(results => {
      console.log('Zipcode: ', results);
      this.setState({
        ddZipcode: results.data
      });
    })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onImageError(e) {
    e.target.setAttribute(
      "src",
      `${process.env.PUBLIC_URL}/images/default.png`
    );
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChangeEditAddress(e)
  {
    const {id, value} = e.target;
    this.state.inputBody[id] = value;

    switch(id)
    {
      case "rw_member_province":
        this.setState({
          inputBody: {
            ...this.state.inputBody,
            rw_member_province: value
          }
        }); 
        this.amphoeInit(value);
      ;
      case "rw_member_amphoe":
        this.setState({
          inputBody: {
            ...this.state.inputBody,
            rw_member_amphoe: value
          }
        }); 
        this.districtInit(value);
      ;
      case "rw_member_district":
        this.setState({
          inputBody: {
            ...this.state.inputBody,
            rw_member_district: value
          }
        }); 
        this.zipcodeInit(value);
      ;
      case "rw_member_zipcode":
        this.setState({
          inputBody: {
            ...this.state.inputBody,
            rw_member_zipcode: value
          }
        }); 
      ;
      default: return;
    }
  }

  submitEditAddress()
  {
    const { inputBody } = this.state;

    let reqData = {
      rw_member_address: inputBody['rw_member_address'],
      rw_member_province: inputBody['rw_member_province'],
      rw_member_amphoe: inputBody['rw_member_amphoe'],
      rw_member_district: inputBody['rw_member_district'],
      rw_member_zipcode: inputBody['rw_member_zipcode']
    }; 
    console.log('Req Data: ', reqData);
    rewardAction.activeLogsHistoryByID(reqData, this.state.rewardid)
    .then(results => {
      if(results.isSuccess === true)
      {
        this.onModalSuccess("Success", results.message);
      }
    })
  }

  render() {
    const {ddProvince, ddAmphoe, ddDistrict, ddZipcode} = this.state;

    return (
      <div className="reward-container row">
        <form
          className="form-horizontal"
          onSubmit={e => this.handleSubmit(e)}
        >
          <div className="col-xs-4 col-sm-4 col-lg-4">
            <div className="panel panel-flat">
              <div className="panel-heading panel-heading-wline">
                <h4 className="panel-title">ข้อมูลผู้ใช้งาน</h4>
              </div>
              <div className="">
                <table className="table">
                  <thead>
                    <tr><td><strong>รหัสผู้แลกของรางวัล</strong></td><td>{this.state.inputBody['mem_number']} </td></tr>
                    <tr><td><strong>ชื่อผู้แลกของรางวัล</strong></td><td>{this.state.inputBody['mem_firstname']} {this.state.inputBody['mem_lastname']}</td></tr>
                    <tr><td><strong>เบอร์ติดต่อ</strong></td><td>{this.state.inputBody['mem_phone']}</td></tr>
                    <tr><td><strong>อีเมลติดต่อ</strong></td><td>{this.state.inputBody['mem_email']}</td></tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="panel panel-flat">
              <div className="panel-heading panel-heading-wline">
                <h4 className="panel-title">ข้อมูลการแลกรางวัล</h4>
              </div>
              <div className="">
                <table className="table">
                  <thead>
                    <tr><td><strong>รหัสออเดอร์</strong></td><td>{this.state.inputBody['rw_transection_id']} </td></tr>
                    <tr><td><strong>วันที่แลกรางวัล</strong></td><td>{this.state.inputBody['created_at']} </td></tr>
                    <tr><td><strong>ประเภทการรับสินค้า</strong></td><td>{this.state.inputBody['rw_type_receive']}</td></tr>
                    <tr><td><strong>เลข tracking</strong></td><td>
                      <input name="rw_tracking" type="text" className="form-control" value={this.state.inputBody['rw_tracking']} onChange={this.handleChange}/>
                    </td></tr>
                    <tr><td><strong>สาขาที่ต้องการรับ</strong></td><td>{(this.state.inputBody['b_name'] ? this.state.inputBody['b_name'] : '-')}</td></tr>
                    <tr><td><strong>ชื่อผู้รับ</strong></td><td>{(this.state.inputBody['rw_member_name'] ? this.state.inputBody['rw_member_name'] : '-' )}</td></tr>
                    <tr><td><strong>เบอร์โทร</strong></td><td>{(this.state.inputBody['rw_member_phone'] ? this.state.inputBody['rw_member_phone'] : '-')}</td></tr>
                    <tr>
                      <td>
                        <strong>สถานที่รับ</strong>
                      </td>
                      <td>
                        {(this.state.inputBody['rw_member_address'] ? this.state.inputBody['rw_member_address'] : '-')} {this.state.inputBody['districts_name']} {this.state.inputBody['amphoe_name']} {this.state.inputBody['provinces_name']} {(this.state.inputBody['rw_member_zipcode'] == 0 ? '' : this.state.inputBody['rw_member_zipcode'])}
                        <br />
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modaleditaddressdeli">
                          แก้ไขสถานที่รับ
                        </button>
                      </td>
                    </tr>
                  </thead>
                </table>
                <div className="row p-3">
                  <div className="col-lg-12 text-center">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-8 col-sm-8 col-lg-8">
            <div className="panel panel-flat">
              <div className="panel-heading panel-heading-wline">
                <h4 className="panel-title">ข้อมูลของรางวัลที่แลก</h4>
              </div>
              <div className="">
              <div className="">
                <table className="table">
                  <thead>
                    <tr><td><strong>รูปของรางวัล</strong></td><td><img style={{ maxHeight: '150px' }} src={this.state.inputBody['rewards_image']} alt={this.state.inputBody['rewards_name_th']} /></td></tr>
                    <tr><td><strong>รหัสของรางวัล</strong></td><td>{this.state.inputBody['rw_rewardcode']}</td></tr>
                    <tr><td><strong>รางวัลที่แลก</strong></td><td>{this.state.inputBody['rewards_name_th']}</td></tr>
                    <tr><td><strong>จำนวนรางวัลที่แลก</strong></td><td>{this.state.inputBody['rw_count']}</td></tr>
                    <tr><td><strong>ประเภทของรางวัล</strong></td><td>{this.state.inputBody['rewards_category_name']}</td></tr>
                    <tr><td><strong>คะแนนที่ใช้แลก/รางวัล</strong></td><td>{this.state.inputBody['rw_pointperunit']} P</td></tr>
                    <tr><td><strong>คะแนนรวมที่ใช้แลก</strong></td><td>{this.state.inputBody['totalPoint']} P</td></tr>
                    <tr><td><strong>รหัสของรางวัล</strong></td><td>{this.state.inputBody['rw_rewardcode']}</td></tr>
                    <tr><td><strong>สถานะการใช้รางวัล</strong></td><td>
                      <select name="rw_rewardstatus" className="form-control" value={this.state.inputBody['rw_rewardstatus']} onChange={this.handleChange}>
                        <option value="N">ยังไม่ได้ใช้งาน</option>
                        <option value="Y">ใช้งานแล้ว</option>
                        <option value="C">ยกเลิก</option>
                      </select>                      
                    </td></tr>
                    <tr><td><strong>วันที่ใช้งาน</strong></td><td>{this.state.inputBody['rw_burn_date']}</td></tr>
                    <tr><td><strong>วันหมดอายุของรางวัล</strong></td><td>{this.state.inputBody['rw_expired_at']}</td></tr>
                  </thead>
                </table>
              </div>
              </div>
            </div>
          </div>
          {this.state.modal}
        </form>

        {/* Modal Edit Address */}
        <div class="modal fade" id="modaleditaddressdeli" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="modaleditaddressdeliTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLongTitle">แก้ไขสถานที่รับ</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="mdedaddr-item">
                  <label>ที่อยู่</label>
                  <textarea 
                    onChange={(e) => this.handleChangeEditAddress(e)} 
                    className="form-control" 
                    defaultValue={this.state.inputBody["rw_member_address"]}
                    id="rw_member_address" 
                  ></textarea>
                  <label>จังหวัด</label>
                  <select 
                    className="form-control"  
                    onChange={(e) => this.handleChangeEditAddress(e)}
                    value={this.state.inputBody['rw_member_province']}
                    id="rw_member_province"
                  >
                    <option value="">เลือกจังหวัด</option>
                    {ddProvince.length > 0 && ddProvince.map(item => 
                      <option value={item.id}>{item.name_th}</option> 
                    )}    
                  </select>
                  <label>อำเภอ</label>
                  <select 
                    className="form-control"  
                    onChange={(e) => this.handleChangeEditAddress(e)}
                    value={this.state.inputBody['rw_member_amphoe']}
                    id="rw_member_amphoe"
                  >
                    <option value="">เลือกอำเภอ</option>
                    {ddAmphoe.length > 0 && ddAmphoe.map(item => 
                      <option value={item.id}>{item.name_th}</option> 
                    )}    
                  </select>
                  <label>ตำบล</label>
                  <select 
                    className="form-control"  
                    onChange={(e) => this.handleChangeEditAddress(e)}
                    value={this.state.inputBody['rw_member_district']}
                    id="rw_member_district"
                  >
                    <option value="">เลือกตำบล</option>
                    {ddDistrict.length > 0 && ddDistrict.map(item => 
                      <option value={item.id}>{item.name_th}</option> 
                    )}    
                  </select>
                  <label>รหัสไปรษณีย์</label>
                  <select
                    className="form-control"  
                    onChange={(e) => this.handleChangeEditAddress(e)}
                    value={this.state.inputBody['rw_member_zipcode']}
                    id="rw_member_zipcode"
                  >
                    <option value="">เลือกรหัสไปรษณีย์</option>
                    <option value={!!ddZipcode && ddZipcode.zip_code}>{!!ddZipcode &&ddZipcode.zip_code}</option>   
                  </select>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>
                <button onClick={() => this.submitEditAddress()} type="button" class="btn btn-primary">บันทึก</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
