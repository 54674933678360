import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import { ewalletAction } from "../../../../_actions";
import "../account.css";
import ReactExport from 'react-data-export';

$.DataTable = require("datatables.net");
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}


export class Detail_Ewallet extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fields: {},
      merchant_name: 'After You'
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    
    ewalletAction.getDetailTransaction(params.transaction_id).then(e => {
      
      this.setState({
        fields: {
          ...this.state.fields,
          transaction_id: e.data.transaction_id,
          transaction_description: e.data.transaction_description,
          transaction_amount: e.data.transaction_amount,
          fx_rate: e.data.fx_rate,
          total_amount: e.data.total_amount,
          MDR: e.data.MDR,
          transaction_fee: e.data.transaction_fee,
          VAT: e.data.VAT,
          EWT: e.data.EWT,
          transaction_status: e.data.transaction_status,
          settlement_Initiated_by: e.data.settlement_Initiated_by,
          payment_channel_name: e.data.payment_channel_name,
          mem_number: e.data.mem_number,
          cardholder_name: e.data.cardholder_name,
          cardholder_email: e.data.cardholder_email,
          approval_code: e.data.approval_code,
          transaction_date: e.data.transaction_date,
          updated_at: e.data.updated_at,
          settlement_type: e.data.settlement_type,
          branch_code: e.data.branch_code,
          selected_bank_country: e.data.selected_bank_country,
          selected_bank_name: e.data.selected_bank_name,
          system_bank_country: e.data.system_bank_country,
          system_bank_name: e.data.system_bank_name,
          cardholder_IP: e.data.cardholder_IP,
          IP_country: e.data.IP_country,
          IP_region: e.data.IP_region,
          IP_city: e.data.IP_city,
        }
      })
    })
  }


  render() {
    // console.log(parseFloat(this.state.fields.total_amount).replace(/,/g,'')));
    
    // var TextHolder = "I am a React Native Developer."
    // var NewText = TextHolder.replace("Developer", "App Builder");
    // console.log(TextHolder);
    // console.log(typeof TextHolder);
    // console.log(NewText);

    // var total_amount = this.state.fields.total_amount
    // console.log(total_amount);
    // console.log(typeof total_amount);
    // var NewTextq = total_amount.replace(",", "");
    // console.log(NewTextq);
    

    

    let net_amount = parseFloat(this.state.fields.total_amount) - (parseFloat(this.state.fields.MDR) + parseFloat(this.state.fields.transaction_fee) + parseFloat(this.state.fields.VAT) + parseFloat(this.state.fields.EWT))
    let name_excel = dd+mm+yyyy+hh+mn+ss+ms+'-transaction-detail-'+this.state.fields.transaction_id

    var dataExcel = [
      [
        {
          value: "Transaction ID",
        },
        {
          value: this.state.fields.transaction_id,
        },
      ],
      [
        {
          value: "Merchant Name",
        },
        {
          value: this.state.merchant_name
        },
      ],
      [
        {
          value: "Description"
        },
        {
          value: this.state.fields.transaction_description
        },
      ],
      [
        {
          value: "Transaction Amount"
        },
        {
          value: "THB " + this.state.fields.transaction_amount
        },
      ],
      [
        {
          value: "FX Rate"
        },
        {
          value: "THB " + this.state.fields.fx_rate
        },
      ],
      [
        {
          value: "Total Amount"
        },
        {
          value: "THB " + this.state.fields.total_amount
        },
      ],
      [
        {
          value: "MDR"
        },
        {
          value: "THB " + this.state.fields.MDR
        },
      ],
      [
        {
          value: "Transaction Fee"
        },
        {
          value: "THB " + this.state.fields.transaction_fee
        },
      ],
      [
        {
          value: "VAT"
        },
        {
          value: "THB " + this.state.fields.VAT
        },
      ],
      [
        {
          value: "EWT"
        },
        {
          value: "THB " + this.state.fields.EWT
        },
      ],
      [
        {
          value: "Net Amount"
        },
        {
          value: "THB " + this.state.fields.net_amount
        },
      ],
      [
        {
          value: "Status"
        },
        {
          value: this.state.fields.transaction_status
        },
      ],
      [
        {
          value: "Settlement Initiated By"
        },
        {
          value: this.state.fields.settlement_Initiated_by
        },
      ],
      [
        {
          value: "Payment Channel"
        },
        {
          value: this.state.fields.payment_channel_name
        },
      ],
      [
        {
          value: "Cardholder name"
        },
        {
          value: this.state.fields.cardholder_name
        },
      ],
      [
        {
          value: "Cardholder Email"
        },
        {
          value: this.state.fields.cardholder_email
        },
      ],
      [
        {
          value: "Member Number"
        },
        {
          value: this.state.fields.mem_number
        },
      ],
      [
        {
          value: "Approval Code"
        },
        {
          value: this.state.fields.approval_code
        },
      ],
      [
        {
          value: "Transaction Date/Time"
        },
        {
          value: this.state.fields.created_at
        },
      ],
      [
        {
          value: "Void/Settlement Date/Time"
        },
        {
          value: this.state.fields.updated_at
        },
      ],
      [
        {
          value: "Verified By Visa/MasterCard Secure Code"
        },
        {
          value: this.state.fields.secure_code
        },
      ],
      [
        {
          value: "Selected Bank Country"
        },
        {
          value: this.state.fields.selected_bank_country
        },
      ],
      [
        {
          value: "Selected Bank Name"
        },
        {
          value: this.state.fields.selected_bank_name
        },
      ],
      [
        {
          value: "System Bank Country"
        },
        {
          value: this.state.fields.system_bank_country
        },
      ],
      [
        {
          value: "System Bank Name"
        },
        {
          value: this.state.fields.system_bank_name
        },
      ],
      [
        {
          value: "Cardholder IP"
        },
        {
          value: this.state.fields.IP_city
        },
      ],
      [
        {
          value: "IP Country"
        },
        {
          value: this.state.fields.cardholder_IP
        },
      ],
      [
        {
          value: "IP Region"
        },
        {
          value: this.state.fields.IP_region
        },
      ],
      [
        {
          value: "IP City"
        },
        {
          value: this.state.fields.IP_city
        },
      ],
      [
        {
          value: "Message to Merchant"
        },
        {
          value: this.state.fields.transaction_comment
        },
      ],
      


    ]

    
    multiDataSet = [
      {
        columns: [
            {title: "Transaction Details", width: {wch: 40}},//pixels width 
            {title: "", width: {wch: 40}},//char width 

        ],
        data: dataExcel
      }
    ];
    return (
      <div>
        <nav className="navbar navbar-default">
          <div className="container-fluid pt-3 pl-0">
            <div className="navbar-header w-100">
              <div className="row">
                <div className="col-md-6">
                  <a href="javascript:history.back()" className="a-icon"><i className="icon-chevron-left"></i> return</a>
                </div>
                <div className="col-md-6 text-right">
                  <ExcelFile 
                    element={
                      <button
                      type="button"
                      name="add_banner_btn"
                      data-content=""
                      className="btn btn-add mr-2 mb-3"
                      >
                      <i className="icon-download"></i> Export Excel
                      </button>
                    }
                    filename = {name_excel}
                  >
                    <ExcelSheet dataSet={multiDataSet} name="Transaction-Detail" />
                  </ExcelFile>
                </div>
              </div>
            </div>
          </div>
        </nav>
 
        <div className="panel panel-ewallet">
          <div className="panel-body">
            <div>
              <h4>Transaction Details</h4>
              <div className="detail-text">
                <div className="row">
                  <div className="col-md-6 text-right">Transaction ID:</div>
                  <div className="col-md-6 text-bold">{this.state.fields.transaction_id}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Merchant Name:</div>
                  <div className="col-md-6">{this.state.merchant_name}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Description:</div>
                  <div className="col-md-6">{this.state.fields.transaction_description}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Transaction Amount:</div>
                  <div className="col-md-6">{this.state.fields.transaction_amount}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">FX Rate:</div>
                  <div className="col-md-6">{this.state.fields.fx_rate}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Total Amount:</div>
                  <div className="col-md-6">{this.state.fields.total_amount}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">MDR:</div>
                  <div className="col-md-6">{this.state.fields.MDR}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Transaction Fee:</div>
                  <div className="col-md-6">{this.state.fields.transaction_fee}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">VAT:</div>
                  <div className="col-md-6">{this.state.fields.VAT}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">EWT:</div>
                  <div className="col-md-6">{this.state.fields.EWT}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Net Amount:</div>
                  <div className="col-md-6">{Number(parseFloat(net_amount).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Status:</div>
                  <div className="col-md-6">{this.state.fields.transaction_status}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Settlement Initiated By:</div>
                  <div className="col-md-6">{this.state.fields.settlement_Initiated_by}</div>
                </div>
              </div>
              <hr className="mx-0 my-4"/>
              <div className="detail-text">
                <div className="row">
                  <div className="col-md-6 text-right">Payment Channel:</div>
                  <div className="col-md-6 text-bold">{this.state.fields.payment_channel_name}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Cardholder name:</div>
                  <div className="col-md-6">{this.state.fields.cardholder_name}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Cardholder Email:</div>
                  <div className="col-md-6">{this.state.fields.cardholder_email}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Member Number:</div>
                  <div className="col-md-6">{this.state.fields.mem_number}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Approval Code:</div>
                  <div className="col-md-6">{this.state.fields.approval_code}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Transaction Date/Time:</div>
                  <div className="col-md-6">{this.state.fields.created_date}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Void/Settlement Date/Time:</div>
                  <div className="col-md-6">{this.state.fields.updated_at}</div>
                </div>
              </div>
              <hr className="mx-0 my-4"/>
              <div className="detail-text">
                <div className="row">
                  <div className="col-md-6 text-right">Verified By Visa/MasterCard Secure Code:</div>
                  <div className="col-md-6 text-bold"></div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Selected Bank Country:</div>
                  <div className="col-md-6">{this.state.fields.selected_bank_country}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Selected Bank Name:</div>
                  <div className="col-md-6">{this.state.fields.selected_bank_name}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">System Bank Country:</div>
                  <div className="col-md-6">{this.state.fields.system_bank_country}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">System Bank Name:</div>
                  <div className="col-md-6">{this.state.fields.system_bank_name}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Cardholder IP:</div>
                  <div className="col-md-6">{this.state.fields.cardholder_IP}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">IP Country:</div>
                  <div className="col-md-6">{this.state.fields.IP_country}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">IP Region:</div>
                  <div className="col-md-6">{this.state.fields.IP_region}</div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">IP City:</div>
                  <div className="col-md-6">{this.state.fields.IP_city}</div>
                </div>
              </div>
              <hr className="mx-0 my-4"/>
              <div className="detail-text">
                <div className="row">
                  <div className="col-md-6 text-right">Fraud Response Code:</div>
                  <div className="col-md-6 text-bold"></div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">Fraud Response Description:</div>
                  <div className="col-md-6"></div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">PGW Response Code:</div>
                  <div className="col-md-6"></div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-right">PGW Response Description:</div>
                  <div className="col-md-6"></div>
                </div>
              </div>
              <hr className="mx-0 my-4"/>
              <div className="detail-text">
                <div className="row">
                  <div className="col-md-6 text-right">Message to Merchant:</div>
                  <div className="col-md-6 text-bold">{this.state.fields.transaction_comment}</div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
