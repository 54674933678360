import React from "react";
import { ewalletAction } from '../../../../_actions'
import SweetAlert from "react-bootstrap-sweetalert";
import "../../ewallet.css";

let modalAlert;

export class Edit_Problem_Ewallet_Title extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      isStatus: '',
      status: '',

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getProblemTitleByID(params.problem_ewallet_title_id);
  }

  getProblemTitleByID(id) {

    ewalletAction.getProblemEwalletTitle(id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            problem_ewallet_title_id: e.data.problem_ewallet_title_id,
            problem_ewallet_title_name_th: e.data.problem_ewallet_title_name_th,
            problem_ewallet_title_name_en: e.data.problem_ewallet_title_name_en,
            isStatus: e.data.is_active,
          }
        });
        this.setState({isStatus: String(this.state.fields["isStatus"]) == "1"});
        this.setState({status: this.state.fields["isStatus"]});
      
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/ewallet/problem/title";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["problem_ewallet_title_name_th"]) {
      formIsValid = false;
      errors["problem_ewallet_title_name_th"] = "*กรุณากรอกหัวข้อ (TH)";
    }

    if (!fields["problem_ewallet_title_name_en"]) {
      formIsValid = false;
      errors["problem_ewallet_title_name_en"] = "*กรุณากรอกหัวข้อ (EN)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });


    const inputBody = {
      problem_ewallet_title_name_th: this.state.fields["problem_ewallet_title_name_th"],
      problem_ewallet_title_name_en: this.state.fields["problem_ewallet_title_name_en"],
      is_active: this.state["status"],
      is_delete: this.state["delete"],
    }


    ewalletAction.updateProblemEwalletTitle(inputBody, this.state.fields["problem_ewallet_title_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleDateChange = date => {
    this.setState({
      birthdate: date
    });
  };

  handleInputChange = e => {

  const target = e.target;
  const name = target.name;


  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })

    if(this.state.isStatus == true){
      this.setState({ status: '2' })
    }else{
      this.setState({ status: '1' })
    }
  }
}

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };


  render() {

    const {

    } = this.state;

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขหัวข้อแจ้งปัญหาการใช้งาน</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >

              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-1">หัวข้อ (TH)</label>
                  <div className="col-lg-5">
                    <input
                      name="problem_ewallet_title_name_th"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหัวข้อ (TH)"
                      value={this.state.fields["problem_ewallet_title_name_th"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.problem_ewallet_title_name_th}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-1">หัวข้อ (EN)</label>
                  <div className="col-lg-5">
                    <input
                      name="problem_ewallet_title_name_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหัวข้อ (EN)"
                      value={this.state.fields["problem_ewallet_title_name_en"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.problem_ewallet_title_name_en}</div>
                  </div>
                </div>


                <div className="form-group">
                  <label className="control-label col-lg-1">สถานะการใช้งาน</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>


                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
