import React from 'react';
import $ from "jquery";
import { activityAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../activity.css";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone"

var urlAU = ""
const urlPath = window.location.hostname
if (urlPath === "https://podsable.com" || urlPath === "podsable.com") {
  // urlAU = "/afteryou"  
}

$.DataTable = require("datatables.net");
let modalAlert;

export class DiscountDelivery_List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      modal: null,
      isModal: false,
      activity_id: "1",
      fields: {}
    };
  }

  componentDidMount() {
    const columns = [
      {
        title: "ราคาที่ซื้อขั้นต่ำ",
        class: "text-center",
        data: "total_amount_product_au"
      },
      {
        title: "ระยะทางขั้นต่ำ",
        class: "text-center",
        data: "distance_min_delivery_free"
      },
      {
        title: "ระยะทางสูงสุด",
        class: "text-center",
        data: "distance_max_delivery_free"
      },
      {
        title: "สถานะส่งฟรี",
        class: "text-center",
        data: "status_delivery_cost_free"
      },
      {
        title: "ส่วนลดค่าส่ง",
        class: "text-center",
        data: "discount_delivery_cost"
      },
      {
        title: "วันเวลาเริ่มต้น",
        class: "text-center",
        data: "discount_delivery_time_start"
      },
      {
        title: "วันเวลาสิ้นสุด",
        class: "text-center",
        data: "discount_delivery_time_end"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "discount_delivery_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [ 
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div>
                ${cellData===1 ? "<i class='fa fa-check-circle' style='color:green !important;'></i>" : "<i class='fa fa-times-circle' style='color:red !important;'></i>"}
                </div>
              `);

          },
          orderable: false
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(!!cellData ? moment(cellData).format('DD-MM-Y HH:mm') : '-')
          },
          orderable: false
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(!!cellData ? moment(cellData).format('DD-MM-Y HH:mm') : '-')
          },
          orderable: false
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/activity/discount_delivery/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                      <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                    </div>
                  </div>
                </div>
              `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.order_online}/api/DiscountDelivery/list`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "ASC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(id) {
      activityAction.DiscountDeliveryDelete(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
           onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  onCancel(ev) {
    this.setState({ modal: null });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onReload}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">ข้อมูลกิจกรรม Discount Delivery</h4>
            <div className="heading-elements">
              <NavLink to={urlAU + "/backoffice/activity/discount_delivery/create"}>
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add"
                >
                  เพิ่มรายการ
                </button>
              </NavLink>

              {/* <a href="">
                
              </a> */}
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
