import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import { memberAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../ewallet.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd+mm+yyyy+hh+mn+ss+ms+'-problem'

$.DataTable = require("datatables.net");

export class List_Problem_Ewallet extends React.Component {
  constructor(props, context) {
    super(props, context);


    this.state = {
      photoIndex: 0,
      isOpen: false,
      modal: null,
      preview_image: ""
    };
  }

  componentDidMount() {
    memberAction.exportProblemList().then(res => {
      this.setState({
          dataExport: res.data
      })
    })

    const columns = [
      {
        title: "วันที่แจ้งปัญหา",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "รูปภาพ",
        class: "text-center",
        data: "problem_ewallet_image"
      },
      {
        title: "e-mail ผู้แจ้ง",
        class: "text-center",
        data: "mem_email"
      },
      {
        title: "เบอร์โทรศัพท์",
        class: "text-center",
        data: "mem_phone"
      },
      {
        title: "หัวข้อ",
        class: "text-center",
        data: "problem_ewallet_title_name"
      },
      {
        title: "รายละเอียด",
        class: "text-center text-long-title",
        data: "problem_ewallet_description"
      },
      {
        title: "สถานะ",
        class: "text-center",
        data: "status_problem"
      },
      // {
      //   title: "",
      //   class: "text-center",
      //   data: "problem_ewallet_id"
      // }

    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [

        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="container-img"><img class='img_thumbnail previewimg' data-id="${cellData}" src=${cellData} alt=${rowData.problem_ewallet_title_name}> 
            <div class="middle"><i class="icon-search4"></i>
            </div></div>
            `);
          },
          orderable: false
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
             .html(`<div class="text-center">${cellData}</div>`);
            },
          orderable: false
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
             .html(`<div class="text-center">${!!cellData ? '+66 '+cellData : 'ไม่พบข้อมูล'}</div>`);
            },
          orderable: false
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            console.log(cellData)
            $(td)
             .html(`<div class="text-center"><div class="status-wrap"><p class="${cellData===0 ? 'statustext inactive' : 'statustext active'}">${cellData===0 ? 'ยังไม่ได้รับการแก้ไข' : 'แก้ไขแล้ว'}</p><input name="update-status" ${cellData===0 ?null : 'checked'} data-id='${rowData.problem_ewallet_id}' type="checkbox" /></div></div>`);
            },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.member}/api/v1/problem`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
      
    });

    var state = this;

    $(this.refs.main).on("click", ".previewimg", function () {
      let preview_image = $(this).data("id");

      state.setState({ 
        isOpen: true ,
        preview_image: preview_image
      })
    });

    $(this.refs.main).on("click", 'input[name="update-status"]', function (e) {
      e.target.checked = !e.target.checked; 
      let problem_id = $(this).data("id");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmUpdateStatus(problem_id, !e.target.checked)}
          onCancel={() => onConfirm()}
        >
          คุณต้องอัพเดตสถานะนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmUpdateStatus(id, status) {

      let formData = {
        "status_problem": status===true ? 1 : 0
      };

      memberAction.updateProblemEwallet(id, formData).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    var dataExport = []
    let data = this.state.dataExport   
    for(var i in data){
      dataExport.push(
        [
          {value: parseInt(i)+1},
          {value: data[i].created_at},
          {value: (data[i].problem_ewallet_image ? data[i].problem_ewallet_image : "")},
          {value: (data[i].mem_email ? data[i].mem_email : "")},
          {value: data[i].problem_ewallet_title_name},
          {value: data[i].problem_ewallet_description}
        ],
      )
    }    
    
    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "วันที่แจ้งปัญหา", width: {wch: 40}},//char width 
              {title: "รูปภาพ", width: {wch: 20}},
              {title: "e-mail ผู้แจ้ง", width: {wch: 20}},
              {title: "คะแนนคงเหลือ", width: {wch: 15}},
              {title: "วันเวลาที่ลงทะเบียน", width: {wch: 15}},
              {title: "สถานะ"},
          ],
          data: dataExport
          
      }
    ];
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการแจ้งปัญหาการใช้งาน</h4>
            <div className="heading-elements">
            <ExcelFile 
                element={
                  <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                  >
                  <i className="icon-download"></i> Export Excel
                  </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Member" />
              </ExcelFile>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={this.state.preview_image}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
        {this.state.modal}
      </div>
    );
  }
}
