import React from "react";
import { activityAction } from "../../../../../_actions";
import { Base_API } from "../../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../../activity.css";
import Select from 'react-select';

let modalAlert;

export class Flashsale_Time_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: '',
      fields: {
        is_active: 0,
        is_specific_time: 0
      },
      errors: {},
      orderType: [],
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    activityAction.listOrderType().then(res => {
      this.setState({
          orderType: res.data
      })
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/activity/flashsale/time"
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["flashsale_name_th"]) {
      formIsValid = false;
      errors["flashsale_name_th"] = "*กรุณากรอกชื่อเริ่มกิจกรรม (TH)";
    }
    if (!fields["flashsale_name_en"]) {
      formIsValid = false;
      errors["flashsale_name_en"] = "*กรุณากรอกชื่อเริ่มกิจกรรม (EN)";
    }

    if (!fields["flashsale_time_datetime_start"]) {
      formIsValid = false;
      errors["flashsale_time_datetime_start"] = "*กรุณากรอกเวลาที่เริ่มกิจกรรม";
    }

    if (!fields["flashsale_time_datetime_end"]) {
      formIsValid = false;
      errors["flashsale_time_datetime_end"] = "*กรุณากรอกเวลาที่สิ้นสุดกิจกรรม";
    }
    
    if(fields["is_specific_time"] === 1)
    {
      if (!fields["flashsale_specific_time_start"]) {
        formIsValid = false;
        errors["flashsale_specific_time_start"] = "*กรุณากรอกเวลาเริ่มต้นการแสดงสินค้า";
      }
  
      if (!fields["flashsale_specific_time_end"]) {
        formIsValid = false;
        errors["flashsale_specific_time_end"] = "*กรุณากรอกเวลาสิ้นสุดการแสดงสินค้า";
      }
    }

    if (!fields["typeorder_id"]) {
      formIsValid = false;
      errors["typeorder_id"] = "*กรุณาเลือกหมวดหมู่ออเดอร์";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    let inputBody = {
      flashsale_time_datetime_start: this.state.fields["flashsale_time_datetime_start"],
      flashsale_time_datetime_end: this.state.fields["flashsale_time_datetime_end"],
      flashsale_specific_time_start: this.state.fields["flashsale_specific_time_start"],
      flashsale_specific_time_end: this.state.fields["flashsale_specific_time_end"],
      typeorder_id: this.state.fields["typeorder_id"],
      is_active: this.state.fields["is_active"],
      is_specific_time: this.state.fields["is_specific_time"],
      flashsale_name_en: this.state.fields["flashsale_name_en"],
      flashsale_name_th: this.state.fields["flashsale_name_th"]
    }
    activityAction.flashsaleInsertTimeV2(inputBody).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })

    if(name === "is_specific_time")
    {
      if(e.target.checked === false)
      {
        fields.flashsale_specific_time_start = ""
        fields.flashsale_specific_time_end = ""
      }
    }
  }

  render() {
    let optionsTypeOrder = this.state.orderType.map(function (ordertype) {
      return { value: ordertype.typeorder_id, label: ordertype.typeorder_name };
    })

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มเวลา Flash Sale</h4>
          </div>
          <br />
          <div className="panel-body">
            
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>

            <div className="form-group">
                <label className="control-label col-md-2">ชื่อกิจกรรม (TH)</label>
                <div className="col-md-3">
                  <input
                    name="flashsale_name_th"
                    type="text"
                    className="form-control"
                    placeholder="ชื่อกิจกรรม (TH)"
                    value={this.state.fields.flashsale_name_th || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.flashsale_name_th}</div>
                </div>

                <label className="control-label col-md-2">ชื่อกิจกรรม (EN)</label>
                <div className="col-md-3">
                  <input
                    name="flashsale_name_en"
                    type="text"
                    className="form-control"
                    placeholder="ชื่อกิจกรรม (EN)"
                    value={this.state.fields.flashsale_name_en || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.flashsale_name_en}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-2">วันที่เริ่มกิจกรรม</label>
                <div className="col-md-3">
                  <input
                    name="flashsale_time_datetime_start"
                    type="datetime-local"
                    className="form-control"
                    placeholder="กรอกวันที่เริ่มกิจกรรม"
                    value={this.state.fields.flashsale_time_datetime_start || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.flashsale_time_datetime_start}</div>
                </div>

                <label className="control-label col-md-2">วันที่สิ้นสุดกิจกรรม</label>
                <div className="col-md-3">
                  <input
                    name="flashsale_time_datetime_end"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่สิ้นสุดกิจกรรม"
                    value={this.state.fields.flashsale_time_datetime_end || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.flashsale_time_datetime_end}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">ตั้งเวลาที่แสดง<p className="remark-red">(จะแสดงสินค้าเฉพาะเวลาที่กำหนด)</p></label>
                <div className="col-md-3">
                  <label className="switch">
                    <input name="is_specific_time" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_specific_time}/>
                    <div className="slider"></div>
                  </label>
                </div>
              </div>
              <div className={`form-group ${this.state.fields.is_specific_time ? 'active' : 'inactive'}`}>
                <label className="control-label col-md-2">เวลาเริ่ม</label>
                <div className="col-md-3">
                  <input
                    name="flashsale_specific_time_start"
                    type="time"
                    className="form-control"
                    value={this.state.fields.flashsale_specific_time_start || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.flashsale_specific_time_start}</div>
                </div>

                <label className="control-label col-md-2">เวลาสิ้นสุด</label>
                <div className="col-md-3">
                  <input
                    name="flashsale_specific_time_end"
                    type="time"
                    className="form-control"
                    value={this.state.fields.flashsale_specific_time_end || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.flashsale_specific_time_end}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-2">หมวดหมู่ออเดอร์</label>
                <div className="col-md-3">
                  <Select
                    name="typeorder_id"
                    placeholder="เลือกหมวดหมู่ออเดอร์"
                    onChange={this.handleSelectChange}
                    options={optionsTypeOrder}
                  />
                  <div className="errorMsg">{this.state.errors.typeorder_id}</div>
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-lg-2">การใช้งาน</label>
                <div className="col-md-3">
                  <label className="switch">
                    <input name="is_active" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_active}/>
                    <div className="slider"></div>
                  </label>
                </div>
              </div>
  
              <div className="form-group">
                <div className="col-lg-12 text-right">
                  <input
                    type="submit"
                    className="btn btn-success btn-md btn-banner-create"
                    value="บันทึก"
                  />
                </div>
              </div>
            </form>  
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
