import React from "react";
import $ from "jquery";
import { Base_API } from "../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../activities.css";
import { activitiesAction } from "../../../_actions/activities.action";
import { Tooltip } from 'primereact/tooltip';
import OverlayEvents, { OverlayEvents_Switch } from "./overlayEvents";
import OverlayLoading, { _OverlayLoadingSwitch } from "../../../Components/OverlayLoading";
import 'jquery-ui/ui/widgets/datepicker'; // Import jQuery UI datepicker
import 'jquery-ui/themes/base/datepicker.css'; // Optional: import datepicker CSS
import 'jquery-ui/ui/i18n/datepicker-th'; // Import Thai locale
import { BackofficeAction } from "../../../_actions";

// Set the default regional settings to Thai
$.datepicker.setDefaults($.datepicker.regional['th']);
$.DataTable = require("datatables.net");

let modalAlert;

export class Activities_Log extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false,
      eventsTypeList: [],
      filterGroup: {
        textSearch: '',
        date_start: '',
        date_end: ''
      }
    };
  }

  // Lifecycle Methods
  componentDidMount() {
    this.pageInit();
    this.initializeDataTable();
    this.initializeDatepickers();
  }

  // Data Handling
  async pageInit() {
    await this.initEventsTypeActivityLog();
    _OverlayLoadingSwitch(false);
  }

  async initEventsTypeActivityLog() {
    return new Promise(async resolve => {
      let { eventsTypeList } = this.state;
      let eventsRes = await activitiesAction.listEventsTypeActivities();
      if (eventsRes.isSuccess) {
        eventsTypeList = eventsRes.data;
      }
      this.setState({ eventsTypeList: eventsTypeList });
      console.log('eventsRes: ', eventsRes);
      resolve();
    });
  }

  exportExcelActivitiesLogByFilter = async () => {
    const { filterGroup } = this.state;
    let payload = {
      date_start: filterGroup.date_start,
      date_end: filterGroup.date_end,
      search: { value: filterGroup.textSearch, regex: 'false' },
    }
    _OverlayLoadingSwitch(true);
    await BackofficeAction.exportExcelActivitiesLog(payload)
      .then(res => {
        if (res.status) {
          let downloadUrl = res.data;
          this.downloadFileByURL(downloadUrl);
        } else {
          this.onModalError('ไม่สำเร็จ', res.msg)
        }
        _OverlayLoadingSwitch(false);
      });
    console.log('Export parameters:', filterGroup);
  }

  downloadFileByURL(downloadUrl) {
    // Create a temporary <a> element to trigger download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'activities_log.xlsx'; // Set the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // DOM Manipulation
  initializeDataTable() {
    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "id"
      },
      {
        title: "ผู้ใช้งาน",
        class: "text-center",
        data: "mem_phone"
      },
      {
        title: "อีเว้นท์",
        class: "text-center",
        data: "event_type"
      },
      {
        title: "คำขยายความ",
        class: "text-center",
        data: "event_desc"
      },
      {
        title: "วันที่บันทึก",
        class: "text-center",
        data: "created_at"
      }
    ];

    this.table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(row + 1);
          },
          orderable: false
        }
      ],
      ajax: {
        url: this.getDataTableUrl(),
        type: "GET",
        dataType: "JSON",
        data: data => data
      },
      order: [[4, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      language: {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
      initComplete: () => {
        // Attach event listener to DataTable's default search input
        let inputSearch = '#dataTable_filter input[type="search"]'
        $(inputSearch).on('input', () => {
          const searchValue = $(inputSearch).val();
          // Update state or perform any additional actions based on search value
          this.setState(prevState => ({
            filterGroup: {
              ...prevState.filterGroup,
              textSearch: searchValue
            }
          }), () => {
            // Update the DataTable URL and reload with new filters
            this.table.ajax.url(this.getDataTableUrl()).load();
          });
        });
      }
    });

    // Re-initialize datepickers to ensure they are visible
    setTimeout(() => this.initializeDatepickers(), 100);
  }

  initializeDatepickers() {
    // Check if the datepickers are already initialized
    if (!$("#date_start").hasClass("hasDatepicker")) {
      // Append datepickers and clear filter button to DataTable filter
      const dateFilterHtml = `
        <label>Date range: <input autocomplete="off" type="text" id="date_start" placeholder="Start Date" /></label>
        <label style="margin-right: 10px;">-<input autocomplete="off" type="text" id="date_end" placeholder="End Date" /></label>
      `;
      const clearFilterBtn = `
        <button type="button" class="btn clearfilter-btn">Clear Filter</button>
      `;
  
      $('#dataTable_filter').prepend(dateFilterHtml);
      $('#dataTable_filter').append(clearFilterBtn);
  
      $("#date_start").datepicker({
        dateFormat: "yy-mm-dd",
        onSelect: (dateText) => this.handleDateChange('date_start', dateText + ' 00:00:00'),
        changeMonth: true,
        changeYear: true,
      });
  
      $("#date_end").datepicker({
        dateFormat: "yy-mm-dd",
        onSelect: (dateText) => this.handleDateChange('date_end', dateText + ' 23:59:59'),
        changeMonth: true,
        changeYear: true,
      });
  
      // Event handler for the clear filter button
      $('.clearfilter-btn').click(() => {
        this.clearFilterHandler();
      });
    }
  }

  clearFilterHandler() {
    // Clear datepickers
    $("#date_start").datepicker('setDate', null);
    $("#date_end").datepicker('setDate', null);
  
    // Clear the DataTable's internal search and redraw the table
    const dataTable = $('#dataTable').DataTable();
    dataTable.search('').draw();
    
    // Clear date filters in state
    this.setState(prevState => ({
      filterGroup: {
        ...prevState.filterGroup,
        date_start: '',
        date_end: '',
        textSearch: ''
      }
    }), () => {
      // Reload DataTable with cleared filters
      this.table.ajax.url(this.getDataTableUrl()).load();
    });

  }

  handleDateChange = (field, dateText) => {
    this.setState(prevState => ({
      filterGroup: {
        ...prevState.filterGroup,
        [field]: dateText
      }
    }), () => {
      // Update the DataTable URL and reload with new filters
      this.table.ajax.url(this.getDataTableUrl()).load();
    });
  }

  // Event Handlers
  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  }

  // Helper Methods
  getDataTableUrl() {
    const { filterGroup } = this.state;
    return `${Base_API.backoffice}/api/activity_log?date_start=${encodeURIComponent(filterGroup.date_start)}&date_end=${encodeURIComponent(filterGroup.date_end)}`;
  }

  render() {
    let { eventsTypeList } = this.state;
    return (
      <div className="activitieslog-con">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <div className="head-wrap">
              <h4 className="panel-title">รายการบันทึกเข้าใช้งาน</h4>
              <div className="heading-elements">
                <button onClick={this.exportExcelActivitiesLogByFilter} type="button" className="btn btn-add">Export Excel</button>
              </div>
              <button onClick={() => OverlayEvents_Switch(true)} className="question-btn" id="question-btn" data-toggle="tooltip" data-placement="right" title="Tooltip on right">
                <i className="bi bi-question-circle"></i>
              </button>
            </div>
            <Tooltip target=".question-btn" content={`คลิกเพื่อโชว์ประเภทอีเว้นท์`} position="right" event="hover" style={{ backgroundColor: '#374050', color: 'white', fontSize: '19px' }} />
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
        <OverlayEvents data={eventsTypeList} />
        <OverlayLoading />
      </div>
    );
  }
}

export default Activities_Log;
