import React from "react";
import $ from "jquery";
import { branchAction,orderAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../order.css";
import Select from "react-select";

$.DataTable = require("datatables.net");
let modalAlert;

var optionType = [
  { value: 'Eat in', label: 'Eat in' },
  { value: 'Takehome', label: 'Takehome' },
];

var optionStatus = [
  { value: '4', label: 'รอรับออเดอร์' },
  { value: '5', label: 'จัดเตรียมสินค้า' },
  { value: '6', label: 'คนขับกำลังไปส่งสินค้า' },
  { value: '7', label: 'รับสินค้าแล้ว' },
  { value: '8', label: 'ยกเลิกรายการ' },
];

let dataSearch = {
  s_Order: '',
  s_Branch: '',
  s_Date: '',
  s_Status: '',
  s_Type: '',
}


export class PreOrder_Receive_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false,
      inputBody: [],
      fields: {},
      branchList: [],
      searchStatus:'',
      searchBranch:'',
      searchType:''
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    branchAction.getBranchEatinAll()
    .then(res => {
        this.setState({
            branchList: res.data
        })
    })

    this.showTable(dataSearch);
  }

  showTable(dataSearch){
    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "od_id"
      },
      {
        title: "เลขที่ออเดอร์",
        class: "text-center",
        data: "od_number"
      },
      {
        title: "สาขา",
        class: "text-center",
        data: "b_name"
      },
      {
        title: "ประเภทการรับสินค้า",
        class: "text-center",
        data: "order_type"
      },
      {
        title: "ชื่อลูกค้า",
        class: "text-center",
        data: "mem_firstname",
      },
      {
        title: "ราคารวม",
        class: "text-center",
        data: "sum_price"
      },
      {
        title: "วันที่สร้างรายการ",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "สถานะการรับสินค้า",
        class: "text-center",
        data: "status_receive_name"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "od_number"
      },
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left"><a href="/backoffice/member/edit/${rowData.mem_id}">${cellData} ${rowData.mem_lastname}</a></div>`);
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-right">${cellData} ฿</div>`);
          }
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            var status = '';
            if(rowData.status_receive === 4) status = `<span class="badge badge-warning">${cellData}</span>`
            else if(rowData.status_receive === 5) status = `<span class="badge badge-info">${cellData}</span>`
            else if(rowData.status_receive === 6) status = `<span class="badge badge-success">${cellData}</span>`
            else if(rowData.status_receive === 7) status = `<span class="badge badge-success">${cellData}</span>`
            else if(rowData.status_receive === 8) status = `<span class="badge badge-danger">${cellData}</span>`
            else status = `<span class="badge badge-info">ไม่ระบุสถานะ</span>`
            $(td).html(status);
          },
          orderable: false
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            var bt_manage = '';
            if(rowData.status_receive == '0' || rowData.status_receive == '4' || rowData.status_receive == '5'){
              bt_manage =

                `<div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/preorder/receive_order/info/${cellData}" class="dropdown-item"><i class="icon-clipboard3"></i> ดูรายละเอียด</a>
                      <a href="/backoffice/preorder/receive_order/edit/${cellData}" class="dropdown-item"><i class="icon-gear"></i> จัดการออเดอร์</a>
                    </div>
                  </div>
                </div>`
            }else{
              bt_manage =

              `<div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/preorder/receive_order/info/${cellData}" class="dropdown-item"><i class="icon-clipboard3"></i> ดูรายละเอียด</a>
                    <a class="dropdown-item disabled"><i class="icon-gear"></i> จัดการออเดอร์</a>
                  </div>
                </div>
              </div>`
            }

            $(td).html(bt_manage);
          },
          orderable: false
        }
      ],

      ajax: {
        url: `${Base_API.order}/order-recive?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchStatus=${dataSearch.s_Status}&searchType=${dataSearch.s_Type}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[5, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

  }

  handleSubmit(e) {
    var table = $('#dataTable').DataTable();
    table.destroy();

    e.preventDefault()
    var dataSearch = {
        s_Status: (this.state.fields["searchStatus"] ? this.state.fields["searchStatus"] : ''),
        s_Branch: (this.state.fields["searchBranch"] ? this.state.fields["searchBranch"] : ''),
        s_Order: (this.state.fields["searchOrder"] ? this.state.fields["searchOrder"] : ''),
        s_Date: (this.state.fields["searchDate"] ? this.state.fields["searchDate"] : ''),
        s_Type: (this.state.fields["searchType"] ? this.state.fields["searchType"] : '')
    }
    this.showTable(dataSearch);
  }

  handleSearchStatus = (selectedOption) => {
      let { fields } = this.state;
      fields["searchStatus"] = selectedOption.value;
      this.setState({ fields });
  };

  handleSearchBranch = (selectedOption) => {
      let { fields } = this.state;
      fields["searchBranch"] = selectedOption.value;
      this.setState({ fields });
  };

  handleSearchType = (selectedOption) => {
      let { fields } = this.state;
      fields["searchType"] = selectedOption.value;
      this.setState({ fields });
  };

  handleChangeSearch = (e) => {
      let { fields } = this.state;
      fields[e.target.name] = e.target.value;
      this.setState({ fields });
  };

  // handleSearch = (selectedOption) => {
  //     let { fields } = this.state;
  //     fields["searchBranch"] = selectedOption.value;
  //     this.setState({ fields });
  // };

  render() {
    let optionBranch = this.state.branchList.map(function (branchList) {
      return { value: branchList.b_id, label: branchList.b_name };
    })
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการรับสินค้า</h4>
          </div>
          <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>

            <div className="row form-search">
              <div className="col-md-2">
                <input
                  type="text"
                  name="searchOrder"
                  placeholder="เลขที่ออเดอร์"
                  onChange={this.handleChangeSearch}
                  className="form-control"
                />
              </div>
              <div className="col-md-2">
                <Select
                  name="searchBranch"
                  placeholder="เลือกสาขา"
                  onChange={this.handleSearchBranch}
                  options={optionBranch}
                  className="Select-menu-outer"
                />
              </div>
              <div className="col-md-2">
                <input
                  type="date"
                  name="searchDate"
                  placeholder="วันที่สร้างรายการ"
                  onChange={this.handleChangeSearch}
                  className="form-control"
                />
              </div>
              <div className="col-md-2">
                <Select
                  name="searchType"
                  placeholder="เลือกประเภทการรับสินค้า"
                  onChange={this.handleSearchType}
                  options={optionType}
                  className="Select-menu-outer"
                />
              </div>
              <div className="col-md-2">
                <Select
                  name="searchStatus"
                  placeholder="เลือกสถานะออเดอร์"
                  onChange={this.handleSearchStatus}
                  options={optionStatus}
                  className="Select-menu-outer"
                />
              </div>

              <div className="col-lg-2 text-left">
                <input
                  type="submit"
                  className="btn btn-add btn-md btn-banner-create"
                  value="ค้นหา"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table id="dataTable" className="table table-hover" ref="main" />
                </div>
              </div>
            </div>
          </form>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
