import React from "react";
import $ from "jquery";
import { productAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../product.css";
import ReactExport from 'react-data-export';
import Select from 'react-select';
import OverlayLoading, {_OverlayLoadingSwitch} from "../../../../Components/OverlayLoading";

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-online-product'

export class Product_Online_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.setUpEventHandlers = this.setUpEventHandlers.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onModalError = this.onModalError.bind(this);
    this.onModalSuccess = this.onModalSuccess.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleImportProductBySheet = this.handleImportProductBySheet.bind(this);
    this.loadingImpotProduct = this.loadingImpotProduct.bind(this);
    this.setProgressImportProduct = this.setProgressImportProduct.bind(this);

    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false,
      // dropdown
      prPromoTypeOptions: [
        {
          label: 'สินค้าทั้งหมด',
          value: 'all'
        },
        {
          label: 'สินค้าโปรโมชั่น',
          value: 'promotion'
        },
      ],
      prPromoTypeSelected: null,
      prTypeRadio: 'all',
      prTypeRadioMobile: 'all',
      fields: {},
      errors: {},
      disabledImportProduct: false,
      sheetURL: '',
      percentage: 0
    };

    // Bind context for modal handling functions
    this.setUpEventHandlers = this.setUpEventHandlers.bind(this);
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    this.initializeDataTable(`${Base_API.product}/product_online`);
    this.setState({
      prPromoTypeSelected: this.state.prPromoTypeOptions[0]
    })
    productAction.getProductOnlineList()
    .then(res => {
      this.setState({
          dataProduct: res.data
      })
    })
    _OverlayLoadingSwitch(false);
  }

  initializeDataTable(apiUrl) {
    const columns = [
      { title: "ลำดับ", class: "text-center", data: "pr_online_id" },
      { title: "รูปภาพ", class: "text-center", data: "pr_online_image" },
      { title: "ไอดีสินค้า", class: "text-center", data: "pr_online_id" },
      { title: "รหัสสินค้า", class: "text-center", data: "pr_online_code" },
      { title: "ชื่อสินค้า (TH)", class: "text-center", data: "pr_online_name" },
      { title: "หมวดหมู่", class: "text-center", data: "pr_online_category_name" },
      { title: "ราคาสินค้าตั้งต้น", class: "text-center", data: "pr_online_price" },
      { title: "ลำดับที่แสดง", class: "text-center", data: "pr_online_sort" },
      { title: "สถานะ Recommend", class: "text-center", data: "is_recommend" },
      { title: "สถานะสินค้า", class: "text-center", data: "is_active" },
      { title: "วันที่แก้ไข", class: "text-center", data: "updated_at" },
      { title: "จัดการ", class: "text-center", data: "pr_online_id" }
    ];

    if ($.fn.dataTable.isDataTable(this.refs.main)) {
      $(this.refs.main).DataTable().destroy();
    }

    $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(row + 1);
          }
        },
        {
          targets: 1,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(`<img class='product_thumbnail' src='${cellData}' alt='${rowData.pr_online_name}' />`);
          },
          orderable: false
        },
        {
          targets: 2,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(`<div class="text-center">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 3,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(`<div class="text-center">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 4,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(`<div class="text-center">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 5,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(`<div class="text-left">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 8,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(`
              <label class="switch">
                <input name="is_recommend" class="isRecommend" data-id="${rowData.pr_online_id}" type="checkbox" ${cellData === 1 ? "checked" : ""}/>
                <div class="slider"></div>
              </label>
            `);
          },
          orderable: true
        },
        {
          targets: 9,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(`
              <label class="switch">
                <input name="isStatus" class="activeBtn" data-id="${rowData.pr_online_id}" type="checkbox" ${cellData === 1 ? "checked" : ""}/>
                <div class="slider"></div>
              </label>
            `);
          },
          orderable: true
        },
        {
          targets: 11,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/order-online/product/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                    <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                  </div>
                </div>
              </div>
            `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: apiUrl,
        type: "GET",
        dataType: "JSON",
        data: data => {
          _OverlayLoadingSwitch(false);
          return data
        },
      },
      order: [[11, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
      },
    });

    this.setUpEventHandlers();
  }

  setUpEventHandlers() {
    $(this.refs.main).on("click", ".activeBtn", (event) => {
      var fid = $(event.target).data("id");
      var fval = $(event.target).is(":checked") === true ? 1 : 2;
  
      var formData = new FormData();
      formData.append("is_active", fval);
  
      productAction.updateProductOnline(formData, fid).then(e => {
        if (e.isSuccess === false) {
          this.onModalError(e.data.message);
        } else {
          this.onModalSuccess("อัพเดตสำเร็จ");
        }
      });
    });
  
    $(this.refs.main).on("click", ".isRecommend", (event) => {
      var fid = $(event.target).data("id");
      var fval = $(event.target).is(":checked") === true ? 1 : 0;
  
      var formData = new FormData();
      formData.append("is_recommend", fval);
  
      productAction.updateProductOnline(formData, fid).then(e => {
        if (e.isSuccess === false) {
          this.onModalError(e.data.message);
        } else {
          this.onModalSuccess("อัพเดตสำเร็จ");
        }
      });
    });
  
    $(this.refs.main).on("click", 'button[name="delete_btn"]', (event) => {
      var mcid = $(event.target).data("content");
      this.setState({
        show: true,
        modal: (
          <SweetAlert
            style={{ display: "block" }}
            info
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="default"
            title="Delete"
            onConfirm={() => this.onConfirmDelete(mcid)}
            onCancel={this.onConfirm}
          >
            คุณต้องการลบข้อมูลนี้หรือไม่ ?
          </SweetAlert>
        )
      });
    });
  }
  
  onConfirmDelete(cid) {
    productAction.deletePreProductV2(cid).then(e => {
      if (e.isSuccess === false) {
        this.onModalError(e.data.message);
      } else {
        this.onModalSuccess("ลบข้อมูลสำเร็จ");
        $(this.refs.main).DataTable().ajax.reload(null, false);
      }
    });
    this.setState({ show: false });
  }
  
  onConfirm() {
    this.setState({ show: false });
  }
  
  onModalError(message) {
    this.setState({
      isModal: true,
      modal: (
        <SweetAlert
          style={{ display: "block" }}
          title="Error"
          onConfirm={this.hideAlert}
        >
          {message}
        </SweetAlert>
      )
    });
  }
  
  onModalSuccess(message) {
    this.setState({
      isModal: true,
      modal: (
        <SweetAlert
          style={{ display: "block" }}
          success
          title="Success"
          onConfirm={this.hideAlert}
        >
          {message}
        </SweetAlert>
      )
    });
  }
  
  hideAlert() {
    this.setState({ isModal: false, modal: null });
  }

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    if(action.name == "product-promotion-type"){
      this.setState({
        prPromoTypeSelected:
        {
          label: e.label,
          value: e.value
        }
      })
    }
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  handleChangeRadio(event) {
    let apiUrl = `${Base_API.product}/product_online`;
    _OverlayLoadingSwitch(true);
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ prTypeRadio: value, prTypeRadioMobile: value })
  
    if(name === 'typepr')
    {
      if(value === 'all' || value === 'mb_all')
      {
        this.initializeDataTable(apiUrl); 
      }else if(value === 'promotion' || value === 'mb_promotion')
      {
        apiUrl = `${Base_API.product}/product_online_promotion`;  
        this.initializeDataTable(apiUrl);
      }
    } 
  }

  popupImportProduct(status) {
    if(status){
      $('.popup_import_product')
      .css("display", "flex")
      .hide()
      .fadeIn();
      $('body').css('overflow','hidden')
    }else{
      $('.popup_import_product').fadeOut(); 
      $('body').css('overflow','auto')
    }
  }
  loadingImpotProduct(status) {
    if(status){
      $('.overlay-loading')
      .css("display", "flex")
      .hide()
      .fadeIn();
      $('body').css('overflow','hidden')
    }else{
      $('.overlay-loading').fadeOut(); 
      $('body').css('overflow','auto')
    }
  }

  isValidGoogleSpreadsheetUrl(url) {
    const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)\/edit/;
    return regex.test(url);
  }

  handleInputChange(event) {
    let {errors} = this.state;
    let name = event.target.name;
    let value = event.target.value;
    if(name === 'sheet_url'){
      let isCorrect = this.isValidGoogleSpreadsheetUrl(value);
      if(!isCorrect){
        errors["sheet_url"] = `Sheet URL ไม่ถูกต้อง`; 
        this.setState({errors, disabledImportProduct:true})
      }else{
        errors["sheet_url"] = ``; 
        this.setState({errors, disabledImportProduct:false, sheetURL: value})
      }
    }
  }

  handleImportProductBySheet() {
    let {sheetURL} = this.state;
    if(sheetURL.length > 0){
      this.loadingImpotProduct(true);
      let payload = {sheet_url: sheetURL}
      productAction.importProductOnlineBySheet(payload, this.setProgressImportProduct)
      .then(res => {
        if(res.isSuccess){
          this.onModalSuccess(res.message);
          this.popupImportProduct(false);
          this.setProgressImportProduct(0);
          this.loadingImpotProduct(false);
          $(this.refs.main).DataTable().ajax.reload(null, false); 
        }else{
          this.onModalError(res.message);
          this.setProgressImportProduct(0);
          this.loadingImpotProduct(false);
        }
      })
    }
  }

  setProgressImportProduct(percent){
    this.setState({percentage: percent})
  }

  render() {

    var dataExcel = []
    let data = this.state.dataProduct 
    let {prTypeRadio, prTypeRadioMobile, disabledImportProduct, errors, percentage} = this.state;

    for(var i in data){
      dataExcel.push(
        [
          {value: i},
          {value: data[i].pr_online_image},
          {value: data[i].pr_online_code},
          {value: data[i].pr_online_name},
          {value: data[i].pr_online_category_name},
          {value: data[i].pr_online_price},
          {value: data[i].pr_online_sort},
          {value: (data[i].is_active == 1 ? 'active' : 'inactive')}
        ],
      )
    }    
    
    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "URL รูปภาพ", width: {wch: 40}},//char width 
              {title: "รหัสสินค้า", width: {wch: 20}},
              {title: "ชื่อสินค้า (TH)", width: {wch: 40}},
              {title: "หมวดหมู่", width: {wch: 20}},
              {title: "ราคาสินค้าตั้งต้น", width: {wch: 10}},
              {title: "ลำดับที่แสดง", width: {wch: 15}},
              {title: "สถานะ"},
          ],
          data: dataExcel
          
      }
    ];

    return (
      <div className="pronline-list-con">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">จัดการสินค้าออนไลน์</h4>
            <div className="typepr-group">
              <div className="item">
                <input type="radio" name="typepr" id="all" value="all" defaultChecked={true} onChange={this.handleChangeRadio} />
                <label htmlFor="all">สินค้าทั้งหมด</label>
              </div>
              <div className="item">
                <input type="radio" name="typepr" id="promotion" value="promotion" onChange={this.handleChangeRadio} />
                <label htmlFor="promotion">สินค้าโปรโมชั่น</label>
              </div>
            </div>
            <div className="heading-elements">
              <div className="typepr-group mobile">
                <div className="item">
                  <input type="radio" name="typepr" id="mb_all" value="mb_all" onChange={this.handleChangeRadio} />
                  <label htmlFor="mb_all">สินค้าทั้งหมด</label>
                </div>
                <div className="item">
                  <input type="radio" name="typepr" id="mb_promotion" value="mb_promotion" onChange={this.handleChangeRadio} />
                  <label htmlFor="mb_promotion">สินค้าโปรโมชั่น</label>
                </div>
              </div>
              <a href="/backoffice/order-online/product/create">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                  เพิ่มสินค้า
                </button>

              </a>

              <button
                type="button"
                name="add_banner_btn"
                data-content=""
                className="btn btn-add mr-2"
                onClick={() => this.popupImportProduct(true)}
              >
                Import สินค้า
              </button>

              <ExcelFile 
                element={
                  <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                  >
                  <i className="icon-download"></i> Export Excel
                  </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Online-Product" />
              </ExcelFile>
            </div>
          </div>

          {/* <div className="product-pomotion-type-select">
            <label htmlFor="">ประเภทสินค้า: </label>
            <div className="select-wrap">
              <Select
                name="product-promotion-type"
                placeholder="ประเภทสินค้า"
                onChange={this.handleSelectChange}
                options={this.state.prPromoTypeOptions}
                value={this.state.prPromoTypeSelected}
              />
            </div>
          </div> */}
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
        <OverlayLoading />
        <div className="popup_import_product">
          <div className="main">
            <div className="head">
              <h3>เพิ่มสินค้าด้วย Google Sheet URL</h3>
              <button onClick={() => this.popupImportProduct(false)}><i class="bi bi-x-circle-fill"></i></button>
            </div>
            <div className="group">
              <input type="text" onChange={this.handleInputChange} className={`form-control ${disabledImportProduct && 'dis'}`} name="sheet_url" id="sheet_url" />
              <button type="button" onClick={this.handleImportProductBySheet} className={`btn ${disabledImportProduct && 'disabled'}`}>import</button>
            </div>
            <p className="remark">**โปรดเช็คให้แน่ใจว่าชีทของท่านใช้เทมเพลตที่ถูกต้องและสิทธิ์การเข้าถึงเปํ็น "ทุกคนที่มีลิ้งค์"</p>
            {/* loading */}
            <div className="overlay-loading">
              <div className="loading-wrap">
                <div className="loading-head">
                  <p className="percentage">{percentage}%</p>
                  <span className="customloader"></span>
                </div>
                <div className="progress-bar-wrap"><div className="progress-bar" style={{width: `${percentage}%`}}></div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
