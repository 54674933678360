import React from "react";
import $ from "jquery";
import { staffAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../reward.css";

$.DataTable = require("datatables.net");
let modalAlert;

export class Reward_Staff extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    const columns = [
      {
        title: "รหัสพนักงาน",
        class: "text-center",
        data: "staff_number"
      },
      {
        title: "ขื่อจริง",
        class: "text-left",
        data: "staff_firstname"
      },
      {
        title: "นามสกุล",
        class: "text-left",
        data: "staff_lastname"
      },
      {
        title: "แผนก",
        class: "text-left",
        data: "staff_department"
      },
      {
        title: "ตำแหน่ง",
        class: "text-left",
        data: "staff_position"
      },
      {
        title: "รหัสบัตรประชาชน",
        class: "text-left",
        data: "staff_idcard"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "staff_idcard"
      },
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets:6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/reward/staff/list/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i>จัดการรายการรีวอร์ดสำหรับพนักงาน</a>
                    </div>
                </div>
              </div>
            `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.admin}/staff`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "asc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายชื่อพนักงาน ( สำหรับสิทธิ์ในการแลกรีวอร์ด )</h4>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
