import React from "react";
import { activityAction, productAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "./activity.css";
import Select from 'react-select';
import { promotionAction } from "../../../../_actions/promotion.action";
import moment from "moment-timezone";

let modalAlert;

export class Edit_Promotion extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: '',
      fields: {
        is_active: 0,
      },
      errors: {},
      promoCate: [],
      productCate: [],
      promo_list_id: this.props.match.params.promo_list_id,
      selectData: {},
      discountCate: [{label: 'เปอร์เซ็น', value: 'percentage'}, {label: 'บาท', value: 'baht'}] 
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    await this.fetchInit();
    this.initialInputData();
  }

  fetchInit() {
    return new Promise(async resolve => {
      let {promo_list_id} = this.state;
      const promoCategoryPromise = promotionAction.listPromoCategory();
      const productCategoryPromise = productAction.getCategoryProductListAll();
      const promotionByIdPromise = promotionAction.getPromotionById(promo_list_id);
  
      const [promoCategoryRes, productCategoryRes, promotionByIdRes] = await Promise.all([
        promoCategoryPromise,
        productCategoryPromise,
        promotionByIdPromise
      ]);
  
      const promoCateOptions = promoCategoryRes.data.map(item => ({
        value: item.promo_cate_code,
        label: item.promo_cate_name
      }));
  
      const productCateOptions = productCategoryRes.data.map(item => ({
        value: item.pr_category_id,
        label: item.pr_category_name
      }));

      const fieldsData = promotionByIdRes.data;
  
      this.setState({
        promoCate: promoCateOptions,
        productCate: productCateOptions,
        fields: fieldsData
      }, () => {
        // Once state is updated, resolve the promise
        resolve();
      });
    });
  }

  initialInputData() {
    let {promoCate, productCate, fields, selectData, discountCate} = this.state;
    let promoCateOptions = promoCate.filter((item) => item.value === fields["promo_cate_code"])
    let productCateOptions = productCate.filter((item) => item.value === fields["pr_online_category_id"])
    let discountCateOptions = discountCate.filter((item) => item.value === fields["discount_type"])
    this.setState({
      selectData: {...selectData, promo_cate_code: promoCateOptions, pr_online_category_id: productCateOptions, discount_type: discountCateOptions},
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/product/promotion"
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["promo_list_code"]) {
      formIsValid = false;
      errors["promo_list_code"] = "*กรุณากรอกรหัสโปรโมชั่น";
    }
    if (!fields["promo_cate_code"]) {
      formIsValid = false;
      errors["promo_cate_code"] = "*กรุณาระบุเงื่อนไขโปรโมชั่น";
    }
    if (!fields["discount_price"]) {
      formIsValid = false;
      errors["discount_price"] = "*กรุณากรอกราคา";
    }
    if (!fields["start_date"]) {
      formIsValid = false;
      errors["start_date"] = "*กรุณาระบุวันเริ่มต้นโปรโมชั่น";
    }
    if (!fields["end_date"]) {
      formIsValid = false;
      errors["end_date"] = "*กรุณาระบุวันสิ้นสุดโปรโมชั่น";
    }
    if (fields["promo_cate_code"] === 'PMCT001') { // ซื้อสินค้าในหมวดเดียวกัน X ชิ้นได้ราคาพิเศษ Y บาท
      if(!fields["pr_online_category_id"])
      {
        formIsValid = false;
        errors["pr_online_category_id"] = "*กรุณาระบุหมวดหมู่สินค้า";
      }
      if (!fields["quantity"]) {
        formIsValid = false;
        errors["quantity"] = "*กรุณากรอกจำนวนสินค้าที่ต้องซื้อ";
      }
    }
    if (fields["promo_cate_code"] === 'PMCT002') { // ซื้อครบ X บาทลด Y (บาท,%)
      if(!fields["purchase_price"])
      {
        formIsValid = false;
        errors["purchase_price"] = "*กรุณากรอกราคาซื้อ";
      }
      if(!fields["discount_type"])
      {
        formIsValid = false;
        errors["discount_type"] = "*กรุณาระบุรูปแบบการลด";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    let {promo_list_id} = this.state;
    let inputBody = this.getReqPayload();
    promotionAction.updatePromotionById(inputBody, promo_list_id).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  getReqPayload = () => {
    let {fields} = this.state;
    let resData;
    if(fields["promo_cate_code"] === "PMCT001") // ซื้อสินค้าในหมวดเดียวกัน X ชิ้นได้ราคาพิเศษ Y บาท
    {
      resData = {
        "is_active": fields["is_active"],
        "promo_list_code": fields["promo_list_code"],
        "promo_cate_code": fields["promo_cate_code"],
        "pr_online_category_id": Number(fields["pr_online_category_id"]),
        "quantity": Number(fields["quantity"]),
        "discount_price": parseFloat(fields["discount_price"]).toFixed(2),
        "start_date": moment(fields["start_date"]).format('Y-MM-DD HH:mm:ss'),
        "end_date": moment(fields["end_date"]).format('Y-MM-DD HH:mm:ss'),
      }
    }
    if(fields["promo_cate_code"] === "PMCT002") // ซื้อครบ X บาทลด Y (บาท,%)
    {
      resData = {
        "is_active": fields["is_active"],
        "promo_list_code": fields["promo_list_code"],
        "promo_cate_code": fields["promo_cate_code"],
        "purchase_price": parseFloat(fields["purchase_price"]).toFixed(2),
        "discount_price": parseFloat(fields["discount_price"]).toFixed(2),
        "discount_type": fields["discount_type"],
        "start_date": moment(fields["start_date"]).format('Y-MM-DD HH:mm:ss'),
        "end_date": moment(fields["end_date"]).format('Y-MM-DD HH:mm:ss'),
      }
    }
    return resData;
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    if(name === 'promo_list_code')
    {
      fields[name] = String(value).toUpperCase();
      this.state.errors[name] = null;
      this.setState({ fields, errors });
    }else
    {
      fields[name] = value;
      this.state.errors[name] = null;
      this.setState({ fields, errors });
    }
  };

  handleSelectChange = (e, action) => {
    let { fields, errors, selectData } = this.state;
    fields[action.name] = e.value;
    selectData[action.name] = {value: e.value, label: e.label};
    this.state.errors[action.name] = null;
    this.setState({ fields, errors, selectData });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })
  }

  render() {
    let {promoCate, productCate, fields, selectData, discountCate} = this.state;

    return (
      <div className="promotion-edit-con">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขโปรโมชั่นสินค้า</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="form-group">
                <label className="control-label col-md-2">รหัสโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="promo_list_code"
                    type="text"
                    className="form-control"
                    placeholder="รหัสโปรโมชั่น"
                    value={this.state.fields.promo_list_code || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.promo_list_code}</div>
                </div>
                
                <label className="control-label col-md-2">เงื่อนไขโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <Select
                    name="promo_cate_code"
                    placeholder="เงื่อนไขโปรโมชั่น"
                    onChange={this.handleSelectChange}
                    options={promoCate}
                    value={selectData.promo_cate_code}
                  />
                  <div className="errorMsg">{this.state.errors.promo_cate_code}</div>
                </div>
              </div>

              {fields["promo_cate_code"] === 'PMCT001'
                &&
                <div className="form-group">
                  <label className="control-label col-md-2">หมวดหมู่สินค้า <span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      name="pr_online_category_id"
                      placeholder="หมวดหมู่สินค้า"
                      onChange={this.handleSelectChange}
                      options={productCate}
                      value={selectData.pr_online_category_id}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_category_id}</div>
                  </div>
                </div>
              }
              {fields["promo_cate_code"] === 'PMCT001' &&
                <div className="form-group">
                  <label className="control-label col-md-2">จำนวนสินค้าที่ต้องซื้อ <span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <input
                      name="quantity"
                      type="number"
                      className="form-control"
                      placeholder="จำนวนสินค้าที่ต้องซื้อ"
                      value={this.state.fields.quantity || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.quantity}</div>
                  </div>
                  <label className="control-label col-md-2">ราคาทั้งเซ็ต <span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <input
                      name="discount_price"
                      type="number"
                      className="form-control"
                      placeholder="ราคาทั้งเซ็ต"
                      value={this.state.fields.discount_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.discount_price}</div>
                  </div>
                </div>
              }

              {fields["promo_cate_code"] === 'PMCT002' && 
                <div className="form-group">
                  <label className="control-label col-md-2">ราคาซื้อ <span className="remark-red">(ที่ต้องซื้อ)</span></label>
                  <div className="col-md-3">
                    <input
                      name="purchase_price"
                      type="number"
                      className="form-control"
                      placeholder="ราคาซื้อ"
                      value={this.state.fields.purchase_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.purchase_price}</div>
                  </div>
                </div>
              }
              {fields["promo_cate_code"] === 'PMCT002' && 
                <div className="form-group">
                  <label className="control-label col-md-2">ลด(%,บาท) <span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      name="discount_type"
                      placeholder="รูปแบบการลด"
                      onChange={this.handleSelectChange}
                      options={discountCate}
                      value={selectData.discount_type}
                    />
                    <div className="errorMsg">{this.state.errors.discount_type}</div>
                  </div>
                  <div className="col-md-3">
                    <span className="disp-input-wrap">
                      <input
                        name="discount_price"
                        type="number"
                        className="form-control"
                        placeholder="ลด"
                        value={this.state.fields.discount_price || ""}
                        onChange={this.handleChange}
                      />
                      <span>{fields["discount_type"] === 'percentage' ? '%' : fields["discount_type"] === 'baht' ? 'บาท' : ''}</span>
                    </span>
                    <div className="errorMsg">{this.state.errors.discount_price}</div>
                  </div>
                </div>
              }

              <div className="form-group">
                <label className="control-label col-md-2">วันที่เริ่มโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="start_date"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่เริ่มโปรโมชั่น"
                    value={moment(this.state.fields.start_date).format('YYYY-MM-DDTHH:mm:ss') || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.start_date}</div>
                </div>

                <label className="control-label col-md-2">วันที่สิ้นสุดโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="end_date"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่สิ้นสุดโปรโมชั่น"
                    value={moment(this.state.fields.end_date).format('YYYY-MM-DDTHH:mm:ss') || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.end_date}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">การใช้งาน</label>
                <div className="col-md-3">
                  <label className="switch">
                    <input name="is_active" type="checkbox" onChange={this.handleInputChange} checked={this.state.fields["is_active"] === 1 ? true : false}/>
                    <div className="slider"></div>
                  </label>
                </div>
              </div>
  
              <div className="form-group">
                <div className="col-lg-12 text-right">
                  <input
                    type="submit"
                    className="btn btn-success btn-md btn-banner-create"
                    value="บันทึก"
                  />
                </div>
              </div>
            </form>  
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
