import React from "react";
import $ from "jquery";
import { activityAction, productAction } from "../../../../../_actions";
import { Base_API } from "../../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../../activity.css";
import Select from 'react-select';
import { NavLink } from "react-router-dom";
import ReactModal from 'react-modal';
const moment = require('moment-timezone');

var urlAU = ""
const urlPath = window.location.hostname
if (urlPath === "https://podsable.com" || urlPath === "podsable.com") {
  // urlAU = "/afteryou"  
}

$.DataTable = require("datatables.net");
let modalAlert;
const customStyles = {
  content : {
    top: '40px',
    left: '20%',
    width: '60%',
    height: '40%',
    padding: '0px'
  }
};
export class Flashsale_Product_List extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      isModal: false,
      products: [],
      fields: {},
      errors: {},
      typeorder_name: sessionStorage.getItem("typeorder_name"),
      flashsale_time_datetime_start: moment(sessionStorage.getItem("flashsale_time_datetime_start")).format("DD-MM-YYYY HH:mm:ss"),
      flashsale_time_datetime_end: moment(sessionStorage.getItem("flashsale_time_datetime_end")).format("DD-MM-YYYY HH:mm:ss")
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);

  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.setState({flashsale_time_id: params.flashsale_time_id});

    await productAction.listProductIsactive().then(res => {
      this.setState({
          products: res.data
      })
    })

    const columns = [
      {
        title: "รหัสสินค้า",
        class: "text-center",
        data: "pr_online_code"
      },
      {
        title: "ชื่อสินค้า",
        class: "text-left",
        data: "pr_online_name"
      },
      {
        title: "ราคาปกติ",
        class: "text-center",
        data: "pr_online_price"
      },
      {
        title: "ราคา Flash Sale",
        class: "text-center",
        data: "flashsale_product_price_discount"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "flashsale_product_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [ 
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-long"> ${cellData}</div>`);
          },
          orderable: false
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <button type='button' class="dropdown-item" name='edit_btn' data-content='${cellData}'><i class="icon-pencil3 mr-3 mb-1"></i>แก้ไข</button>
                      <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                    </div>
                  </div>
                    
                  </div>
                </div>
                `);

          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.product}/flashsale/list_product/${params.flashsale_time_id}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    $(this.refs.main).on("click", 'button[name="edit_btn"]', async function() {
      var id = $(this).data("content");

      await getDataProductById(id)

      state.setState({ 
        flashsale_product_id: id,
        type: 'update',
        showModal: true 
      });
    });

    function getDataProductById(id) {
      activityAction.flashsaleGetProduct(id).then(e => {
        if (e.isSuccess === true) {
          state.setState({
            fields: {
              ...state.state.fields,
              flashsale_product_id: e.data.flashsale_product_id,
              pr_online_id: e.data.pr_online_id,
              pr_online_code: e.data.pr_online_code,
              flashsale_product_price_discount: e.data.flashsale_product_price_discount,
              flashsale_time_id: e.data.flashsale_time_id,
              pr_online_name: e.data.pr_online_name
            }
          });
          state.setState({
            defaultProduct:
            {
              label: e.data.pr_online_code + " - " + e.data.pr_online_name,
              value: e.data.pr_online_id
            }
          });
        } else {
          this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
        }
      });
    }

    function onConfirmDelete(id) {
      activityAction.flashsaleDeleteProduct(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
           onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }
  }

  handleSubmit(event, type) {
    event.preventDefault()
    this.setState({ modalOption: null })
    console.log(event);
    console.log(type);
    if (this.validateForm()) {
      if(type == "create"){
        this.onModalQuestionCreate('Confirm','คุณต้องการเพิ่มข้อมูล ?')
      }else if(type == "update"){
        this.onModalQuestionUpdate('Confirm','คุณต้องการเพิ่มข้อมูล ?')
      }
    }
  }

  onModalQuestionCreate(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestionUpdate(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmUpdate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    let inputBody = {
      pr_online_id: this.state.fields["pr_online_id"],
      flashsale_product_price_discount: this.state.fields["flashsale_product_price_discount"],
      flashsale_time_id: this.state.flashsale_time_id
    }
    activityAction.flashsaleInsertProduct(inputBody).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onConfirmUpdate(ev) {
    this.setState({ modal: null });
    let inputBody = {
      flashsale_product_price_discount: this.state.fields["flashsale_product_price_discount"],
    }

    activityAction.flashsaleUpdateProduct(inputBody, this.state.fields["flashsale_product_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["pr_online_id"]) {
      formIsValid = false;
      errors["pr_online_id"] = "*กรุณาเลือกสินค้า Flash Sale";
    }

    if (!fields["flashsale_product_price_discount"]) {
      formIsValid = false;
      errors["flashsale_product_price_discount"] = "*กรุณากรอกราคาสินค้า Flash Sale";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    if(action.name == "pr_online_id"){
      this.setState({
        defaultProduct:
        {
          label: e.label,
          value: e.value
        }
      })
    }
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  onCancel(ev) {
    this.setState({ modal: null });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleOpenModal (type_event) {
    this.setState({ 
      type: type_event,
      showModal: true 
    });
  }

  onConfirm() {
    this.setState({ 
      showModal: false,
      modal: null 
    });
    window.location.reload();
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  render() {
    let optionsProducts = this.state.products.map(function (prouct) {
      return { value: prouct.pr_online_id, label: prouct.pr_online_code + " - " +prouct.pr_online_name };
    })

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">สินค้า Flash Sale</h4>
            <div> หมวดหมู่ออเดอร์: {(this.state.typeorder_name).toUpperCase()} </div>
            <div> เวลาเริ่ม - สิ้นสุดกิจกรรม: {this.state.flashsale_time_datetime_start +" - "+ this.state.flashsale_time_datetime_end} </div>
            <div className="heading-elements">
              <button
                type="button"
                name="add_banner_btn"
                data-content=""
                className="btn btn-add"
                onClick={()=>this.handleOpenModal('create')}
              >
                เพิ่มรายการ
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>

                
        <ReactModal 
            isOpen={this.state.showModal}
            contentLabel="form-edit-price"
            style={customStyles}
        >
          <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e, this.state.type)}
            >
              <div className="modal-header modal-header-form">
                <button type="button" className="close" onClick={this.handleCloseModal}><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">แก้ไขข้อมูลสินค้าแต่ละสาขา</h4>
              </div>
              <div className="modal-body modal-body-form">
                <div className="form-group">
                  <label className="control-label col-md-3">สินค้า Flash Sale</label>
                  <div className="col-md-9">
                    <Select
                      name="pr_online_id"
                      placeholder="เลือกสินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsProducts}
                      value={this.state.defaultProduct}
                      isDisabled={this.state.type == 'create' ? false : true }
                    />
                  </div>
                  <div className="errorMsg">{this.state.errors.pr_online_id}</div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">ราคาสินค้า Flash Sale</label>
                  <div className="col-md-4">
                    <input
                      name="flashsale_product_price_discount"
                      type="number"
                      className="form-control"
                      placeholder="กรอกราคาสินค้า Flash Sale"
                      value={this.state.fields.flashsale_product_price_discount || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.flashsale_product_price_discount}</div>
                  </div>
                </div>
                

              </div>
              <div className="modal-footer modal-footer-form">
                <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>Close</button>
                <button type="submit" className="btn btn-success">บันทึก</button>
              </div>
            </form>          
          </ReactModal>
        {this.state.modal}
      </div>
    );
  }
}
