import React from "react";
import $ from "jquery";
import { productAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../product.css";

$.DataTable = require("datatables.net");
let modalAlert;
// var editor;

export class Product_Branch_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    let ad_b_status = localStorage.getItem('ad_b_status');
    let ad_b_id = localStorage.getItem('ad_b_id');
    
    const columns = [
      {
        class: "details-control",
        orderable: false,
        data: null,
        defaultContent: ""
      },
      {
        title: "ชื่อสินค้า",
        class: "text-center",
        data: "pr_name"
      },
      {
        title: "รหัสสินค้า",
        class: "text-center",
        data: "pr_code"
      },
      {
        title: "หมวดหมู่สินค้า",
        class: "text-center",
        data: "pr_category_name"
      },
      {
        title: "สถานะสินค้า",
        class: "text-center",
        data: "pr_status"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "pr_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class='text-left'>${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class='text-left'>${cellData}</div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            let isStatus = ''
            if(cellData==1){
              isStatus = '<span class="label label-success label-status">ใช้งาน</span>'
            }else{
              isStatus = '<span class="label label-danger label-status">ไม่ใช้งาน</span>'
            }

            $(td)
              .html(isStatus);

              // <label class="switch">
              //   <input name="pr_status" className="activeBtn" type="checkbox" ${cellData==1 ? "checked" : ""} disabled/>
              //   <div class="slider"></div>
              // </label>
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/preorder/product/branch/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3"></i> แก้ไข</a>
                    </div>
                                        
                  </div>
                </div>
                `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.product}/product_by_branch?ad_b_status=${ad_b_status}&ad_b_id=${ad_b_id}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "ASC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
      },
      
    });

    function format ( d ) {
      let branch_by_product = ""
      let color_status_branch_switch = ""
      
      let status_branch = ""
      let status_switch = (d.pr_status != 1 ? "disabled" : "")
      let color_status_switch = (d.pr_status != 1 ? "color_switch_disabled" : "")
      
      for(var i in d.branch){
        if(d.branch[i].b_status == 1 && d.branch[i].b_preorder_status == 1){
          status_branch = "dot-green"
          color_status_branch_switch = ""
          status_switch = ""
        }else{
          status_branch = "dot-red"
          color_status_branch_switch = "color_switch_disabled"
          status_switch = "disabled"
        }

        branch_by_product += 
          '<tr>'+
            '<td>'+ `<div class=${status_branch}></div> ` + d.branch[i].b_name +'</td>'+
            '<td>'+d.branch[i].pr_branch_price+'</td>'+
            '<td>'+
              `<label class="switch">
                  <input name="status_soldout" class="soldoutBtn" data-id="${d.branch[i].pr_branch_id}" type="checkbox" ${d.branch[i].is_soldout==1 ? "checked" : ""} ${status_switch}/>
                  <div class="slider slider_2color ${color_status_switch} ${color_status_branch_switch}"></div>
                </label>`+
            '</td>'+
            '<td>'+
              `<label class="switch">
                  <input name="status_product" class="activeBtn" data-id="${d.branch[i].pr_branch_id}" type="checkbox" ${d.branch[i].pr_branch_status==1 ? "checked" : ""} ${status_switch}/>
                  <div class="slider ${color_status_switch} ${color_status_branch_switch}"></div>
                </label>`+
            '</td>'+
            // '<td>'+
            //   `<label class="switch">
            //       <input name="status_takehome" class="activeBtn_takehome" data-id="${d.branch[i].pr_branch_id}" type="checkbox" ${d.branch[i].pr_branch_takehome==1 ? "checked" : ""} ${status_switch}/>
            //       <div class="slider ${color_status_switch} ${color_status_branch_switch}"></div>
            //     </label>`+
            // '</td>'+
          '</tr>'
      }
      
      return '<table border="0" style="width:100%">'+
        '<thead class="header_table">'+
          '<tr>'+
            '<td style="width:25%">'+'ชื่อสาขา'+'</td>'+
            '<td style="width:25%">'+'ราคา'+'</td>'+
            '<td style="width:25%">'+'สถานะ soldout'+'</td>'+
            '<td style="width:25%">'+'สถานะแสดงสินค้า'+'</td>'+
            // '<td style="width:25%">'+'สถานะ Takehome'+'</td>'+
          '</tr>'+
        '</thead>'+
        '<tbody class="body_table">'+
          branch_by_product+
        '<tbody>'+
      '</table>';
    }

    $('#dataTable tbody').on('click', 'td.details-control', function () {
      var tr = $(this).closest('tr');
      var row = table.row( tr );

      if ( row.child.isShown() ) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('shown');
      }
      else {
          // Open this row
          row.child( format(row.data()) ).show();
          tr.addClass('shown');
      }
    });

    $(this.refs.main).on("click", ".soldoutBtn", function () {
      var id = $(this).data("id");
      var val = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("is_soldout", val);

      productAction.updateEatinBranchByProductID(formData, id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", ".activeBtn", function () {
      var id = $(this).data("id");
      var val = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("pr_branch_status", val);

      productAction.updateEatinBranchByProductID(formData, id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", ".activeBtn_takehome", function () {
      var id = $(this).data("id");
      var val = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("pr_branch_takehome", val);

      productAction.updateEatinBranchByProductID(formData, id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    var state = this;

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการสินค้าพรีออเดอร์แต่ละสาขา</h4>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
