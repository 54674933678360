import React from "react";
import { activityAction, productAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "./activity.css";
import Select from 'react-select';
import { promotionAction } from "../../../../_actions/promotion.action";
import moment from "moment-timezone";

let modalAlert;

export class Edit_Promotion_Payment extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: '',
      fields: {
        is_active: 0,
      },
      errors: {},
      promoCate: [],
      discountCate: [{label: 'เปอร์เซ็น', value: 'percentage'}, {label: 'บาท', value: 'baht'}],
      typeOrderCate: [{label: 'delivery', value: '1'}, {label: 'ems', value: '2'}, {label: 'pickup', value: '3'}, {label: 'snackbox', value: '4'}, {label: 'gift', value: '5'}],
      paymentChannel: [{label: 'E-Wallet', value: '1'}, {label: 'Credit/Debit Card', value: '2'}, {label: 'Cash On Delivery', value: '4'}],
      freeEarnCate: [],
      rewardsOption: [],
      selectData: {},
      promo_list_id: this.props.match.params.promo_list_id,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    await this.fetchInit();
    this.initialInputData();
  }

  fetchInit() {
    return new Promise(async resolve => {
      let {promo_list_id} = this.state;
      const promoCategoryPromise = promotionAction.listPaymentPromoCategory();
      const promotionByIdPromise = promotionAction.getPaymentPromotionById(promo_list_id);
      const promoFreeEarnCatePromise = promotionAction.listPaymentPromoFreeEarnCategory();
      const promoActiveRewardsDDPromise = promotionAction.getActiveRewardsDropdown();
  
      const [promoCategoryRes, promotionByIdRes, promoFreeEarnCateRes, promoActiveRewardsDDRes] = await Promise.all([
        promoCategoryPromise,
        promotionByIdPromise,
        promoFreeEarnCatePromise, 
        promoActiveRewardsDDPromise
      ]);
  
      const promoCateOptions = promoCategoryRes.data.map(item => ({
        value: item.promo_cate_code, label: item.promo_cate_name
      }));
      const promoFreeEarnCateOptions = promoFreeEarnCateRes.data.map(item => ({
        value: item.free_earn_cate_id, label: item.free_earn_name_en 
      }))
      const promoActiveRewardsDdOptions = promoActiveRewardsDDRes.data.map(item => ({
        value: item.rewards_code, label: item.rewards_name_th
      }));
      const fieldsData = promotionByIdRes.data;
  
      this.setState({
        promoCate: promoCateOptions,
        fields: fieldsData,
        freeEarnCate: promoFreeEarnCateOptions,
        rewardsOption: promoActiveRewardsDdOptions
      }, () => {
        resolve();
      });
    });
  }

  initialInputData() {
    let {promoCate, fields, selectData, freeEarnCate, rewardsOption, typeOrderCate, paymentChannel} = this.state;
    let promoCateOptions = promoCate.filter((item) => item.value === fields["promo_cate_code"])
    let promoFreeEarnCateOptions = freeEarnCate.filter((item) => item.value === fields["free_earn_cate_id"])
    let promoActiveRewardsDdOptions = rewardsOption.filter((item) => item.value === fields["rewards_code"])
    let promoTypeOrderOptions = [];
    let typeOrderList = String(fields["typeorder_id"]).split(',');
    let promoPaymentChannelOptions = []
    let paymentChannelList = String(fields["payment_channel_id"]).split(',');

    for(var i in typeOrderList)
    {
      for(var x in typeOrderCate)
      {
        if(typeOrderList[i] === typeOrderCate[x].value)
        {
          promoTypeOrderOptions.push(typeOrderCate[x])
        }
      }
    }
    for(var i in paymentChannelList)
    {
      for(var x in paymentChannel)
      {
        if(paymentChannelList[i] === paymentChannel[x].value)
        {
          promoPaymentChannelOptions.push(paymentChannel[x])
        }
      }
    }

    fields["payment_channel_id"] = promoPaymentChannelOptions;
    fields["typeorder_id"] = promoTypeOrderOptions;

    this.setState({
      selectData: {
        ...selectData, 
        promo_cate_code: promoCateOptions, 
        free_earn_cate_id: promoFreeEarnCateOptions, 
        rewards_code: promoActiveRewardsDdOptions, 
        typeorder_id: promoTypeOrderOptions,
        payment_channel_id: promoPaymentChannelOptions
      },
      fields
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/promotion/payment"
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      
    console.log('fields: ', fields);

    if (!fields["promo_list_code"]) {
      formIsValid = false;
      errors["promo_list_code"] = "*กรุณากรอกรหัสโปรโมชั่น";
    }
    if (!fields["promo_cate_code"]) {
      formIsValid = false;
      errors["promo_cate_code"] = "*กรุณาระบุเงื่อนไขโปรโมชั่น";
    }
    if (!fields["start_date"]) {
      formIsValid = false;
      errors["start_date"] = "*กรุณาระบุวันเริ่มต้นโปรโมชั่น";
    }
    if (!fields["end_date"]) {
      formIsValid = false;
      errors["end_date"] = "*กรุณาระบุวันสิ้นสุดโปรโมชั่น";
    }
    if (fields["promo_cate_code"] === 'PPMC001') { // สั่งออเดอร์ X ชำระเงินผ่านช่องทาง X รับฟรี Y
      if (!fields["typeorder_id"] || fields["typeorder_id"].length === 0) {
        formIsValid = false;
        errors["typeorder_id"] = "*กรุณาระบุประเภทออเดอร์";
      }
      if (!fields["payment_channel_id"] || fields["payment_channel_id"].length === 0) {
        formIsValid = false;
        errors["payment_channel_id"] = "*กรุณาระบุช่องทางการชำระเงิน";
      }
      if (!fields["free_earn_cate_id"]) {
        formIsValid = false;
        errors["free_earn_cate_id"] = "*กรุณาระบุประเภทของแถม";
      }else
      {
        if(fields["free_earn_cate_id"] === 1)  
        {
          if(!fields["earn_point"])
          {
            formIsValid = false;
            errors["earn_point"] = "*กรุณากรอกจำนวนพอยต์";  
          }
        }
        if(fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4)
        {
          if(!fields["rewards_code"])
          {
            formIsValid = false;
            errors["rewards_code"] = "*กรุณารีวอร์ด";  
          }
        }
      }
      if (fields["purchase_price"] < 0 || fields["purchase_price"] === null || fields["purchase_price"] === "") {
        formIsValid = false;
        errors["purchase_price"] = "*กรุณากรอกราคาซื้อขั้นต่ำ";
      }
    }
    if (fields["promo_cate_code"] === 'PPMC002') { // Topup X บาทแถมฟรี Y
      if (!fields["free_earn_cate_id"]) {
        formIsValid = false;
        errors["free_earn_cate_id"] = "*กรุณาระบุประเภทของแถม";
      }else
      {
        if(fields["free_earn_cate_id"] === 1)  
        {
          if(!fields["earn_point"])
          {
            formIsValid = false;
            errors["earn_point"] = "*กรุณากรอกจำนวนพอยต์";  
          }
        }
        if(fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4)
        {
          if(!fields["rewards_code"])
          {
            formIsValid = false;
            errors["rewards_code"] = "*กรุณารีวอร์ด";  
          }
        }
      }
      if (fields["purchase_price"] < 0 || fields["purchase_price"] === null || fields["purchase_price"] === "") {
        formIsValid = false;
        errors["purchase_price"] = "*กรุณากรอกราคาซื้อขั้นต่ำ";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    let {promo_list_id} = this.state;
    let inputBody = this.getReqPayload();
    promotionAction.updatePaymentPromotionById(inputBody, promo_list_id).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  getReqPayload = () => {
    let {fields} = this.state;
    let resData;

    if(fields["promo_cate_code"] === "PPMC001") // สั่งออเดอร์ X ชำระเงินผ่านช่องทาง X รับฟรี Y
    {
      resData = {
        "is_active": fields["is_active"],
        "promo_list_code": fields["promo_list_code"],
        "promo_cate_code": fields["promo_cate_code"],
        "typeorder_id": String(fields["typeorder_id"].map(item => item.value)),
        "payment_channel_id": String(fields["payment_channel_id"].map(item => item.value)),
        "free_earn_cate_id": fields["free_earn_cate_id"],
        "earn_point": fields["free_earn_cate_id"] === 1 ? parseInt(fields["earn_point"]) : 0,
        "rewards_code": fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4 ? fields["rewards_code"] : '',
        "purchase_price": parseFloat(fields["purchase_price"]).toFixed(2),
        "start_date": moment(fields["start_date"]).format('Y-MM-DD HH:mm:ss'),
        "end_date": moment(fields["end_date"]).format('Y-MM-DD HH:mm:ss'),
      }
    }
    if(fields["promo_cate_code"] === "PPMC002") // Topup X บาทแถมฟรี Y
    {
      resData = {
        "is_active": fields["is_active"],
        "promo_list_code": fields["promo_list_code"],
        "promo_cate_code": fields["promo_cate_code"],
        "typeorder_id": "",
        "payment_channel_id": "",
        "free_earn_cate_id": fields["free_earn_cate_id"],
        "earn_point": fields["free_earn_cate_id"] === 1 ? parseInt(fields["earn_point"]) : 0,
        "rewards_code": fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4 ? fields["rewards_code"] : '',
        "purchase_price": parseFloat(fields["purchase_price"]).toFixed(2),
        "start_date": moment(fields["start_date"]).format('Y-MM-DD HH:mm:ss'),
        "end_date": moment(fields["end_date"]).format('Y-MM-DD HH:mm:ss'),
      }
    }

    return resData;
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    if(name === 'promo_list_code')
    {
      fields[name] = String(value).toUpperCase();
      this.state.errors[name] = null;
      this.setState({ fields, errors });
    }else
    {
      fields[name] = value;
      this.state.errors[name] = null;
      this.setState({ fields, errors });
    }
  };

  handleSelectChange = (e, action) => {
    let { fields, errors, selectData } = this.state;
    fields[action.name] = e.value;
    selectData[action.name] = {value: e.value, label: e.label};
    this.state.errors[action.name] = null;
    this.setState({ fields, errors, selectData });
  };

  handleMultiSelectChange = (e, action) => {
    let { fields, errors, selectData } = this.state;
    fields[action.name] = e;
    selectData[action.name] = e;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors, selectData });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })
  }

  render() {
    let {promoCate, fields, typeOrderCate, paymentChannel, freeEarnCate, rewardsOption, selectData} = this.state;

    return (
      <div className="promotion-edit-con">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขโปรโมชั่นการชำระเงิน</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="form-group">
                <label className="control-label col-md-2">รหัสโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="promo_list_code"
                    type="text"
                    className="form-control"
                    placeholder="รหัสโปรโมชั่น"
                    value={this.state.fields.promo_list_code || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.promo_list_code}</div>
                </div>
                
                <label className="control-label col-md-2">เงื่อนไขโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <Select
                    name="promo_cate_code"
                    placeholder="เงื่อนไขโปรโมชั่น"
                    onChange={this.handleSelectChange}
                    options={promoCate}
                    value={selectData.promo_cate_code}
                  />
                  <div className="errorMsg">{this.state.errors.promo_cate_code}</div>
                </div>
              </div>

              {fields["promo_cate_code"] === 'PPMC001' && 
                <div className="form-group">
                  <label className="control-label col-md-2">ประเภทออเดอร์<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      isMulti
                      name="typeorder_id"
                      placeholder="ประเภทออเดอร์"
                      onChange={this.handleMultiSelectChange}
                      options={typeOrderCate}
                      value={selectData.typeorder_id}
                    />
                    <div className="errorMsg">{this.state.errors.typeorder_id}</div>
                  </div>
                  <label className="control-label col-md-2">ช่องทางการชำระเงิน<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      isMulti
                      name="payment_channel_id"
                      placeholder="ช่องทางการชำระเงิน"
                      onChange={this.handleMultiSelectChange}
                      options={paymentChannel}
                      value={selectData.payment_channel_id}
                    />
                    <div className="errorMsg">{this.state.errors.payment_channel_id}</div>
                  </div>
                </div>
              }

              {fields["promo_cate_code"] === 'PPMC002' && 
                <div className="form-group">
                  <label className="control-label col-md-2">Topup ขั้นต่ำ<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <input
                      name="purchase_price"
                      type="number"
                      className="form-control"
                      placeholder="Topup ขั้นต่ำ"
                      value={this.state.fields.purchase_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.purchase_price}</div>
                  </div>
                </div>
              }

              {fields["promo_cate_code"] === 'PPMC001' || fields["promo_cate_code"] === 'PPMC002' ?
                <div className="form-group">
                  <label className="control-label col-md-2">ประเภทของแถม<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      name="free_earn_cate_id"
                      placeholder="ประเภทของแถม"
                      onChange={this.handleSelectChange}
                      options={freeEarnCate}
                      value={selectData.free_earn_cate_id}
                    />
                    <div className="errorMsg">{this.state.errors.free_earn_cate_id}</div>
                  </div>
                  {/* Custom Point */}
                  {fields["free_earn_cate_id"] === 1 &&
                    <>
                    <label className="control-label col-md-2">คะแนน(กำหนดเอง)<span className="remark-red">*</span></label>
                    <div className="col-md-3">
                      <input
                        name="earn_point"
                        type="number"
                        className="form-control"
                        placeholder="คะแนน(กำหนดเอง)"
                        value={this.state.fields.earn_point || ""}
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.earn_point}</div>
                    </div>
                    </>
                  }
                  {/* Rewards */}
                  {fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4 ?
                    <>
                    <label className="control-label col-md-2">รีวอร์ด<span className="remark-red">*</span></label>
                    <div className="col-md-3">
                      <Select
                        name="rewards_code"
                        placeholder="รีวอร์ด"
                        onChange={this.handleSelectChange}
                        options={rewardsOption}
                        value={selectData.rewards_code}
                      />
                      <div className="errorMsg">{this.state.errors.rewards_code}</div>
                    </div>
                    </>
                    : ''
                  }
                </div>
                : ''
              }

              {fields["promo_cate_code"] === 'PPMC001' && 
                <div className="form-group">
                  <label className="control-label col-md-2">ราคาซื้อขั้นต่ำ<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <input
                      name="purchase_price"
                      type="number"
                      className="form-control"
                      placeholder="ราคาซื้อขั้นต่ำ"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.purchase_price}</div>
                  </div>
                </div>
              }

              <div className="form-group">
                <label className="control-label col-md-2">วันที่เริ่มโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="start_date"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่เริ่มโปรโมชั่น"
                    value={moment(this.state.fields.start_date).format('YYYY-MM-DDTHH:mm:ss') || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.start_date}</div>
                </div>

                <label className="control-label col-md-2">วันที่สิ้นสุดโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="end_date"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่สิ้นสุดโปรโมชั่น"
                    value={moment(this.state.fields.end_date).format('YYYY-MM-DDTHH:mm:ss') || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.end_date}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">การใช้งาน</label>
                <div className="col-md-3">
                  <label className="switch">
                    <input name="is_active" type="checkbox" onChange={this.handleInputChange} checked={this.state.fields["is_active"] === 1 ? true : false}/>
                    <div className="slider"></div>
                  </label>
                </div>
              </div>
  
              <div className="form-group">
                <div className="col-lg-12 text-right">
                  <input
                    type="submit"
                    className="btn btn-success btn-md btn-banner-create"
                    value="บันทึก"
                  />
                </div>
              </div>
            </form>  
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
