import React from "react";
import { activityAction } from "../../../../../_actions";
import { Base_API } from "../../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../../activity.css";
import Select from 'react-select';
const moment = require('moment-timezone');

let modalAlert;

export class Flashsale_Time_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      orderType: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmUpdate = this.onConfirmUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.flashsaleGetTime(params.flashsale_time_id);

    activityAction.listOrderType().then(res => {
      this.setState({
          orderType: res.data
      })
    })
  }

  flashsaleGetTime(flashsale_time_id) {

    activityAction.flashsaleGetTime(flashsale_time_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            flashsale_time_id: e.data.flashsale_time_id,
            flashsale_time_datetime_start: moment(e.data.flashsale_time_datetime_start).format("Y-MM-DD")+"T"+moment(e.data.flashsale_time_datetime_start).format("HH:mm:ss"),
            flashsale_time_datetime_end: moment(e.data.flashsale_time_datetime_end).format("Y-MM-DD")+"T"+moment(e.data.flashsale_time_datetime_start).format("HH:mm:ss"),
            typeorder_id: e.data.typeorder_id,
            typeorder_name: e.data.typeorder_name,
            is_active: e.data.is_active
          }
        });
        this.setState({isStatus: String(this.state.fields["is_active"]) == "1"});
        this.setState({status: this.state.fields["is_active"]});
        this.setState({
          defaultTypeOrder:
          {
            label: this.state.fields["typeorder_name"],
            value: this.state.fields["typeorder_id"]
          }
        });

        console.log(this.state.fields);
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmUpdate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/activity/flashsale/time";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["flashsale_time_datetime_start"]) {
      formIsValid = false;
      errors["flashsale_time_datetime_start"] = "*กรุณากรอกเวลาที่เริ่มกิจกรรม";
    }

    if (!fields["flashsale_time_datetime_end"]) {
      formIsValid = false;
      errors["flashsale_time_datetime_end"] = "*กรุณากรอกเวลาที่สิ้นสุดกิจกรรม";
    }

    if (!fields["typeorder_id"]) {
      formIsValid = false;
      errors["typeorder_id"] = "*กรุณาเลือกหมวดหมู่ออเดอร์";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmUpdate(ev) {
    this.setState({ modal: null });

    let inputBody = {
      flashsale_time_datetime_start: this.state.fields["flashsale_time_datetime_start"],
      flashsale_time_datetime_end: this.state.fields["flashsale_time_datetime_end"],
      typeorder_id: this.state.fields["typeorder_id"],
      is_active: this.state.fields["is_active"]
    }

    activityAction.flashsaleUpdateTime(inputBody, this.state.fields["flashsale_time_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active") {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };


  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    if(action.name == "typeorder_id"){
      this.setState({
        defaultTypeOrder:
        {
          label: e.label,
          value: e.value
        }
      })
    }
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })
  }


  render() {

    let optionsTypeOrder = this.state.orderType.map(function (ordertype) {
      return { value: ordertype.typeorder_id, label: ordertype.typeorder_name };
    })
    console.log(this.state.fields);
    console.log(this.state.defaultTypeOrder);

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขเวลา Flash Sale</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
              <div className="form-group">
                <label className="control-label col-md-2">วันที่เริ่มกิจกรรม</label>
                <div className="col-md-3">
                  <input
                    name="flashsale_time_datetime_start"
                    type="datetime-local"
                    className="form-control"
                    placeholder="กรอกวันที่เริ่มกิจกรรม"
                    value={ this.state.fields.flashsale_time_datetime_start}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.flashsale_time_datetime_start}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-2">วันที่สิ้นสุดกิจกรรม</label>
                <div className="col-md-3">
                  <input
                    name="flashsale_time_datetime_end"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่สิ้นสุดกิจกรรม"
                    value={this.state.fields.flashsale_time_datetime_end || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.flashsale_time_datetime_end}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-2">หมวดหมู่ออเดอร์</label>
                <div className="col-md-3">
                  <Select
                    name="typeorder_id"
                    placeholder="เลือกหมวดหมู่ออเดอร์"
                    onChange={this.handleSelectChange}
                    options={optionsTypeOrder}
                    value={this.state.defaultTypeOrder}
                  />
                  <div className="errorMsg">{this.state.errors.typeorder_id}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">การใช้งาน</label>
                <div className="col-md-3">
                <label className="switch">
                  <input
                    name="is_active"
                    type="checkbox"
                    onChange={this.handleChange}
                    checked={(this.state.fields["is_active"] === 1) ? true : false}
                    />
                  <div className="slider"></div>
                  </label>
                </div>
              </div>


                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
