import React from 'react';
import { Route , withRouter} from 'react-router-dom';
import $ from "jquery";
import { activityAction } from "../../../../../_actions";
import { Base_API } from "../../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../../activity.css";
import { NavLink } from "react-router-dom";


var urlAU = ""
const urlPath = window.location.hostname
if (urlPath === "https://podsable.com" || urlPath === "podsable.com") {
  // urlAU = "/afteryou"  
}

$.DataTable = require("datatables.net");
let modalAlert;

export class Flashsale_Time_List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      modal: null,
      isModal: false,
    };
  }

  componentDidMount() {
    const columns = [
      {
        title: "ชื่อกิจกรรม",
        class: "text-center",
        data: "flashsale_name_th"
      },
      {
        title: "วันที่เริ่มกิจกรรม",
        class: "text-center",
        data: "flashsale_time_datetime_start"
      },
      {
        title: "วันที่สิ้นสุดกิจกรรม",
        class: "text-left",
        data: "flashsale_time_datetime_end"
      },
      {
        title: "เวลาเริ่มต้นการแสดง",
        class: "text-center",
        data: "flashsale_specific_time_start"
      },
      {
        title: "เวลาลิ้นสุดการแสดง",
        class: "text-left",
        data: "flashsale_specific_time_end"
      },
      {
        title: "หมวดหมู่ออเดอร์",
        class: "text-center",
        data: "typeorder_name"
      },
      {
        title: "การใช้งาน",
        class: "text-center",
        data: "is_active"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "flashsale_time_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [ 
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-long"> ${cellData}</div>`);
          },
          orderable: false
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="is_active" class="activeBtn" data-id="${rowData.flashsale_time_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: false
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/activity/flashsale/time/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                      <button type='button' class="dropdown-item" name='list_product_btn' data-content='${cellData}'  data-name='${rowData.typeorder_name}' data-timestart='${rowData.flashsale_time_datetime_start}' data-timeend='${rowData.flashsale_time_datetime_end}'><i class="icon-pencil3 mr-3 mb-1"></i>จัดการข้อมูลสินค้า</button>
                      <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                    </div>
                  </div>
                    
                  </div>
                </div>
                `);

          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.product}/flashsale/list_time`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 2;

      let formData = {
        is_active: fval
      }

      activityAction.flashsaleUpdateTime(formData, fid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    $(this.refs.main).on("click", 'button[name="list_product_btn"]', function() {
      var id = $(this).data("content");
      var typeorder_name = $(this).data("name");
      var timestart = $(this).data("timestart");
      var timeend = $(this).data("timeend");
      gotoListProduct(id,typeorder_name,timestart,timeend)
    });

    function onConfirmDelete(id) {
      activityAction.flashsaleDeleteTime(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
           onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function gotoListProduct(id,typeorder_name,timestart,timeend){
      sessionStorage.setItem('flashsale_time_datetime_start' , timestart)
      sessionStorage.setItem('flashsale_time_datetime_end' , timeend)
      sessionStorage.setItem('typeorder_name' , typeorder_name)
      window.location.href=`/backoffice/activity/flashsale/${id}/product/list`

    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  onCancel(ev) {
    this.setState({ modal: null });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onReload}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">กิจกรรม Flash Sale</h4>
            <div className="heading-elements">
              <NavLink to={urlAU + "/backoffice/activity/flashsale/time/create"}>
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add"
                >
                  เพิ่มรายการ
                </button>
              </NavLink>

              {/* <a href="">
                
              </a> */}
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
