import React from "react";
import $ from "jquery";
import Select from 'react-select';
import { ewalletAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../report.css";
import ReactExport from 'react-data-export';

$.DataTable = require("datatables.net");

var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) dd='0'+dd;
if(mm<10)  var mm_lable='0'+mm;

let name_excel = yyyy+mm_lable+dd+hh+mn+ss+ms+'-report-monthly-settlement'

var dataSearch = {}

let arrYear = []
for(var i = 2 ; i >= 0; i--){
  let year3 = date.getFullYear();
  arrYear.push(
    {value: year3 - i, label: year3 - i}
  )
}

const arrMonth = [
  {value: '01', label: 'Jan'},
  {value: '02', label: 'Feb'},
  {value: '03', label: 'Mar'},
  {value: '04', label: 'Apr'},
  {value: '05', label: 'May'},
  {value: '06', label: 'Jun'},
  {value: '07', label: 'Jul'},
  {value: '08', label: 'Aug'},
  {value: '09', label: 'Sep'},
  {value: '10', label: 'Oct'},
  {value: '11', label: 'Nov'},
  {value: '12', label: 'Dec'},
]


export class Report_Monthly_Settlement_Ewallet extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      fields: {
        searchTransactionMonthFrom: {
          label: arrMonth[mm-1].label, 
          value: arrMonth[mm-1].value
        },
        searchTransactionYearFrom: {
          label: arrYear[2].label, 
          value: arrYear[2].value
        },
        searchTransactionMonthTo: {
          label: arrMonth[mm-1].label, 
          value: arrMonth[mm-1].value
        },
        searchTransactionYearTo: {
          label: arrYear[2].label, 
          value: arrYear[2].value
        },
  
      },
      payment_channel:[],
      transection_status:[],
    };
  }

  componentDidMount() {
    this.showTable(dataSearch);
  }

  showTable(dataSearch){
    
    const columns = [
      {
        title: "Month",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "Total Amount (฿)",
        class: "text-center",
        data: "total_amount"
      },
      {
        title: "Total MDR (฿)",
        class: "text-center",
        data: "total_MDR", 
      },
      {
        title: "Total Transaction Fee (฿)",
        class: "text-center",
        data: "total_transaction_fee", 
      },
      {
        title: "Total VAT (฿)",
        class: "text-center",
        data: "total_VAT"
      },
      {
        title: "Total EWT (฿)",
        class: "text-center",
        data: "total_EWT"
      },
      {
        title: "Net Amount (฿)",
        class: "text-center",
        data: "net_amount"
      },
      
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            let d = new Date(cellData);
            let month = d.getMonth()+1
            let year = d.getFullYear()
            if(month<10) month='0'+month;
            
            $(td)
              .html(`<div className="text-left">${month}/${year} </div>`);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div className="text-right">${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div className="text-right">${cellData}</div>`);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-right">${cellData}</div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-right">${cellData}</div>`);
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-right">${cellData}</div>`);
          }
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-right">${cellData}</div>`);
          }
        },
        
      ],

      ajax: {
        url: `${Base_API.ewallet}/api/v1/ewallet/report/monthly?searchTransactionMonthFrom=${dataSearch.searchTransactionMonthFrom}&searchTransactionYearFrom=${dataSearch.searchTransactionYearFrom}&searchTransactionMonthTo=${dataSearch.searchTransactionMonthTo}&searchTransactionYearTo=${dataSearch.searchTransactionYearTo}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[2, "DESC"]],
      columns,
      serverSide: true,
      ordering: false,
      searching: false,
      processing: true,
      bLengthChange: true,
      "language": {
        processing: '<i className="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  handleSubmit(e) {
    
    var table = $('#dataTable').DataTable();
    table.destroy();

    e.preventDefault()
    dataSearch = {
      searchTransactionMonthFrom: (this.state.fields["searchTransactionMonthFrom"].value ? this.state.fields["searchTransactionMonthFrom"].value : mm_lable),
      searchTransactionYearFrom: (this.state.fields["searchTransactionYearFrom"].value ? this.state.fields["searchTransactionYearFrom"].value : yyyy),
      searchTransactionMonthTo: (this.state.fields["searchTransactionMonthTo"].value ? this.state.fields["searchTransactionMonthTo"].value : mm_lable),
      searchTransactionYearTo: (this.state.fields["searchTransactionYearTo"].value ? this.state.fields["searchTransactionYearTo"].value : yyyy),
    }

    this.showTable(dataSearch);
    this.exportExcel(dataSearch)

  }

  handleChange = e => {
    let { fields } = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };


  handleSelectChange = (e, action) => {

    let { fields } = this.state;
    fields[action.name].label = e.label;
    fields[action.name].value = e.value;
    this.setState({ fields });
  };


  exportExcel = (dataSearch) => {
    ewalletAction.reportMonthlySettlement(dataSearch)
    .then(res => {
        this.setState({
            dataTransSearch: res.data
        })
    })
  };


  render() {   
    console.log(this.state.fields);


    let optionsMonth = arrMonth.map(function (item) {
      return { value: item.value, label: item.label };
    })
    
    let optionsYear = arrYear.map(function (item) {
      return { value: item.value, label: item.label };
    })    

    var dataExcel = []
    let data = this.state.dataTransSearch    
    for(var i in data){
      let d = new Date(data[i].created_at);
      let month = d.getMonth()+1
      let year = d.getFullYear()
      if(month<10) month='0'+month;

      dataExcel.push(
        [
          // {value: data[i].num},
          {value: month+'/'+year},
          {value: data[i].total_amount},
          {value: data[i].total_MDR},
          {value: data[i].total_transaction_fee},
          {value: data[i].total_VAT},
          {value: data[i].total_EWT},
          {value: data[i].net_amount},
        ],
      )
    }    
    
    multiDataSet = [
      {
        columns: 
        [
            {title: "Month", width: {wch: 20}},
            {title: "Total Amount (฿)", width: {wch: 20}},
            {title: "Total MDR (฿)", width: {wch: 20}},
            {title: "Total Transaction Fee (฿)", width: {wch: 20}},
            {title: "Total VAT (฿)", width: {wch: 20}},
            {title: "Total EWT (฿)", width: {wch: 20}},
            {title: "Net Amount (฿)", width: {wch: 20}},
        ],
        

        data: dataExcel
      }
    ];

    
    
    return (
      <div>
        <ol className="breadcrumb">
          <li>E-Wallet</li>
          <li>Report</li>
          <li className="active">Monthly Settlement</li>
        </ol>

        <ul className="nav nav-tabs">
          <li role="Daily Settlement"><a href="/backoffice/ewallet/report/daily/settlement">Daily Settlement</a></li>
          {/* <li role="Daily Refund" ><a href="/backoffice/ewallet/report/daily/refund">Daily Refund</a></li> */}
          <li role="Monthly Settlement" className="active"><a href="/backoffice/ewallet/report/monthly/settlement">Monthly Settlement</a></li>
          {/* <li role="Monthly Refund"><a href="/backoffice/ewallet/report/monthly/refund">Monthly Refund</a></li> */}
        </ul>
        <div className="panel panel-flat panel-ewallet">
          <div className="panel-body">

          <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
            <div>
              <h4>Monthly Settlement Report</h4>
              <div className="panel-body panel-color-gray px-5 py-3">
                
                <div className="pl-3">
                  
                  <div className="row mb-2 mt-2">
                    <div className="col-md-2 pt-2">Select Month: From</div>
                    <div className="col-md-4" style={{display:'inline-flex'}}>
                      <Select
                          className="select-input-month mr-2"
                          name="searchTransactionMonthFrom"
                          placeholder="mm"
                          onChange={this.handleSelectChange}
                          options={optionsMonth}
                          value={this.state.fields.searchTransactionMonthFrom}
                        />
                        <Select
                          className="select-input-month"
                          name="searchTransactionYearFrom"
                          placeholder="yyyy"
                          onChange={this.handleSelectChange}
                          options={optionsYear}
                          value={this.state.fields["searchTransactionYearFrom"]}

                        />
                    </div>
                    <div className="col-md-2 pt-2 text-right">To</div>
                    <div className="col-md-4" style={{display:'inline-flex'}}>
                      <Select
                          className="select-input-month mr-2"
                          name="searchTransactionMonthTo"
                          placeholder="mm"
                          onChange={this.handleSelectChange}
                          options={optionsMonth}
                          value={this.state.fields["searchTransactionMonthTo"]}

                        />
                        <Select
                          className="select-input-month"
                          name="searchTransactionYearTo"
                          placeholder="yyyy"
                          onChange={this.handleSelectChange}
                          options={optionsYear}
                          value={this.state.fields["searchTransactionYearTo"]}

                        />
                    </div>
                  </div>
                  
                  <div className="form-group mt-3">
                    <div className="">
                    <input type="submit" className="btn btn-success" value="Show Statement"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </form>

            <div>
              <div className="text-right my-2">
                <ExcelFile 
                  element={
                    <button
                    type="button"
                    name="add_banner_btn"
                    data-content=""
                    className="btn btn-add mr-2"
                    >
                    <i className="icon-download"></i> Export Excel
                    </button>
                  }
                  filename = {name_excel}
                >
                  <ExcelSheet dataSet={multiDataSet} name="Monthly Settlement" />
                </ExcelFile>
              </div>
              <div className="table-responsive">
                <table id="dataTable" className="table table-hover" ref="main" />
              </div>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
