import React from "react";
import { branchAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../branch.css";

let modalAlert;

export class Order_Online_Branch_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      fields: {},
      errors: {},
      typeProgram: null,
      selectType: null,
      isStatus: '',
      status: '',

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getBranchDataByID(params.branchid);
  }

  getBranchDataByID(b_id) {

    branchAction.getBranchPreorderId(b_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            branchCode: e.data.b_code,
            branchName: e.data.b_name,
            branchNameEN: e.data.b_name_en,
            branchAddress: e.data.b_address,
            branchAddressEN: e.data.b_address_en,
            branchTelephone: e.data.b_telephone,
            latName: e.data.b_lat,
            longName: e.data.b_long,
            branchImage: e.data.b_image,
            time_open: e.data.time_open,
            time_openEN: e.data.time_open_en,
            isStatus: e.data.b_status,
            b_id: b_id
          }
        });

        this.setState({isStatus: String(this.state.fields["isStatus"]) == "1"});
        this.setState({status: this.state.fields["isStatus"]});


      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/order-online/branch";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["branchCode"]) {
      formIsValid = false;
      errors["branchCode"] = "*กรุณากรอกรหัสสาขา";
    }

    if (!fields["branchName"]) {
      formIsValid = false;
      errors["branchName"] = "*กรุณาชื่อสาขา (TH)";
    }

    if (!fields["branchNameEN"]) {
      formIsValid = false;
      errors["branchNameEN"] = "*กรุณากรอกชื่อสาขา (EN)";
    }

    if (!fields["branchImage"]) {
      formIsValid = false;
      errors["branchImage"] = "*กรุณาแนบไฟล์รูปภาพ";
    }

    if (!fields["branchAddress"]) {
      formIsValid = false;
      errors["branchAddress"] = "*กรุณากรอกที่ตั้งสาขา (TH)";
    }

    if (!fields["branchAddressEN"]) {
      formIsValid = false;
      errors["branchAddressEN"] = "*กรุณากรอกที่ตั้งสาขา (EN)";
    }

    if (!fields["branchTelephone"]) {
      formIsValid = false;
      errors["branchTelephone"] = "*กรุณากรอกเบอร์โทรศัพท์";
    }

    if (!fields["latName"]) {
      formIsValid = false;
      errors["latName"] = "*กรุณากรอกละติจูด";
    }

    if (!fields["longName"]) {
      formIsValid = false;
      errors["longName"] = "*กรุณากรอกลองติจูด";
    }

    if (!fields["time_open"]) {
      formIsValid = false;
      errors["time_open"] = "*กรุณากรอกเวลาทำการ (TH)";
    }

    if (!fields["time_openEN"]) {
      formIsValid = false;
      errors["time_openEN"] = "*กรุณากรอกเวลาทำการ (EN)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    formData.append("b_code", this.state.fields["branchCode"]);
    formData.append("b_name", this.state.fields["branchName"]);
    formData.append("b_name_en", this.state.fields["branchNameEN"]);
    formData.append("b_address", this.state.fields["branchAddress"]);
    formData.append("b_address_en", this.state.fields["branchAddressEN"]);
    formData.append("b_image", this.state.fields["branchImage"]);
    formData.append("b_telephone", this.state.fields["branchTelephone"]);
    formData.append("b_lat", this.state.fields["latName"]);
    formData.append("b_long", this.state.fields["longName"]);
    formData.append("time_open", this.state.fields["time_open"]);
    formData.append("time_open_en", this.state.fields["time_openEN"]);
    formData.append("b_status", this.state.status);

    branchAction.updateBranchPreorder(formData, this.state.fields["b_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (selectedOption) => {
      let { fields, errors } = this.state;

      if(typeof selectedOption.value != 'undefined'){
        fields["cate_id"] = selectedOption.value;
        this.state.errors["cate_id"] = null;

        this.setState({
          defaultValue:
            {
              label: selectedOption.label,
              value: selectedOption.value
            }
        })
      }else {
        fields[selectedOption.target.name] = selectedOption.target.value;
        this.state.errors[selectedOption.target.name] = null;
      }
      this.setState({ fields, errors });

  };

  handleInputChange = e => {

  const target = e.target;
  const name = target.name;

  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })

    if(this.state.isStatus == true){
      this.setState({ status: '2' })
    }else{
      this.setState({ status: '1' })
    }

  }
}

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขสาขาที่รับสินค้าออนไลน์</h4>
          </div>
          <br />
          <div className="panel-body">
            <div className="text-center mb-5">
              <img
                className="preview-img"
                src={this.state.fields["branchImage"] || ""}
                alt={this.state.fields["branchName"] || ""}
              />
            </div>
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รูปภาพประจำสาขา
                    <div className="text-danger"> (1065 x 1065 px)</div>
                    </label>
                    <div className="col-lg-10">
                    <input type="file" className="file-input" name="branchImage" onChange={this.onChangeUploadHandler}/>
                    <div className="errorMsg">{this.state.errors.branchImage}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสาขา</label>
                  <div className="col-lg-10">
                  <input
                      name="branchCode"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสสาขา"
                      onChange={this.handleChange}
                      value={this.state.fields["branchCode"] || ""}
                    />
                    <div className="errorMsg">{this.state.errors.branchCode}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสาขา (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="branchName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสาขา (TH)"
                      onChange={this.handleChange}
                      value={this.state.fields["branchName"] || ""}
                    />
                    <div className="errorMsg">{this.state.errors.branchName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสาขา (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="branchNameEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสาขา (EN)"
                      onChange={this.handleChange}
                      value={this.state.fields["branchNameEN"] || ""}
                    />
                    <div className="errorMsg">{this.state.errors.branchNameEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ที่ตั้งสาขา (TH)</label>
                  <div className="col-lg-10">
                  <textarea
                      name="branchAddress"
                      className="form-control"
                      placeholder="กรอกที่ตั้งสาขา (TH)"
                      value={this.state.fields["branchAddress"] || ""}
                      onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.branchAddress}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ที่ตั้งสาขา (EN)</label>
                  <div className="col-lg-10">
                  <textarea
                      name="branchAddressEN"
                      className="form-control"
                      placeholder="กรอกที่ตั้งสาขา"
                      value={this.state.fields["branchAddressEN"] || ""}
                      onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.branchAddressEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เบอร์โทรศัพท์</label>
                  <div className="col-lg-4">
                  <input
                      name="branchTelephone"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      value={this.state.fields["branchTelephone"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branchTelephone}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ละติจูด</label>
                  <div className="col-lg-4">
                  <input
                      name="latName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกละติจูด"
                      value={this.state.fields["latName"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.latName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ลองติจูด</label>
                  <div className="col-lg-4">
                  <input
                      name="longName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกลองติจูด"
                      value={this.state.fields["longName"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.longName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เวลาทำการ (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="time_open"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเวลาทำการ เช่น 10:00 น. - 10:00 น. (ทุกวัน) หรือ จันทร์ - พฤหัสบดี เปิด 11.00 น. - 21.30 น."
                      value={this.state.fields["time_open"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.time_open}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เวลาทำการ (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="time_openEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเวลาทำการ เช่น 10:00 น. - 10:00 น. (ทุกวัน) หรือ จันทร์ - พฤหัสบดี เปิด 11.00 น. - 21.30 น."
                      value={this.state.fields["time_openEN"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.time_openEN}</div>
                  </div>
                </div>

                

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
