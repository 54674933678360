import React from "react";
import { adminAction, branchAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../admin.css";
import Select from "react-select";

let modalAlert;

export class Admin_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      errors: {},
      selectOptionLevel: [],
      selectOptionBranch: [],
      select: {
        level: {
          value: ""
        },
        branch: {
          value: ""
        }
      },
      fields: {
        'ad_b_status': 0
      },
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let { selectOptionLevel, selectOptionBranch } = this.state;

    adminAction.listLevelAdmin().then(res => {
      for (var i in res.data) {
        selectOptionLevel.push({
          label: res.data[i].level_name,
          value: res.data[i].level_id
        });
      }
      this.setState({ selectOptionLevel });
    });

    branchAction.listBranchAll().then(res_branch => {
      for (var j in res_branch.data) {
        selectOptionBranch.push({
          label: res_branch.data[j].b_name,
          value: res_branch.data[j].b_id
        });
      }
      this.setState({ selectOptionBranch });
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/admin"
  }

  validateForm() {

    let fields = this.state.fields;
    let select = this.state.select;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*กรุณากรอกชื่อ.";
    }

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "*กรุณากรอกนามสกุล.";
    }

    if (!fields["telephone"]) {
      formIsValid = false;
      errors["telephone"] = "*กรุณากรอกเบอร์โทรศัพท์";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*กรุณากรอก e-mail";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*กรุณากรอกรหัสผ่าน";
    }

    if (!fields["cf_password"]) {
      formIsValid = false;
      errors["cf_password"] = "*กรุณากรอกยืนยันรหัสผ่าน";
    }

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "*กรุณากรอก username";
    }

    if (select.level["value"] == "") {
      formIsValid = false;
      errors["level"] = "*กรุณาเลือกระดับ";
    }

    if(fields["password"] !== "" && typeof fields["password"] !== 'undefined'){
      if (fields["password"].length < 8) {
        formIsValid = false;
        errors["password"] = "*กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว";
      }
    }

    if(fields["cf_password"] !== "" && typeof fields["password"] !== 'undefined'){
      if (fields["password"] !== fields["cf_password"]) {
        formIsValid = false;
        errors["cf_password"] = "*ยืนยันรหัสผ่านไม่ถูกต้อง";
      }
    }

    if (fields["ad_b_status"] == 1 && !select.branch["value"]) {
      formIsValid = false;
      errors["branch"] = "*กรุณาเลือกสาขาที่สังกัด";
    }
    console.log(fields["ad_b_status"] == 1 && !select.branch["value"]);

    this.setState({
      errors: errors
    });
    return formIsValid;
  }


  validateData() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if(fields["password"] !== "" && typeof fields["password"] !== 'undefined'){
      if (fields["password"].length < 8) {
        formIsValid = false;
        errors["password"] = "*กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    var formData = ({
      'ad_firstname' : this.state.fields["firstName"],
      'ad_lastname' : this.state.fields["lastName"],
      'ad_phone' : this.state.fields["telephone"],
      'ad_email' : this.state.fields["email"],
      'ad_username' : this.state.fields["username"],
      'ad_password' : this.state.fields["password"],
      "ad_level" : this.state.select.level["value"],
      'ad_b_status' : this.state.fields["ad_b_status"],
      'ad_b_id' : this.state.select.branch["value"],
      'is_active' : this.state.fields["is_active"] ? this.state.fields["is_active"] : 0,
      'is_delete' : 0
    });

    adminAction.createAdmin(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
  })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleSelect = (e, action) => {
    let { select, fields, errors } = this.state;    
    select[action.name] = {
      label: e.label,
      value: e.value
    };
    this.state.errors[action.name] = null;
    this.setState({ select, fields, errors });
  };


  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active" || e.target.name === "ad_b_status") {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });

    // this.validateData();


  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };


  render() {
    let { select } = this.state;

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มข้อมูลผู้ดูแลระบบ</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อจริง</label>
                  <div className="col-lg-4">
                  <input
                      name="firstName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อจริง"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.firstName}</div>
                  </div>

                  <label className="control-label col-lg-2">นามสกุล</label>
                  <div className="col-lg-4">
                  <input
                      name="lastName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกนามสกุล"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.lastName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เบอร์โทรศัพท์</label>
                  <div className="col-lg-4">
                  <input
                      name="telephone"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.telephone}</div>
                  </div>

                  <label className="control-label col-lg-2">E-Mail</label>
                  <div className="col-lg-4">
                  <input
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="กรอก e-mail"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.email}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ระดับ</label>
                  <div className="col-lg-4">
                    <Select
                        className="typeSelect"
                        classNamePrefix="typeSelect-menu"
                        placeholder="เลือกระดับ"
                        value={select.level}
                        name="level"
                        onChange={this.handleSelect}
                        options={this.state.selectOptionLevel}
                      />
                    <div className="errorMsg">{this.state.errors["level"]}</div>
                  </div>

                  <label className="control-label col-lg-2">Username</label>
                    <div className="col-lg-4">
                    <input
                        name="username"
                        type="text"
                        className="form-control"
                        placeholder="กรอก Username"
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.username}</div>
                    </div>
                  </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสผ่าน</label>
                  <div className="col-lg-4">
                  <input
                      name="password"
                      type="password"
                      className="form-control"
                      placeholder="กรอกรหัสผ่าน"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.password}</div>
                  </div>

                  <label className="control-label col-lg-2">ยืนยันรหัสผ่าน</label>
                  <div className="col-lg-4">
                  <input
                      name="cf_password"
                      type="password"
                      className="form-control"
                      placeholder="กรอกยืนยันรหัสผ่าน"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.cf_password}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเป็นพนักงานสาขา</label>
                  <div className="col-lg-4">
                    <label className="switch">
                      <input
                        name="ad_b_status"
                        type="checkbox"
                        onChange={this.handleChange}
                      />
                      <div className="slider round" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">สาขาที่สังกัด</label>
                  <div className="col-lg-4">
                    <Select
                      className="typeSelect"
                      classNamePrefix="typeSelect-menu"
                      placeholder="เลือกสาขาที่สังกัด"
                      value={select.branch}
                      name="branch"
                      onChange={this.handleSelect}
                      options={this.state.selectOptionBranch}
                    />
                    <div className="errorMsg">{this.state.errors["branch"]}</div>
                  </div>
                </div>
                

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะเปิดใช้งาน</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="is_active"
                        type="checkbox"
                        onChange={this.handleChange}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
