import React from "react";
import { branchAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../branch.css";

let modalAlert;

export class Branch_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      isStatus: '',
      isStatus_preorder: '',
      isStatus_orderonline: '',
      inputCheck: {
        branch_time_monday_status: 0,
        branch_time_tuesday_status: 0,
        branch_time_wednesday_status: 0,
        branch_time_thursday_status: 0,
        branch_time_friday_status: 0,
        branch_time_saturday_status: 0,
        branch_time_sunday_status: 0
      }

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getBranchDataByID(params.branchid);
  }

  getBranchDataByID(b_id) {

    branchAction.getBranchEatinId(b_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            branchCode: e.data.b_code,
            branchName: e.data.b_name,
            branchNameEN: e.data.b_name_en,
            branchAddress: e.data.b_address,
            branchAddressEN: e.data.b_address_en,
            branchTelephone: e.data.b_telephone,
            latName: e.data.b_lat,
            longName: e.data.b_long,
            branchImage: e.data.b_image,
            branchImagePin: e.data.b_image_pin,
            time_open: e.data.time_open,
            time_openEN: e.data.time_open_en,
            isStatus: e.data.b_status,
            isStatus_preorder: e.data.b_preorder_status,
            isStatus_orderonline: e.data.b_orderonline_status,
            
            branch_time_monday_open: e.data.branch_time_monday_open,
            branch_time_monday_close: e.data.branch_time_monday_close,
            branch_time_tuesday_open: e.data.branch_time_tuesday_open,
            branch_time_tuesday_close: e.data.branch_time_tuesday_close,
            branch_time_wednesday_open: e.data.branch_time_wednesday_open,
            branch_time_wednesday_close: e.data.branch_time_wednesday_close,
            branch_time_thursday_open: e.data.branch_time_thursday_open,
            branch_time_thursday_close: e.data.branch_time_thursday_close,
            branch_time_friday_open: e.data.branch_time_friday_open,
            branch_time_friday_close: e.data.branch_time_friday_close,
            branch_time_saturday_open: e.data.branch_time_saturday_open,
            branch_time_saturday_close: e.data.branch_time_saturday_close,
            branch_time_sunday_open: e.data.branch_time_sunday_open,
            branch_time_sunday_close: e.data.branch_time_sunday_close,

            b_id: b_id
          },
          inputCheck: {
            ...this.state.inputCheck,
            branch_time_monday_status: e.data.branch_time_monday_status,
            branch_time_tuesday_status: e.data.branch_time_tuesday_status,
            branch_time_wednesday_status: e.data.branch_time_wednesday_status,
            branch_time_thursday_status: e.data.branch_time_thursday_status,
            branch_time_friday_status: e.data.branch_time_friday_status,
            branch_time_saturday_status: e.data.branch_time_saturday_status,
            branch_time_sunday_status: e.data.branch_time_sunday_status,
          }
        });

        this.setState({
          isStatus: this.state.fields["isStatus"],
          // status: this.state.fields["isStatus"],
          isStatus_preorder: this.state.fields["isStatus_preorder"],
          // status_preorder: this.state.fields["isStatus_preorder"],
          isStatus_orderonline: this.state.fields["isStatus_orderonline"],
          // status_orderonline: this.state.fields["isStatus_orderonline"]
        });


      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/preorder/branch";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["branchCode"]) {
      formIsValid = false;
      errors["branchCode"] = "*กรุณากรอกรหัสสาขา";
    }

    if (!fields["branchName"]) {
      formIsValid = false;
      errors["branchName"] = "*กรุณาชื่อสาขา (TH)";
    }

    if (!fields["branchNameEN"]) {
      formIsValid = false;
      errors["branchNameEN"] = "*กรุณากรอกชื่อสาขา (EN)";
    }

    if (!fields["branchImage"]) {
      formIsValid = false;
      errors["branchImage"] = "*กรุณาแนบไฟล์รูปภาพ";
    }

    if (!fields["branchImagePin"]) {
      formIsValid = false;
      errors["branchImagePin"] = "*กรุณาแนบไฟล์รูปภาพ Pin";
    }

    if (!fields["branchAddress"]) {
      formIsValid = false;
      errors["branchAddress"] = "*กรุณากรอกที่ตั้งสาขา (TH)";
    }

    if (!fields["branchAddressEN"]) {
      formIsValid = false;
      errors["branchAddressEN"] = "*กรุณากรอกที่ตั้งสาขา (EN)";
    }

    if (!fields["branchTelephone"]) {
      formIsValid = false;
      errors["branchTelephone"] = "*กรุณากรอกเบอร์โทรศัพท์";
    }

    if (!fields["latName"]) {
      formIsValid = false;
      errors["latName"] = "*กรุณากรอกละติจูด";
    }

    if (!fields["longName"]) {
      formIsValid = false;
      errors["longName"] = "*กรุณากรอกลองติจูด";
    }

    if (!fields["time_open"]) {
      formIsValid = false;
      errors["time_open"] = "*กรุณากรอกเวลาทำการ (TH)";
    }

    if (!fields["time_openEN"]) {
      formIsValid = false;
      errors["time_openEN"] = "*กรุณากรอกเวลาทำการ (EN)";
    }

    if (!fields["branch_time_monday_open"]) {
      formIsValid = false;
      errors["branch_time_monday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันจันทร์)";
    }

    if (!fields["branch_time_monday_close"]) {
      formIsValid = false;
      errors["branch_time_monday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันจันทร์)";
    }

    if (!fields["branch_time_tuesday_open"]) {
      formIsValid = false;
      errors["branch_time_tuesday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันอังคาร)";
    }

    if (!fields["branch_time_tuesday_close"]) {
      formIsValid = false;
      errors["branch_time_tuesday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันอังคาร)";
    }

    if (!fields["branch_time_wednesday_open"]) {
      formIsValid = false;
      errors["branch_time_wednesday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันพุธ)";
    }

    if (!fields["branch_time_wednesday_close"]) {
      formIsValid = false;
      errors["branch_time_wednesday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันพุธ)";
    }

    if (!fields["branch_time_thursday_open"]) {
      formIsValid = false;
      errors["branch_time_thursday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันพฤหัสบดี)";
    }

    if (!fields["branch_time_thursday_close"]) {
      formIsValid = false;
      errors["branch_time_thursday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันพฤหัสบดี)";
    }

    if (!fields["branch_time_friday_open"]) {
      formIsValid = false;
      errors["branch_time_friday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันศุกร์)";
    }

    if (!fields["branch_time_friday_close"]) {
      formIsValid = false;
      errors["branch_time_friday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันศุกร์)";
    }

    if (!fields["branch_time_saturday_open"]) {
      formIsValid = false;
      errors["branch_time_saturday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันเสาร์)";
    }

    if (!fields["branch_time_saturday_close"]) {
      formIsValid = false;
      errors["branch_time_saturday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันเสาร์)";
    }

    if (!fields["branch_time_sunday_open"]) {
      formIsValid = false;
      errors["branch_time_sunday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันอาทิตย์)";
    }

    if (!fields["branch_time_sunday_close"]) {
      formIsValid = false;
      errors["branch_time_sunday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันอาทิตย์)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    formData.append("b_image", this.state.fields["branchImage"]);
    formData.append("b_image_pin", this.state.fields["branchImagePin"]);
    formData.append("b_code", this.state.fields["branchCode"]);
    formData.append("b_name", this.state.fields["branchName"]);
    formData.append("b_name_en", this.state.fields["branchNameEN"]);
    formData.append("b_address", this.state.fields["branchAddress"]);
    formData.append("b_address_en", this.state.fields["branchAddressEN"]);
    formData.append("b_telephone", this.state.fields["branchTelephone"]);
    formData.append("b_lat", this.state.fields["latName"]);
    formData.append("b_long", this.state.fields["longName"]);
    formData.append("time_open", this.state.fields["time_open"]);
    formData.append("time_open_en", this.state.fields["time_openEN"]);
    formData.append("b_status", (this.state.isStatus == true ? 1 : 0));
    formData.append("b_preorder_status", (this.state.isStatus_preorder == true ? 1 : 0));
    formData.append("b_orderonline_status", (this.state.isStatus_orderonline== true ? 1 : 0));

    formData.append("branch_time_monday_status", this.state.inputCheck["branch_time_monday_status"]);
    formData.append("branch_time_monday_open", this.state.fields["branch_time_monday_open"]);
    formData.append("branch_time_monday_close", this.state.fields["branch_time_monday_close"]);

    formData.append("branch_time_tuesday_status", this.state.inputCheck["branch_time_tuesday_status"]);
    formData.append("branch_time_tuesday_open", this.state.fields["branch_time_tuesday_open"]);
    formData.append("branch_time_tuesday_close", this.state.fields["branch_time_tuesday_close"]);

    formData.append("branch_time_wednesday_status", this.state.inputCheck["branch_time_wednesday_status"]);
    formData.append("branch_time_wednesday_open", this.state.fields["branch_time_wednesday_open"]);
    formData.append("branch_time_wednesday_close", this.state.fields["branch_time_wednesday_close"]);

    formData.append("branch_time_thursday_status", this.state.inputCheck["branch_time_thursday_status"]);
    formData.append("branch_time_thursday_open", this.state.fields["branch_time_thursday_open"]);
    formData.append("branch_time_thursday_close", this.state.fields["branch_time_thursday_close"]);

    formData.append("branch_time_friday_status", this.state.inputCheck["branch_time_friday_status"]);
    formData.append("branch_time_friday_open", this.state.fields["branch_time_friday_open"]);
    formData.append("branch_time_friday_close", this.state.fields["branch_time_friday_close"]);

    formData.append("branch_time_saturday_status", this.state.inputCheck["branch_time_saturday_status"]);
    formData.append("branch_time_saturday_open", this.state.fields["branch_time_saturday_open"]);
    formData.append("branch_time_saturday_close", this.state.fields["branch_time_saturday_close"]);

    formData.append("branch_time_sunday_status", this.state.inputCheck["branch_time_sunday_status"]);
    formData.append("branch_time_sunday_open", this.state.fields["branch_time_sunday_open"]);
    formData.append("branch_time_sunday_close", this.state.fields["branch_time_sunday_close"]);


    branchAction.updateBranchEatin(formData, this.state.fields["b_id"]).then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (selectedOption) => {
      let { fields, errors } = this.state;

      if(typeof selectedOption.value != 'undefined'){
        fields["cate_id"] = selectedOption.value;
        this.state.errors["cate_id"] = null;

        this.setState({
          defaultValue:
            {
              label: selectedOption.label,
              value: selectedOption.value
            }
        })
      }else {
        fields[selectedOption.target.name] = selectedOption.target.value;
        this.state.errors[selectedOption.target.name] = null;
      }
      this.setState({ fields, errors });
  };

  handleInputChange = e => {
    let { inputCheck } = this.state;
    const target = e.target;
    const name = target.name;

    if(name == "isStatus"){
      this.setState({ isStatus: !this.state.isStatus })
    }else if(name == "isStatus_preorder"){
      this.setState({ isStatus_preorder: !this.state.isStatus_preorder })
    }else if(name == "isStatus_orderonline"){
      this.setState({ isStatus_orderonline: !this.state.isStatus_orderonline })
    }else if(
      name === "branch_time_monday_status" || 
      name === "branch_time_tuesday_status" ||
      name === "branch_time_wednesday_status" ||
      name === "branch_time_thursday_status" ||
      name === "branch_time_friday_status" ||
      name === "branch_time_saturday_status" ||
      name === "branch_time_sunday_status"
      ){
      inputCheck[name] = e.target.checked === true ? 1 : 0;
      this.setState({ inputCheck });
    }
  }

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขสาขา หมวด EAT IN</h4>
          </div>
          <br />
          <div className="panel-body">
            <div className="text-center mb-5">
              <img
                className="preview-img"
                src={this.state.fields["branchImage"] || ""}
                alt={this.state.fields["branchName"] || ""}
              />
            </div>

            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                <label className="control-label col-lg-2">
                  รูปภาพประจำสาขา
                  <div className="text-danger"> (1200 x 750 px)</div>
                </label>
                  <div className="col-lg-10">
                  <input type="file" className="file-input" name="branchImage" onChange={this.onChangeUploadHandler}/>
                  <div className="errorMsg">{this.state.errors.branchImage}</div>
                  </div>
                </div>

                <div className="text-center mb-5">
                  <img
                    className="preview-img"
                    src={this.state.fields["branchImagePin"] || ""}
                    alt={this.state.fields["branchImagePin"] || ""}
                  />
                </div>

                <div className="form-group">
                <label className="control-label col-lg-2">
                รูปภาพ Pin
                  <div className="text-danger"> (500 x 500 px)</div>
                </label>
                  <div className="col-lg-10">
                  <input type="file" className="file-input" name="branchImagePin" onChange={this.onChangeUploadHandler}/>
                  <div className="errorMsg">{this.state.errors.branchImagePin}</div>
                  </div>
                </div>
                
                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสาขา</label>
                  <div className="col-lg-10">
                  <input
                      name="branchCode"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสสาขา"
                      onChange={this.handleChange}
                      value={this.state.fields["branchCode"] || ""}
                    />
                    <div className="errorMsg">{this.state.errors.branchCode}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสาขา (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="branchName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสาขา (TH)"
                      onChange={this.handleChange}
                      value={this.state.fields["branchName"] || ""}
                    />
                    <div className="errorMsg">{this.state.errors.branchName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสาขา (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="branchNameEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสาขา (EN)"
                      onChange={this.handleChange}
                      value={this.state.fields["branchNameEN"] || ""}
                    />
                    <div className="errorMsg">{this.state.errors.branchNameEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ที่ตั้งสาขา (TH)</label>
                  <div className="col-lg-10">
                  <textarea
                      name="branchAddress"
                      className="form-control"
                      placeholder="กรอกที่ตั้งสาขา (TH)"
                      value={this.state.fields["branchAddress"] || ""}
                      onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.branchAddress}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ที่ตั้งสาขา (EN)</label>
                  <div className="col-lg-10">
                  <textarea
                      name="branchAddressEN"
                      className="form-control"
                      placeholder="กรอกที่ตั้งสาขา"
                      value={this.state.fields["branchAddressEN"] || ""}
                      onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.branchAddressEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เบอร์โทรศัพท์</label>
                  <div className="col-lg-4">
                  <input
                      name="branchTelephone"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      value={this.state.fields["branchTelephone"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branchTelephone}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ละติจูด</label>
                  <div className="col-lg-4">
                  <input
                      name="latName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกละติจูด"
                      value={this.state.fields["latName"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.latName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ลองติจูด</label>
                  <div className="col-lg-4">
                  <input
                      name="longName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกลองติจูด"
                      value={this.state.fields["longName"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.longName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เวลาทำการ (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="time_open"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเวลาทำการ เช่น 10:00 น. - 10:00 น. (ทุกวัน) หรือ จันทร์ - พฤหัสบดี เปิด 11.00 น. - 21.30 น."
                      value={this.state.fields["time_open"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.time_open}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เวลาทำการ (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="time_openEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเวลาทำการ เช่น 10:00 น. - 10:00 น. (ทุกวัน) หรือ จันทร์ - พฤหัสบดี เปิด 11.00 น. - 21.30 น."
                      value={this.state.fields["time_openEN"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.time_openEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันจันทร์)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_monday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_monday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันจันทร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_monday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันจันทร์)"
                      value={this.state.fields['branch_time_monday_open']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_monday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันจันทร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_monday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันจันทร์)"
                      value={this.state.fields['branch_time_monday_close']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_monday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันอังคาร)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_tuesday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_tuesday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันอังคาร)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_tuesday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันอังคาร)"
                      value={this.state.fields['branch_time_tuesday_open']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_tuesday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันอังคาร)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_tuesday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันอังคาร)"
                      value={this.state.fields['branch_time_tuesday_close']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_tuesday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันพุธ)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_wednesday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_wednesday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันพุธ)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_wednesday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันพุธ)"
                      value={this.state.fields['branch_time_wednesday_open']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_wednesday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันพุธ)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_wednesday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันพุธ)"
                      value={this.state.fields['branch_time_wednesday_close']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_wednesday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันพฤหัสบดี)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_thursday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_thursday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันพฤหัสบดี)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_thursday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันพฤหัสบดี)"
                      value={this.state.fields['branch_time_thursday_open']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_thursday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันพฤหัสบดี)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_thursday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันพฤหัสบดี)"
                      value={this.state.fields['branch_time_thursday_close']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_thursday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันศุกร์)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_friday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_friday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันศุกร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_friday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันศุกร์)"
                      value={this.state.fields['branch_time_friday_open']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_friday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันศุกร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_friday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันศุกร์)"
                      value={this.state.fields['branch_time_friday_close']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_friday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันเสาร์)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_saturday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_saturday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันเสาร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_saturday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันเสาร์)"
                      value={this.state.fields['branch_time_saturday_open']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_saturday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันเสาร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_saturday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันเสาร์)"
                      value={this.state.fields['branch_time_saturday_close']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_saturday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันอาทิตย์)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_sunday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_sunday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันอาทิตย์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_sunday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันอาทิตย์)"
                      value={this.state.fields['branch_time_sunday_open']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_sunday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันอาทิตย์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_sunday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันอาทิตย์)"
                      value={this.state.fields['branch_time_sunday_close']}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_sunday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสาขา</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสาขาพรีออเดอร์</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isStatus_preorder" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus_preorder}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสาขาออนไลน์</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isStatus_orderonline" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus_orderonline}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
