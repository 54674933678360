import React from "react";
import { Base_API } from "../../../../_constants/matcher";
import { orderAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../order.css";

let modalAlert;

export class PreOrder_Info extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      typeProgram: null,
      selectType: null,
      inputBody: {},
      inputBodyDetail: [],
    };

  }

  componentDidMount() {
    var params = this.props.match.params;
    this.getOrderDataByID(params.od_number);
    this.getOrderDetail(params.od_number);
  }

  getOrderDataByID(id) {
    orderAction.getEatinOrderId(id).then(e => {
      if (e.isSuccess === true) {
        var newObject = Object.assign(e.data, this.state.inputBody);
        if(newObject['status_payment'] === 1) newObject['status_payment_name'] = <strong className="text-orange-400">{newObject['status_payment_name']}</strong>
        else if(newObject['status_payment'] === 2) newObject['status_payment_name'] = <strong className="text-success">{newObject['status_payment_name']}</strong>
        else if(newObject['status_payment'] === 3) newObject['status_payment_name'] = <strong className="text-danger">{newObject['status_payment_name']}</strong>
        else newObject['status_payment'] = <strong className="text-info">ไม่ระบุสถานะ</strong>

        this.setState({ inputBody: newObject });
      } else {
        alert("ไม่พบข้อมูล");
      }
    });
  }

  getOrderDetail(id) {
    orderAction.getEatinOrderDetailId(id).then(e => {
      if (e.isSuccess === true) {
        var newObject = Object.assign(e.data, this.state.inputBodyDetail);
        this.setState({ inputBodyDetail: newObject });
      } else {
        alert("ไม่พบข้อมูล");
      }
    });
  }

  onImageError(e) {
    e.target.setAttribute(
      "src",
      `${process.env.PUBLIC_URL}/images/default.png`
    );
  }

  render() {
    let listProduct = this.state.inputBodyDetail.map(( item, product ) => {
        return (
          <tr key={product}>
            <td>{item.pr_name}</td>
            <td>{item.price} ฿</td>
            <td>{item.od_product_count}</td>
            <td>{item.total_product_price} ฿</td>
          </tr>
        );
      })

    return (
      <div className="reward-container row">
        <div className="col-12">
          <div className="panel panel-flat">
            <div className="panel-heading panel-heading-wline">
              <h4 className="panel-title">รายการสั่งซื้อหน้าร้าน (Eat In)</h4>
            </div>
            <div className="panel-body pt-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="row f-16">
                    <div className="col-md-12"><strong>ข้อมูลทั่วไป</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">รหัสการซื้อสินค้า :</div>
                    <div className="col-md-8">{this.state.inputBody['od_number']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">วันที่สร้างรายการ :</div>
                    <div className="col-md-8">{this.state.inputBody['created_at']}</div>
                  </div>
                  <div className="row pt-3 f-16">
                    <div className="col-md-12"><strong>สาขาที่รับสินค้า</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ชื่อสาขา :</div>
                    <div className="col-md-8">{this.state.inputBody['b_name']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ที่อยู่ :</div>
                    <div className="col-md-8">{this.state.inputBody['b_address']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ละติจูด :</div>
                    <div className="col-md-8">{this.state.inputBody['b_lat']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ลองจิจูด :</div>
                    <div className="col-md-8">{this.state.inputBody['b_long']}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row f-16">
                    <div className="col-md-12"><strong>รายละเอียดสมาชิก</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ชื่อสมาชิก :</div>
                    <div className="col-md-8"><a href={`/backoffice/member/edit/${this.state.inputBody['mem_id']}`}>{this.state.inputBody['mem_firstname']} {this.state.inputBody['mem_lastname']}</a></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">อีเมล :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_email']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">เลขบัตรประชาชน :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_idcard']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">วันเกิด :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_birthdate']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">โทร :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_phone']}</div>
                  </div>
                  <div className="row pt-3 f-16">
                    <div className="col-md-4">สถานะ :</div>
                    <div className="col-md-8">{this.state.inputBody['status_payment_name']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">หมายเหตุ :</div>
                    <div className="col-md-8">{this.state.inputBody['od_comment']}</div>
                  </div>
                </div>
              </div>
              <div className="row pt-5 m-1">
                <div className="col-12">
                  <h4 className="panel-title">รายการสั่งซื้อสินค้า</h4>
                </div>
              </div>
              <div className="row pt-3 m-1">
                <div className="col-12">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td width="50%"><strong>ชื่อสินค้า</strong></td>
                        <td><strong>ราคา (/ชิ้น)</strong></td>
                        <td><strong>จำนวน (ชิ้น)</strong></td>
                        <td><strong>ราคารวม</strong></td>
                      </tr>
                    </thead>
                    <tbody>
                      {listProduct}
                      <tr>
                        <td colSpan="3" className="text-right"><strong>รวมเป็นเงิน</strong></td>
                        <td><strong>{this.state.inputBody['sum_price']} ฿</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.modal}

      </div>
    );
  }
}
