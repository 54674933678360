import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const orderAction = {
  getEatinOrderId,
  getEatinOrderDetailId,
  updateEatinOrder,

  getOrderOnlineById,
  getOrderDetailId,
  updateOrderOnline,
  getStatus,

  getOrderOnlineListExcel,
  getReceiveOrderOnlineListExcel,

  deleteDetail,
  DeleteEatinDetail,

  getTimelogsByBranch,
  getTimelogsByID,
  createTimelogs,
  updateTimelogs,

  getOrderEatinList,
  getOrderEatinListExcel,
  getOrderDeliveryList,
  getOrderDeliveryListExcel,

  sendSMS,

  getPaymentChannelStatus,
  updatePaymentChannelStatus
};

function getPaymentChannelStatus() {
  return axios.get(`${Base_API.order}/paymentchannel-status?lang=th`).then(res => {
    return res.data;
  });
}
function updatePaymentChannelStatus(reqData) {
  return axios.put(`${Base_API.order}/paymentchannel-status`, reqData).then(res => {
    return res.data;
  });
}

function getEatinOrderId(id) {
  return axios.get(`${Base_API.order}/order/${id}`, "").then(res => {
    return res.data;
  });
}

function getEatinOrderDetailId(id) {
  return axios.get(`${Base_API.order}/order-detail/${id}`, "").then(res => {
    return res.data;
  });
}

function updateEatinOrder(formData, id) {
  return axios.put(`${Base_API.order}/order/${id}`, formData).then(res => {
    return res.data;
  });
}

function DeleteEatinDetail(id) {
  return axios.delete(`${Base_API.order}/order/${id}`, "").then(res => {
    return res.data;
   })
}


function getOrderOnlineById(id) {
  return axios.get(`${Base_API.order_online}/order-online/${id}`, "").then(res => {
    return res.data;
  });
}

function getOrderDetailId(id) {
  return axios.get(`${Base_API.order_online}/order-online-detail/${id}`, "").then(res => {
    return res.data;
  });
}

function getOrderOnlineListExcel(dataSearch) {
  return axios.get(`${Base_API.order_online}/list/orderOnlineExcel?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchDateTo=${dataSearch.s_DateTo}&searchStatus=${dataSearch.s_Status}&searchReceiveChannel=${dataSearch.s_ReceiveChannel}`, "").then(res => {
    return res.data;
  });
}

function getReceiveOrderOnlineListExcel(dataSearch) {
  return axios.get(`${Base_API.order_online}/list/receiveOrderOnlineExcel?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchStatus=${dataSearch.s_Status}&searchDateTo=${dataSearch.s_DateTo}&searchReceiveChannel=${dataSearch.s_ReceiveChannel}`, "").then(res => {
    return res.data;
  });
}

function updateOrderOnline(formData, id) {
  return axios.put(`${Base_API.order_online}/order-online/${id}`, formData).then(res => {
    return res.data;
  });
}

function getStatus() {
  return axios.get(`${Base_API.order_online}/status`, "").then(res => {
    return res.data;
   })
}

function deleteDetail(id) {
  console.log(`${Base_API.order_online}/order-online/${id}`);
  return axios.delete(`${Base_API.order_online}/order-online/${id}`, "").then(res => {
    return res.data;
   })
}

function getTimelogsByBranch(data) {
  return axios.get(`${Base_API.order_online}/api/date-online/list-date/${data}`, "").then(res => {
    return res.data;
  });
}

function getTimelogsByID(id) {
  return axios.get(`${Base_API.order_online}/date-online/ByDate/${id}`, "").then(res => {
    return res.data;
  });
}

function createTimelogs(formData) {
  return axios.post(`${Base_API.order_online}/date-online/`, formData).then(res => {
    return res.data;
  });
}

function updateTimelogs(formData, id) {
  return axios.put(`${Base_API.order_online}/date-online/${id}`, formData).then(res => {
    return res.data;
  });
}

function getOrderEatinList(dataSearch) {
  return axios.get(`${Base_API.order}/list/order?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchStatus=${dataSearch.s_Status}`, "").then(res => {
    return res.data;
  });
}

function getOrderEatinListExcel(dataSearch) {
  return axios.get(`${Base_API.order}/list/orderExcel?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchStatus=${dataSearch.s_Status}`, "").then(res => {
    return res.data;
  });
}

function getOrderDeliveryList(dataSearch) {
  return axios.get(`${Base_API.order}/orderDelivery?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchStatus=${dataSearch.s_Status}`, "").then(res => {
    return res.data;
  });
}

function getOrderDeliveryListExcel(dataSearch) {
  return axios.get(`${Base_API.order}/orderDeliveryExcel?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchStatus=${dataSearch.s_Status}`, "").then(res => {
    return res.data;
  });
}

function sendSMS(data) {
  console.log(data);
  return axios.post(`${Base_API.order_online}/notification/tracking`, data).then(res => {
    return res.data;
  });
}