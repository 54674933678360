import React, {useState, useEffect} from 'react'
import LoadingGif from '../../assets/images/icons/loading.gif'
import {Base_API} from '../../_constants/matcher'
import {orderAction} from '../../_actions/order.action'
import './edtpmchsts.style.css'
import SweetAlert from "react-bootstrap-sweetalert";

function Edit_PaymentChannel_Status() {

    const [data, setData] = useState(null);
    const [channelList, setChannelList] = useState({
        od_ewallet: false,
        od_credit: false,
        od_cod: false,
        topup_pos: false,
        topup_credit: false,
        topup_payplus: false
    });
    const [modal, setModal] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        dataInit();
    }, [])

    const dataInit = async() => {
        let channelList = await paymentChannelStatusInit();
        await checkboxInit(channelList);
    }

    const paymentChannelStatusInit = () => {
        return new Promise(async resolve => {
            orderAction.getPaymentChannelStatus()
            .then(res => {
                if(res.isSuccess === true)
                {
                    setData(res.data);
                    resolve(res.data);
                }
            })
        })
    }
 
    const checkboxInit = (data) => {
        return new Promise(async resolve => {
            console.log('data', data);
            setChannelList({
                ...channelList,
                od_ewallet: data.order_ewallet_status,
                od_credit: data.order_credit_status,
                od_cod: data.order_cod_status,
                topup_pos: data.topup_pos_status,
                topup_credit: data.topup_credit_status,
                topup_payplus: data.topup_payplus_status
            })
            document.getElementById('od-ewallet').checked = data.order_ewallet_status;
            document.getElementById('od-credit').checked = data.order_credit_status;
            document.getElementById('od-cod').checked = data.order_cod_status;
            document.getElementById('topup-pos').checked = data.topup_pos_status;
            document.getElementById('topup-credit').checked = data.topup_credit_status;
            document.getElementById('toppu-pp').checked = data.topup_payplus_status;
        })
    }

    const handleChange = e => {
        let checked = e.target.checked;
        let newChannelList = channelList;
        newChannelList[e.target.name] = checked;

        orderAction.updatePaymentChannelStatus(newChannelList)    
        .then(res => {
            console.log(res);
            if(res.isSuccess === true)
            {
                onModalSuccess('Success', 'อััพเดตสถานะสำเร็จ');
            }
        })
    }

    const onModalSuccess = (head, body) => {
        let modalAlert = () => (
          <SweetAlert
            style={{ display: "block" }}
            success
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="default"
            title={head}
            onConfirm={onConfirm}
          >
            {body}
          </SweetAlert>
        );
    
        setShow(true);
        setModal(modalAlert());
    }

    const onConfirm = () => {
        setModal(null);
    }


    return (
        <div className="edt-pmchsts-container">
            {/* header text */}
            <h2 className="header-text">ออเดอร์</h2>
            {/* switch lists */}
            <div className="edt-pmchsts-wrapper">
                <p className="item-label">E-Wallet</p> 
                <label class="switch">
                    <input id="od-ewallet" name="od_ewallet" class="activeBtn" type="checkbox" onChange={(e) => handleChange(e)} />
                <div class="slider"></div>
                </label>
            </div>
            <div className="edt-pmchsts-wrapper">
                <p className="item-label">Credit/Debit</p> 
                <label class="switch">
                    <input id="od-credit" name="od_credit" class="activeBtn" type="checkbox" onChange={(e) => handleChange(e)} />
                <div class="slider"></div>
                </label>
            </div>
            <div className="edt-pmchsts-wrapper">
                <p className="item-label">Cash on Delivery (COD)</p> 
                <label class="switch">
                    <input id="od-cod" name="od_cod" class="activeBtn" type="checkbox" onChange={(e) => handleChange(e)} />
                <div class="slider"></div>
                </label>
            </div>

            {/* header text */}
            <h2 className='header-text'>เติมเงิน E-Wallet</h2>
            {/* switch lists */}
            <div className="edt-pmchsts-wrapper">
                <p className="item-label">POS</p> 
                <label class="switch">
                    <input id="topup-pos" name="topup_pos" class="activeBtn" type="checkbox" onChange={(e) => handleChange(e)} />
                <div class="slider"></div>
                </label>
            </div>
            <div className="edt-pmchsts-wrapper">
                <p className="item-label">Credit/Debit</p> 
                <label class="switch">
                    <input id="topup-credit" name="topup_credit" class="activeBtn" type="checkbox" onChange={(e) => handleChange(e)} />
                <div class="slider"></div>
                </label>
            </div>
            <div className="edt-pmchsts-wrapper">
                <p className="item-label">Pay Plus</p> 
                <label class="switch">
                    <input id="toppu-pp" name="topup_payplus" class="activeBtn" type="checkbox" onChange={(e) => handleChange(e)} />
                <div class="slider"></div>
                </label>
            </div>
            {modal}
        </div>
    )
}

export default Edit_PaymentChannel_Status