import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const newsAction = {
  updateNews,
  updateDeleteNews,
  activateNews,
  getNewsId,
  createNews,
  deleteNews,

  createPromotion,
  getPromotionId,
  updatePromotion,
  activatePromotion,

  createPromotionBanner,
  getRewardsList,
  getPromotionBanner,

  insertContent,
  deleteContent,
  getContent,
  updateContent,

  insertBannerPromotion,
  getBannerPromotion,
  updateBannerPromotion,
  deleteBannerPromotion,

  deletePromotionByID,
  deleteNewsContentByID
};

function deleteNewsContentByID(id) {
  return axios.delete(`${Base_API.news}/ContentNews/${id}`).then(res => {
    return res.data;
  }); 
}
function deletePromotionByID(id) {
  return axios.delete(`${Base_API.news}/news/promotion/${id}`).then(res => {
    return res.data;
  }); 
}

function getNewsId(id) {
  return axios.get(`${Base_API.news}/getNews/${id}`, "").then(res => {
    return res.data;
  });
}

function updateNews(formData, id) {
  return axios.put(`${Base_API.news}/getNews/${id}`, formData).then(res => {
    return res.data;
  });
}

function updateDeleteNews(formData, id) {
  return axios.put(`${Base_API.news}/DeleteNews/${id}`, formData).then(res => {
    return res.data;
  });
}

function activateNews(formData, id) {
  return axios.put(`${Base_API.news}/getNews/${id}`, formData).then(res => {
    return res.data;
  });
}

function createNews(formData) {
  return axios.post(`${Base_API.news}/news/news`, formData).then(res => {
    return res.data;
  });
}

function deleteNews(id) {
  return axios.delete(`${Base_API.news}/getNews/${id}`).then(res => {
    return res.data;
  });
}

function getPromotionId(id) {
  return axios.get(`${Base_API.news}/getPromotion/${id}`, "").then(res => {
    return res.data;
  });
}

function createPromotion(formData) {
  return axios.post(`${Base_API.news}/news/promotion`, formData).then(res => {
    return res.data;
  });
}
function createPromotionBanner(formData) {
  return axios.post(`${Base_API.backoffice}/api/news/promotion-banner`, formData).then(res => {
    return res.data;
  });
}
function getRewardsList() {
  return axios.get(`${Base_API.backoffice}/api/rewards-list`, "").then(res => {
    return res.data;
  });
}
function getPromotionBanner() {
  return axios.get(`${Base_API.backoffice}/api/news/promotion-banner`, "").then(res => {
    return res.data;
  });
}

function updatePromotion(formData, id) {
  return axios.put(`${Base_API.news}/getPromotion/${id}`, formData).then(res => {
    return res.data;
  });
}

function activatePromotion(formData, id) {
  return axios.put(`${Base_API.news}/getPromotion/${id}`, formData).then(res => {
    return res.data;
  });
}

function insertContent(formData) {
  return axios.post(`${Base_API.news}/contentPromotion`, formData).then(res => {
    return res.data;
  });
}

function deleteContent(id) {
  return axios.delete(`${Base_API.news}/contentPromotion/${id}`).then(res => {
    return res.data;
  });
}

function getContent(id) {
  return axios.get(`${Base_API.news}/contentPromotion/${id}`).then(res => {
    return res.data;
  });
}

function updateContent(id, formData) {
  return axios.put(`${Base_API.news}/contentPromotion/${id}`, formData).then(res => {
    return res.data;
  });
}

function insertBannerPromotion(formData) {
  return axios.post(`${Base_API.news}/bannerPromotion`, formData).then(res => {
    return res.data;
  });
}

function getBannerPromotion(id) {
  return axios.get(`${Base_API.news}/bannerPromotion/${id}`).then(res => {
    return res.data;
  });
}

function updateBannerPromotion(formData, id) {
  return axios.put(`${Base_API.news}/bannerPromotion/${id}`, formData).then(res => {
    return res.data;
  });
}

function deleteBannerPromotion(id) {
  return axios.delete(`${Base_API.news}/bannerPromotion/${id}`).then(res => {
    return res.data;
  });
}