import React from "react";
import Select from 'react-select';

import { productAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../product.css";

let modalAlert;

export class Product_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: '',
      fields: {
        pr_typebadge: "normal"
      },
      errors: {},
      isStatus: false,
      status: 0,
      cateName:[],
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    productAction.getCategoryProductListAll().then(res => {
      this.setState({
          cateName: res.data
      })
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{display:'block'}}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
        >
        {body}
        </SweetAlert>
    );
    this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/preorder/product"
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["productCode"]) {
      formIsValid = false;
      errors["productCode"] = "*กรุณากรอกรหัสสินค้า";
    }

    if (!fields["productName"]) {
      formIsValid = false;
      errors["productName"] = "*กรุณากรอกชื่อสินค้า (TH)";
    }

    if (!fields["productNameEN"]) {
      formIsValid = false;
      errors["productNameEN"] = "*กรุณากรอกชื่อสินค้า (EN)";
    }

    // if (!fields["descriptProduct"]) {
    //   formIsValid = false;
    //   errors["descriptProduct"] = "*กรุณากรอกรายละเอียดสินค้า (TH)";
    // }

    // if (!fields["descriptProductEN"]) {
    //   formIsValid = false;
    //   errors["descriptProductEN"] = "*กรุณากรอกรายละเอียดสินค้า (EN)";
    // }

    if (typeof fields["productPoint"] == "string" && fields["productPoint"] == "") {
      formIsValid = false;
      errors["productPoint"] = "*กรุณากรอกคะแนนสินค้า";
    }

    if (!fields["productImage"]) {
      formIsValid = false;
      errors["productImage"] = "*กรุณาแนบไฟล์รูปภาพ";
    }

    if (!fields["productPrice"]) {
      formIsValid = false;
      errors["productPrice"] = "*กรุณากรอกราคาสินค้า";
    }

    // if (!fields["productType"]) {
    //   formIsValid = false;
    //   errors["productType"] = "*กรุณาเลือกประเภทสินค้า";
    // }

    if (!fields["productSort"]) {
      formIsValid = false;
      errors["productSort"] = "*กรุณากรอกลำดับที่แสดง";
    }

    if (!fields["cate_id"]) {
      formIsValid = false;
      errors["cate_id"] = "*กรุณาเลือกหมวดหมู่สินค้า";
    }


    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    var formData = new FormData();
    formData.append("pr_sort", this.state.fields["productSort"]);
    formData.append("pr_code", this.state.fields["productCode"]);
    formData.append("pr_name", this.state.fields["productName"]);
    formData.append("pr_name_en", this.state.fields["productNameEN"]);
    // formData.append("pr_description", this.state.fields["descriptProduct"]);
    // formData.append("pr_description_en", this.state.fields["descriptProductEN"]);
    formData.append("pr_image", this.state.fields["productImage"]);
    // formData.append("pr_type", this.state.fields["productType"]);
    formData.append("pr_price", this.state.fields["productPrice"]);
    formData.append("pr_point", this.state.fields["productPoint"]);
    formData.append("pr_status", this.state["status"]);
    formData.append("pr_category_id", this.state.fields["cate_id"]);
    formData.append("pr_typebadge", this.state.fields["pr_typebadge"]);
    formData.append("user_id", 1);
    
    productAction.createEatinProduct(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
};

handleSelectChange = (e, action) => {
  let { fields, errors } = this.state;
  fields[action.name] = e.value;
  this.state.errors[action.name] = null;
  this.setState({ fields, errors });

};

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };


handleInputChange = e => {

  const target = e.target;
  const name = target.name;
  let status;

  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })
    if(this.state.isStatus == false){
      this.setState({ status: '1' })
    }else{
      this.setState({ status: '0' })
    }
  }
}


  render() {
    let optionsCate = this.state.cateName.map(function (cateName) {
      return { value: cateName.pr_category_id, label: cateName.pr_category_name };
    })

    let optionsTypebadge = [
      { value: "normal", label: "normal" },
      { value: "recommend", label: "recommend" },
      { value: "hot", label: "hot" },
      { value: "promotion", label: "promotion" },
      { value: "new", label: "new" }
    ]

    return (

      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มสินค้าพรีออเดอร์</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">รูปภาพสินค้า
                    <div className="text-danger"> (1280 x 1280 px)</div>
                  </label>
                    <div className="col-lg-10">
                    <input type="file" className="file-input" name="productImage" onChange={this.onChangeUploadHandler}/>
                    <div className="errorMsg">{this.state.errors.productImage}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสินค้า</label>
                  <div className="col-lg-10">
                  <input
                      name="productCode"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productCode}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสินค้า (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="productName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสินค้า (TH)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสินค้า (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="productNameEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสินค้า (EN)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productNameEN}</div>
                  </div>
                </div>

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียด (TH)</label>
                  <div className="col-lg-10">
                  <textarea className="form-control"
                      name="descriptProduct"
                      placeholder="กรอกรายละเอียดสินค้า (TH)"
                      onChange={this.handleChange}
                      rows="3">
                  </textarea>
                    <div className="errorMsg">{this.state.errors.descriptProduct}</div>
                  </div>
                </div> */}

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียด (EN)</label>
                  <div className="col-lg-10">
                  <textarea className="form-control"
                      name="descriptProductEN"
                      placeholder="กรอกรายละเอียดสินค้า (EN)"
                      onChange={this.handleChange}
                      rows="3">
                  </textarea>
                    <div className="errorMsg">{this.state.errors.descriptProductEN}</div>
                  </div>
                </div> */}

                <div className="form-group">
                  <label className="control-label col-lg-2">ราคาเริ่มต้น (฿)</label>
                  <div className="col-lg-4">
                  <input
                      name="productPrice"
                      type="number"
                      step="0.01"
                      className="form-control"
                      placeholder="กรอกราคาสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productPrice}</div>
                  </div>

                  <label className="control-label col-lg-2">คะแนนสินค้า (P)</label>
                  <div className="col-lg-4">
                  <input
                      name="productPoint"
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="กรอกคะแนนสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productPoint}</div>
                  </div>
                </div>


                {/* <div className="form-group">
                  <label className="control-label col-lg-2">ส่วนลด (บาท)</label>
                  <div className="col-lg-4">
                  <input
                      name="discountProduct"
                      type="number"
                      step="0.01"
                      className="form-control"
                      placeholder="กรอกส่วนลดสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.discountProduct}</div>
                  </div>
                </div> */}

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">ประเภทสินค้า</label>
                  <div className="col-lg-4">
                    <Select
                      name="productType"
                      placeholder="เลือกประเภทสินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsType}
                    />
                    <div className="errorMsg">{this.state.errors.productType}</div>
                  </div>
                </div> */}

                <div className="form-group">
                  <label className="control-label col-lg-2">หมวดหมู่สินค้า</label>
                  <div className="col-lg-4">
                    <Select
                      name="cate_id"
                      placeholder="เลือกหมวดหมู่สินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsCate}
                    />
                    <div className="errorMsg">{this.state.errors.cate_id}</div>
                  </div>

                  <label className="control-label col-lg-2">ป้ายกำกับสินค้า</label>
                  <div className="col-lg-4">
                    <Select
                      name="pr_typebadge"
                      placeholder="เลือกป้ายกำกับสินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsTypebadge}
                    />
                    <div className="errorMsg">{this.state.errors.pr_typebadge}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ลำดับที่แสดง</label>
                  <div className="col-lg-2">
                  <input
                      name="productSort"
                      type="number"
                      step="0"
                      className="form-control"
                      placeholder="กรอกลำดับที่แสดง"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productSort}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus_preorder}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
