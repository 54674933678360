import React from "react";
import { productAction } from "../../../../_actions";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import SweetAlert from "react-bootstrap-sweetalert";
import "../product.css";
import Select from 'react-select';
import ReactModal from 'react-modal';

let modalAlert;
const customStyles = {
  content : {
    top: '40px',
    left: '20%',
    width: '60%',
    height: '68%',
    padding: '0px'
  }
};

export class Product_Online_Branch_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      status: '',
      data_product:{},
      showModal: false,

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    let ad_b_status = localStorage.getItem('ad_b_status');
    let ad_b_id = localStorage.getItem('ad_b_id');
    const {
      match: { params }
    } = this.props;
    this.onlineListBranchByProduct(params.pr_online_id,ad_b_status,ad_b_id);
    this.setState({
      pr_online_id: params.pr_online_id
    })
  }

  onlineListBranchByProduct(pr_online_id,ad_b_status,ad_b_id) {
    var data_table = []
    productAction.onlineListBranchByProduct(pr_online_id,ad_b_status,ad_b_id).then(e => {
      if (e.isSuccess === true) {
        
        for(var i in e.data[0].data_branch){
          var btn_edit = ''
          let isStatus_is_soldout = ''
          let isStatus_is_active = ''
          let isStatus_b_status = ''

          if(e.data[0].data_branch[i].is_active==1){
            isStatus_is_active = <span className="label label-success label-status">เปิดใช้งาน</span>
          }else{
            isStatus_is_active = <span className="label label-danger label-status">ปิดใช้งาน</span>
          }

          if(e.data[0].data_branch[i].is_soldout==0){
            isStatus_is_soldout = <span className="label label-success label-status">มีสินค้า</span>
          }else{
            isStatus_is_soldout = <span className="label label-danger label-status">สินค้าหมด</span>
          }

          if(e.data[0].data_branch[i].b_status==1){
            isStatus_b_status = <span className="label label-success label-status-long">สาขาเปิดปกติ</span>
            if(e.data[0].data_branch[i].b_orderonline_status==1){
              btn_edit = <button name="btn-edit" className="btn btn-warning" type="button" value={e.data[0].data_branch[i].pr_online_branch_id}  onClick={this.handleOpenModal}>แก้ไข</button>
            }else{
              btn_edit = <button name="btn-edit" className="btn btn-warning" type="button" value=""  onClick="" disabled>แก้ไข</button>
            }
          }else{
            if(e.data[0].data_branch[i].pr_online_branch_type === 'ems'){
              if(e.data[0].data_branch[i].is_active === 1){
                isStatus_b_status = <span className="label label-success label-status-long">สาขาเปิดปกติ</span>
                btn_edit = <button name="btn-edit" className="btn btn-warning" type="button" value={e.data[0].data_branch[i].pr_online_branch_id}  onClick={this.handleOpenModal}>แก้ไข</button>
              }else{
                isStatus_b_status = <span className="label label-danger label-status">สาขาปิด</span>
                btn_edit = <button name="btn-edit" className="btn btn-warning" type="button" value={e.data[0].data_branch[i].pr_online_branch_id}  onClick={this.handleOpenModal}>แก้ไข</button>
              }
            }else{ //delivery
              isStatus_b_status = <span className="label label-danger label-status">สาขาปิด</span>
              btn_edit = <button name="btn-edit" className="btn btn-warning" type="button" value=""  onClick="" disabled>แก้ไข</button>
            }
          }

          data_table.push(
            <tr className="text-center">
              <th scope="row">{parseInt(i)+1}</th>
              <td className="text-left">{(e.data[0].data_branch[i].b_name != null ? e.data[0].data_branch[i].b_name : e.data[0].data_branch[i].pr_online_branch_type)}</td>
              <td className="text-price">{e.data[0].data_branch[i].pr_online_branch_price}</td>
              <td>
                {isStatus_b_status}
              </td>
              <td>
                {isStatus_is_soldout}
              </td>
              <td>
                {isStatus_is_active}
              </td>
              <td>{btn_edit}</td>
            </tr>
          )
          
        }

        this.setState({
          data_product : e.data[0],
          data_table_branch: data_table
        });


      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = `/backoffice/order-online/product/branch/edit/${this.state.pr_online_id}`;
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["pr_online_branch_price"]) {
      formIsValid = false;
      errors["pr_online_branch_price"] = "*กรุณากรอกราคาสินค้า";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    const inputBody = {
      pr_online_branch_price: this.state.fields["pr_online_branch_price"],
      is_soldout: this.state["status_is_soldout"],
      is_active: this.state["status_is_active"],
    }

    productAction.onlineUpdateProductByProductID(inputBody, this.state.fields["pr_online_branch_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {

    const name = e.target.name;

    
    if(name == "is_active"){
      this.setState({ is_active: !this.state.is_active })
      if(this.state.is_active == true){
        this.setState({ status_is_active: '0' })
      }else{
        this.setState({ status_is_active: '1' })
      }
    }

    if(name == "is_soldout"){
      this.setState({ is_soldout: !this.state.is_soldout })

      if(this.state.is_soldout == true){
        this.setState({ status_is_soldout: '0' })
      }else{
        this.setState({ status_is_soldout: '1' })
      }
    }
  }

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleOpenModal (e) {
    this.onlineGetProductByProductBranchID(e.target.value)
    this.setState({ showModal: true, });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  onlineGetProductByProductBranchID(pr_online_branch_id) {
    productAction.onlineGetProductByProductBranchID(pr_online_branch_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            pr_online_branch_id : e.data.pr_online_branch_id,
            pr_online_branch_price: e.data.pr_online_branch_price,
            pr_online_branch_type: e.data.pr_online_branch_type,
            is_active: e.data.is_active,
            is_soldout: e.data.is_soldout,
            b_name: e.data.b_name,
            b_code: e.data.b_code,
            pr_online_name: e.data.pr_online_name,
            pr_online_code: e.data.pr_online_code
          }

        });

        this.setState({
          is_active: String(this.state.fields.is_active) == "1",
          is_soldout: String(this.state.fields.is_soldout) == "1",
          status_eatin: this.state.fields.pr_branch_eatin,
          status_takehome: this.state.fields.pr_branch_takehome,
          status_is_active: this.state.fields.is_active,
          status_is_soldout: this.state.fields.is_soldout
        });


      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  render() {
    console.log(this.state.is_soldout);
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขสินค้าออนไลน์แต่ละสาขา</h4>
          </div>
          <div className="panel-body">
            <div className="row mt-3">
              <div className="col-md-2">
                รหัสสินค้า:
              </div>
              <div className="col-md-2">
                {this.state.data_product.pr_online_code}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                ชื่อสินค้า:
              </div>
              <div className="col-md-2">
                {this.state.data_product.pr_online_name}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                สถานะแสดงสินค้า
              </div>
              <div className="col-md-2">
                {(this.state.data_product.is_active == 1 ? <div className="text-success">ใช้งาน</div> : <div className="text-danger">ไม่ใช้งาน</div>)}
              </div>
            </div>

            <hr className="mx-0"/>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">การจัดส่ง/ชื่อสาขา</th>
                    <th scope="col">ราคา</th>
                    <th scope="col">สถานะสาขา</th>
                    <th scope="col">สถานะ soldout</th>
                    <th scope="col">สถานะแสดงสินค้า</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data_table_branch}
                </tbody>
              </table>

              
          </div>
        </div>

        
        <ReactModal 
            isOpen={this.state.showModal}
            contentLabel="form-edit-price"
            style={customStyles}
        >
          <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <div className="modal-header modal-header-form">
                <button type="button" className="close" onClick={this.handleCloseModal}><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">แก้ไขข้อมูลสินค้าแต่ละสาขา</h4>
              </div>
              <div className="modal-body modal-body-form">
                <div className="form-group">
                  <label className="control-label col-md-2">ชื่อสินค้า</label>
                  <div className="col-md-3">
                    <p className="form-control-static">({this.state.fields.pr_online_code}) {this.state.fields.pr_online_name}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2 ">การจัดส่ง/ชื่อสาขา</label>
                  <div className="col-md-3">
                    <p className="form-control-static">{(this.state.fields.b_code != null ? `(${this.state.fields.b_code})` + this.state.fields.b_name : this.state.fields.pr_online_branch_type)}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2">ราคาสินค้า (฿)</label>
                  <div className="col-md-3">
                    <input
                      name="pr_online_branch_price"
                      type="number"
                      className="form-control"
                      placeholder="กรอกราคาสินค้า"
                      value={this.state.fields.pr_online_branch_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_branch_price}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ soldout</label>
                  <div className="col-md-3">
                    <label className="switch">
                      <input name="is_soldout" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_soldout}/>
                      <div className="slider slider_2color"></div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะแสดงสินค้า</label>
                  <div className="col-md-3">
                    <label className="switch">
                      <input name="is_active" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_active}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer modal-footer-form">
                <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>Close</button>
                <button type="submit" className="btn btn-success">Save changes</button>
              </div>
            </form>          
          </ReactModal>
        {this.state.modal}
      </div>
    );
  }
}
