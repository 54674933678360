import React from "react";
import $ from "jquery";
import { newsAction,memberAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../news.css";

$.DataTable = require("datatables.net");
let modalAlert;

export class News_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);
    
    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false,
      showLoader: "none"
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    const columns = [
      // {
      //   title: "รูปภาพ",
      //   class: "text-center",
      //   data: "news_image"
      // },
      {
        title: "ลำดับ",
        class: "text-center",
        data: "news_id"
      },
      {
        title: "หัวข้อข่าวสาร (TH)",
        class: "text-left w-50",
        data: "news_title"
      },
      {
        title: "รายละเอียดข่าวสาร (TH)",
        class: "text-left",
        data: "news_description"
      },
      {
        title: "Link ข่าวสาร",
        class: "text-left",
        data: "news_link"
      },
      {
        title: "การใช้งาน",
        class: "text-center",
        data: "news_active"
      },
      {
        title: "การแจ้งเตือนอัตโนมัติ",
        class: "text-center",
        data: "is_autonoti"
      },
      {
        title: "วันและเวลาการแจ้งเตือนอัตโนมัติ",
        class: "text-center",
        data: "autonoti_datetime"
      },
      {
        title: "วันที่แก้ไขรายการ",
        class: "text-center",
        data: "updated_at"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "news_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          },
          orderable: false
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
             .html(`<div class="text-long">${cellData}</div>`);
            },
          orderable: false
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
             .html(`<div class="text-long">${cellData}</div>`);
            },
          orderable: false
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
             .html(`<div class="text-long">${cellData}</div>`);
            },
          orderable: false
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="news_active" class="activeBtn" data-id="${rowData.news_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: false
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="news_active" class="" data-id="${rowData.news_id}" disabled type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider" style="opacity: 0.6"></div>
                </label>
              `);
          },
          orderable: false
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                    <div class="list-icons">
                      <div class="dropdown">
                        <a href="#" class="list-icons-item" data-toggle="dropdown">
                          <i class="icon-menu9"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="#" data-content='${cellData}' class="dropdown-item sent-massage"><i class="icon-bell3 mr-3 mb-1"></i> แจ้งเตือน</a>
                          <a href="/backoffice/news/news/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                          <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                        </div>
                      </div>
                    </div>
                  `);
                    // <div class="text-center" width="20%">
                    // <a href="#"><button type='button' name='sent_btn' data-content='${cellData}' class='btn btn-success sent-massage'> <img src="../images/bell_icon.png" class="news_bell" title="คลิกเพื่อส่งข่าวสารนี้"/> แจ้งเตือน</button></a>
                    // <a href="/news/news/edit/${cellData}"><button type='button' name='edit_btn' data-content='' class='btn btn-edit'>แก้ไข</button></a>
                    //

                    // <button type='button' name='delete_btn' data-content='${cellData}' class='btn btn-danger delete_btn'>ลบ</button></div>
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.news}/news/news`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[7, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 2;

      var formData = new FormData();
      formData.append("news_active", fval);

      newsAction.activateNews(formData, fid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", ".sent-massage", function () {
      var id = $(this).data("content");
      // var Message = $(this).is(":checked") === true ? 1 : 2;

      newsAction.getNewsId(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {

          let inputData = {
            Title_th: e.data.news_title,
            Title_en: e.data.news_title_en,
            Message_th: e.data.news_description,
            Message_en: e.data.news_description_en,
            Receive: "ALL",
            Type: "1",
            news_id: e.data.news_id,
            open_type: e.data.news_open_type,
          }

          modalAlert = () => (
            <SweetAlert
              style={{ display: "block" }}
              info
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="default"
              title="Sent Massage"
              onConfirm={() => onConfirmSent(inputData)}
              onCancel={() => onConfirm()}
            >
              คุณต้องการส่งข่าวสารนี้หรือไม่ ?
            </SweetAlert>
          );

          state.setState({ show: true, modal: modalAlert() });
        }
      });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var mcid = $(this).data("content");
      var fval = 1;

      var formData = new FormData();
      formData.append("news_delete", fval);

      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(formData,mcid)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmSent(inputData) {
      state.setState({ modal: null });
      state.setState({
        showLoader: "block"
      })
      memberAction.sentNotificationAll(inputData).then(e => {
        state.setState({
          showLoader: "none"
        })
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    }

    function onConfirmDelete(formData,id) {

      newsAction.updateDeleteNews(formData,id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }


  }

  render() {
    console.log(this.state.showLoader);
    return (
      <div>
        <div className="bg_loader" style={{display:this.state.showLoader}}>
          <div className="loader" id="loader-1" style={{display:this.state.showLoader}}></div>
        </div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แจ้งเตือนข่าวสารทั่วไป</h4>
            <div className="heading-elements">
              <a href="/backoffice/news/news/create">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add"
                >
                  เพิ่มรายการ
                </button>
              </a>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
