import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const categoryAction = {
  updateEatinCategory,
  getEatinCategoryId,
  createEatinCategory,
  deleteEatinCategory,
  getEatinCategory,

  createOnlineCategory,
  updateOnlineCategory,
  getOnlineCategoryId,
  deleteOnlineCategory,

};

function getEatinCategoryId(cid) {
  return axios.get(`${Base_API.category}/category/${cid}`, "").then(res => {
    return res.data;
  });
}

function getEatinCategory() {
  return axios.get(`${Base_API.category}/category`, "").then(res => {
    return res.data;
  });
}

function updateEatinCategory(formData, cid) {
  return axios.put(`${Base_API.category}/category/${cid}`, formData).then(res => {
    return res.data;
  });
}

function createEatinCategory(formData) {
  return axios.post(`${Base_API.category}/category`, formData).then(res => {
    return res.data;
  });
}

function deleteEatinCategory(cid) {
  return axios.put(`${Base_API.category}/category/delete/${cid}`).then(res => {
    return res.data;
  });
}

function createOnlineCategory(formData) {
  return axios.post(`${Base_API.category}/product_online_category`, formData).then(res => {
    return res.data;
  });
}

function updateOnlineCategory(formData, cid) {
  return axios.put(`${Base_API.category}/product_online_category/${cid}`, formData).then(res => {
    return res.data;
  });
}

function getOnlineCategoryId(cid) {
  return axios.get(`${Base_API.category}/product_online_category/${cid}`, "").then(res => {
    return res.data;
  });
}

function deleteOnlineCategory(cid) {
  return axios.put(`${Base_API.category}/product_online_category/delete/${cid}`).then(res => {
    return res.data;
  });
}