import React from "react";
import { newsAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../banner.css";

let modalAlert;

export class BannerPromotion_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      active: 'General',
      subactive: 'G-Banner',
      menu_general: 1,
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      isStatus: '',
      status: '',

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getBannerPromotionByID(params.banner_promotion_id);
  }

  getBannerPromotionByID(banner_promotion_id) {
    console.log(banner_promotion_id);
    newsAction.getBannerPromotion(banner_promotion_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            banner_promotion_image: e.data.banner_promotion_image,
            banner_promotion_sort: e.data.banner_promotion_sort,
            banner_promotion_id: e.data.banner_promotion_id,
            is_active: e.data.is_active,
            banner_promotion_image_show: e.data.banner_promotion_image
          }
        });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/banner_promotion/list";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["banner_promotion_image"]) {
      formIsValid = false;
      errors["banner_promotion_image"] = "*กรุณาเลือกรูปภาพ";
    }

    if (!fields["banner_promotion_sort"]) {
      formIsValid = false;
      errors["banner_promotion_sort"] = "*กรุณากรอกลำดับ";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    formData.append("banner_promotion_image", this.state.fields["banner_promotion_image"]);
    formData.append("banner_promotion_sort", this.state.fields["banner_promotion_sort"]);
    formData.append("is_active", this.state.fields["is_active"]);

    newsAction.updateBannerPromotion(formData, this.state.fields["banner_promotion_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active") {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };


  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขแบนเนอร์โปรโมชัน</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="text-center my-3">
                <img
                  className="preview-img"
                  src={this.state.fields["banner_promotion_image_show"] || ""}
                />
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">รูปภาพแบนเนอร์โปรโมชัน</label>
                  <div className="col-lg-9">
                    <input
                      type="file"
                      className="file-input"
                      name="banner_promotion_image"
                      onChange={this.onChangeUploadHandler}
                      data-allowed-file-extensions='["jpg", "jpeg", "png"]'
                    />
                    <div className="errorMsg">
                      {this.state.errors["banner_promotion_image"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">ลำดับ</label>
                  <div className="col-lg-2">
                  <input
                      name="banner_promotion_sort"
                      type="number"
                      className="form-control"
                      placeholder="กรอกลำดับ"
                      onChange={this.handleChange}
                      value={this.state.fields["banner_promotion_sort"]}
                    />
                    <div className="errorMsg">{this.state.errors.banner_promotion_sort}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">สถานะใช้งาน</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                      name="is_active"
                      type="checkbox"
                      onChange={this.handleChange}
                      checked={(this.state.fields["is_active"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
