import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import { orderAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../order.css";
import Select from 'react-select';


let modalAlert;

const options = [
  { value: '1', label: 'รอชำระเงิน' },
  { value: '2', label: 'ชำระเงินสำเร็จ' },
  { value: '3', label: 'ยกเลิกการชำระเงิน	' },
];

export class Takehome_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      fields: {},
      typeProgram: null,
      selectType: null,
      inputBody: {},
      inputBodyDetail: [],
      s_name:[],
      selectedOption: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);

  }

  componentDidMount() {
    var params = this.props.match.params;
    this.getOrderDataByID(params.od_number);
    this.getOrderDetail(params.od_number);

    let { fields } = this.state
  }

  getOrderDataByID(id) {
    orderAction.getEatinOrderId(id).then(e => {
      if (e.isSuccess === true) {

        const payment_id = e.data.status_payment;

        var newObject = Object.assign(e.data, this.state.inputBody);
        if(newObject['status_payment'] === 1) newObject['status_payment'] = "รอการชำระเงิน"
        else if(newObject['status_payment'] === 2) newObject['status_payment'] = "ชำระเงินสำเร็จ"
        else if(newObject['status_payment'] === 3) newObject['status_payment'] = "ยกเลิกการชำระเงิน"
        else newObject['status_payment'] = "ไม่ระบุสถานะ"

        this.setState({ inputBody: newObject });

        this.setState({
          fields: {
            ...this.state.fields,
            od_comment: this.state.inputBody['od_comment'],
            selectValue: payment_id,
            selectLabel: this.state.inputBody['status_payment'],
          }
        });

        this.setState({defaultValue:
          {
            value: this.state.fields["selectValue"],
            label: this.state.fields["selectLabel"]
          }
        });

      } else {
        alert("ไม่พบข้อมูล");
      }
    });
  }

  getOrderDetail(id) {
    orderAction.getEatinOrderDetailId(id).then(e => {
      if (e.isSuccess === true) {
        var newObject = Object.assign(e.data, this.state.inputBodyDetail);
        this.setState({ inputBodyDetail: newObject });
      } else {
        alert("ไม่พบข้อมูล");
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
  }

  handleDelete(event) {
    var detail_id = event.target.value;

    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title="Delete"
        onConfirm={() => this.onConfirmDelete(detail_id)}
        onCancel={() => this.onCancel()}
      >
        คุณต้องการลบข้อมูลนี้หรือไม่ ?
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onConfirmDelete(detail_id) {
    orderAction.DeleteEatinDetail(detail_id).then(e => {
      if (e.isSuccess === false) {
        this.onModalError("Error", e.message);
      } else {
        this.onModalSuccess("Success", e.message);
        // table.ajax.reload();
      }
    });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }


  onConfirm(ev) {
    this.setState({ modal: null });
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = `/backoffice/preorder/order/info/${this.state.inputBody['od_number']}`;
  }


  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    formData.append("status_payment", this.state.defaultValue.value);
    formData.append("od_comment", this.state.fields.od_comment);


    orderAction.updateEatinOrder(formData, this.state.inputBody['od_number'])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onImageError(e) {
    e.target.setAttribute(
      "src",
      `${process.env.PUBLIC_URL}/images/default.png`
    );
  }

  handleChange = (selectedOption) => {
      let { fields, errors } = this.state;

      if(typeof selectedOption.value != 'undefined'){
        fields["status_id"] = selectedOption.value;

        this.setState({
          defaultValue:
            {
              label: selectedOption.label,
              value: selectedOption.value
            }
        })
      }else {
        fields[selectedOption.target.name] = selectedOption.target.value;
      }
      this.setState({ fields, errors });
  };

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }



  render() {
    let listProduct = this.state.inputBodyDetail.map(( item, product ) => {
        return (
          <tr key={product}>
            <td>{item.pr_name}</td>
            <td>{item.price}</td>
            <td>{item.od_product_count}</td>
            <td>{item.total_product_price} ฿</td>
            <td className="text-center"><button type='button' name='delete_btn' value={item.od_detail_id} className='btn btn-danger delete_btn' onClick={(e) => this.handleDelete(e)}>ลบ</button></td>
          </tr>
        );
      })

    const { selectedOption } = this.state;

    return (
      <div className="reward-container row">
        <div className="col-12">
          <div className="panel panel-flat">
            <div className="panel-heading panel-heading-wline">
              <h4 className="panel-title">รายการสั่งซื้อหน้าร้าน (Take Home)</h4>
            </div>
            <div className="panel-body pt-4">
              <form className="form-horizontal" onSubmit={e => this.handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row f-16">
                      <div className="col-md-12"><strong>ข้อมูลทั่วไป</strong></div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">รหัสการซื้อสินค้า :</div>
                      <div className="col-md-8">{this.state.inputBody['od_number']}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">วันที่สร้างรายการ :</div>
                      <div className="col-md-8">{this.state.inputBody['created_at']}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">วันที่ส่งสินค้า :</div>
                      <div className="col-md-8">{this.state.inputBody['date_receive']}</div>
                    </div>
                    <div className="row pt-3 f-16">
                      <div className="col-md-12"><strong>สาขาที่รับสินค้า</strong></div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">ชื่อสาขา :</div>
                      <div className="col-md-8">{this.state.inputBody['b_name']}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">ที่อยู่ :</div>
                      <div className="col-md-8">{this.state.inputBody['b_address']}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">ละติจูด :</div>
                      <div className="col-md-8">{this.state.inputBody['b_lat']}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">ลองจิจูด :</div>
                      <div className="col-md-8">{this.state.inputBody['b_long']}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row f-16">
                      <div className="col-md-12"><strong>รายละเอียดสมาชิก</strong></div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">ชื่อสมาชิก :</div>
                      <div className="col-md-8"><a href={`/member/edit/${this.state.inputBody['mem_id']}`}>{this.state.inputBody['mem_firstname']} {this.state.inputBody['mem_lastname']}</a></div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">อีเมล :</div>
                      <div className="col-md-8">{this.state.inputBody['mem_email']}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">เลขบัตรประชาชน :</div>
                      <div className="col-md-8">{this.state.inputBody['mem_idcard']}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">วันเกิด :</div>
                      <div className="col-md-8">{this.state.inputBody['mem_birthdate']}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-4">โทร :</div>
                      <div className="col-md-8">{this.state.inputBody['mem_phone']}</div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pt-5 m-1">
                  <div className="col-12">
                    <h4 className="panel-title">อัพเดตสถานะการชำระเงิน</h4>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-2"><strong>สถานะออเดอร์</strong></div>
                  <div className="col-md-3">
                    <Select
                      name="status_id"
                      placeholder="เลือกสถานะออเดอร์"
                      onChange={this.handleChange}
                      options={options}
                      value={this.state.defaultValue ||""}
                      clearable={true}
                      className="Select-menu-outer"
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-2"><strong>หมายเหตุ</strong></div>
                  <div className="col-md-3">
                    <textarea
                      rows="4"
                      name="od_comment"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.fields['od_comment'] || ""}
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-5 text-right">
                  <input
                    type="submit"
                    className="btn btn-success btn-md btn-banner-create"
                    value="อัพเดต"
                  />
                  </div>
                </div>
                <hr />
                <div className="row pt-5 m-1">
                  <div className="col-12">
                    <h4 className="panel-title">รายการสั่งซื้อสินค้า</h4>
                  </div>
                </div>
                <div className="row pt-3 m-1">
                  <div className="col-12">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <td width="50%"><strong>ชื่อสินค้า</strong></td>
                          <td><strong>ราคา (/ชิ้น)</strong></td>
                          <td><strong>จำนวน (ชิ้น)</strong></td>
                          <td><strong>ราคารวม</strong></td>
                          <td className="text-center"><strong>จัดการ</strong></td>
                        </tr>
                      </thead>
                      <tbody>
                        {listProduct}
                        <tr>
                          <td colSpan="3" className="text-right"><strong>รวมเป็นเงิน</strong></td>
                          <td colSpan="2"><strong>{this.state.inputBody['sum_price']} ฿</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
