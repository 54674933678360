import React, { Component } from "react";
import Routing from "../../routes";
import { Sidebar, Footer, Header } from "../../_pagebuilder";

export class NormalLayout extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="page-container">
          <div className="page-content">
            <Sidebar />
            <div className="content-wrapper">
              <div className="content">
                <Routing />
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
