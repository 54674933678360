import React from "react";
import { activityAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../activity.css";
import Select from 'react-select';
const moment = require('moment-timezone');

let modalAlert;

export class Activity_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      activity_friends: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmUpdate = this.onConfirmUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.ActivityFriendGetByID(params.activity_id);

    await activityAction.FriendGetFriendList().then(res => {
      this.setState({
          activity_friends: res.data
      })
    })
  }

  ActivityFriendGetByID(activity_id) {
    activityAction.ActivityFriendGetByID(activity_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            activity_id: e.data.activity_id,
            activity_name: e.data.activity_name,
            datetime_start: moment(e.data.datetime_start).format("Y-MM-DD")+"T"+moment(e.data.datetime_start).format("HH:mm:ss"),
            datetime_stop: moment(e.data.datetime_stop).format("Y-MM-DD")+"T"+moment(e.data.datetime_stop).format("HH:mm:ss"),
            activity_desc_th: e.data.activity_desc_th,
            activity_desc_en: e.data.activity_desc_en,
            activity_ref_id: e.data.activity_ref_id,
            is_used: e.data.is_used
          }
        });

        this.setState({
          defaultActivityFriend:
          {
            label: e.data.invite_condition_name,
            value: e.data.activity_ref_id
          },
        });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmUpdate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/activity/friendgetfriend/list";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["datetime_start"]) {
      formIsValid = false;
      errors["datetime_start"] = "*กรุณากรอกเวลาที่เริ่มกิจกรรม";
    }

    if (!fields["datetime_stop"]) {
      formIsValid = false;
      errors["datetime_stop"] = "*กรุณากรอกเวลาที่สิ้นสุดกิจกรรม";
    }

    if (!fields["activity_desc_th"]) {
      formIsValid = false;
      errors["activity_desc_th"] = "*กรุณารายละเอียดกิจกรรม (TH)";
    }

    if (!fields["activity_desc_en"]) {
      formIsValid = false;
      errors["activity_desc_en"] = "*กรุณารายละเอียดกิจกรรม (EN)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmUpdate(ev) {
    this.setState({ modal: null });

    let inputBody = {
      datetime_start: this.state.fields["datetime_start"],
      datetime_stop: this.state.fields["datetime_stop"],
      activity_desc_th: this.state.fields["activity_desc_th"],
      activity_desc_en: this.state.fields["activity_desc_en"],
      activity_ref_id: this.state.defaultActivityFriend.value,
      is_used: this.state.fields["is_used"]
    }

    activityAction.ActivityUpdate(inputBody, this.state.fields["activity_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_used") {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };


  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    if(action.name == "activity_ref_id"){
      this.setState({
        defaultActivityFriend:
        {
          label: e.label,
          value: e.value
        }
      })
    }
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })
  }


  render() {
    let optionsActivityFriend = this.state.activity_friends.map(function (activity) {
      return { value: activity.invite_condition_id, label: activity.invite_condition_name };
    })

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขข้อมูลกิจกรรม {this.state.fields['activity_name']}</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
              <div className="form-group">
                <label className="control-label col-md-2">วันที่เริ่มกิจกรรม</label>
                <div className="col-md-3">
                  <input
                    name="datetime_start"
                    type="datetime-local"
                    className="form-control"
                    placeholder="กรอกวันที่เริ่มกิจกรรม"
                    value={ this.state.fields.datetime_start}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.datetime_start}</div>
                </div>

                <label className="control-label col-md-2">วันที่สิ้นสุดกิจกรรม</label>
                <div className="col-md-3">
                  <input
                    name="datetime_stop"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่สิ้นสุดกิจกรรม"
                    value={this.state.fields.datetime_stop || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.datetime_stop}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-2"> กิจกรรมที่กำลังใช้งาน </label>
                <div className="col-md-5">
                  <Select
                    name="activity_ref_id"
                    placeholder="เลือกกิจกรรมที่กำลังใช้งาน"
                    onChange={this.handleSelectChange}
                    options={optionsActivityFriend}
                    value={this.state.defaultActivityFriend}
                  />
                  <div className="errorMsg">{this.state.errors.activity_ref_id}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">
                  รายละเอียดกิจกรรม (TH)
                </label>
                <div className="col-lg-9">
                  <textarea
                    name="activity_desc_th"
                    type="text"
                    className="form-control"
                    placeholder="กรอกรายละเอียดกิจกรรม (TH)"
                    onChange={this.handleChange}
                    value={this.state.fields["activity_desc_th"]}
                    rows={6}
                  />
                  <div className="errorMsg">
                    {this.state.errors["activity_desc_th"]}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">
                  รายละเอียดกิจกรรม (EN)
                </label>
                <div className="col-lg-9">
                  <textarea
                    name="activity_desc_en"
                    type="text"
                    className="form-control"
                    placeholder="กรอกรายละเอียดกิจกรรม (EN)"
                    onChange={this.handleChange}
                    value={this.state.fields["activity_desc_en"]}
                    rows={6}
                  />
                  <div className="errorMsg">
                    {this.state.errors["activity_desc_en"]}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">การใช้งาน</label>
                <div className="col-md-3">
                <label className="switch">
                  <input
                    name="is_used"
                    type="checkbox"
                    onChange={this.handleChange}
                    checked={(this.state.fields["is_used"] === 1) ? true : false}
                    />
                  <div className="slider"></div>
                  </label>
                </div>
              </div>


                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
