import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const promotionAction = {
  getPromotionList,
  updatePromotionById,
  deletePromotionById,
  listPromoCategory,
  createPromotion,
  getPromotionById,
  listPaymentPromoCategory,
  listPaymentPromoFreeEarnCategory,
  getActiveRewardsDropdown,
  createPaymentPromotion,
  getPaymentPromotionById,
  updatePaymentPromotionById,
  updatePaymentPromotionWithoutGiftById,
  deletePaymentPromotionById
};

// Product Promotion
function createPromotion(formData) {
  return axios.post(`${Base_API.product}/api/product_promotion`, formData).then(res => {
    return res.data;
  });
}
function getPromotionList() {
  return axios.get(`${Base_API.product}/api/product_promotion`).then(res => {
    return res.data;
  });
}
function updatePromotionById(formData, promo_list_id) {
  return axios.put(`${Base_API.product}/api/product_promotion/${promo_list_id}`, formData).then(res => {
    return res.data;
  });
}
function deletePromotionById(promo_list_id) {
  return axios.delete(`${Base_API.product}/api/product_promotion/${promo_list_id}`).then(res => {
    return res.data;
  });
}
function listPromoCategory() {
  return axios.get(`${Base_API.product}/api/product_promotion_category`).then(res => {
    return res.data;
  });
}
function getPromotionById(promo_list_id) {
  return axios.get(`${Base_API.product}/api/product_promotion/${promo_list_id}`).then(res => {
    return res.data;
  });
}
// Payment Promotion
function listPaymentPromoCategory() {
  return axios.get(`${Base_API.product}/api/payment_promotion_category`).then(res => {
    return res.data;
  });
}
function listPaymentPromoFreeEarnCategory() {
  return axios.get(`${Base_API.product}/api/payment_promotion_free_earn_category`).then(res => {
    return res.data;
  });
}
function getActiveRewardsDropdown() {
  return axios.get(`${Base_API.product}/api/getActiveRewardsDropdown`).then(res => {
    return res.data;
  });
}
function createPaymentPromotion(formData) {
  return axios.post(`${Base_API.product}/api/payment_promotion`, formData).then(res => {
    return res.data;
  });
}
function getPaymentPromotionById(promo_list_id) {
  return axios.get(`${Base_API.product}/api/payment_promotion/${promo_list_id}`).then(res => {
    return res.data;
  });
}
function updatePaymentPromotionById(formData, promo_list_id) {
  return axios.put(`${Base_API.product}/api/payment_promotion/${promo_list_id}`, formData).then(res => {
    return res.data;
  });
}
function updatePaymentPromotionWithoutGiftById(formData, promo_list_id) {
  return axios.put(`${Base_API.product}/api/payment_promotion/without_gift/${promo_list_id}`, formData).then(res => {
    return res.data;
  });
}
function deletePaymentPromotionById(promo_list_id) {
  return axios.delete(`${Base_API.product}/api/payment_promotion/${promo_list_id}`).then(res => {
    return res.data;
  });
}