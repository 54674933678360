import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const pointAction = {
  getManagePoint,
  updateManagePoint,
  deleteManagePoint,
  exportExcelPointLog
};

function getManagePoint(id) {
  return axios.get(`${Base_API.point}/api/point/v1/manage_point/${id}`, "").then(res => {
    return res.data;
  });
}

function updateManagePoint(formData, id) {
  return axios.put(`${Base_API.point}/api/point/v1/manage_point/${id}`, formData).then(res => {
    return res.data;
  });
}

function deleteManagePoint(id) {
  return axios.delete(`${Base_API.point}/api/point/v1/manage_point/${id}`).then(res => {
    return res.data;
  });
}

function exportExcelPointLog(id) {
  return axios.get(`${Base_API.point}/api/point/v1/excellogPoint/${id}`).then(res => {
    return res.data;
  });
}