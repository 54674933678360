import React from "react";
import { memberAction } from "../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../member.css";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let modalAlert;

export class Member_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      fields: {},
      errors: {},
      typeProgram: null,
      selectType: null,
      provinceName:[],
      amphoeName:[],
      districtName:[],
      zipcode:'',
      isStatus: '',
      status: '',
      birthdate: new Date()
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getMemberDataByID(params.mem_id);

    memberAction.getProvince()
        .then(res => {
            this.setState({
                provinceName: res.data
            })
        })
  }

  getMemberDataByID(mem_id) {

    memberAction.getMemberId(mem_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            firstname: e.data.mem_firstname,
            lastname: e.data.mem_lastname,
            phone: e.data.mem_phone,
            email: e.data.mem_email,
            birthdate: e.data.mem_birthdate,
            idcard: e.data.mem_idcard,
            address: e.data.mem_address,
            zipcode: e.data.mem_zipcode,
            isStatus: e.data.is_active,
            isDelete: e.data.is_delete,
            district: (e.data.mem_district != null ? e.data.mem_district.id : ''),
            country: (e.data.mem_country != null ? e.data.mem_country.id : ''),
            province: (e.data.mem_province != null ? e.data.mem_province.id : ''),
            province_name: (e.data.mem_province != null ? e.data.mem_province.name_th : ''),
            country_name: (e.data.mem_country != null ? e.data.mem_country.name_th : '') ,
            district_name: (e.data.mem_district != null ? e.data.mem_district.name_th : ''),
            mem_id: mem_id
          }
        });
        this.setState({isStatus: String(this.state.fields["isStatus"]) == "1"});
        this.setState({status: this.state.fields["isStatus"]});
        this.setState({
          defaultProvince:
          {
            label: this.state.fields["province_name"],
            value: this.state.fields["province"]
          },
          defaultCountry:
          {
            label: this.state.fields["country_name"],
            value: this.state.fields["country"]
          },
          defaultDistrict:
          {
            label: this.state.fields["district_name"],
            value: this.state.fields["district"]
          }
        });

        if(this.state.fields["province"]){
          memberAction.getAmphoe(this.state.fields["province"])
          .then(res => {
              this.setState({
                  amphoeName: res.data
              })
          })
        }

        if(this.state.fields["country"]){
          memberAction.getDistrict(this.state.fields["country"])
          .then(res => {
              this.setState({
                  districtName: res.data
              })
          })
        }

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/member";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = "*กรุณากรอกชื่อจริง.";
    }

    if (!fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = "*กรุณากรอกนามสกุล.";
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*กรุณากรอกเบอร์โทรศัพท์.";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*กรุณากรอก e-mail.";
    }

    if (!fields["birthdate"]) {
      formIsValid = false;
      errors["birthdate"] = "*กรุณากรอกวัน/เดือน/ปี เกิด.";
    }

    // if (!fields["idcard"]) {
    //   formIsValid = false;
    //   errors["idcard"] = "*กรุณากรอกเลขประจำตัวประชาชน.";
    // }

    // if (!fields["address"]) {
    //   formIsValid = false;
    //   errors["address"] = "*กรุณากรอกที่อยู่.";
    // }

    // if (!fields["district"]) {
    //   formIsValid = false;
    //   errors["district"] = "*กรุณากรอกตำบล/แขวง.";
    // }

    // if (!fields["country"]) {
    //   formIsValid = false;
    //   errors["country"] = "*กรุณากรอกเขต/อำเภอ.";
    // }

    // if (!fields["province"]) {
    //   formIsValid = false;
    //   errors["province"] = "*กรุณากรอกจังหวัด.";
    // }

    // if (!fields["zipcode"]) {
    //   formIsValid = false;
    //   errors["zipcode"] = "*กรุณากรอกรหัสไปรษณีย์.";
    // }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });


    const inputBody = {
      mem_firstname: this.state.fields["firstname"],
      mem_lastname: this.state.fields["lastname"],
      mem_phone: this.state.fields["phone"],
      mem_email: this.state.fields["email"],
      mem_password: this.state.fields["password"],
      mem_birthdate: this.state.fields["birthdate"],
      mem_idcard: this.state.fields["idcard"],
      mem_address: this.state.fields["address"],
      mem_district: this.state.fields["district"],
      mem_country: this.state.fields["country"],
      mem_province: this.state.fields["province"],
      mem_zipcode: this.state.fields["zipcode"],
      is_active: this.state["isStatus"],
      is_delete: this.state["delete"],
    }


    memberAction.update_Member(inputBody, this.state.fields["mem_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleDateChange = date => {
    this.setState({
      birthdate: date
    });
  };

  handleInputChange = e => {

  const target = e.target;
  const name = target.name;


  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })

    if(this.state.isStatus == true){
      this.setState({ status: '2' })
    }else{
      this.setState({ status: '1' })
    }
  }
}

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleProvinceChange = (selectedOption) => {

    let { fields, errors } = this.state;

    if(selectedOption != null){
      fields["province"] = selectedOption.value;
      this.state.errors["province"] = null;
    }else{
      fields["province"] = null
    }

    fields["country"] = null;
    fields["district"] = null;

    this.setState({
      defaultProvince:
        {
          label: selectedOption.label,
          value: selectedOption.value
        }
    })

    this.setState({
      defaultCountry: null,
      defaultDistrict: null,
      fields: {
        ...this.state.fields,
        zipcode: null
      }

    })


    this.setState({ fields, errors });

    memberAction.getAmphoe(this.state.fields["province"])
      .then(res => {
          this.setState({
              amphoeName: res.data
          })
      })

    memberAction.getDistrict()
      .then(res => {
          this.setState({
              districtName: res.data
          })
      })
  }

  handleAmphoeChange = (selectedOptionAmphoe) => {
    let { fields, errors } = this.state;

    if(selectedOptionAmphoe != null){
      fields["country"] = selectedOptionAmphoe.value;
      this.state.errors["country"] = null;
    }else{
      fields["country"] = null
    }

    this.setState({selectedOptionAmphoe, fields, errors });

    this.setState({
      defaultCountry:
        {
          label: selectedOptionAmphoe.label,
          value: selectedOptionAmphoe.value
        }
    })

    memberAction.getDistrict(this.state.fields["country"])
      .then(res => {
          this.setState({
              districtName: res.data
          })
      })
  }

  handleDistrictChange = (selectedOptionDistrict) => {
    let { fields, errors } = this.state;

    if(selectedOptionDistrict != null){
      fields["district"] = selectedOptionDistrict.value;
      this.state.errors["district"] = null;
      this.state.errors["mem_zipcode"] = null;
    }else{
      fields["district"] = null
    }

    this.setState({ selectedOptionDistrict, fields, errors });

    this.setState({
      defaultDistrict:
        {
          label: selectedOptionDistrict.label,
          value: selectedOptionDistrict.value
        }
    })

    this.getZipcodeByID(fields["district"]);
  }

  getZipcodeByID(id_district) {

    memberAction.getZipcode(id_district).then(e => {
      if (e.isSuccess === true) {
        this.setState({
            fields:{
              ...this.state.fields,
              zipcode: e.data.zip_code
            }
        });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });

  }

  render() {

    let optionsProvince = this.state.provinceName.map(function (provinceName) {
      return { value: provinceName.id, label: provinceName.name_th };
    })

    let optionsAmphoe = this.state.amphoeName.map(function (amphoe) {
      return { value: amphoe.id, label: amphoe.name_th };
    })

    let optionsDistrict = this.state.districtName.map(function (district) {
      return { value: district.id, label: district.name_th };
    })

    const {
      selectedOptionAmphoe,
      selectedOptionDistrict
    } = this.state;

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขข้อมูลสมาชิก</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >

              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-1">ชื่อ</label>
                  <div className="col-lg-5">
                  <input
                      name="firstname"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อ"
                      value={this.state.fields["firstname"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.firstname}</div>
                  </div>

                <label className="control-label col-lg-1">นามสกุล</label>
                <div className="col-lg-5">
                  <input
                    name="lastname"
                    type="text"
                    className="form-control"
                    placeholder="กรอกนามสกุล"
                    value={this.state.fields["lastname"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.lastname}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-1">เบอร์โทรศัพท์</label>
                <div className="col-lg-5">
                  <input
                    name="phone"
                    type="text"
                    className="form-control"
                    placeholder="กรอกเบอร์โทรศัพท์"
                    value={this.state.fields["phone"] || ""}
                    onChange={this.handleChange}
                    // readOnly
                  />
                  <div className="errorMsg">{this.state.errors.phone}</div>
                </div>

                <label className="control-label col-lg-1">e-mail</label>
                <div className="col-lg-5">
                  <input
                    name="email"
                    type="text"
                    className="form-control"
                    placeholder="กรอก e-mail"
                    value={this.state.fields["email"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.email}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-1">วัน/เดือน/ปี เกิด</label>
                <div className="col-lg-5">
                <input
                    name="birthdate"
                    type="date"
                    className="form-control"
                    placeholder="Please input Birthdate"
                    value={this.state.fields["birthdate"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.birthdate}</div>
                </div>

                <label className="control-label col-lg-1">เลขประจำตัวประชาชน</label>
                <div className="col-lg-5">
                  <input
                    name="idcard"
                    type="text"
                    className="form-control"
                    placeholder="กรอกเลขประจำตัวประชาชน"
                    value={this.state.fields["idcard"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.idcard}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-1">ที่อยู่</label>
                <div className="col-lg-5">
                  <textarea
                    name="address"
                    type="text"
                    className="form-control"
                    placeholder="กรอกที่อยู่"
                    value={this.state.fields["address"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.address}</div>
                </div>

                <label className="control-label col-lg-1">จังหวัด</label>
                <div className="col-lg-5">
                  <Select
                    name="province"
                    onChange={this.handleProvinceChange}
                    placeholder="เลือกจังหวัด"
                    options={optionsProvince}
                    value={this.state.defaultProvince ||""}
                  />
                  <div className="errorMsg">{this.state.errors.province}</div>
                </div>

              </div>

              <div className="form-group">
                <label className="control-label col-lg-1">อำเภอ/เขต</label>
                <div className="col-lg-5">
                  <Select
                    name="country"
                    placeholder="เลือกเขต/อำเภอ"
                    options={optionsAmphoe}
                    onChange={this.handleAmphoeChange}
                    value={this.state.defaultCountry ||""}
                  />
                  <div className="errorMsg">{this.state.errors.country}</div>
                </div>
                <label className="control-label col-lg-1">ตำบล/แขวง</label>
                <div className="col-lg-5">
                  <Select
                    name="district"
                    placeholder="เลือกแขวง/ตำบล"
                    options={optionsDistrict}
                    onChange={this.handleDistrictChange}
                    value={selectedOptionDistrict}
                    value={this.state.defaultDistrict ||""}

                  />
                  <div className="errorMsg">{this.state.errors.district}</div>
                </div>

              </div>

              <div className="form-group">
                <label className="control-label col-lg-1">รหัสไปรษณีย์</label>
                <div className="col-lg-5">
                  <input
                    name="zipcode"
                    type="text"
                    className="form-control"
                    placeholder="กรอกรหัสไปรษณีย์"
                    value={this.state.fields["zipcode"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.zipcode}</div>
                </div>

              </div>

              <div className="form-group">
                <label className="control-label col-lg-1">สถานะการใช้งาน</label>
                <div className="col-lg-5">
                <label className="switch">
                  <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                  <div className="slider"></div>
                  </label>
                </div>
              </div>


                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
