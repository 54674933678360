import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { PrivateRoute } from '../_components'

import Home from '../pages/Home'
import { Banner_List,Banner_Edit } from '../pages/General'
import { BannerPromotion_List, BannerPromotion_Create, BannerPromotion_Edit } from '../pages/General'
import { Contact_List,Contact_Create,Contact_Edit } from '../pages/General/Contact'

import { PreOrder_List,PreOrder_Info,PreOrder_Edit } from '../pages/Preorder/PreorderOrder'
import { PreOrder_Receive_List,PreOrder_Receive_Info,PreOrder_Receive_Edit } from '../pages/Preorder/PreorderReceiveOrder'
import { Takehome_List,Takehome_Info,Takehome_Edit  } from '../pages/Preorder/TakehomeOrder'
import { Category_Create, Category_Edit, Category_List,Category_Edit_Show } from '../pages/Preorder/PreorderCategory'
import { Product_Create, Product_Edit, Product_List } from '../pages/Preorder/PreorderProduct'
import { Product_Branch_List, Product_Branch_Edit } from '../pages/Preorder/PreorderProductBranch'
import { Queue_List } from '../pages/Preorder/PreorderQueue'
import { Branch_Create,Branch_List,Branch_Edit } from '../pages/Preorder/PreorderBranch'

import { Order_Online_List,Order_Online_Info,Order_Online_Edit } from '../pages/OrderOnline/OrderOnlineOrder'
import { Order_Online_Category_Create, Order_Online_Category_Edit, Order_Online_Category_List,Order_Online_Category_Edit_Show } from '../pages/OrderOnline/OrderOnlineCategory'
import { Order_Online_Recive_List,Order_Online_Recive_Info,Order_Online_Recive_Edit } from '../pages/OrderOnline/OrderOnlineReciveOrder'
import { Order_Online_Branch_Create,Order_Online_Branch_List,Order_Online_Branch_Edit } from '../pages/OrderOnline/OrderOnlineBranch'
import { Order_Online_Timelogs_List,Order_Online_Timelogs_Create,Order_Online_Timelogs_Edit,Order_Online_Timelogs_Info } from '../pages/OrderOnline/OrderOnlineTimelogs'
import { Product_Online_List,Product_Online_Create,Product_Online_Edit, Product_Online_ImportExcel } from '../pages/OrderOnline/OrderOnlineProduct'
import { Product_Online_Branch_List, Product_Online_Branch_Edit, Product_Online_Branch_Edit_By_Code } from '../pages/OrderOnline/ProductOnlineBranch'
import { Product_AddOn_List, Product_AddOn_Create, Product_AddOn_Edit } from '../pages/OrderOnline/ProductOnlineAddOn'
import { Promotion_List, Create_Promotion, Edit_Promotion } from '../pages/OrderOnline/Promotion'

import { Point_Earn_List } from '../pages/Point/PointEarn'
import { Point_Burn_List, Point_Burn_Info } from '../pages/Point/PointBurn'
import { Point_Manage } from '../pages/Point/PointManage'
import { Reward_Burn_List,Reward_Burn_Info } from '../pages/Rewards/RewardBurn'
import { Reward_List, Reward_Create, Reward_Edit } from '../pages/Rewards/RewardList'
import { Reward_Staff, Reward_Staff_List, Reward_Staff_Edit } from '../pages/Rewards/RewardStaff'

import { Reward_Category, Reward_Category_Create, Reward_Category_Edit } from '../pages/Rewards/RewardCategory'
import { Redeem_List, Redeem_Create, Redeem_Edit } from '../pages/Rewards/RedeemList'
import { Redeem_Category, Redeem_Category_Create, Redeem_Category_Edit } from '../pages/Rewards/RedeemCategory'
import { News_Create,News_List, News_Edit,News_Delete } from '../pages/News/News'
import { NewsPromotionBanner } from '../pages/News/Promotion/Banner'
import { NewsPromotionBanner_Edit } from '../pages/News/Promotion/Banner/Edit'
import { NewsPromotionB_Create } from '../pages/News/Promotion/Banner/Create'
import { NewsPromotion_Create,NewsPromotion_List,NewsPromotion_Edit } from '../pages/News/Promotion'
import { Member_Create,Member_List,Member_Edit,Member_Point  } from '../pages/Member'
import { Admin_Create,Admin_List,Admin_Edit,Admin_EditPassword } from '../pages/Admin/Admin'
import { Level_List,Level_Create,Level_Edit } from '../pages/Admin/Level'
import { List_Problem } from '../pages/Problem/ProblemSystem'
import { List_Problem_Ewallet } from '../pages/Ewallet/ProblemEwallet'
import { List_Problem_Ewallet_Title,Create_Problem_Ewallet_Title,Edit_Problem_Ewallet_Title } from '../pages/Ewallet/ProblemEwalletTitle'
import { Overview_Ewallet,Detail_Ewallet } from '../pages/Ewallet/Account/Overview'
import { Withdraw_Ewallet,WithdrawFund_Ewallet } from '../pages/Ewallet/Account/Withdraw'
import { History_Ewallet } from '../pages/Ewallet/Account/History'
// import { Transaction_Recent,Transaction_Search,Transaction_Void,Transaction_Refund } from '../pages/Ewallet/Transaction'
import { Transaction_List} from '../pages/Ewallet/Transaction'
import { Topup_Create } from '../pages/Ewallet/Topup'
import { Refund_Create } from '../pages/Ewallet/Refund'
import { Report_Daily_Settlement_Ewallet,Report_Daily_Refund_Ewallet,Report_Monthly_Settlement_Ewallet,Report_Monthly_Refund_Ewallet } from '../pages/Ewallet/Report'
import { List_Payment, Promotion_Payment_List, Edit_Promotion_Payment, Create_Promotion_Payment } from '../pages/Payment'

import { Staff_List, Staff_Create, Staff_Edit } from '../pages/Staff'
import { Ewallet_Staff_Edit } from '../pages/Staff/EwalletStaff'

import { Activity_All, Activity_Edit} from '../pages/Activity'
import { Flashsale_Time_List, Flashsale_Time_Create, Flashsale_Time_Edit } from '../pages/Activity'
import { Flashsale_Product_List } from '../pages/Activity'
import { FriendGetFriend_List, FriendGetFriend_Create, FriendGetFriend_Edit } from '../pages/Activity'

import { DiscountDelivery_List, DiscountDelivery_Create, DiscountDelivery_Edit } from '../pages/Activity'

import Edit_Payment_Channel_Status from '../pages/PaymentChannelStatus/edit'

import LoginPage from '../pages/LoginPage';
import NotFoundPages from '../pages/404'
import { Activities_Log } from '../pages/ActivitiesLog'

const chLogin = (localStorage.getItem('login') === null ? LoginPage : Home);
const urlPath = window.location.hostname
var urlAU = ""

export default () => (
  <Switch>
    <Route exact path="/" component={chLogin} />
    <Route exact path={urlAU+"/backoffice"} component={chLogin} />
    <Route exact path={urlAU+"/backoffice/login"} component={chLogin} />
    <PrivateRoute exact path={urlAU+"/backoffice/homepage"} component={Home} />

    <PrivateRoute exact path={urlAU+"/backoffice/preorder/order"} component={PreOrder_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/order/info/:od_number"} component={PreOrder_Info} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/order/edit/:od_number"} component={PreOrder_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/preorder/delivery"} component={Takehome_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/delivery/info/:od_number"} component={Takehome_Info} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/delivery/edit/:od_number"} component={Takehome_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product/category"} component={Category_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product/category/create"} component={Category_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product/category/edit/:categoryid"} component={Category_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product/category/editshow"} component={Category_Edit_Show} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product"} component={Product_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product/branch"} component={Product_Branch_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product/branch/edit/:pr_id"} component={Product_Branch_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product/create"} component={Product_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/product/edit/:pr_id"} component={Product_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/queue"} component={Queue_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/branch"} component={Branch_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/branch/create"} component={Branch_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/branch/edit/:branchid"} component={Branch_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/preorder/receive_order"} component={PreOrder_Receive_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/receive_order/info/:od_number"} component={PreOrder_Receive_Info} />
    <PrivateRoute exact path={urlAU+"/backoffice/preorder/receive_order/edit/:od_number"} component={PreOrder_Receive_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/order-online/order"} component={Order_Online_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/order/edit/:od_online_number"} component={Order_Online_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/order/info/:od_online_number"} component={Order_Online_Info} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product"} component={Product_Online_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/create"} component={Product_Online_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/importexcel"} component={Product_Online_ImportExcel} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/edit/:pr_online_id"} component={Product_Online_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/branch"} component={Order_Online_Branch_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/branch/create"} component={Order_Online_Branch_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/branch/edit/:branchid"} component={Order_Online_Branch_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/timelogs"} component={Order_Online_Timelogs_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/timelogs/create/:branchid"} component={Order_Online_Timelogs_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/timelogs/info/:branch_code"} component={Order_Online_Timelogs_Info} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/timelogs/edit/:branchid/:date_id"} component={Order_Online_Timelogs_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/category"} component={Order_Online_Category_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/category/create"} component={Order_Online_Category_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/category/edit/:pr_online_category_id"} component={Order_Online_Category_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/branch"} component={Product_Online_Branch_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/branch/edit/:pr_online_id"} component={Product_Online_Branch_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/branch/edit_by_code"} component={Product_Online_Branch_Edit_By_Code} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/add-on"} component={Product_AddOn_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/add-on/create"} component={Product_AddOn_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/product/add-on/edit/:id"} component={Product_AddOn_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/product/promotion"} component={Promotion_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/product/promotion/create"} component={Create_Promotion} />
    <PrivateRoute exact path={urlAU+"/backoffice/product/promotion/edit/:promo_list_id"} component={Edit_Promotion} />

    <PrivateRoute exact path={urlAU+"/backoffice/promotion/payment"} component={Promotion_Payment_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/promotion/payment/create"} component={Create_Promotion_Payment} />
    <PrivateRoute exact path={urlAU+"/backoffice/promotion/payment/edit/:promo_list_id"} component={Edit_Promotion_Payment} />

    <PrivateRoute exact path={urlAU+"/backoffice/order-online/receive_order"} component={Order_Online_Recive_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/receive_order/info/:od_online_number"} component={Order_Online_Recive_Info} />
    <PrivateRoute exact path={urlAU+"/backoffice/order-online/receive_order/edit/:od_online_number"} component={Order_Online_Recive_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/banner"} component={Banner_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/banner/edit/:banner_id"} component={Banner_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/contact"} component={Contact_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/contact/create"} component={Contact_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/contact/edit/:contact_id"} component={Contact_Edit} />


    <PrivateRoute exact path={urlAU+"/backoffice/point/earn"} component={Point_Earn_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/point/burn"} component={Point_Burn_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/point/manage"} component={Point_Manage} />
    <PrivateRoute exact path={urlAU+"/backoffice/point/burn/info/:logsid"} component={Point_Burn_Info} />

    <PrivateRoute exact path={urlAU+"/backoffice/reward/category"} component={Reward_Category} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/category/create"} component={Reward_Category_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/category/edit/:rewards_id"} component={Reward_Category_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/list"} component={Reward_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/create"} component={Reward_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/edit/:rewards_id"} component={Reward_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/burn/list"} component={Reward_Burn_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/burn/info/:logsid"} component={Reward_Burn_Info} />

    <PrivateRoute exact path={urlAU+"/backoffice/reward/staff/list"} component={Reward_Staff} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/staff/list/:staff_idcard"} component={Reward_Staff_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/reward/staff/edit/:staff_reward_id"} component={Reward_Staff_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/redeem/category"} component={Redeem_Category} />
    <PrivateRoute exact path={urlAU+"/backoffice/redeem/category/create"} component={Redeem_Category_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/redeem/category/edit/:redeem_id"} component={Redeem_Category_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/redeem/list"} component={Redeem_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/redeem/create"} component={Redeem_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/redeem/edit/:redeem_id"} component={Redeem_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/member"} component={Member_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/member/create"} component={Member_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/member/edit/:mem_id"} component={Member_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/member/log_point/:mem_id"} component={Member_Point} />

    <PrivateRoute exact path={urlAU+"/backoffice/news/news"} component={News_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/news/news/create"} component={News_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/news/news/edit/:news_id"} component={News_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/news/promotion"} component={NewsPromotion_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/news/promotion/create"} component={NewsPromotion_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/news/promotion/edit/:news_id"} component={NewsPromotion_Edit} />
    
    {/* Promotion Banner */}
    <PrivateRoute exact path={urlAU+"/backoffice/news/promotion-banner"} component={NewsPromotionBanner} />
    <PrivateRoute exact path={urlAU+"/backoffice/news/promotion-banner/edit/:banner_id"} component={NewsPromotionBanner_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/news/promotion-banner/create"} component={NewsPromotionB_Create} />

    <PrivateRoute exact path={urlAU+"/backoffice/admin"} component={Admin_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/admin/create"} component={Admin_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/admin/edit/:admin_id"} component={Admin_Edit} />
    <PrivateRoute exact path={urlAU+"/backoffice/admin/editpassword/:admin_id"} component={Admin_EditPassword} />
    <PrivateRoute exact path={urlAU+"/backoffice/level"} component={Level_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/level/create"} component={Level_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/level/edit/:level_id"} component={Level_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/problem"} component={List_Problem} />

    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/problem"} component={List_Problem_Ewallet} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/problem/title"} component={List_Problem_Ewallet_Title} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/problem/title/create"} component={Create_Problem_Ewallet_Title} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/problem/title/edit/:problem_ewallet_title_id"} component={Edit_Problem_Ewallet_Title} />

    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/account/overview"} component={Overview_Ewallet} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/account/withdraw"} component={Withdraw_Ewallet} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/account/withdraw_fund"} component={WithdrawFund_Ewallet} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/account/history"} component={History_Ewallet} />

    {/* <PrivateRoute exact path={urlAU+"/backoffice/ewallet/transaction/recent"} component={Transaction_Recent} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/transaction/search"} component={Transaction_Search} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/transaction/void"} component={Transaction_Void} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/transaction/refund"} component={Transaction_Refund} /> */}
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/transaction/list"} component={Transaction_List} />

    {/* <PrivateRoute exact path={urlAU+"/backoffice/ewallet/topup/list"} component={Topup_List} /> */}
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/topup/create/:mem_number"} component={Topup_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/refund/create/:mem_number/:transaction_id"} component={Refund_Create} />

    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/transaction/search/detail/:transaction_id"} component={Detail_Ewallet} />
    
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/report/daily/settlement"} component={Report_Daily_Settlement_Ewallet} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/report/daily/refund"} component={Report_Daily_Refund_Ewallet} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/report/monthly/settlement"} component={Report_Monthly_Settlement_Ewallet} />
    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/report/monthly/refund"} component={Report_Monthly_Refund_Ewallet} />

    <PrivateRoute exact path={urlAU+"/backoffice/payment/list"} component={List_Payment} />
    
    <PrivateRoute exact path={urlAU+"/backoffice/staff"} component={Staff_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/staff/create"} component={Staff_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/staff/edit/:staff_id"} component={Staff_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/ewallet/staff/edit/:staff_ewallet_id"} component={Ewallet_Staff_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/activity/all"} component={Activity_All} />
    <PrivateRoute exact path={urlAU+"/backoffice/activity/friend/edit/:activity_id"} component={Activity_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/activity/flashsale/time"} component={Flashsale_Time_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/activity/flashsale/time/create"} component={Flashsale_Time_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/activity/flashsale/time/edit/:flashsale_time_id"} component={Flashsale_Time_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/activity/flashsale/:flashsale_time_id/product/list"} component={Flashsale_Product_List} />

    <PrivateRoute exact path={urlAU+"/backoffice/activity/friendgetfriend/list"} component={FriendGetFriend_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/activity/friendgetfriend/create"} component={FriendGetFriend_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/activity/friendgetfriend/edit/:invite_condition_id"} component={FriendGetFriend_Edit} />
    
    <PrivateRoute exact path={urlAU+"/backoffice/activity/discount_delivery/list"} component={DiscountDelivery_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/activity/discount_delivery/create"} component={DiscountDelivery_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/activity/discount_delivery/edit/:discount_delivery_id"} component={DiscountDelivery_Edit} />

    <PrivateRoute exact path={urlAU+"/backoffice/banner_promotion/list"} component={BannerPromotion_List} />
    <PrivateRoute exact path={urlAU+"/backoffice/banner_promotion/create"} component={BannerPromotion_Create} />
    <PrivateRoute exact path={urlAU+"/backoffice/banner_promotion/edit/:banner_promotion_id"} component={BannerPromotion_Edit} />
    
    <PrivateRoute exact path={urlAU+"/backoffice/paymentchannel_status/edit"} component={Edit_Payment_Channel_Status} />

    <PrivateRoute exact path={urlAU+"/backoffice/activitieslog"} component={Activities_Log} />

    <PrivateRoute component={NotFoundPages} />
  </Switch>
)
