import React, { Component } from "react";

export class Subheader extends Component {
    render() {
        var tt = this.props.title

        return (
            <div className="page-header page-header-dark">
                <div className="page-header-content header-elements-md-inline">
                    <div className="page-title d-flex">
                        <h4>{tt}</h4>
                    </div>
                </div>
            </div>
        );
    }
}
