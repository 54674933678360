import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import { ewalletAction } from "../../../../_actions";
import "../account.css";
let modalAlert;

export class WithdrawFund_Ewallet extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);

  }

  componentDidMount() {
    ewalletAction.getAccountMerchant()
    .then(res => {
        this.setState({
          amount_balance: res.data.amount_balance,
        })
    })
  }

  handleSubmit(event) {    
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion(
        "Confirm"
      );    
    }
  }

  resetInput(e) {    
    let { fields } = this.state;
    fields['withdraw_amount'] = '';
    this.setState({ fields });

  }
  

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var data = {
      "transaction_amount": this.state.fields["withdraw_amount"],
      "transaction_description": "Withdraw by AU"
    }


    ewalletAction.withdrawForAU(data)
      .then(e => {
        if(e.isSuccess === true) {
          this.onModalSuccess('Success', e.message)
        } else {
          this.onModalError('Error', e.message)
        }
      });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
      Processing fee:	0 THB  <br />Payable amount:	{this.state.fields["withdraw_amount"]} ฿ <br /><div>คุณต้องการ withdraw ?</div>
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/ewallet/account/withdraw"
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["withdraw_amount"]) {
      formIsValid = false;
      errors["withdraw_amount"] = "*กรุณากรอกจำนวนเงินที่ต้องการ withdraw";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }


  render() {
    return (
      <div>
        <ol className="breadcrumb">
          <li><a href="#">E-Wallet</a></li>
          <li><a href="/ewallet/account/overview">บัญชี</a></li>
          <li><a href="/ewallet/account/withdraw">Whitdraw</a></li>
          <li className="active">Whitdraw Funds</li>
        </ol>
        <ul className="nav nav-tabs">
          <li role="Overview"><a href="/ewallet/account/overview">Overview</a></li>
          <li role="Withdraw" className="active"><a href="#">Withdraw</a></li>
          <li role="History"><a href="/ewallet/account/history">History</a></li>
        </ul>
        <div className="panel panel-flat">
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={e => this.handleSubmit(e)}>
              <div>
                <h4>Withdraw Funds</h4>
                <div className="panel-body panel-color-gray px-5 py-3">
                  <h4 className="text-center">Merchant details</h4>
                  <div className="row">
                    <div className="col-sm-2">Name of account:</div>
                    <div className="col-md-3">After You</div>
                  </div>
                  <br />
                  <h4 className="text-center">Enter amount of withdrawal</h4>
                  <div className="form-group">
                    <label htmlFor="balance" className="col-sm-2 control-label">Avilable balance</label>
                    <div className="col-sm-3">
                      {(parseFloat(this.state.amount_balance)).toLocaleString()} ฿
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="input-withdraw" className="col-sm-2 control-label">Withdraw amount</label>
                    <div className="col-sm-3">
                      <input 
                      type="text" 
                      className="form-control" 
                      name="withdraw_amount"
                      id="input-withdraw" 
                      placeholder="" 
                      onChange={this.handleChange}
                      />
                    </div>
                    <div className="errorMsg">{this.state.errors.withdraw_amount}</div>

                  </div>
                  <p style={{color:'red'}}>*การขอทำรายการ withdraw จะไม่มีผลจำนวนเงินในระบบ</p>
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                    <button type="reset" className="btn btn-danger mr-3" onClick={e => this.resetInput(e)}>Cancel</button>
                    <button type="submit" className="btn btn-success">Continue</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {this.state.modal}

      </div>
    );
  }
}
