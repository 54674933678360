import React from "react";
import { rewardAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import moment from "moment-timezone"
import "../../reward.css";

let modalAlert;
let checkbox_reward_type_receive = []
var checkbox_rewards_showtype = []


export class Reward_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      typeProgram: null,
      selectType: null,
      inputBody: {
        rewards_category_id: 0
      },
      selectOption: [],
      selectedOption: {},
      select_RedeemType_Option: [],
      selected_RedeemType_Option: {},
      check_checkbox_reward_type_receive: [],
      check_checkbox_rewards_showtype: [],
      checkboxItems_reward_type_receive: [],
      checkboxItems_rewards_showtype: []

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmEdit = this.onConfirmEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  componentDidMount() {
    let { selectOption, select_RedeemType_Option } = this.state;
    var params = this.props.match.params

    rewardAction.getRewardCategoryList().then(res => {
      for (var i in res.data) {
        selectOption.push({
          label: res.data[i].rewards_category_name,
          value: res.data[i].rewards_category_id
        });
      }
      this.setState({ selectOption });
    });

    rewardAction.getRedeemTypeLists().then(data => {
      for (var i in data) {
        select_RedeemType_Option.push({
          label: data[i].type_name,
          value: data[i].id
        });
      }
      this.setState({ select_RedeemType_Option });
    })

    this.setState({ rewardid: params.rewards_id })
    this.getRewardDataByID(params.rewards_id)
  }

  getRewardDataByID(id) {
    var { 
      selectedOption, 
      selected_RedeemType_Option,
      inputBody, 
      check_checkbox_reward_type_receive, 
      check_checkbox_rewards_showtype, 
      rewards_limited,
      rewards_limited_yes, 
      rewards_limited_no, 
      redeem_limited,
      redeem_limited_yes, 
      redeem_limited_no, 
      rewards_expired_type,
      rewards_expired_type_date,
      rewards_expired_type_number_day,
      rewards_expired_type_all,
      rewards_discount_type, 
      rewards_discount_type_baht, 
      rewards_discount_type_percent, 
      rewards_discount_type_product, 
    } = this.state;
    rewardAction.getRewardDataByID(id).then(e => {
      if(e.isSuccess === true) {
          var resp = e.data['0']
          console.log('detail: ', resp);
            inputBody = resp
            if(inputBody['rewards_image'] === Base_API.reward_category_image) {
                inputBody['oldImage'] =  `${Base_API.reward}/images/default.png`
            } else {
                inputBody['oldImage'] =  inputBody['rewards_image']
            }
            // ประเภทการรับรางวัล
            check_checkbox_reward_type_receive['type1'] = (inputBody['rewards_type_receive'].indexOf("1") != -1 ? true : false);
            check_checkbox_reward_type_receive['type2'] = (inputBody['rewards_type_receive'].indexOf("2") != -1 ? true : false);
            check_checkbox_reward_type_receive['type3'] = (inputBody['rewards_type_receive'].indexOf("3") != -1 ? true : false);

            for(let i in inputBody['rewards_type_receive']){
              checkbox_reward_type_receive.push(inputBody['rewards_type_receive'][i])
            }

            // เมนูที่ต้องแสดงรางวัล
            check_checkbox_rewards_showtype['showtype1'] = (inputBody['rewards_showtype'].indexOf("1") != -1 ? true : false);
            check_checkbox_rewards_showtype['showtype2'] = (inputBody['rewards_showtype'].indexOf("2") != -1 ? true : false);
            check_checkbox_rewards_showtype['showtype3'] = (inputBody['rewards_showtype'].indexOf("3") != -1 ? true : false);
            check_checkbox_rewards_showtype['showtype4'] = (inputBody['rewards_showtype'].indexOf("0") != -1 ? true : false);
            check_checkbox_rewards_showtype['showtype5'] = (inputBody['rewards_showtype'].indexOf("5") != -1 ? true : false);
            for(let i in inputBody['rewards_showtype']){
              if(inputBody['rewards_showtype'][i] != ","){
                checkbox_rewards_showtype.push(inputBody['rewards_showtype'][i])
              }
            }
            selectedOption['label'] = inputBody['rewards_category_name']
            selectedOption['value'] = inputBody['rewards_category_id']
            selected_RedeemType_Option['label'] = inputBody['type_name']
            selected_RedeemType_Option['value'] = inputBody['rewards_type_redeem']
            console.log(inputBody);

            rewards_limited = inputBody['rewards_limited']
            rewards_limited_yes = (inputBody['rewards_limited'] == "y" ? "checked" : "")
            rewards_limited_no = (inputBody['rewards_limited'] == "n" ? "checked" : "")

            redeem_limited = inputBody['redeem_limited']
            redeem_limited_yes = (inputBody['redeem_limited'] == "y" ? "checked" : "")
            redeem_limited_no = (inputBody['redeem_limited'] == "n" ? "checked" : "")
            
            rewards_discount_type = inputBody['rewards_discount_type']
            rewards_discount_type_baht = (inputBody['rewards_discount_type'] == "baht" ? "checked" : "")
            rewards_discount_type_percent = (inputBody['rewards_discount_type'] == "percent" ? "checked" : "")
            rewards_discount_type_product = (inputBody['rewards_discount_type'] == "product" ? "checked" : "")

            rewards_expired_type = inputBody['rewards_expired_type']
            rewards_expired_type_date = (inputBody['rewards_expired_type'] == "date" ? "checked" : "")
            rewards_expired_type_number_day = (inputBody['rewards_expired_type'] == "number_day" ? "checked" : "")
            rewards_expired_type_all = (inputBody['rewards_expired_type'] == "all" ? "checked" : "")

            this.setState({ 
              selectedOption, 
              inputBody: inputBody, 
              check_checkbox_reward_type_receive, 
              checkboxItems_reward_type_receive: inputBody['rewards_type_receive'],
              check_checkbox_rewards_showtype, 
              checkboxItems_rewards_showtype: inputBody['rewards_showtype'],
              rewards_limited,
              rewards_limited_yes,
              rewards_limited_no,
              redeem_limited,
              redeem_limited_yes,
              redeem_limited_no,
              rewards_discount_type, 
              rewards_discount_type_baht, 
              rewards_discount_type_percent, 
              rewards_discount_type_product, 
              rewards_expired_type,
              rewards_expired_type_date,
              rewards_expired_type_number_day,
              rewards_expired_type_all

            })
            

        } else {
            this.onModalError('ผิดพลาด', e.message)
        }
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmEdit()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/reward/list";
  }

  validateForm() {
    let inputBody = this.state.inputBody;
    let errors = {};
    let formIsValid = true;

    if (!inputBody["rewards_code"]) {
      formIsValid = false;
      errors["rewards_code"] = "*กรุณากรอกรหัสรางวัล";
    }

    if (!inputBody["rewards_name_th"]) {
      formIsValid = false;
      errors["rewards_name_th"] = "*กรุณากรอกชื่อรางวัล (TH)";
    }
    if (!inputBody["rewards_description_th"]) {
      formIsValid = false;
      errors["rewards_description_th"] = "*กรุณากรอกรายละเอียดรางวัล (TH)";
    }

    if (!inputBody["rewards_name_en"]) {
      formIsValid = false;
      errors["rewards_name_en"] = "*กรุณากรอกชื่อรางวัล (EN)";
    }
    if (!inputBody["rewards_description_en"]) {
      formIsValid = false;
      errors["rewards_description_en"] = "*กรุณากรอกรายละเอียดรางวัล (EN)";
    }

    if (!inputBody["rewards_category_id"]) {
      formIsValid = false;
      errors["rewards_category_id"] = "*กรุณาเลือกประเภทของรางวัล";
    }
    if (!inputBody["rewards_type_redeem"]) {
      formIsValid = false;
      errors["rewards_type_redeem"] = "*กรุณาเลือกเงื่อนไขในการแลกรีวอร์ด";
    }
    if (!inputBody["rewards_start"]) {
      formIsValid = false;
      errors["rewards_start"] = "*กรุณากรอกวันที่เริ่มต้น";
    }
    if (!inputBody["rewards_image"]) {
      formIsValid = false;
      errors["rewards_image"] = "*กรุณาแนบไฟล์รูปภาพ";
    }
    if (typeof this.state.checkboxItems_reward_type_receive !== 'undefined' && this.state.checkboxItems_reward_type_receive.length === 0) {
      formIsValid = false;
      errors["rewards_type_receive"] = "*กรุณาเลือกประเภทการรับสินค้าอย่างน้อย 1 ประเภท";
    }
    if (this.state.rewards_limited === undefined) {
      formIsValid = false;
      errors["rewards_limited"] = "*กรุณาแนบเลือกจำกัดของรางวัล";
    }
    if (this.state.rewards_limited === 'y' && !inputBody["rewards_count"]) {
      formIsValid = false;
      errors["rewards_count"] = "*กรุณากรอกจำนวนของรางวัล";
    }
    if (this.state.redeem_limited === undefined) {
      formIsValid = false;
      errors["redeem_limited"] = "*กรุณาแนบเลือกจำกัดการแลกของรางวัล";
    }
    if (this.state.redeem_limited === 'y' && !inputBody["redeem_count"]) {
      formIsValid = false;
      errors["redeem_count"] = "*กรุณากรอกจำนวนของรางวัล";
    }
    if (this.state.rewards_expired_type === undefined) {
      formIsValid = false;
      errors["rewards_expired_type"] = "*กรุณาเลือกประเภทวันที่สิ้นสุดของรางวัล";
    }
    if (this.state.rewards_expired_type === 'date' && !inputBody["rewards_end"]) {
      formIsValid = false;
      errors["rewards_end"] = "*กรุณากรอกวันทีสิ้นสุด";
    }
    if (this.state.rewards_expired_type === 'number_day' && !inputBody["rewards_expired_day"]) {
      formIsValid = false;
      errors["rewards_expired_day"] = "*กรุณากรอกวันทีสิ้นสุด";
    }

    if (this.state.rewards_expired_type === 'all' && !inputBody["rewards_end"]) {
      formIsValid = false;
      errors["rewards_end"] = "*กรุณากรอกวันที่สิ้นสุด";
    }
    if (this.state.rewards_expired_type === 'all' && !inputBody["rewards_expired_day"]) {
      formIsValid = false;
      errors["rewards_expired_day"] = "*กรุณากรอกจำนวนวันที่สิ้นสุด";
    }

    if (this.state.rewards_discount_type === undefined) {
      formIsValid = false;
      errors["rewards_discount_type"] = "*กรุณาเลือกประเภทส่วนลด";
    }
    if (this.state.inputBody['rewards_amount_min'] === undefined) {
      formIsValid = false;
      errors["rewards_amount_min"] = "*กรุณากรอกจำนวนเงินขั้นต่ำในการใช้รางวัล (บาท)";
    }
    if (this.state.inputBody['rewards_discount_max'] === undefined && (this.state.rewards_discount_type === "baht" || this.state.rewards_discount_type === "percent")) {
      formIsValid = false;
      errors["rewards_discount_max"] = "*กรุณากรอกจำนวนเงินที่ลดสูงสุด (บาท)";
    }
    if (this.state.inputBody['rewards_discount_percent'] === undefined && this.state.rewards_discount_type === "percent") {
      formIsValid = false;
      errors["rewards_discount_percent"] = "*กรุณากรอกจำนวนเงินที่ลด (เปอร์เซ็น)";
    }
    if (this.state.checkboxItems_rewards_showtype == undefined) {
      formIsValid = false;
      errors["rewards_showtype"] = "*กรุณาเลือกเมนูที่ต้องแสดงรางวัลอย่างน้อย 1 ประเภท";
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmEdit(ev) {
    this.setState({ modal: null });
    var formData = new FormData();
    if(typeof(this.state.inputBody["rewards_image"]) === 'object') {
      formData.append("rewards_image", this.state.inputBody["rewards_image"]);
    }
    if(Array.isArray(this.state.checkboxItems_reward_type_receive)) {
      formData.append("rewards_type_receive", this.state.checkboxItems_reward_type_receive);
    }
    if(Array.isArray(this.state.checkboxItems_rewards_showtype)) {
      formData.append("rewards_showtype", this.state.checkboxItems_rewards_showtype);
    }
    formData.append("rewards_code", this.state.inputBody["rewards_code"]);
    formData.append("rewards_channel_code", this.state.inputBody["rewards_channel_code"]);
    formData.append("rewards_name_th", this.state.inputBody["rewards_name_th"]);
    formData.append("rewards_description_th",this.state.inputBody["rewards_description_th"]);
    formData.append("rewards_name_en", this.state.inputBody["rewards_name_en"]);
    formData.append("rewards_description_en",this.state.inputBody["rewards_description_en"]);
    formData.append("point", this.state.inputBody["point"]);
    formData.append("rewards_category_id",this.state.inputBody["rewards_category_id"]);
    formData.append("rewards_type_redeem",this.state.inputBody["rewards_type_redeem"]);
    formData.append("rewards_count", this.state.inputBody["rewards_count"]);
    formData.append("rewards_remark", this.state.inputBody["rewards_remark"]);
    formData.append("rewards_remark_en", this.state.inputBody["rewards_remark_en"]);
    formData.append("is_open", this.state.inputBody["is_open"]);
    formData.append("is_show", this.state.inputBody["is_show"]);
    formData.append("rewards_start", this.state.inputBody["rewards_start"]);
    formData.append("rewards_lastdate_redeem", this.state.inputBody["rewards_lastdate_redeem"]);
    formData.append("rewards_end", this.state.inputBody["rewards_end"]);
    formData.append("rewards_limited", this.state.rewards_limited);
    formData.append("redeem_count", this.state.inputBody["redeem_count"]);
    formData.append("redeem_limited", this.state.redeem_limited);
    formData.append("rewards_expired_type", this.state.rewards_expired_type);
    formData.append("rewards_expired_day", this.state.inputBody["rewards_expired_day"]);
    formData.append("rewards_discount_type", this.state.rewards_discount_type);
    formData.append("rewards_amount_min", this.state.inputBody['rewards_amount_min']);
    formData.append("rewards_discount_max", this.state.inputBody['rewards_discount_max']);
    formData.append("rewards_forstaff", this.state.inputBody['rewards_forstaff']);
    formData.append("rewards_discount_percent", this.state.inputBody['rewards_discount_percent']);
    formData.append("rewards_welcome", this.state.inputBody['rewards_welcome']);
    formData.append("rewards_birthday", this.state.inputBody['rewards_birthday']);

    rewardAction.updateReward(formData, this.state.rewardid).then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleSelect = selectedOption => {
    let { inputBody, errors } = this.state;
    inputBody["rewards_category_id"] = selectedOption.value;
    this.state.errors["rewards_category_id"] = null;
    this.setState({ selectedOption, inputBody, errors });
  };
  handleSelectRedeemType = selected_RedeemType_Option => {
    let { inputBody, errors } = this.state;
    inputBody["rewards_type_redeem"] = selected_RedeemType_Option.value;
    this.state.errors["rewards_type_redeem"] = null;
    this.setState({ selected_RedeemType_Option, inputBody, errors });
  }

  handleChange = e => {
    let { inputBody, errors } = this.state;
    if (e.target.name === "is_open" || e.target.name === "is_show" || e.target.name === "rewards_forstaff" || e.target.name === "rewards_welcome" || e.target.name === "rewards_birthday") {
      inputBody[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      inputBody[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ inputBody, errors });
  };

  handleCheckbox(e) {
    let { inputBody,errors } = this.state;

    const itemName = e.target.name;
    const itemValue = e.target.value;
    const isChecked = e.target.checked;
    if(itemName == 'rewards_type_receive'){
      if(e.target.checked === true){
        checkbox_reward_type_receive.push(itemValue)
      }else{
        const index = checkbox_reward_type_receive.indexOf(itemValue);
        if (index > -1) {
          checkbox_reward_type_receive.splice(index, 1);
        }
      }   
      this.state.errors["rewards_type_receive"] = null;
      this.setState({
        checkboxItems_reward_type_receive: checkbox_reward_type_receive
      })
    }

    if(itemName == 'rewards_showtype'){
      if(e.target.checked === true){
        checkbox_rewards_showtype.push(itemValue)
      }else{
        const index = checkbox_rewards_showtype.indexOf(itemValue);
        if (index > -1) {
          checkbox_rewards_showtype.splice(index, 1);
        }
      }   
      this.state.errors["rewards_showtype"] = null;
      this.setState({
        checkboxItems_rewards_showtype: checkbox_rewards_showtype
      })
    }
    this.setState({ errors, inputBody });
  }
  

  handleRadio = e => {
    let { inputBody, errors } = this.state;

    const itemName = e.target.name;
    const itemValue = e.target.value;

    if(itemName === "rewards_limited"){
      this.state.errors["rewards_limited"] = null;  

      inputBody['rewards_count'] = 0;
      
      this.setState({
        inputBody,
        rewards_limited : itemValue,
      })
    }
    if(itemName === "redeem_limited"){
      this.state.errors["redeem_limited"] = null;  

      inputBody['redeem_count'] = 0;
      
      this.setState({
        inputBody,
        redeem_limited : itemValue,
      })
    }
    
    if(itemName === "rewards_expired_type"){
      this.state.errors["rewards_expired_type"] = null;
      
      inputBody['rewards_end'] = "";
      inputBody['rewards_expired_day'] = "";

      this.setState({
        inputBody,
        rewards_expired_type : itemValue
      })
    }

    if(itemName === "rewards_discount_type"){
      this.state.errors["rewards_discount_type"] = null;
      
      if(itemValue === "baht" || itemValue === "product"){
        inputBody['rewards_discount_percent'] = ""
      }
      if(itemValue === "product"){
        inputBody['rewards_discount_max'] = ""
      }

      this.setState({
        inputBody,
        rewards_discount_type : itemValue
      })
    }
    this.setState({ errors }); 
  };


  onChangeUploadHandler = e => {
    let { inputBody, errors } = this.state;
    inputBody[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      inputBody,
      errors
    });
  };

  render() {    
    let { selectedOption, selected_RedeemType_Option } = this.state;
    return (
      <div className="reward-container">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขรางวัล - {this.state.inputBody["rewards_name_th"]}</h4>
          </div>
          <br />
          <div className="panel-body">
            <div className="text-center mb-3">
              <img src={this.state.inputBody['oldImage']} style={{ maxWidth: "100%",maxHeight: "200px" }} alt={this.state.inputBody["rewards_name_th"]} />
            </div>
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสรางวัล</label>
                  <div className="col-lg-9">
                    <input
                      name="rewards_code"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสรางวัล"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_code"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_code"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อรางวัล (TH)</label>
                  <div className="col-lg-9">
                    <input
                      name="rewards_name_th"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อของรางวัล (TH)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_name_th"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_name_th"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อรางวัล (EN)</label>
                  <div className="col-lg-9">
                    <input
                      name="rewards_name_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อของรางวัล (EN)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_name_en"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_name_en"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสช่องทางรีวอร์ด (POS)</label>
                  <div className="col-lg-4">
                    <input
                      name="rewards_channel_code"
                      type="text"
                      className="form-control"
                      placeholder="รหัสช่องทางรีวอร์ด (POS)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_channel_code"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_channel_code"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รายละเอียดของรางวัล (TH)
                  </label>
                  <div className="col-lg-9">
                    <textarea
                      name="rewards_description_th"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรายละเอียดของรางวัล (TH)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_description_th"]}
                      rows={6}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_description_th"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รายละเอียดของรางวัล (EN)
                  </label>
                  <div className="col-lg-9">
                    <textarea
                      name="rewards_description_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรายละเอียดของรางวัล (EN)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_description_en"]}
                      rows={6}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_description_en"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    คะแนนที่ใช้แลก
                  </label>
                  <div className="col-lg-9">
                    <input
                      name="point"
                      type="number"
                      className="form-control"
                      placeholder="กรอกคะแนนที่ใช้แลกของรางวัล"
                      onChange={this.handleChange}
                      value={this.state.inputBody["point"]}
                    />
                    <div className="errorMsg">{this.state.errors["point"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    ประเภทรางวัล
                  </label>
                  <div className="col-lg-9">
                    <Select
                      className="typeSelect"
                      classNamePrefix="typeSelect-menu"
                      placeholder="ประเภทรางวัล"
                      value={selectedOption}
                      name="rewards_category_id"
                      onChange={this.handleSelect}
                      options={this.state.selectOption}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_category_id"]}
                    </div>
                  </div>
                </div>

                {/* เงื่อนไขการแลกรีวอร์ด */}
                <div className="form-group">
                  <label className="control-label col-lg-2">เงื่อนไขการแลกรีวอร์ด</label>
                  <div className="col-lg-10">
                    <Select
                      className="redeemTypeSelect"
                      classNamePrefix="redeemTypeSelect-menu"
                      placeholder="เลือกเงื่อนไขการแลกรีวอร์ด"
                      name="rewards_type_redeem"
                      value={selected_RedeemType_Option}
                      onChange={this.handleSelectRedeemType}
                      options={this.state.select_RedeemType_Option}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_type_redeem"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ประเภทส่วนลด</label>
                  <div className="col-lg-9">                    
                    <input type="radio" name="rewards_discount_type" id="rewards_discount_type_baht" value="baht" onChange={this.handleRadio} defaultChecked={this.state.rewards_discount_type_baht}/> จำนวนเงิน (บาท) <br/>
                    <input type="radio" name="rewards_discount_type" id="rewards_discount_type_percent" value="percent" onChange={this.handleRadio} defaultChecked={this.state.rewards_discount_type_percent}/> จำนวนเงิน (เปอร์เซ็น) <br/>
                    <input type="radio" name="rewards_discount_type" id="rewards_discount_type_product" value="product" onChange={this.handleRadio} defaultChecked={this.state.rewards_discount_type_product}/> แถมสินค้า

                    <div className="errorMsg">{this.state.errors["rewards_discount_type"]}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">จำนวนเงินขั้นต่ำในการใช้รางวัล (บาท)</label>
                  <div className="col-lg-3">
                    <input
                      name="rewards_amount_min"
                      type="number"
                      className="form-control"
                      placeholder="กรอกจำนวนเงินขั้นต่ำในการใช้รางวัล"
                      onChange={this.handleChange}
                      value={this.state.inputBody['rewards_amount_min']}
                    />
                    <div className="errorMsg">{this.state.errors["rewards_amount_min"]}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">จำนวนเงินที่ลด (เปอร์เซ็น)</label>
                  <div className="col-lg-3">
                    <input
                      name="rewards_discount_percent"
                      type="number"
                      value={this.state.inputBody['rewards_discount_percent']}
                      className={
                        (this.state.rewards_discount_type === "percent"
                          ? "form-control" 
                          : "form-control input-disabled disabled")
                        }
                      placeholder="กรอกจำนวนเงินที่ลด (เปอร์เซ็น)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors["rewards_discount_percent"]}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">จำนวนเงินที่ลดสูงสุด (บาท)</label>
                  <div className="col-lg-3">
                    <input
                      name="rewards_discount_max"
                      type="number"
                      value={this.state.inputBody['rewards_discount_max']}
                      className={
                        (this.state.rewards_discount_type === "baht" || this.state.rewards_discount_type === "percent"
                          ? "form-control" 
                          : "form-control input-disabled disabled")
                        }
                      placeholder="กรอกจำนวนเงินที่ลดสูงสุด (บาท)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors["rewards_discount_max"]}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">จำกัดของรางวัล</label>
                  <div className="col-lg-2">
                    <input type="radio" name="rewards_limited" id="rewards_limited_yes" value="y" onChange={this.handleRadio} defaultChecked={this.state.rewards_limited_yes}/> จำกัด <br/>
                    <input type="radio" name="rewards_limited" id="rewards_limited_no" value="n" onChange={this.handleRadio} defaultChecked={this.state.rewards_limited_no}/> ไม่จำกัด <br/>
                    
                    <div className="errorMsg">{this.state.errors["rewards_limited"]}</div>
                  </div>
                  <div className="col-lg-2">
                    <input
                      name="rewards_count"
                      type="number"
                      className={(this.state.rewards_limited === "y" ? "form-control" : "form-control input-disabled disabled")}
                      placeholder="กรอกจำนวนของรางวัล"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_count"]}
                    />
                    <div className="errorMsg">{this.state.errors["rewards_count"]}</div>
                  </div>
                </div>

                {/* จำกัดแลกของรางวัล */}
                <div className="form-group row">
                  <label className="control-label col-lg-2">จำกัดแลกของรางวัล</label>
                  <div className="col-lg-2">
                    <input type="radio" name="redeem_limited" id="redeem_limited_yes" value="y" onChange={this.handleRadio} defaultChecked={this.state.redeem_limited_yes}/> จำกัด <br/>
                    <input type="radio" name="redeem_limited" id="redeem_limited_no" value="n" onChange={this.handleRadio} defaultChecked={this.state.redeem_limited_no}/> ไม่จำกัด <br/>
                    <div className="errorMsg">{this.state.errors["redeem_limited"]}</div>
                  </div>
                  <div className="col-lg-2">
                    <input
                      name="redeem_count"
                      type="number"
                      className={(this.state.redeem_limited === "y" ? "form-control" : "form-control input-disabled disabled")}
                      placeholder="กรอกจำนวนของรางวัล"
                      onChange={this.handleChange}
                      value={this.state.inputBody["redeem_count"]}
                    />
                    <div className="errorMsg">{this.state.errors["redeem_count"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ประเภทการรับรางวัล</label>
                  <div className="col-lg-9">
                    <input type="checkbox" name="rewards_type_receive" value="1" onClick={this.handleCheckbox} defaultChecked={this.state.check_checkbox_reward_type_receive['type1']}/> แลกของรางวัล <br/>
                    <input type="checkbox" name="rewards_type_receive" value="2" onClick={this.handleCheckbox} defaultChecked={this.state.check_checkbox_reward_type_receive['type2']} /> รับสินค้าที่ร้าน <br/>
                    <input type="checkbox" name="rewards_type_receive" value="3" onClick={this.handleCheckbox} defaultChecked={this.state.check_checkbox_reward_type_receive['type3']} /> จัดส่งสินค้าทางไปรษณีย์
                    
                    <div className="errorMsg">{this.state.errors["rewards_type_receive"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">วันที่เริ่มต้น</label>
                  <div className="col-lg-3">
                    <input
                      name="rewards_start"
                      type="date"
                      className="form-control"
                      placeholder="กรอกวันที่เริ่มต้น"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_start"]}
                    />
                    <div className="errorMsg">{this.state.errors["rewards_start"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">วันที่สิ้นสุดแลกของรางวัล</label>
                  <div className="col-lg-3">
                    <input
                      name="rewards_lastdate_redeem"
                      type="date"
                      className="form-control"
                      placeholder="กรอกวันที่สิ้นสุดแลกของรางวัล"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_lastdate_redeem"]}
                    />
                    <div className="errorMsg">{this.state.errors["rewards_lastdate_redeem"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ประเภทวันที่สิ้นสุดของรางวัล</label>
                  <div className="col-lg-9">
                    <input type="radio" name="rewards_expired_type" id="rewards_expired_type_date" value="date" onChange={this.handleRadio} defaultChecked={this.state.rewards_expired_type_date}/> หมดตามวันที่กำหนด <br/>
                    <input type="radio" name="rewards_expired_type" id="rewards_expired_type_number_day" value="number_day" onChange={this.handleRadio} defaultChecked={this.state.rewards_expired_type_number_day}/> หมดตามจำนวนวันที่กำหนด <br/>
                    <input type="radio" name="rewards_expired_type" id="rewards_expired_type_all" value="all" onChange={this.handleRadio} defaultChecked={this.state.rewards_expired_type_all}/> หมดตามจำนวนวัน และ วันที่กำหนด <br/>

                    <div className="errorMsg">{this.state.errors["rewards_expired_type"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">วันที่สิ้นสุด</label>
                  <div className="col-lg-3">
                    <input
                      name="rewards_end"
                      type="date"
                      className={
                        (this.state.rewards_expired_type === "date" || this.state.rewards_expired_type === "all"
                          ? "form-control" 
                          : "form-control input-disabled disabled")
                        }
                      placeholder="กรอกวันที่สิ้นสุด"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_end"]}
                    />
                    <div className="errorMsg">{this.state.errors["rewards_end"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">จำนวนวันท่ีจะหมดอายุ (วัน)</label>
                  <div className="col-lg-3">
                    <input
                      name="rewards_expired_day"
                      type="number"
                      value={this.state.inputBody.rewards_expired_day}
                      className={
                        (this.state.rewards_expired_type === "number_day" || this.state.rewards_expired_type === "all"
                          ? "form-control" 
                          : "form-control input-disabled disabled")
                        }
                      placeholder="กรอกจำนวนวันท่ีจะหมดอายุ (วัน)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_expired_day"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">หมายเหตุ (TH)</label>
                  <div className="col-lg-9">
                    <input
                      name="rewards_remark"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหมายเหตุ (TH) เช่น วันที่รับของ : 3 นับวันจากที่สั่งสินค้า"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_remark"]}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">หมายเหตุ (EN)</label>
                  <div className="col-lg-9">
                    <input
                      name="rewards_remark_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหมายเหตุ (EN)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_remark_en"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_remark_en"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">เมนูที่ต้องแสดงรางวัล</label>
                  <div className="col-lg-9">
                    <input type="checkbox" name="rewards_showtype" value="1" onClick={this.handleCheckbox} defaultChecked={this.state.check_checkbox_rewards_showtype['showtype1']}/> Delivery <br/>
                    <input type="checkbox" name="rewards_showtype" value="2" onClick={this.handleCheckbox} defaultChecked={this.state.check_checkbox_rewards_showtype['showtype2']}/> EMS <br/>
                    <input type="checkbox" name="rewards_showtype" value="3" onClick={this.handleCheckbox} defaultChecked={this.state.check_checkbox_rewards_showtype['showtype3']}/> Pickup <br/>
                    <input type="checkbox" name="rewards_showtype" value="5" onChange={this.handleCheckbox} defaultChecked={this.state.check_checkbox_rewards_showtype['showtype5']}/> Snackbox <br/>
                    <input type="checkbox" name="rewards_showtype" value="0" onClick={this.handleCheckbox} defaultChecked={this.state.check_checkbox_rewards_showtype['showtype4']}/> เฉพาะ My Rewards <br/>
                    {/* <input type="checkbox" name="rewards_showtype" value="0" onChange={this.handleCheckbox}/> เฉพาะใน My rewards */}
                    
                    <div className="errorMsg">{this.state.errors["rewards_showtype"]}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">สำหรับพนักงาน</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="rewards_forstaff"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.inputBody["rewards_forstaff"] === 1) ? true : false}

                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">Welcome Pack</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="rewards_welcome"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.inputBody["rewards_welcome"] === 1) ? true : false}
                      />
                    <div className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">Birthday Special</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="rewards_birthday"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.inputBody["rewards_birthday"] === 1) ? true : false}
                      />
                    <div className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">รูปของรางวัล
                    <span className="text-danger"> (500 x 500 px)</span>
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="file"
                      className="file-input"
                      name="rewards_image"
                      onChange={this.onChangeUploadHandler}
                      data-allowed-file-extensions='["jpg", "jpeg", "png", "gif"]'
                      // value={this.state.inputBody["rewards_image"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_image"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    ต้องการโชว์รีวอร์ด
                  </label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="is_show"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.inputBody["is_show"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    สถานะเปิดใช้งาน
                  </label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="is_open"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.inputBody["is_open"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
