import React from "react";
import { ewalletAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../../ewallet.css";

let modalAlert;

export class Create_Problem_Ewallet_Title extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      errors: {},
      fields: {},
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/ewallet/problem/title"
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if(!fields["problem_ewallet_title_name_th"]){
      formIsValid = false;
      errors["problem_ewallet_title_name_th"] = "*กรุณากรอกหัวข้อ (TH)";
    }

    if(!fields["problem_ewallet_title_name_en"]){
      formIsValid = false;
      errors["problem_ewallet_title_name_en"] = "*กรุณากรอกหัวข้อ (EN)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }


  onConfirmCreate(ev) {
    this.setState({ modal: null })
    var formData = ({
      'problem_ewallet_title_name_th' : this.state.fields["problem_ewallet_title_name_th"],
      'problem_ewallet_title_name_en' : this.state.fields["problem_ewallet_title_name_en"],
      'is_active' : this.state.fields["is_active"] ? this.state.fields["is_active"] : 2,
      'is_delete' : 2
    });

    ewalletAction.createProblemEwalletTitle(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active") {
      fields[e.target.name] = e.target.checked === true ? 1 : 2;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });

    // this.validateData();


  };



  render() {
    let { selectedOption } = this.state;

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มหัวข้อแจ้งปัญหาการใช้งาน</h4>
          </div>
          <br />


          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">หัวข้อ (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="problem_ewallet_title_name_th"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหัวข้อ (TH)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.problem_ewallet_title_name_th}</div>
                  </div>

                  <label className="control-label col-lg-2">หัวข้อ (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="problem_ewallet_title_name_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหัวข้อ (EN)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.problem_ewallet_title_name_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะเปิดใช้งาน</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="is_active"
                        type="checkbox"
                        onChange={this.handleChange}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
