import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import { ewalletAction } from "../../../../_actions";
import "../transaction.css";
import ReactExport from 'react-data-export';


$.DataTable = require("datatables.net");

var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd+mm+yyyy+hh+mn+ss+ms+'-transaction-recent'


export class Transaction_Recent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }

  componentDidMount() {
    this.showTable();
    this.exportExcel()

  }

  showTable(){
    const columns = [
      {
        title: "#",
        class: "text-center",
        data: "num"
      },
      {
        title: "Transaction No",
        class: "text-center",
        data: "transaction_id"
      },
      {
        title: "Card Member Number",
        class: "text-center",
        data: "mem_number"
      },
      {
        title: "Date/Time",
        class: "text-center",
        data: "created_at", 
      },
      {
        title: "Settlement Date/Time",
        class: "text-center",
        data: "settlement_date", 
      },
      {
        title: "Transaction Amount",
        class: "text-center",
        data: "transaction_amount"
      },
      {
        title: "Total Amount (฿)",
        class: "text-center",
        data: "total_amount"
      },
      {
        title: "Frees (฿)",
        class: "text-center",
        data: "fees"
      },
      {
        title: "Net Amount (฿)",
        class: "text-center",
        data: "net_amount"
      },
      {
        title: "Status",
        class: "text-center",
        data: "transaction_status"
      },
      {
        title: "",
        class: "text-center",
        data: "transaction_id"
      },
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-right">${Number(parseFloat(cellData).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})} ฿</div>`);
          }
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-right">${Number(parseFloat(cellData).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})}</div>`);
          }
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-right">${Number(parseFloat(cellData).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})}</div>`);
          }
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-right">${Number(parseFloat(cellData).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})}</div>`);
          }
        },
        {
          targets: 9,
          createdCell: function(td, cellData, rowData, row, col) {
          //   var status = '';
          //   if(rowData.status_payment == '1') status = `<span class="badge badge-warning">${cellData}</span>`
          //   else if(rowData.status_payment == '2') status = `<span class="badge badge-success">${cellData}</span>`
          //   else if(rowData.status_payment == '3') status = `<span class="badge badge-danger">${cellData}</span>`
          //   else status = `<span class="badge badge-info">ไม่ระบุสถานะ</span>`
            $(td).html(`${cellData}`);
          },
          orderable: false
        },
        {
          targets: 10,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).html(`
            <div>
              <a href="/backoffice/ewallet/transaction/search/detail/${cellData}">
                <img width="20px" src="../../../images/search_icon.png">
              </a>
            </div>
            `);
          },
          orderable: false
        }
      ],

      ajax: {
        url: `${Base_API.ewallet}/api/v1/ewallet/search/recent`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[2, "DESC"]],
      columns,
      serverSide: true,
      ordering: false,
      searching: false,
      processing: true,
      bLengthChange: true,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

  }

  exportExcel = () => {
    ewalletAction.getRecentList()
    .then(res => {
        this.setState({
            dataTransSearch: res.data
        })
    })
  };



  render() {

    var dataExcel = []
    let data = this.state.dataTransSearch    
    for(var i in data){
      dataExcel.push(
        [
          {value: data[i].num},
          {value: data[i].transaction_id},
          {value: data[i].mem_number},
          {value: data[i].created_at},
          {value: data[i].settlement_date},
          {value: data[i].transaction_amount},
          {value: data[i].total_amount},
          {value: data[i].fees},
          {value: data[i].net_amount},
          {value: data[i].transaction_status},
        ],
      )
    }    
    
    multiDataSet = [
      {
        columns: [
            {title: "#"},//pixels width 
            {title: "Transaction No", width: {wch: 40}},//char width 
            {title: "Card Member Number", width: {wch: 20}},
            {title: "Date/Time", width: {wch: 20}},
            {title: "Settlement Date/Time", width: {wch: 15}},
            {title: "Transaction Amount", width: {wch: 20}},
            {title: "Total Amount (฿)", width: {wch: 20}},
            {title: "Frees (฿)", width: {wch: 20}},
            {title: "Net Amount (฿)", width: {wch: 20}},
            {title: "Status"},
        ],
        data: dataExcel
      }
    ];

    return (
      <div>
        <ol className="breadcrumb">
          <li><a href="#">E-Wallet</a></li>
          <li><a href="#">Transaction</a></li>
          <li className="active">Recent</li>
        </ol>
        
        <ul className="nav nav-tabs">
          <li role="Overview" className="active"><a href="#">Recent</a></li>
          <li role="Search"><a href="/backoffice/ewallet/transaction/search">Search</a></li>
          <li role="Void"><a href="/backoffice/ewallet/transaction/void">Void</a></li>
          <li role="Refund"><a href="/backoffice/ewallet/transaction/refund">Refund</a></li>
        </ul>
        <div className="panel panel-flat panel-ewallet">
          <div className="panel-body">
            <div>
              <div className="row">
                <div className="col-md-6">
                  <h4>Recent Transactions</h4>
                </div>
                <div className="col-md-6">
                  <div className="text-right my-2">
                    <ExcelFile 
                      element={
                        <button
                        type="button"
                        name="add_banner_btn"
                        data-content=""
                        className="btn btn-add"
                        >
                        <i className="icon-download"></i> Export Excel
                        </button>
                      }
                      filename = {name_excel}
                    >
                      <ExcelSheet dataSet={multiDataSet} name="Transaction-Search" />
                    </ExcelFile>
                  </div>
                </div>
              </div>
              
              <div className="table-responsive">
                <table id="dataTable" className="table table-hover" ref="main" />
              </div>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
