import React from "react";
import $ from "jquery";
import { rewardAction } from "../../../../_actions"
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../reward.css";

$.DataTable = require("datatables.net");
let modalAlert;

export class Reward_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  onError(ev) {
    console.log(ev)
  }

  componentDidMount() {
    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "rewards_id"
      },
      {
        title: "รูปภาพ",
        class: "text-center",
        data: "rewards_image"
      },
      {
        title: "รหัสรางวัล",
        data: "rewards_code"
      },
      {
        title: "ชื่อรางวัล",
        data: "rewards_name_th"
      },
      {
        title: "คะแนน (P)",
        data: "point"
      },
      {
        title: "ประเภท",
        data: "rewards_category_name"
      },
      {
        title: "จำนวน (รางวัล)",
        data: "rewards_count"
      },
      {
        title: "วันที่สร้าง",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "สถานะการใช้งาน",
        class: "text-center",
        data: "is_open"

      },
      {
        title: " จัดการ",
        class: "text-center",
        data: "rewards_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).html(row+1);
          },
          orderable: false
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            console.log(rowData)
            
            var request = new XMLHttpRequest();  
            request.open('GET', rowData.rewards_image, true);
            request.onreadystatechange = function(){
                if (request.readyState === 4){
                    if (request.status === 404) {  
                      document.querySelector(`#catth-${rowData.rewards_id}`).src = 'https://grabapi.afteryoudessertcafe.com:4020/images/default.png'           
                    }  
                }
            };
            request.send();

            $(td).html(`<img class='category_thumbnail' id="catth-${rowData.rewards_id}" src='${cellData}' alt='${rowData.rewards_name}' />`);
          },
          orderable: false
        },
        // {
        //   targets: 8,
        //   createdCell: function(td, cellData, rowData, row, col) {
        //     $(td)
        //       .html(`
        //         <label class="switch">
        //           <input name="is_open" class="activeBtn" data-id="${rowData.rewards_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
        //           <div class="slider"></div>
        //         </label>
        //       `);
        //   },
        //   orderable: false
        // },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="is_open" class="activeBtn" data-id="${rowData.rewards_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: false
        },
        {
          targets: 9,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/reward/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                    <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                  </div>
                </div>
              </div>
              `);
              // <div class="text-center"><a href="/reward/edit/${cellData}"><button type='button' name='edit_btn' data-content='' class='btn btn-edit'>แก้ไข</button></a>
              // <button type='button' name='delete_btn' data-id='${cellData}' class='btn btn-danger delete_btn'>ลบ</button></div>`);
            },
            orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.reward}/reward`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          console.log(data)
          return data;
        }
      },
      order: [[7, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 0;
      var formData = new FormData();
      formData.append('is_open', fval)

      rewardAction.activateReward(formData, fid).then(e => {

        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var rewardId = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(rewardId)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(rewardId) {
      rewardAction.deleteReward(rewardId).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }


    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการของรางวัล</h4>
            <div className="heading-elements">
            <a href="/backoffice/reward/create">
              <button
                type="button"
                name="add_banner_btn"
                data-content=""
                className="btn btn-add"
              >
                  เพิ่มรางวัล
                </button>
              </a>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
