import React from "react";
import $ from "jquery";
import { newsAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { Base_API } from "../../../../_constants/matcher";
import Select from "react-select";
import "../promotion.css";
// import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let today = new Date();
let year = today.getFullYear()
let month_num = (today.getMonth() + 1)
let month = (month_num < 9 ? "0"+month_num.toString() : month_num)
let date_num = today.getDate()
let date = (date_num < 9 ? "0"+date_num.toString() : date_num)

var datenow = year + '-' + month + '-' + date;

let modalAlert;
export class NewsPromotion_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      fields: {
        // newsUpdate: datenow,
      },
      errors: {},
      typeProgram: null,
      selectType: null,
      isStatus: true,
      status: 0,
      delete: 0,
      news_like: "0",
      editorState: EditorState.createEmpty(),
      editorStateDes: EditorState.createEmpty()
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.test = this.test.bind(this);
  }

  componentDidMount(){
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการเพิ่มข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/news/promotion";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;


    if (!fields["newsDescription"]) {
      formIsValid = false;
      errors["newsDescription"] = "*กรุณากรอกคำอธิบายภาพ (TH)";
    }

    if (!fields["newsDescription_en"]) {
      formIsValid = false;
      errors["newsDescription_en"] = "*กรุณากรอกคำอธิบายภาพ (EN)";
    }

    // if (!fields["news_image"]) {
    //   formIsValid = false;
    //   errors["news_image"] = "*กรุณาแนบไฟล์รูปภาพ";
    // }

    if (!fields["newsStart"]) {
      formIsValid = false;
      errors["newsStart"] = "*กรุณากรอกวันที่เริ่มต้นข่าวสาร";
    }

    if (!fields["newsEnd"]) {
      formIsValid = false;
      errors["newsEnd"] = "*กรุณากรอกวันที่สิ้นสุดข่าวสาร";
    }

    // if (!fields["news_image_sub"]) {
    //   formIsValid = false;
    //   errors["news_image_sub"] = "*กรุณาแนบไฟล์รูปภาพ";
    // }

    if (!fields["news_image_1"] && !fields["news_image_2"] && !fields["news_image_3"] && !fields["news_image_4"] && !fields["news_video_1"]) {
      formIsValid = false;
      errors["news_image_1"] = "*กรุณาแนบรูปภาพข่าวสาร หรือวีดีโอข่าวสารอย่างน้อย 1 อย่าง";
      errors["news_video_1"] = "*กรุณาแนบรูปภาพข่าวสาร หรือวีดีโอข่าวสารอย่างน้อย 1 อย่าง";
    }

    if (fields["news_image_1"] && !fields["promotion_sort_1"]) {
      formIsValid = false;
      errors["promotion_sort_1"] = "*กรุณากรอกลำดับการแสดงผล";
    }

    if (fields["news_image_2"] && !fields["promotion_sort_2"]) {
      formIsValid = false;
      errors["promotion_sort_2"] = "*กรุณากรอกลำดับการแสดงผล";
    }

    if (fields["news_image_3"] && !fields["promotion_sort_3"]) {
      formIsValid = false;
      errors["promotion_sort_3"] = "*กรุณากรอกลำดับการแสดงผล";
    }

    if (fields["news_image_4"] && !fields["promotion_sort_4"]) {
      formIsValid = false;
      errors["promotion_sort_4"] = "*กรุณากรอกลำดับการแสดงผล";
    }

    if (fields["news_video_1"] && !fields["promotion_sort_5"]) {
      formIsValid = false;
      errors["promotion_sort_5"] = "*กรุณากรอกลำดับการแสดงผล";
    }

    if (fields["news_video_1"] && !fields["news_image_video_1"]) {
      formIsValid = false;
      errors["news_image_video_1"] = "*กรุณาแนบรูปภาพหน้าปกวีดีโอ";
    }

    if (fields["news_image_1"] && fields["news_image_1"].type != 'image/jpeg') {
      formIsValid = false;
      errors["news_image_1"] = "*กรุณาแนบเฉพาะรูป (.jpg)";
    }

    if (fields["news_image_2"] && fields["news_image_2"].type != 'image/jpeg') {
      formIsValid = false;
      errors["news_image_2"] = "*กรุณาแนบเฉพาะรูป (.jpg)";
    }

    if (fields["news_image_3"] && fields["news_image_3"].type != 'image/jpeg') {
      formIsValid = false;
      errors["news_image_3"] = "*กรุณาแนบเฉพาะรูป (.jpg)";
    }

    if (fields["news_image_4"] && fields["news_image_4"].type != 'image/jpeg') {
      formIsValid = false;
      errors["news_image_4"] = "*กรุณาแนบเฉพาะรูป (.jpg)";
    }

    if (fields["news_image_video_1"] && fields["news_image_video_1"].type != 'image/jpeg') {
      formIsValid = false;
      errors["news_image_video_1"] = "*กรุณาแนบเฉพาะรูป (.jpg)";
    }

    if (fields["news_video_1"] && fields["news_video_1"].type != 'video/mp4') {
      formIsValid = false;
      errors["news_video_1"] = "*กรุณาแนบเฉพาะวีดีโอ (.mp4)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });
    var formData = new FormData();
    // formData.append("news_image", this.state.fields["news_image"]);
    // formData.append("news_image_sub", this.state.fields["news_image_sub"]);
    // formData.append("news_title", this.state.fields["newsTitle"]);
    // formData.append("news_type", this.state["newsType"]);
    formData.append("news_description", this.state.fields["newsDescription"]);
    formData.append("news_description_en", this.state.fields["newsDescription_en"]);
    formData.append("news_start", this.state.fields["newsStart"]);
    formData.append("news_end", this.state.fields["newsEnd"]);
    formData.append("news_active", this.state["status"]);
    formData.append("news_delete", 0);
    formData.append("news_like", 0);

    formData.append("news_image_1", this.state.fields["news_image_1"]);
    formData.append("promotion_sort_1", this.state.fields["promotion_sort_1"]);

    formData.append("news_image_2", this.state.fields["news_image_2"]);
    formData.append("promotion_sort_2", this.state.fields["promotion_sort_2"]);

    formData.append("news_image_3", this.state.fields["news_image_3"]);
    formData.append("promotion_sort_3", this.state.fields["promotion_sort_3"]);

    formData.append("news_image_4", this.state.fields["news_image_4"]);
    formData.append("promotion_sort_4", this.state.fields["promotion_sort_4"]);

    formData.append("news_image_video_1", this.state.fields["news_image_video_1"]);
    formData.append("news_video_1", this.state.fields["news_video_1"]);
    formData.append("promotion_sort_5", this.state.fields["promotion_sort_5"]);

    newsAction.createPromotion(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;

    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;

    this.setState({ fields, errors });
  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleInputChange = e => {
    const target = e.target;
    const name = target.name;
    let status;

    if (name == "isStatus") {
      this.setState({ isStatus: !this.state.isStatus });
      if (this.state.isStatus == true) {
        this.setState({ status: "1" });
      } else {
        this.setState({ status: "0" });
      }
    }
  };

  test = e => {
    console.log(e.target.id);
  };

  onEditorStateChange = editorState => {
    var rawHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({ editorState, newsImgSub: rawHtml });

    let { fields,errors } = this.state;

    if(editorState.getCurrentContent().hasText()){
      fields["news_image_sub"] = true;
      this.state.errors["news_image_sub"] = null;
      this.setState({ fields,errors });
    }else{
      fields["news_image_sub"] = false;
    }

  };

  // onEditorStateDesChange = editorStateDes => {
  //   var rawHtml = draftToHtml(convertToRaw(editorStateDes.getCurrentContent()));
  //   this.setState({ editorStateDes, newsDescription: rawHtml });
  //
  //   let { fields,errors } = this.state;
  //
  //   if(editorStateDes.getCurrentContent().hasText()){
  //     fields["newsDescription"] = true;
  //     this.state.errors["newsDescription"] = null;
  //     this.setState({ fields,errors });
  //   }else{
  //     fields["newsDescription"] = false;
  //   }
  //
  //
  //
  // };

  render() {
    const { editorState } = this.state;
    const { editorStateDes } = this.state;    

    function uploadImageCallBack(file) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
        xhr.open("POST", `${Base_API.upload_img}/image/uploads`);
        const data = new FormData(); // eslint-disable-line no-undef
        data.append("image", file);
        xhr.send(data);
        xhr.addEventListener("load", () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener("error", () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      });
    }

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มข่าวสารโปรโมชั่น</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2 col-md-2">
                    รูปภาพข่าวสารโปรโมชั่น
                    <div className="text-danger">(500 x 500 px)</div>
                  </label>
                  <div className="col-lg-10 col-md-10">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 text-center">
                        รูปภาพ <span className="text-danger">(.jpg สูงสุด 4 รูป)</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <input
                          type="file"
                          className="file-input"
                          name="news_image_1"
                          onChange={this.onChangeUploadHandler}
                        />
                        <div className="errorMsg">
                          {this.state.errors.news_image_1}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 text-right">
                        ลำดับการแสดงผล
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <input
                          type="text"
                          className="form-control"
                          name="promotion_sort_1"
                          onChange={this.handleChange}
                          pattern="[0-9]*"
                        />
                        <div className="errorMsg">
                          {this.state.errors.promotion_sort_1}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <input
                          type="file"
                          className="file-input"
                          name="news_image_2"
                          onChange={this.onChangeUploadHandler}
                        />
                        <div className="errorMsg">
                          {this.state.errors.news_image_2}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 text-right">
                        ลำดับการแสดงผล
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <input
                          type="text"
                          className="form-control"
                          name="promotion_sort_2"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                          {this.state.errors.promotion_sort_2}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <input
                          type="file"
                          className="file-input"
                          name="news_image_3"
                          onChange={this.onChangeUploadHandler}
                        />
                        <div className="errorMsg">
                          {this.state.errors.news_image_3}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 text-right">
                        ลำดับการแสดงผล
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <input
                          type="text"
                          className="form-control"
                          name="promotion_sort_3"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                          {this.state.errors.promotion_sort_3}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <input
                          type="file"
                          className="file-input"
                          name="news_image_4"
                          onChange={this.onChangeUploadHandler}
                        />
                        <div className="errorMsg">
                          {this.state.errors.news_image_4}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 text-right">
                        ลำดับการแสดงผล
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <input
                          type="text"
                          className="form-control"
                          name="promotion_sort_4"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                          {this.state.errors.promotion_sort_4}
                        </div>
                      </div>
                    </div>
                  </div>
                    
                  <label className="control-label col-lg-2 col-md-2">
                    วีดีโอสารโปรโมชั่น
                    <div className="text-danger">(500 x 500 px)</div>
                  </label>
                  <div className="col-lg-10 col-md-10">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 text-center">
                        วีดีโอ <span className="text-danger">(.mp4 สูงสุด 1 คลิป)</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <input
                          type="file"
                          className="file-input"
                          name="news_video_1"
                          id="news_video_1"
                          onChange={this.onChangeUploadHandler}
                        />
                        <div className="errorMsg">
                          {this.state.errors.news_video_1}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 text-right">
                        ลำดับการแสดงผล
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <input
                          type="text"
                          className="form-control"
                          name="promotion_sort_5"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                          {this.state.errors.promotion_sort_5}
                        </div>
                      </div>
                    </div>
                  </div>
                  <label className="control-label col-lg-2 col-md-2">
                    รูปภาพหน้าปกวีดีโอสารโปรโมชั่น
                    <div className="text-danger">(500 x 500 px)</div>
                  </label>
                  <div className="col-lg-10 col-md-10">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <input
                          type="file"
                          className="file-input"
                          name="news_image_video_1"
                          onChange={this.onChangeUploadHandler}
                        />
                        <div className="errorMsg">
                          {this.state.errors.news_image_video_1}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="form-group">
                  <label className="control-label col-lg-2 col-md-2">รายละเอียดข่าวสารโปรโมชั่น (TH)</label>
                  <div className="col-lg-10 col-md-10">
                    <textarea
                    name="newsDescription"
                    rows={6}
                    className="form-control"
                    placeholder="กรอกคำอธิบายภาพ (TH)"
                    onChange={this.handleChange}
                    />

                    <div className="errorMsg">
                      {this.state.errors.newsDescription}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2 col-md-2">รายละเอียดข่าวสารโปรโมชั่น (EN)</label>
                  <div className="col-lg-10 col-md-10">
                  <textarea
                      className="form-control"
                      rows={6}
                      name="newsDescription_en"
                      placeholder="คำอธิบายภาพ (EN)"
                      onChange={this.handleChange}
                  >
                  </textarea>
                    <div className="errorMsg">{this.state.errors.newsDescription_en}</div>
                  </div>
                </div>

                {/* <div className="form-group">
                  <label className="control-label col-lg-2 col-md-2">
                    รูปรายละเอียดข่าวสารโปรโมชั่น
                  </label>
                  <div className="col-lg-10 col-md-10">

                    <Editor
                      placeholder="แนบรูปรายละเอียดข่าวสารโปรโมชั่น"
                      initialEditorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'image', 'remove', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                          uploadCallback: uploadImageCallBack,
                          previewImage: true,
                          alt: { present: true, mandatory: true }
                        },
                      }}
                      onEditorStateChange={this.onEditorStateChange}
                    />

                    <div className="errorMsg">
                      {this.state.errors.news_image_sub}
                    </div>
                  </div>
                </div> */}

                {/* <div className="form-group">
                <label className="control-label col-lg-2 col-md-2">วันที่อัพเดตข้อมูล (เดือน/วัน/ปี)</label>
                  <div className="col-lg-4">
                    <input
                      name="newsUpdate"
                      type="date"
                      data-date-format="DD-MM-YYYY"
                      className="form-control"
                      placeholder="วันที่อัพเดตข้อมูล"
                      value={this.state.fields["newsUpdate"] || ""}

                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.newsUpdate}
                    </div>
                  </div>
                </div> */}

                <div className="form-group">
                  <label className="control-label col-lg-2 col-md-2">วันที่เริ่ม (เดือน/วัน/ปี)</label>
                  <div className="col-lg-4 col-md-4">
                    <input
                      name="newsStart"
                      type="date"
                      data-date-format="DD-MM-YYYY"
                      className="form-control"
                      placeholder="กรอกวันที่เริ่ม"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.newsStart}
                    </div>
                  </div>

                  <label className="control-label col-lg-2 col-md-2">
                    วันที่สิ้นสุด (เดือน/วัน/ปี)
                  </label>
                  <div className="col-lg-4 col-md-4">
                    <input
                      name="newsEnd"
                      type="date"
                      data-date-format="DD-MM-YYYY"
                      className="form-control"
                      placeholder="กรอกวันที่สิ้นสุด"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsEnd}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2 col-md-2">สถานะ</label>
                  <div className="col-lg-4 col-md-4">
                    <label className="switch">
                      <input
                        name="isStatus"
                        type="checkbox"
                        onChange={this.handleInputChange}
                      />
                      <div className="slider" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 col-md-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
