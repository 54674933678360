import React from "react";
import $ from "jquery";
import { rewardAction, orderAction, BackofficeAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../reward.css";
import ReactExport from 'react-data-export';

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-rewards-history-burn'

let newDate = new Date()
let month = newDate.getMonth() + 1;
if(month < 10) month = '0'+ month
let year = newDate.getFullYear();

let dataSearch = {
  s_FirstName: '',
  s_LastName: '',
  s_Phone: '',
  s_RewardsCode: '',
  s_RewardsName: '',
  s_RedeemDate: '',
  s_RedeemDateTo: '',
  s_BurnDate: '',
  s_BurnDateTo: '',
  s_ExpiredDate: '',
  s_ExpiredDateTo: '',
}

export class Reward_Burn_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      fields: {},
      errors: {},
      show: false,
      modal: null,
      isModal: false,
      excelUrl: "",
      isExport: true,
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    this.showTable(dataSearch);
  }

  showTable(dataSearch){
    const columns = [
      {
        title: "รหัสออเดอร์",
        class: "text-center",
        data: "rw_transection_id"
      },
      {
        title: "รหัสรีวอร์ด",
        class: "text-center",
        data: "rw_rewardcode"
      },
      {
        title: "รายการ",
        class: "text-center",
        data: "rewards_name_th"
      },
      {
        title: "Friend Get Friend",
        class: "text-center",
        data: "rw_status_friendgetfriend"
      },
      {
        title: "รหัสสมาชิก",
        class: "text-center",
        data: "mem_number"
      },
      {
        title: "ชื่อผู้ใช้งาน",
        class: "text-center",
        data: "mem_firstname"
      },
      {
        title: "เบอร์โทร",
        class: "text-center",
        data: "mem_phone"
      },
      {
        title: "จำนวนรีวอร์ด",
        class: "text-center",
        data: "rw_count"
      },
      {
        title: "ประเภทการรับสินค้า",
        class: "text-center",
        data: "rw_type_receive"
      },
      {
        title: "Remark",
        class: "text-center",
        data: "rw_remark"
      },
      {
        title: "เลข tracking",
        class: "text-center",
        data: "rw_tracking"
      },
      {
        title: "วันที่แลก",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "วันที่หมดอายุ",
        class: "text-center",
        data: "rw_expired_at"
      },
      {
        title: "การใช้งาน",
        class: "text-center",
        data: "rw_rewardstatus"
      },
      {
        title: "ออเดอร์ที่ใช้งาน",
        class: "text-center",
        data: "orderno"
      },
      {
        title: "วันที่ใช้งาน",
        class: "text-center",
        data: "rw_burn_date"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "rw_coupon_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets:  3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`${cellData == 1 ? "/" : ""}`);
          },
          orderable: true
        },
        {
          targets:  5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData + ' ' + rowData.mem_lastname}</div>`);
          },
          orderable: true
        },
        {
          targets:8,
          createdCell: function(td, cellData, rowData, row, col) {
            var status = '';
            if(cellData === 1) status = '<strong class="text-primary">แลกของรางวัล<strong>'
            else if(cellData === 2) status = '<strong class="text-primary">รับสินค้าที่ร้าน<strong>'
            else if(cellData === 3) status = '<strong class="text-primary">จัดส่งทางไปรษณีย์<strong>'
            else status = 'ไม่มีสถานะการจัดส่ง'
            $(td).html(status);
          },
          orderable: true
        },
        {
          targets: 13,
          createdCell: function(td, cellData, rowData, row, col) {
            var status = '';
            if(cellData === 'N') status = '<span class="badge badge-warning">ยังไม่ได้ใช้งาน<span>'
            else if(cellData === 'Y') status = '<span class="badge badge-success">ใช้งานแล้ว<span>'
            else if(cellData === 'C') status = '<span class="badge badge-danger">ยกเลิก<span>'
            else status = 'ไม่ระบุ'
            $(td).html(status);
          },
          orderable: true
        },
        {
          targets: 16,
          createdCell: function(td, cellData, rowData, row, col) {
            var disabled = 'disabled'
            var bt_manage = '';
            var bt_notification = ''
            if(rowData.rw_type_receive == 3){ //ส่ง  notification            
              let dataSMS = {
                "phone_number":rowData.mem_phone,
                "order_online_number":rowData.rewards_name_th,
                "tracking_number":rowData.rw_tracking
              }
              bt_notification =`<button href="#" class="dropdown-item sendSMS" data-value=${JSON.stringify(dataSMS)}><i class="icon-envelope mr-3 mb-1"></i> ส่ง SMS หมายเลข tracking</a>`
            }else{
              bt_notification = `<a href="#" class="dropdown-item disabled-btn"><i class="icon-envelope mr-3 mb-1"></i> ส่ง SMS หมายเลข tracking</a>`
            }
            $(td)
              .html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/reward/burn/info/${cellData}" class="dropdown-item"><i class="icon-file-eye2 mr-3 mb-1"></i> ดูรายละเอียด</a>
                    <button class="dropdown-item activeBtn ${(rowData.rw_rewardstatus == 'N' ? '' : disabled)}" data-id='${cellData}'><i class="icon-cancel-circle2 mr-3 mb-1"></i> ยกเลิกรางวัล</button>
                    ${bt_notification}
                  </div>
                </div>
              </div> 

              `);
          },
              // <div class="text-center"><a href="/reward/burn/info/${cellData}"><button type='button' name='edit_btn' data-content='' class='btn btn-info edit-btn'>ดูรายละเอียด</button></a>
              //       <button type='button' name='cancel_btn' data-id='${cellData}' class='btn btn-danger activeBtn'>ยกเลิกรางวัล</button></div>
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.reward}/burn?searchFirstName=${dataSearch.s_FirstName}&searchLastName=${dataSearch.s_LastName}&searchPhone=${dataSearch.s_Phone}&searchRewardsCode=${dataSearch.s_RewardsCode}&searchRewardsName=${dataSearch.s_RewardsName}&searchRedeemDate=${dataSearch.s_RedeemDate}&searchRedeemDateTo=${dataSearch.s_RedeemDateTo}&searchBurnDate=${dataSearch.s_BurnDate}&searchBurnDateTo=${dataSearch.s_BurnDateTo}&searchExpiredDate=${dataSearch.s_ExpiredDate}&searchExpiredDateTo=${dataSearch.s_ExpiredDateTo}`,
        type: "GET",
        dataType: "JSON",
        data: (data, res) => {
          // let waitJson = setInterval(() => {
          //   if(!!res.json)
          //   {
          //     this.setState({query: res.json.query})
          //     clearInterval(waitJson);
          //   }
          // }, 500)
          return data;
        },
      },
      order: [[11, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var id = $(this).data("id");
      var val = 'C';

      var formData = {
        "rw_rewardstatus" : val
      }

      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmCancel(formData,id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการยกเลิกคูปองนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    $(this.refs.main).on("click", ".sendSMS", function () {
      var obj_data = $(this).data("value");

      orderAction.sendSMS(obj_data).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var logsId = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(logsId)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmCancel(formData,id) {
      rewardAction.activeLogsHistoryByID(formData,id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onConfirmDelete(logsId) {
      rewardAction.deleteBurnHistory(logsId).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

  }

  handleChangeSearch = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (fields["searchRedeemDate"] && !fields["searchRedeemDateTo"]) {
      formIsValid = false;
      errors["searchRedeemDateTo"] = "*กรุณากรอกวันที่แลกรีวอร์ด";
    }

    if (!fields["searchRedeemDate"] && fields["searchRedeemDateTo"]) {
      formIsValid = false;
      errors["searchRedeemDate"] = "*กรุณากรอกวันที่แลกรีวอร์ด";
    }

    if (fields["searchBurnDate"] && !fields["searchBurnDateTo"]) {
      formIsValid = false;
      errors["searchBurnDateTo"] = "*กรุณากรอกวันที่ใช้งานรีวอร์ด";
    }

    if (!fields["searchBurnDate"] && fields["searchBurnDateTo"]) {
      formIsValid = false;
      errors["searchBurnDate"] = "*กรุณากรอกวันที่ใช้งานรีวอร์ด";
    }

    if (fields["searchExpiredDate"] && !fields["searchExpiredDateTo"]) {
      formIsValid = false;
      errors["searchExpiredDateTo"] = "*กรุณากรอกวันที่รีวอร์ดหมดอายุ";
    }

    if (!fields["searchExpiredDate"] && fields["searchExpiredDateTo"]) {
      formIsValid = false;
      errors["searchExpiredDate"] = "*กรุณากรอกวันที่รีวอร์ดหมดอายุ";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  handleSubmit(e) {
    var table = $('#dataTable').DataTable();
    table.destroy();

    e.preventDefault()

    if (this.validateForm()) {
      var dataSearch = {
        s_FirstName: (this.state.fields["searchFirstName"] ? this.state.fields["searchFirstName"] : ''),
        s_LastName: (this.state.fields["searchLastName"] ? this.state.fields["searchLastName"] : ''),
        s_Phone: (this.state.fields["searchPhone"] ? this.state.fields["searchPhone"] : ''),
        s_RewardsCode: (this.state.fields["searchRewardsCode"] ? this.state.fields["searchRewardsCode"] : ''),
        s_RewardsName: (this.state.fields["searchRewardsName"] ? this.state.fields["searchRewardsName"] : ''),
        s_RedeemDate: (this.state.fields["searchRedeemDate"] ? this.state.fields["searchRedeemDate"] : ''),
        s_RedeemDateTo: (this.state.fields["searchRedeemDateTo"] ? this.state.fields["searchRedeemDateTo"] : ''),
        s_BurnDate: (this.state.fields["searchBurnDate"] ? this.state.fields["searchBurnDate"] : ''),
        s_BurnDateTo: (this.state.fields["searchBurnDateTo"] ? this.state.fields["searchBurnDateTo"] : ''),
        s_ExpiredDate: (this.state.fields["searchExpiredDate"] ? this.state.fields["searchExpiredDate"] : ''),
        s_ExpiredDateTo: (this.state.fields["searchExpiredDateTo"] ? this.state.fields["searchExpiredDateTo"] : ''),
      }

      this.showTable(dataSearch);
    }
  }

  clearSearch = (e) => {
    let { fields } = this.state;
    fields['searchFirstName']='';
    fields['searchLastName']='';
    fields['searchPhone']='';
    fields['searchRewardsCode']='';
    fields['searchRewardsName']='';
    fields['searchRedeemDate']='';
    fields['searchRedeemDateTo']='';
    fields['searchBurnDate']='';
    fields['searchBurnDateTo']='';
    fields['searchExpiredDate']='';
    fields['searchExpiredDateTo']='';
    this.setState({ fields });
  };

  exportExcel = () => {
    this.setState({isExport: false})
    BackofficeAction.getExcelRewardRedeemHistory()
    .then(res => {
      console.log(res);
      if (res.status === true) {
        // Assuming that res.data contains the URL of the file
        const fileUrl = res.data;
        
        // Trigger file download
        this.downloadFile(fileUrl);
        this.setState({isExport: true})
      }
    })
  };

  exportExcelByFilter = () => {
    this.setState({isExport: false});
    let {fields} = this.state; 
    let table = $(this.refs.main).DataTable();
    // Get current ordering information
    const ordering = table.order();
    // Get current page information
    const pageInfo = table.page.info();
    const start = pageInfo.start;
    const length = pageInfo.length;
    // Construct column data parameters for the API call
    let columnParams = [];
    table.columns().every(function () {
        let columnData = {
            data: this.dataSrc(),
            name: this.header().textContent.trim(), // Get the column name from the header text
            searchable: this.search() !== null ? 'true' : 'false', // Check if searching is enabled for the column
            orderable: ordering.some(order => order[0] === this.index()), // Check if ordering is enabled for the column
            'search[value]': '',
            'search[regex]': 'false'
        };
        columnParams.push(columnData);
    });
    // Construct ordering parameters for the API call
    let orderParams = ordering.map(order => {
        return `order[column]=${order[0]}&order[dir]=${order[1]}`;
    }).join('&');

    let searchFirstName = !!fields["searchFirstName"] ? fields["searchFirstName"] : ''
    let searchLastName =  !!fields["searchLastName"] ? fields["searchLastName"] : ''
    let searchPhone = !!fields["searchPhone"] ? fields["searchPhone"] : ''
    let searchRewardsCode = !!fields['searchRewardsCode'] ? fields['searchRewardsCode'] : ''
    let searchRewardsName = !!fields["searchRewardsName"] ? fields["searchRewardsName"] : ''
    let searchRedeemDate = !!fields["searchRedeemDate"] ? fields["searchRedeemDate"] : ''
    let searchRedeemDateTo = !!fields["searchRedeemDateTo"] ? fields["searchRedeemDateTo"] : ''
    let searchBurnDate = !!fields["searchBurnDate"] ? fields["searchBurnDate"] : ''
    let searchBurnDateTo = !!fields["searchBurnDateTo"] ? fields["searchBurnDateTo"] : ''
    let searchExpiredDate = !!fields["searchExpiredDate"] ? fields["searchExpiredDate"] : ''
    let searchExpiredDateTo = !!fields["searchExpiredDateTo"] ? fields["searchExpiredDateTo"] : ''
    let searchText = $('input[aria-controls="dataTable"]').val();
    let searchRegex = false;
    let searchField = `searchFirstName=${searchFirstName}&searchLastName=${searchLastName}&searchPhone=${searchPhone}&searchRewardsCode=${searchRewardsCode}&searchRewardsName=${searchRewardsName}&searchRedeemDate=${searchRedeemDate}&searchRedeemDateTo=${searchRedeemDateTo}&searchBurnDate=${searchBurnDate}&searchBurnDateTo=${searchBurnDateTo}&searchExpiredDate=${searchExpiredDate}&searchExpiredDateTo=${searchExpiredDateTo}`;
    let filterText = encodeURI(`?search[value]=${searchText}&search[regex]=${searchRegex}&${orderParams}&${searchField}`);

    // Append column data parameters to the filterText
    columnParams.forEach((column, index) => {
        Object.entries(column).forEach(([key, value]) => {
            filterText += `&columns[${index}][${key}]=${value}`;
        });
    });

    console.log('filterText: ', filterText);

    BackofficeAction.getExcelRewardRedeemHistoryByFilter(filterText)
    .then(res => {
        console.log(res);
        if (res.status === true) {
            // Assuming that res.data contains the URL of the file
            const fileUrl = res.data;
            
            // Trigger file download
            this.downloadFile(fileUrl);
            this.setState({isExport: true});
        }
    })
    .catch(error => {
        // Handle error
        console.error('Error exporting Excel:', error);
        this.setState({isExport: true}); // Ensure isExport is set back to true even in case of error
    });
  };

  downloadFile = (fileUrl) => {
    // Use the fetch API to get the file data
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a download link
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        
        // Extract the filename from the URL or use a default name
        const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1) || 'download';
        a.download = fileName;
        
        // Append the link to the body and trigger the click event
        document.body.appendChild(a);
        a.click();
        
        // Remove the link from the body
        document.body.removeChild(a);
      })
      .catch(error => {
        console.error("Error downloading file:", error);
      });
  };

  render() {
    var dataExcel = []
    let data = this.state.dataRewardHistory    
    for(var i in data){
      dataExcel.push(
        [
          {value: data[i].rw_transection_id ? data[i].rw_transection_id : ''},
          {value: data[i].rw_rewardcode ? data[i].rw_rewardcode : ''},
          {value: data[i].rewards_name_th ? data[i].rewards_name_th : ''},
          {value: data[i].mem_number},
          {value: data[i].mem_firstname + " " + data[i].mem_lastname},
          {value: data[i].mem_phone ? data[i].mem_phone : ''},
          {value: data[i].rw_count ? data[i].rw_count : 0},
          {value: data[i].rw_type_receive_name ? data[i].rw_type_receive_name : ''},
          {value: data[i].rw_tracking ? data[i].rw_tracking : ''},
          {value: data[i].created_at ? data[i].created_at : ''},
          {value: data[i].rw_expired_at ? data[i].rw_expired_at : ''},
          {value: data[i].rw_rewardstatus ? data[i].rw_rewardstatus : ''},
          {value: data[i].orderno ? data[i].orderno : ''},
          {value: data[i].rw_burn_date ? data[i].rw_burn_date : ''}
        ],
      )
    } 
    
    multiDataSet = [
      {
        columns: [
          {title: "รหัสออเดอร์"},//pixels width 
          {title: "รหัสรีวอร์ด"},//pixels width 
          {title: "รายการ", width: {wch: 40}},//char width 
          {title: "รหัสสมาชิก", width: {wch: 30}},//char width 
          {title: "ชื่อผู้ใช้งาน", width: {wch: 50}},//char width 
          {title: "เบอร์โทร", width: {wch: 20}},//char width 
          {title: "จำนวนรีวอร์ด", width: {wch: 10}},//char width 
          {title: "ประเภทการรับสินค้า", width: {wch: 40}},//char width 
          {title: "เลข Tracking", width: {wch: 40}},//char width 
          {title: "วันที่แลก", width: {wch: 20}},//char width 
          {title: "วันที่หมดอายุ", width: {wch: 20}},//char width 
          {title: "การใช้งาน", width: {wch: 20}},//char width 
          {title: "ออเดอร์ที่ใช้งาน", width: {wch: 20}},
          {title: "วันที่ใช้งาน", width: {wch: 20}}
        ],
        data: dataExcel
      }
    ];
    
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">ประวัติการแลกรางวัล</h4>

            <div className="heading-elements">
              {/* <ExcelFile element={
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                <i className="icon-download"></i> Export Excel
                </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Reward-History" />
              </ExcelFile> */}
              <button
                  type="button"
                  name="add_banner_btn"
                  onClick={this.exportExcelByFilter}
                  data-content=""
                  className={`btn mr-2 ${this.state.isExport ? 'btn-add' : 'disabled'}`}
                  style={this.state.isExport ? {} : { cursor: 'not-allowed' }}
                >
                  <i className="icon-download"></i> Export Excel By Filtered
              </button>
              <button
                  type="button"
                  name="add_banner_btn"
                  onClick={this.exportExcel}
                  data-content=""
                  className={`btn mr-2 ${this.state.isExport ? 'btn-add' : 'disabled'}`}
                  style={this.state.isExport ? {} : { cursor: 'not-allowed' }}
                >
                  <i className="icon-download"></i> Export Excel (All)
              </button>
            </div>
          </div>
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="panel-body panel-color-gray px-5 py-3">
                <div className="pl-3">
                  <div className="row my-2">
                    <div className="col-md-3">ชื่อผู้ใช้งาน</div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="searchFirstName"
                        placeholder="ชื่อผู้ใช้งาน"
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchFirstName']}
                      />
                    </div>
                    <div className="col-md-3">นามสกุลผู้ใช้งาน</div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="searchLastName"
                        placeholder="นามสกุลผู้ใช้งาน"
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchLastName']}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-3">เบอร์โทร</div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="searchPhone"
                        placeholder="เบอร์โทร"
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchPhone']}
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-3">รหัสรีวอร์ด</div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="searchRewardsCode"
                        placeholder="รหัสรีวอร์ด"
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchRewardsCode']}
                      />
                      <div className="errorMsg">{this.state.errors.searchRewardsCode}</div>
                    </div>

                    <div className="col-md-3">ชื่อรีวอร์ด</div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="searchRewardsName"
                        placeholder="ชื่อรีวอร์ด"
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchRewardsName']}
                      />
                      <div className="errorMsg">{this.state.errors.searchRewardsName}</div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-3">วันที่แลกรีวอร์ด</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchRedeemDate"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchRedeemDate']}
                      />
                      <div className="errorMsg">{this.state.errors.searchRedeemDate}</div>
                    </div>

                    <div className="col-md-3">ถึง วันที่แลกรีวอร์ด</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchRedeemDateTo"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchRedeemDateTo']}
                      />
                      <div className="errorMsg">{this.state.errors.searchRedeemDateTo}</div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-3">วันที่ใช้งานรีวอร์ด</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchBurnDate"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchBurnDate']}
                      />
                      <div className="errorMsg">{this.state.errors.searchBurnDate}</div>
                    </div>

                    <div className="col-md-3">ถึง วันที่ใช้งานรีวอร์ด</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchBurnDateTo"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchBurnDateTo']}
                      />
                      <div className="errorMsg">{this.state.errors.searchBurnDateTo}</div>
                    </div>
                  </div>
                  
                  <div className="row mb-2">
                    <div className="col-md-3">วันที่รีวอร์ดหมดอายุ</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchExpiredDate"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchExpiredDate']}
                      />
                      <div className="errorMsg">{this.state.errors.searchExpiredDate}</div>
                    </div>

                    <div className="col-md-3">ถึง วันที่รีวอร์ดหมดอายุ</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchExpiredDateTo"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchExpiredDateTo']}
                      />
                      <div className="errorMsg">{this.state.errors.searchExpiredDateTo}</div>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <input
                        type="submit"
                        className="btn btn-add btn-md btn-banner-create mr-3"
                        value="ค้นหา"
                    />
                    <input
                        type="button"
                        className="btn btn-clear btn-md"
                        onClick={this.clearSearch}
                        value="เคลียร์ข้อมูลค้นหา"
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12">
              <div className="table-responsive">
                  <table id="dataTable" className="table table-hover" ref="main" />
              </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
