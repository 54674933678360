import React from "react";
import { branchAction,orderAction } from '../../../../_actions'
import SweetAlert from "react-bootstrap-sweetalert";
import "../timelogs.css";

let modalAlert;

export class Order_Online_Timelogs_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      fields: {},
      errors: {},
      typeProgram: null,
      selectType: null,
      isStatus: '',
      status: '',
      isStatusAvailable: '',
      statusAvailable: '',

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.getTimelogsDataByID(params.date_id);
  }

  getTimelogsDataByID(date_id) {

    orderAction.getTimelogsByID(date_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            date_name: e.data.date_name,
            // available_status: e.data.available_status,
            product_max:e.data.product_max,
            isStatus: e.data.is_active,
            b_id: e.data.b_id,
            b_name: e.data.b_name,
            date_id: date_id
          }
        });

        this.setState({isStatus: String(this.state.fields["isStatus"]) == "1"});
        this.setState({status: this.state.fields["isStatus"]});
        // this.setState({isStatusAvailable: String(this.state.fields["available_status"]) == "1"});
        // this.setState({statusAvailable: this.state.fields["available_status"]});


      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href=`/backoffice/order-online/timelogs/info/${this.state.fields['b_id']}`
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["product_max"]) {
      formIsValid = false;
      errors["product_max"] = "*กรุณากรอกจำนวนสินค้าที่รับได้";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    // formData.append("available_status", this.state.statusAvailable);
    formData.append("product_max", this.state.fields.product_max);
    formData.append("is_active", this.state.status);

    orderAction.updateTimelogs(formData, this.state.fields["date_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {

  const target = e.target;
  const name = target.name;

  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })

    if(this.state.isStatus == true){
      this.setState({ status: '2' })
    }else{
      this.setState({ status: '1' })
    }
  }

  if(name == "isStatusAvailable"){
    this.setState({ isStatusAvailable: !this.state.isStatusAvailable })

    if(this.state.isStatusAvailable == true){
      this.setState({ statusAvailable: '2' })
    }else{
      this.setState({ statusAvailable: '1' })
    }
  }
}

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขเวลาการรับสินค้า</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">

              <div className="form-group">
                <label className="control-label col-lg-2">สาขา</label>
                <div className="col-lg-4">
                  <input
                      name="b_name"
                      type="text"
                      className="form-control"
                      value={this.state.fields["b_name"] || ""}
                      readOnly
                    />
                  <div className="errorMsg">{this.state.errors.b_name}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">วันที่รับสินค้า</label>
                <div className="col-lg-2">
                  <input
                      name="date_name"
                      type="date"
                      className="form-control"
                      placeholder="กรอกวันที่รับสินค้า"
                      value={this.state.fields["date_name"] || ""}
                      onChange={this.handleChange}
                      readOnly
                    />
                  <div className="errorMsg">{this.state.errors.date_name}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">จำนวนสินค้าที่รับได้ (Max Product)</label>
                <div className="col-lg-2">
                  <input
                      name="product_max"
                      type="number"
                      className="form-control"
                      placeholder="กรอกจำนวนสินค้าที่รับได้"
                      value={this.state.fields["product_max"] || ""}
                      onChange={this.handleChange}
                    />
                  <div className="errorMsg">{this.state.errors.product_max}</div>
                </div>
              </div>

              {/*<div className="form-group">
                <label className="control-label col-lg-2">สถานะการรับออเดอร์</label>
                <div className="col-lg-5">
                  <label className="switch">
                    <input name="isStatusAvailable" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatusAvailable}/>
                    <div className="slider" id="available"><span className="on">ว่าง</span><span className="off">เต็ม</span></div>
                  </label>
                </div>
              </div>

              */}

              <div className="form-group">
                <label className="control-label col-lg-2">สถานะการใช้งาน</label>
                <div className="col-lg-5">
                  <label className="switch">
                    <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                    <div className="slider"></div>
                  </label>
                </div>
              </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
