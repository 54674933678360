import React from "react";
import { adminAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import "../level.css";

let modalAlert;

export class Level_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      fields: {},
      errors: {},
      levelList: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getDataByID(params.level_id);
  }

  getDataByID(level_id) {
    adminAction.getLevelById(level_id).then(e => {
      if (e.isSuccess === true) {
          this.setState({
          fields: {
            ...this.state.fields,
            level_name: e.data.level_name,
            is_active: e.data.is_active,
            level_id: level_id,

            menu_general : e.data.menu_general,
            menu_promotion : e.data.menu_promotion,
            menu_branch : e.data.menu_branch,
            menu_branch_queue : e.data.menu_branch_queue,
            menu_branch_detail : e.data.menu_branch_detail,
            menu_preorder : e.data.menu_preorder,
            menu_preorder_sub_order_eatin : e.data.menu_preorder_sub_order_eatin,
            menu_preorder_sub_order_takehome : e.data.menu_preorder_sub_order_takehome,
            menu_preorder_sub_product_preorder : e.data.menu_preorder_sub_product_preorder,
            menu_preorder_sub_product_by_branch : e.data.menu_preorder_sub_product_by_branch,
            menu_preorder_sub_product_preorder_category : e.data.menu_preorder_sub_product_preorder_category,
            menu_order_online : e.data.menu_order_online,
            menu_order_online_sub_order : e.data.menu_order_online_sub_order,
            menu_order_online_sub_receive_order : e.data.menu_order_online_sub_receive_order,
            menu_order_online_sub_product_by_branch : e.data.menu_order_online_sub_product_by_branch,
            menu_order_online_sub_product_online : e.data.menu_order_online_sub_product_online,
            menu_order_online_sub_product_online_category : e.data.menu_order_online_sub_product_online_category,
            menu_product_online_sub_product_online : e.data.menu_product_online_sub_product_online,
            menu_rewards : e.data.menu_rewards,
            menu_rewards_category : e.data.menu_rewards_category,
            menu_rewards_items : e.data.menu_rewards_items,
            menu_rewards_staff : e.data.menu_rewards_staff,
            menu_rewards_history : e.data.menu_rewards_history,
            menu_news : e.data.menu_news,
            menu_member : e.data.menu_member,
            menu_member_list : e.data.menu_member_list,
            menu_staffs : e.data.menu_staffs,
            menu_point_log : e.data.menu_point_log,
            menu_point_earn : e.data.menu_point_earn,
            menu_point_burn : e.data.menu_point_burn,
            menu_ewallet : e.data.menu_ewallet,
            menu_ewallet_account : e.data.menu_ewallet_account,
            menu_ewallet_transaction : e.data.menu_ewallet_transaction,
            menu_ewallet_report : e.data.menu_ewallet_report,
            menu_admin : e.data.menu_admin,
            menu_admin_list : e.data.menu_admin_list,
            menu_permission : e.data.menu_permission,
            menu_problem : e.data.menu_problem,
            menu_problem_title : e.data.menu_problem_title,
            menu_problem_list : e.data.menu_problem_list
          }
        });

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/level";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["level_name"]) {
      formIsValid = false;
      errors["level_name"] = "*กรุณากรอกชื่อสิทธิ์";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = {
      "level_name" : this.state.fields["level_name"],
      "is_active" : this.state.fields["is_active"],
      'menu_general' : this.state.fields["menu_general"] ? this.state.fields["menu_general"] : 0,
      'menu_promotion' : this.state.fields["menu_promotion"] ? this.state.fields["menu_promotion"] : 0,
      'menu_branch' : this.state.fields["menu_branch"] ? this.state.fields["menu_branch"] : 0,
      'menu_branch_queue' : this.state.fields["menu_branch_queue"] ? this.state.fields["menu_branch_queue"] : 0,
      'menu_branch_detail' : this.state.fields["menu_branch_detail"] ? this.state.fields["menu_branch_detail"] : 0,
      'menu_preorder' : this.state.fields["menu_preorder"] ? this.state.fields["menu_preorder"] : 0,
      'menu_preorder_sub_order_eatin' : this.state.fields["menu_preorder_sub_order_eatin"] ? this.state.fields["menu_preorder_sub_order_eatin"] : 0,
      'menu_preorder_sub_order_takehome' : this.state.fields["menu_preorder_sub_order_takehome"] ? this.state.fields["menu_preorder_sub_order_takehome"] : 0,
      'menu_preorder_sub_product_preorder' : this.state.fields["menu_preorder_sub_product_preorder"] ? this.state.fields["menu_preorder_sub_product_preorder"] : 0,
      'menu_preorder_sub_product_by_branch' : this.state.fields["menu_preorder_sub_product_by_branch"] ? this.state.fields["menu_preorder_sub_product_by_branch"] : 0,
      'menu_preorder_sub_product_preorder_category' : this.state.fields["menu_preorder_sub_product_preorder_category"] ? this.state.fields["menu_preorder_sub_product_preorder_category"] : 0,
      'menu_order_online' : this.state.fields["menu_order_online"] ? this.state.fields["menu_order_online"] : 0,
      'menu_order_online_sub_order' : this.state.fields["menu_order_online_sub_order"] ? this.state.fields["menu_order_online_sub_order"] : 0,
      'menu_order_online_sub_receive_order' : this.state.fields["menu_order_online_sub_receive_order"] ? this.state.fields["menu_order_online_sub_receive_order"] : 0,
      'menu_order_online_sub_product_by_branch' : this.state.fields["menu_order_online_sub_product_by_branch"] ? this.state.fields["menu_order_online_sub_product_by_branch"] : 0,
      'menu_order_online_sub_product_online' : this.state.fields["menu_order_online_sub_product_online"] ? this.state.fields["menu_order_online_sub_product_online"] : 0,
      'menu_order_online_sub_product_online_category' : this.state.fields["menu_order_online_sub_product_online_category"] ? this.state.fields["menu_order_online_sub_product_online_category"] : 0,
      'menu_product_online_sub_product_online' : this.state.fields["menu_product_online_sub_product_online"] ? this.state.fields["menu_product_online_sub_product_online"] : 0,
      'menu_rewards' : this.state.fields["menu_rewards"] ? this.state.fields["menu_rewards"] : 0,
      'menu_rewards_category' : this.state.fields["menu_rewards_category"] ? this.state.fields["menu_rewards_category"] : 0,
      'menu_rewards_items' : this.state.fields["menu_rewards_items"] ? this.state.fields["menu_rewards_items"] : 0,
      'menu_rewards_staff' : this.state.fields["menu_rewards_staff"] ? this.state.fields["menu_rewards_staff"] : 0,
      'menu_rewards_history' : this.state.fields["menu_rewards_history"] ? this.state.fields["menu_rewards_history"] : 0,
      'menu_news' : this.state.fields["menu_news"] ? this.state.fields["menu_news"] : 0,
      'menu_member' : this.state.fields["menu_member"] ? this.state.fields["menu_member"] : 0,
      'menu_member_list' : this.state.fields["menu_member_list"] ? this.state.fields["menu_member_list"] : 0,
      'menu_staffs' : this.state.fields["menu_staffs"] ? this.state.fields["menu_staffs"] : 0,
      'menu_point_log' : this.state.fields["menu_point_log"] ? this.state.fields["menu_point_log"] : 0,
      'menu_point_earn' : this.state.fields["menu_point_earn"] ? this.state.fields["menu_point_earn"] : 0,
      'menu_point_burn' : this.state.fields["menu_point_burn"] ? this.state.fields["menu_point_burn"] : 0,
      'menu_ewallet' : this.state.fields["menu_ewallet"] ? this.state.fields["menu_ewallet"] : 0,
      'menu_ewallet_account' : this.state.fields["menu_ewallet_account"] ? this.state.fields["menu_ewallet_account"] : 0,
      'menu_ewallet_transaction' : this.state.fields["menu_ewallet_transaction"] ? this.state.fields["menu_ewallet_transaction"] : 0,
      'menu_ewallet_report' : this.state.fields["menu_ewallet_report"] ? this.state.fields["menu_ewallet_report"] : 0,
      'menu_admin' : this.state.fields["menu_admin"] ? this.state.fields["menu_admin"] : 0,
      'menu_admin_list' : this.state.fields["menu_admin_list"] ? this.state.fields["menu_admin_list"] : 0,
      'menu_permission' : this.state.fields["menu_permission"] ? this.state.fields["menu_permission"] : 0,
      'menu_problem' : this.state.fields["menu_problem"] ? this.state.fields["menu_problem"] : 0,
      'menu_problem_title' : this.state.fields["menu_problem_title"] ? this.state.fields["menu_problem_title"] : 0,
      'menu_problem_list' : this.state.fields["menu_problem_list"] ? this.state.fields["menu_problem_list"] : 0,

    }

    adminAction.updateLevel(formData, this.state.fields["level_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }


  onCancel(ev) {
    this.setState({ modal: null });
  }



  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "level_name") {
      fields[e.target.name] = e.target.value;
    } else {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  render() {
    console.log(this.state.fields);
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขข้อมูลสิทธิ์ผู้ดูแลระบบ</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-3">ชื่อสิทธิ์ผู้ดูแลระบบ</label>
                  <div className="col-lg-9">
                  <input
                      name="level_name"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสิทธิ์ผู้ดูแลระบบ"
                      onChange={this.handleChange}
                      value={this.state.fields.level_name}
                    />
                    <div className="errorMsg">{this.state.errors.level_name}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">สถานะเปิดใช้งานสิทธิ์</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="is_active"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["is_active"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">จัดการข้อมูลทั่วไป</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_general"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_general"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">จัดการข่าวโปรโมชั่นหน้าแรก</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_promotion"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_promotion"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">รายการสาขาทั้งหมด</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_branch"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_branch"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการคิว (Queues)</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_branch_queue"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_branch_queue"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>ข้อมูลสาขา</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_branch_detail"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_branch_detail"] === 1) ? true : false}

                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">จัดการรายการสั่งซื้อหน้าร้าน</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_preorder"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_preorder"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการสั่งซื้อหน้าร้าน (Eatin)</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_preorder_sub_order_eatin"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_preorder_sub_order_eatin"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการสั่งซื้อหน้าร้าน (Takehome)</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_preorder_sub_order_takehome"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_preorder_sub_order_takehome"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการสินค้าพรีออเดอร์</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_preorder_sub_product_preorder"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_preorder_sub_product_preorder"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการสินค้าพรีออเดอร์แต่ละสาขา</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_preorder_sub_product_by_branch"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_preorder_sub_product_by_branch"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการหมวดหมู่สินค้าพรีออเดอร์</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_preorder_sub_product_preorder_category"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_preorder_sub_product_preorder_category"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>


                <div className="form-group">
                  <label className="control-label col-lg-3">จัดการรายการสั่งซื้อออนไลน์</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_order_online"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_order_online"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการสั่งซื้อออนไลน์</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_order_online_sub_order"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_order_online_sub_order"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการรับสินค้าออนไลน์</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_order_online_sub_receive_order"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_order_online_sub_receive_order"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการสินค้าออนไลน์</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_product_online_sub_product_online"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_product_online_sub_product_online"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการสินค้าออนไลน์แต่ละสาขา</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_order_online_sub_product_by_branch"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_order_online_sub_product_by_branch"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการหมวดหมู่สินค้าออนไลน์</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_order_online_sub_product_online_category"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_order_online_sub_product_online_category"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">รายการรีวอร์ด</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_rewards"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_rewards"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>หมวดหมู่รีวอร์ด</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_rewards_category"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_rewards_category"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการรีวอร์ดทั้งหมด</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_rewards_items"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_rewards_items"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>จัดการรายการรีวอร์ดสำหรับพนักงาน</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_rewards_staff"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_rewards_staff"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>ประวัติการแลกรีวอร์ด</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_rewards_history"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_rewards_history"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">แจ้งเตือนข่าวสารทั่วไป</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_news"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_news"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">สมาชิก</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_member"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_member"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายชื่อข้อมูลสมาชิก</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_member_list"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_member_list"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายชื่อข้อมูลพนักงาน</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_staffs"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_staffs"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">จัดการคะแนน</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_point_log"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_point_log"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>ประวัติการรับคะแนน</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_point_earn"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_point_earn"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>ประวัติการแลกคะแนน</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_point_burn"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_point_burn"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">E-Wallet</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_ewallet"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_ewallet"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>บัญชี</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_ewallet_account"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_ewallet_account"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>Transaction</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_ewallet_transaction"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_ewallet_transaction"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>Report</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_ewallet_report"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_ewallet_report"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">ผู้ดูแลระบบ</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_admin"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_admin"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>ผู้ดูแลระบบ</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_admin_list"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_admin_list"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>สิทธิ์ผู้ดูแลระบบ</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_permission"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_permission"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">รายการแจ้งปัญหาการใช้งานระบบ</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_problem"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_problem"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการหัวข้อแจ้งปัญหาการใช้งาน</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_problem_title"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_problem_title"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group ml-4">
                  <label className="control-label col-lg-3"><li>รายการแจ้งปัญหาการใช้งาน</li></label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="menu_problem_list"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["menu_problem_list"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดต"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
