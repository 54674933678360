import React from "react";
import { newsAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
let modalAlert;

export class BannerPromotion_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      errors: {},
      fields: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/banner_promotion/list"
  }

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["banner_promotion_image"]) {
      formIsValid = false;
      errors["banner_promotion_image"] = "*กรุณาเลือกรูปภาพ";
    }

    if (!fields["banner_promotion_sort"]) {
      formIsValid = false;
      errors["banner_promotion_sort"] = "*กรุณากรอกลำดับ";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }


  validateData() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    
    var formData = new FormData();
    formData.append("banner_promotion_image", this.state.fields["banner_promotion_image"]);
    formData.append("banner_promotion_sort", this.state.fields["banner_promotion_sort"]);
    formData.append("is_active", this.state.fields["is_active"]);

    newsAction.insertBannerPromotion(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active") {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });

  };

  render() {    
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มแบนเนอร์โปรโมชัน</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-3">รูปภาพแบนเนอร์โปรโมชัน</label>
                  <div className="col-lg-9">
                    <input
                      type="file"
                      className="file-input"
                      name="banner_promotion_image"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">{this.state.errors.banner_promotion_image}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">ลำดับ</label>
                  <div className="col-lg-2">
                  <input
                      name="banner_promotion_sort"
                      type="number"
                      className="form-control"
                      placeholder="กรอกลำดับ"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.banner_promotion_sort}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">สถานะใช้งาน</label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="is_active"
                        type="checkbox"
                        onChange={this.handleChange}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
