import React from "react";
import { staffAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import moment from "moment-timezone"
import "../staff.css";

let modalAlert;
let checkbox_reward_type_receive = []
var checkbox_rewards_showtype = []


export class Ewallet_Staff_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      inputBody: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmEdit = this.onConfirmEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    var params = this.props.match.params
    this.setState({ staff_ewallet_id: params.staff_ewallet_id })
    this.getEwalletStaffByID(params.staff_ewallet_id)
  }

  getEwalletStaffByID(id) {
    var { 
      inputBody 
    } = this.state;
    staffAction.getEwalletStaff(id).then(e => {
      var resp = e.data
        inputBody = resp  
        this.setState({ 
          inputBody: inputBody
        })
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmEdit()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = `/backoffice/staff`;
  }

  validateForm() {
    let inputBody = this.state.inputBody;
    let errors = {};
    let formIsValid = true;

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmEdit(ev) {
    this.setState({ modal: null });
    let data = {
      staff_ewallet_amount: this.state.inputBody["staff_ewallet_amount"]
    }

    staffAction.updateEwalletStaff(this.state.inputBody['staff_ewallet_id'], data).then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { inputBody, errors } = this.state;
    inputBody[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ inputBody, errors });
  };

  render() {    
    return (
      <div className="reward-container">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขจำนวน e-wallet สำหรับพนักงาน <span className="text-danger">( ระบบจะเติม ewallet เมื่อถึงวันเกิดพนักงาน )</span></h4> 
          </div>
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="row mb-4">
                  <div className="col-lg-3 col-md-3">
                    ชื่อพนักงาน
                  </div>
                  <div className="col-lg-9 col-md-9">
                    ({this.state.inputBody["staff_number"]}) {this.state.inputBody["staff_firstname"]} {this.state.inputBody["staff_lastname"]}
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-3 col-md-3">จำนวน e-wallet ที่ต้องการเติม</label>
                  <div className="col-lg-9 col-md-3">
                    <input
                      name="staff_ewallet_amount"
                      type="number"
                      className="form-control"
                      placeholder="จำนวน e-wallet ที่ต้องการเติม"
                      onChange={this.handleChange}
                      value={this.state.inputBody["staff_ewallet_amount"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["staff_ewallet_amount"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
