import React from "react";
import $ from "jquery";
import { rewardAction,pointAction, BackofficeAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment-timezone";
import "../../point.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

$.DataTable = require("datatables.net");
let modalAlert;

export class Point_Burn_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.buildParams = this.buildParams.bind(this);

    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false,
      startDate: '',
      endDate: '',
      dateStartSearch: '',
      dateEndSearch: ''
    };
    this.table = null;
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    const columns = [
      {
        title: "วันที่ทำรายการ",
        class: "text-center",
        data: "created_at",
        className: "text-center",
      },
      {
        title: "รหัสสมาชิก",
        data: "mem_number",
        className: "text-center",
      },
      {
        title: "ชื่อสมาชิก",
        data: "mem_firstname",
        className: "text-center",
      },
      {
        title: "เบอร์โทร",
        data: "mem_phone",
        className: "text-center",
      },
      {
        title: "รหัสรีวอร์ด",
        data: "rewards_code",
        className: "text-center",
      },
      {
        title: "ชื่อรีวอร์ด",
        data: "rewards_name_th",
        className: "text-center",
      },
      {
        title: "รายละเอียด",
        data: "burn_description",
        className: "text-center",
      },
      {
        title: "คะแนน (P)",
        data: "p_point",
        className: "text-center",
      },
      {
        title: "จัดการ",
        data: "p_id",
        className: "text-center",
      }
    ];
    this.table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`${moment(cellData, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}`);
          },
          orderable: true
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`${rowData.mem_firstname} ${rowData.mem_lastname}`);
          },
          orderable: false
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div>${!!cellData ? cellData : '-'}</div>`);
          },
          orderable: false
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div>${!!cellData ? cellData : '-'}</div>`);
          },
          orderable: false
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div>${!!cellData ? cellData : '-'}</div>`);
          },
          orderable: false
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-danger"> - ${cellData}</div>`);
          },
          orderable: false
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            var btn_manage = ""
              btn_manage = 
              `<div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i> ลบ </button>
                  </div>
                </div>
              </div>`
            $(td).html(btn_manage);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.point}/api/point/v2/listBurn?start_date=${this.state.startDate}&end_date=${this.state.endDate}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var Id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => this.onConfirmDelete(Id)}
          onCancel={() => this.onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

  }

  onConfirmDelete(Id) {
    pointAction.deleteManagePoint(Id).then(e => {
      if (e.isSuccess === false) {
        this.onModalError("Error", e.message);
      } else {
        this.onModalSuccess("Success", e.message);
        this.table.ajax.reload();
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.state.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.state.setState({ show: true, modal: modalAlert() });
  }

  onConfirm(ev) {
    this.state.setState({ modal: null });
  }

  componentDidUpdate(prevProps, prevState) {
    let {dateStartSearch, dateEndSearch} = this.state;
    if (prevState.dateStartSearch !== dateStartSearch || prevState.dateEndSearch !== dateEndSearch) {
      if(!!dateStartSearch && !!dateEndSearch){
        this.table.ajax.url(this.getDataTableUrl()).load(); 
      }
    }
  }

  handleChangeDate(date, type) {
    let value = moment(date).format('YYYY-MM-DD');
    console.log('select change : ', value);
    if(type === 'start'){
      this.setState({
        startDate: date,
        dateStartSearch: value
      })
    }
    if(type === 'end'){
      this.setState({
        endDate: date,
        dateEndSearch: value
      })
    }
  }

  // Helper Methods
  getDataTableUrl() {
    const { dateStartSearch, dateEndSearch } = this.state;
    return `${Base_API.point}/api/point/v2/listBurn?start_date=${encodeURIComponent(dateStartSearch)}&end_date=${encodeURIComponent(dateEndSearch)}`;
  }

  async handleExport() {
    const { dateStartSearch, dateEndSearch } = this.state;
    let buildParams = this.buildParams(this.table.ajax.params());
    let params = `?start_date=${encodeURIComponent(dateStartSearch)}&end_date=${encodeURIComponent(dateEndSearch)}&${buildParams}`
    BackofficeAction.exportExcelPointHistory(params)
    .then(res => {
      if (res.status) {
        let downloadUrl = res.data;
        downloadFileByURL(downloadUrl);
      } else {
        this.onModalError('ไม่สำเร็จ', res.msg)
      }
    })
    function downloadFileByURL(downloadUrl){
      // Create a temporary <a> element to trigger download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'PointHistory.xlsx'; // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  buildParams(obj, prefix) {
    let str = [];
    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        const key = prefix ? `${prefix}[${p}]` : p;
        const value = obj[p];
        str.push(
          value !== null && typeof value === "object"
            ? this.buildParams(value, key)
            : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
    return str.join("&");
  }

  render() {
    let {startDate, endDate} = this.state;
    return (
      <div className="point-history-wrap">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <div className="header-wrap">
              <h4 className="panel-title">ประวัติการแลกแต้ม</h4>
              {/* right */}
              <div className="right">
                <div className="date-wrap">
                  <label htmlFor="startDate">Date Range: </label>
                  <DatePicker id="startDate" className="form-control" onChange={(date) => this.handleChangeDate(date,'start')} 
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="start date"
                  /> 
                    -
                  <DatePicker className="form-control" onChange={(date) => this.handleChangeDate(date,'end')} 
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="end date"
                  /> 
                </div>
                <button className="btn export-btn" onClick={this.handleExport}>Export</button>
              </div>
            </div>
            <div className="heading-elements">
              {/* <a href="/reward/earn/craete">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-success"
                >
                  <i class="fa fa-plus" /> เพิ่มรายการ
                </button>
              </a> */}
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
