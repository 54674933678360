import React from "react";
import { productAction } from "../../../../_actions";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import SweetAlert from "react-bootstrap-sweetalert";
import "../product.css";
import Select from 'react-select';
import ReactModal from 'react-modal';

let modalAlert;
const customStyles = {
  content : {
    top: '40px',
    left: '20%',
    width: '60%',
    height: '68%',
    padding: '0px'
  }
};

export class Product_Online_Branch_Edit_By_Code extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {
        b_code: ""
      },
      errors: {},
      showModal: false,
      status: 0,
      status_ems: "",
      status_gift: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmUpdate = this.onConfirmUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
  
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmUpdate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = `/backoffice/order-online/product/branch`;
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["pr_online_code"]) {
      formIsValid = false;
      errors["pr_online_code"] = "*กรุณากรอกรหัสสินค้า";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmUpdate(ev) {
    this.setState({ modal: null });

    let inputBody = {
      pr_online_code: this.state.fields["pr_online_code"],
      b_code: this.state.fields["b_code"],
      b_id: (this.state["status_ems"] || this.state["status_gift"] ? 0 : ""),
      is_active: this.state["status"],
      is_ems: this.state["status_ems"] ? 1 : 0,
      is_gift: this.state["status_gift"] ? 1 : 0
    }
    console.log('inputBody: ', inputBody);
    productAction.updateProductByBranchCode(inputBody)
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleOpenModal (e) {
    this.setState({ showModal: true, });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  handleInputChange = e => {

    const target = e.target;
    const name = target.name;
  
    if(name == "isStatus"){
      this.setState({ isStatus: !this.state.isStatus })
      if(this.state.isStatus == true){
        this.setState({ status: 0 })
      }else{
        this.setState({ status: 1 })
      }
    }
  
    if(name == "isEMS"){
      this.setState({ isEMS: !this.state.isEMS })
      if(this.state.isEMS == true){
        this.setState({ status_ems: 0 })
      }else{
        this.setState({ status_ems: 1 })
      }
    }
    if(name == "isGift"){
      this.setState({ isGift: !this.state.isGift })
      if(this.state.isGift == true){
        this.setState({ status_gift: 0 })
      }else{
        this.setState({ status_gift: 1 })
      }
    }
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">จัดการสินค้าแต่ละสาขาตามรหัสสินค้า</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสินค้า <div className="text-gray">เช่น "MP"</div></label>
                  <div className="col-lg-3">
                  <input
                      name="pr_online_code"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_code}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสาขา <div className="text-gray">(สามารถใส่ได้มากกว่า 1 สาขา) เช่น "AEP,ABH,ABK"</div></label>
                  <div className="col-lg-5">
                    <input
                        name="b_code"
                        type="text"
                        className="form-control"
                        placeholder="กรอกรหัสสาขา"
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.b_code}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">กรณีเป็น Gift</label>
                  <div className="col-lg-3">
                    <label className="switch">
                      <input name="isGift" type="checkbox" onChange={this.handleInputChange} checked={this.state.isGift}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                  <label className="control-label col-lg-2">กรณีเป็น EMS</label>
                  <div className="col-lg-3">
                    <label className="switch">
                      <input name="isEMS" type="checkbox" onChange={this.handleInputChange} checked={this.state.isEMS}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                
                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ (ปิด/เปิด)</label>
                  <div className="col-lg-3">
                    <label className="switch">
                      <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
