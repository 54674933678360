import React from "react";
import { activityAction, productAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "./activity.css";
import Select from 'react-select';
import { promotionAction } from "../../../../_actions/promotion.action";
import moment from "moment-timezone";

let modalAlert;

export class Create_Promotion_Payment extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: '',
      fields: {
        is_active: 0,
      },
      errors: {},
      promoCate: [],
      discountCate: [{label: 'เปอร์เซ็น', value: 'percentage'}, {label: 'บาท', value: 'baht'}],
      typeOrderCate: [{label: 'delivery', value: '1'}, {label: 'ems', value: '2'}, {label: 'pickup', value: '3'}, {label: 'snackbox', value: '4'}, {label: 'gift', value: '5'}],
      paymentChannel: [{label: 'E-Wallet', value: '1'}, {label: 'Credit/Debit Card', value: '2'}, {label: 'Cash On Delivery', value: '4'}],
      freeEarnCate: [],
      rewardsOption: []
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    promotionAction.listPaymentPromoCategory().then(res => {
      let options = res.data.map(function (item) {
        return { value: item.promo_cate_code, label: item.promo_cate_name };
      })
      this.setState({
        promoCate: options
      })
    })
    promotionAction.listPaymentPromoFreeEarnCategory().then(res => {
      let options = res.data.map(function (item) {
        return { value: item.free_earn_cate_id, label: item.free_earn_name_en };
      })
      this.setState({
        freeEarnCate: options
      })
    })
    promotionAction.getActiveRewardsDropdown().then(res => {
      let options = res.data.map(function (item) {
        return { value: item.rewards_code, label: item.rewards_name_th };
      })
      this.setState({
        rewardsOption: options
      })
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/promotion/payment"
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["promo_list_code"]) {
      formIsValid = false;
      errors["promo_list_code"] = "*กรุณากรอกรหัสโปรโมชั่น";
    }
    if (!fields["promo_cate_code"]) {
      formIsValid = false;
      errors["promo_cate_code"] = "*กรุณาระบุเงื่อนไขโปรโมชั่น";
    }
    if (!fields["start_date"]) {
      formIsValid = false;
      errors["start_date"] = "*กรุณาระบุวันเริ่มต้นโปรโมชั่น";
    }
    if (!fields["end_date"]) {
      formIsValid = false;
      errors["end_date"] = "*กรุณาระบุวันสิ้นสุดโปรโมชั่น";
    }
    if (fields["promo_cate_code"] === 'PPMC001') { // สั่งออเดอร์ X ชำระเงินผ่านช่องทาง X รับฟรี Y
      if (!fields["typeorder_id"]) {
        formIsValid = false;
        errors["typeorder_id"] = "*กรุณาระบุประเภทออเดอร์";
      }
      if (!fields["payment_channel_id"]) {
        formIsValid = false;
        errors["payment_channel_id"] = "*กรุณาระบุช่องทางการชำระเงิน";
      }
      if (!fields["free_earn_cate_id"]) {
        formIsValid = false;
        errors["free_earn_cate_id"] = "*กรุณาระบุประเภทของแถม";
      }else
      {
        if(fields["free_earn_cate_id"] === 1)  
        {
          if(!fields["earn_point"])
          {
            formIsValid = false;
            errors["earn_point"] = "*กรุณากรอกจำนวนพอยต์";  
          }
        }
        if(fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4)
        {
          if(!fields["rewards_code"])
          {
            formIsValid = false;
            errors["rewards_code"] = "*กรุณารีวอร์ด";  
          }
        }
      }
      if (!fields["purchase_price"]) {
        formIsValid = false;
        errors["purchase_price"] = "*กรุณากรอกราคาซื้อขั้นต่ำ";
      }
    }
    if (fields["promo_cate_code"] === 'PPMC002') { // Topup X บาทแถมฟรี Y
      if (!fields["free_earn_cate_id"]) {
        formIsValid = false;
        errors["free_earn_cate_id"] = "*กรุณาระบุประเภทของแถม";
      }else
      {
        if(fields["free_earn_cate_id"] === 1)  
        {
          if(!fields["earn_point"])
          {
            formIsValid = false;
            errors["earn_point"] = "*กรุณากรอกจำนวนพอยต์";  
          }
        }
        if(fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4)
        {
          if(!fields["rewards_code"])
          {
            formIsValid = false;
            errors["rewards_code"] = "*กรุณารีวอร์ด";  
          }
        }
      }
      if (!fields["purchase_price"]) {
        formIsValid = false;
        errors["purchase_price"] = "*กรุณากรอกราคาซื้อขั้นต่ำ";
      }
    }
    
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    let reqPayload = this.getReqPayload();
    promotionAction.createPaymentPromotion(reqPayload).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  getReqPayload = () => {
    let {fields} = this.state;
    let resData;
    if(fields["promo_cate_code"] === "PPMC001") // สั่งออเดอร์ X ชำระเงินผ่านช่องทาง X รับฟรี Y
    {
      resData = {
        "is_active": fields["is_active"],
        "promo_list_code": fields["promo_list_code"],
        "promo_cate_code": fields["promo_cate_code"],
        "typeorder_id": String(fields["typeorder_id"].map(item => item.value)),
        "payment_channel_id": String(fields["payment_channel_id"].map(item => item.value)),
        "free_earn_cate_id": fields["free_earn_cate_id"],
        "earn_point": fields["free_earn_cate_id"] === 1 ? parseInt(fields["earn_point"]) : 0,
        "rewards_code": fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4 ? fields["rewards_code"] : '',
        "purchase_price": parseFloat(fields["purchase_price"]).toFixed(2),
        "start_date": moment(fields["start_date"]).format('Y-MM-DD HH:mm:ss'),
        "end_date": moment(fields["end_date"]).format('Y-MM-DD HH:mm:ss'),
      }
    }
    if(fields["promo_cate_code"] === "PPMC002") // Topup X บาทแถมฟรี Y
    {
      resData = {
        "is_active": fields["is_active"],
        "promo_list_code": fields["promo_list_code"],
        "promo_cate_code": fields["promo_cate_code"],
        "free_earn_cate_id": fields["free_earn_cate_id"],
        "earn_point": fields["free_earn_cate_id"] === 1 ? parseInt(fields["earn_point"]) : 0,
        "rewards_code": fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4 ? fields["rewards_code"] : '',
        "purchase_price": parseFloat(fields["purchase_price"]).toFixed(2),
        "start_date": moment(fields["start_date"]).format('Y-MM-DD HH:mm:ss'),
        "end_date": moment(fields["end_date"]).format('Y-MM-DD HH:mm:ss'),
      }
    }
    return resData;
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    if(name === 'promo_list_code')
    {
      fields[name] = String(value).toUpperCase();
      this.state.errors[name] = null;
      this.setState({ fields, errors });
    }else
    {
      fields[name] = value;
      this.state.errors[name] = null;
      this.setState({ fields, errors });
    }
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  handleMultiSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    fields[action.name] = e;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })
  }

  render() {
    let {promoCate, fields, typeOrderCate, paymentChannel, freeEarnCate, rewardsOption} = this.state;

    return (
      <div className="promotion-create-con">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มโปรโมชั่นการชำระเงิน</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="form-group">
                <label className="control-label col-md-2">รหัสโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="promo_list_code"
                    type="text"
                    className="form-control"
                    placeholder="รหัสโปรโมชั่น"
                    value={this.state.fields.promo_list_code || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.promo_list_code}</div>
                </div>
                
                <label className="control-label col-md-2">เงื่อนไขโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <Select
                    name="promo_cate_code"
                    placeholder="เงื่อนไขโปรโมชั่น"
                    onChange={this.handleSelectChange}
                    options={promoCate}
                  />
                  <div className="errorMsg">{this.state.errors.promo_cate_code}</div>
                </div>
              </div>

              {fields["promo_cate_code"] === 'PPMC001' && 
                <div className="form-group">
                  <label className="control-label col-md-2">ประเภทออเดอร์<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      isMulti
                      name="typeorder_id"
                      placeholder="ประเภทออเดอร์"
                      onChange={this.handleMultiSelectChange}
                      options={typeOrderCate}
                    />
                    <div className="errorMsg">{this.state.errors.typeorder_id}</div>
                  </div>
                  <label className="control-label col-md-2">ช่องทางการชำระเงิน<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      isMulti
                      name="payment_channel_id"
                      placeholder="ช่องทางการชำระเงิน"
                      onChange={this.handleMultiSelectChange}
                      options={paymentChannel}
                    />
                    <div className="errorMsg">{this.state.errors.payment_channel_id}</div>
                  </div>
                </div>
              }

              {fields["promo_cate_code"] === 'PPMC002' && 
                <div className="form-group">
                  <label className="control-label col-md-2">Topup ขั้นต่ำ<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <input
                      name="purchase_price"
                      type="number"
                      className="form-control"
                      placeholder="Topup ขั้นต่ำ"
                      value={this.state.fields.purchase_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.purchase_price}</div>
                  </div>
                </div>
              }

              {fields["promo_cate_code"] === 'PPMC001' || fields["promo_cate_code"] === 'PPMC002' ?
                <div className="form-group">
                  <label className="control-label col-md-2">ประเภทของแถม<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      name="free_earn_cate_id"
                      placeholder="ประเภทของแถม"
                      onChange={this.handleSelectChange}
                      options={freeEarnCate}
                    />
                    <div className="errorMsg">{this.state.errors.free_earn_cate_id}</div>
                  </div>
                  {/* Custom Point */}
                  {fields["free_earn_cate_id"] === 1 &&
                    <>
                    <label className="control-label col-md-2">คะแนน(กำหนดเอง)<span className="remark-red">*</span></label>
                    <div className="col-md-3">
                      <input
                        name="earn_point"
                        type="number"
                        className="form-control"
                        placeholder="คะแนน(กำหนดเอง)"
                        value={this.state.fields.earn_point || ""}
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.earn_point}</div>
                    </div>
                    </>
                  }
                  {/* Rewards */}
                  {fields["free_earn_cate_id"] === 3 || fields["free_earn_cate_id"] === 4 &&
                    <>
                    <label className="control-label col-md-2">รีวอร์ด<span className="remark-red">*</span></label>
                    <div className="col-md-3">
                      <Select
                        name="rewards_code"
                        placeholder="รีวอร์ด"
                        onChange={this.handleSelectChange}
                        options={rewardsOption}
                      />
                      <div className="errorMsg">{this.state.errors.rewards_code}</div>
                    </div>
                    </>
                  }
                </div>
                : ''
              }

              {fields["promo_cate_code"] === 'PPMC001' && 
                <div className="form-group">
                  <label className="control-label col-md-2">ราคาซื้อขั้นต่ำ<span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <input
                      name="purchase_price"
                      type="number"
                      className="form-control"
                      placeholder="ราคาซื้อขั้นต่ำ"
                      value={this.state.fields.purchase_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.purchase_price}</div>
                  </div>
                </div>
              }

              {/* {fields["promo_cate_code"] === 'PMCT001'
                &&
                <div className="form-group">
                  <label className="control-label col-md-2">หมวดหมู่สินค้า <span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      name="pr_online_category_id"
                      placeholder="หมวดหมู่สินค้า"
                      onChange={this.handleSelectChange}
                      options={productCate}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_category_id}</div>
                  </div>
                </div>
              }
              {fields["promo_cate_code"] === 'PMCT001' &&
                <div className="form-group">
                  <label className="control-label col-md-2">จำนวนสินค้าที่ต้องซื้อ <span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <input
                      name="quantity"
                      type="number"
                      className="form-control"
                      placeholder="จำนวนสินค้าที่ต้องซื้อ"
                      value={this.state.fields.quantity || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.quantity}</div>
                  </div>

                  <label className="control-label col-md-2">ราคาทั้งเซ็ต <span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <input
                      name="discount_price"
                      type="number"
                      className="form-control"
                      placeholder="ราคาทั้งเซ็ต"
                      value={this.state.fields.discount_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.discount_price}</div>
                  </div>
                </div>
              }

              {fields["promo_cate_code"] === 'PMCT002' && 
                <div className="form-group">
                  <label className="control-label col-md-2">ราคาซื้อ <span className="remark-red">(ที่ต้องซื้อ)</span></label>
                  <div className="col-md-3">
                    <input
                      name="purchase_price"
                      type="number"
                      className="form-control"
                      placeholder="ราคาซื้อ"
                      value={this.state.fields.purchase_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.purchase_price}</div>
                  </div>
                </div>
              }
              {fields["promo_cate_code"] === 'PMCT002' && 
                <div className="form-group">
                  <label className="control-label col-md-2">ลด(%,บาท) <span className="remark-red">*</span></label>
                  <div className="col-md-3">
                    <Select
                      name="discount_type"
                      placeholder="รูปแบบการลด"
                      onChange={this.handleSelectChange}
                      options={discountCate}
                    />
                    <div className="errorMsg">{this.state.errors.discount_type}</div>
                  </div>
                  <div className="col-md-3">
                    <span className="disp-input-wrap">
                      <input
                        name="discount_price"
                        type="number"
                        className="form-control"
                        placeholder="ลด"
                        value={this.state.fields.discount_price || ""}
                        onChange={this.handleChange}
                      />
                      <span>{fields["discount_type"] === 'percentage' ? '%' : fields["discount_type"] === 'baht' ? 'บาท' : ''}</span>
                    </span>
                    <div className="errorMsg">{this.state.errors.discount_price}</div>
                  </div>
                </div>
              } */}

              <div className="form-group">
                <label className="control-label col-md-2">วันที่เริ่มโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="start_date"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่เริ่มโปรโมชั่น"
                    value={this.state.fields.start_date || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.start_date}</div>
                </div>

                <label className="control-label col-md-2">วันที่สิ้นสุดโปรโมชั่น <span className="remark-red">*</span></label>
                <div className="col-md-3">
                  <input
                    name="end_date"
                    type="datetime-local"
                    className="form-control"
                    placeholder="วันที่สิ้นสุดโปรโมชั่น"
                    value={this.state.fields.end_date || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.end_date}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">การใช้งาน</label>
                <div className="col-md-3">
                  <label className="switch">
                    <input name="is_active" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_active}/>
                    <div className="slider"></div>
                  </label>
                </div>
              </div>
  
              <div className="form-group">
                <div className="col-lg-12 text-right">
                  <input
                    type="submit"
                    className="btn btn-success btn-md btn-banner-create"
                    value="บันทึก"
                  />
                </div>
              </div>
            </form>  
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
