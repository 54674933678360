import React from "react";
import $ from "jquery";
import { adminAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../level.css";

$.DataTable = require("datatables.net");
let modalAlert;

export class Level_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "updated_at"
      },
      {
        title: "ชื่อสิทธิ์",
        class: "text-center",
        data: "level_name"
      },
      {
        title: "สถานะ",
        class: "text-center",
        data: "is_active"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "level_id"
      },
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          },
          orderable: false
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="isStatus" class="activeBtn" data-id="${rowData.level_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: true
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`

              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/level/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                  </div>
                </div>
              </div>
            `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.admin}/level`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var id = $(this).data("id");
      var val = $(this).is(":checked") === true ? 1 : 2;

      state.setState({
        inputBody : {
          is_active: val,
        }
      });

      adminAction.activateLevel(state.state.inputBody, id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    // $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
    //   var mcid = $(this).data("content");
    //   modalAlert = () => (
    //     <SweetAlert
    //       style={{ display: "block" }}
    //       info
    //       showCancel
    //       confirmBtnBsStyle="success"
    //       cancelBtnBsStyle="default"
    //       title="Delete"
    //       onConfirm={() => onConfirmDelete(mcid)}
    //       onCancel={() => onConfirm()}
    //     >
    //       คุณต้องการลบข้อมูลนี้หรือไม่ ?
    //     </SweetAlert>
    //   );
    //
    //   state.setState({ show: true, modal: modalAlert() });
    // });

    // function onConfirmDelete(cid) {
    //   adminAction.deleteEatinCategory(cid).then(e => {
    //     if (e.isSuccess === false) {
    //       onModalError("Error", e.message);
    //     } else {
    //       onModalSuccess("Success", e.message);
    //       table.ajax.reload();
    //     }
    //   });
    // }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">สิทธิ์ผู้ดูแลระบบ</h4>
            <div className="heading-elements">
              <a href="/backoffice/level/create">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add"
                >
                  เพิ่มสิทธิ์ผู้ดูแลระบบ
                </button>
              </a>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
