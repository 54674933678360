import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const activitiesAction = {
  listEventsTypeActivities,
};

function listEventsTypeActivities() {
  return axios.get(`${Base_API.backoffice}/api/activity_log/events`).then(res => {
    return res.data;
  });
} 