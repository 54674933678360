import React from "react";
import $ from "jquery";
import { productAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../product.css";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-preorder-product'

export class Product_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    productAction.getEatinProductList()
    .then(res => {
        this.setState({
            dataProduct: res.data
        })
    })

    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "pr_id"
      },
      {
        title: "รูปภาพ",
        class: "text-center",
        data: "pr_image"
      },
      {
        title: "รหัสสินค้า",
        class: "text-center",
        data: "pr_code"
      },
      {
        title: "ชื่อสินค้า (TH)",
        class: "text-center",
        data: "pr_name"
      },
      {
        title: "ราคาตั้งต้น",
        class: "text-center",
        data: "pr_price"
      },
      {
        title: "หมวดหมู่",
        class: "text-center",
        data: "pr_category_name"
      },
      {
        title: "ลำดับที่แสดง",
        class: "text-center",
        data: "pr_sort"
      },
      {
        title: "สถานะ",
        class: "text-center",
        data: "pr_status"
      },
      {
        title: "วันที่แก้ไข",
        class: "text-center",
        data: "updated_at"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "pr_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          },
          orderable: false
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<img class='product_thumbnail' src='${cellData}' alt='${rowData.pr_name}' />`);
          },
          orderable: false
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        // {
        //   targets: 5,
        //   createdCell: function(td, cellData, rowData, row, col) {
        //     $(td)
        //     .html(`<div class="text-right">${cellData} ฿</div>`);
        //   }
        // },
        

        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="isStatus" class="activeBtn" data-id="${rowData.pr_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: false
        },
        {
          targets: 9,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/preorder/product/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3"></i> แก้ไข</a>
                    </div>
                  </div>
                </div>
                `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.product}/product`,
        type: "GET",
        // dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[7, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("pr_status", fval);

      productAction.activateProductEatin(formData, fid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var mcid = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(mcid)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(cid) {
      productAction.deleteEatinProduct(cid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    var dataExcel = []
    let data = this.state.dataProduct 
    
    for(var i in data){
      dataExcel.push(
        [
          {value: data[i].num},
          {value: data[i].pr_image},
          {value: data[i].pr_code},
          {value: data[i].pr_name},
          {value: data[i].pr_category_name},
          {value: data[i].pr_sort},
          {value: (data[i].pr_status == 1 ? 'active' : 'inactive')}
        ],
      )
    }    
    
    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "URL รูปภาพ", width: {wch: 40}},//char width 
              {title: "รหัสสินค้า", width: {wch: 20}},
              {title: "ชื่อสินค้า (TH)", width: {wch: 40}},
              {title: "หมวดหมู่", width: {wch: 20}},
              {title: "ลำดับที่แสดง", width: {wch: 15}},
              {title: "สถานะ"},
          ],
          data: dataExcel
          
      }
    ];

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">จัดการสินค้า</h4>
            <div className="heading-elements">
              <a href="/backoffice/preorder/product/create">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                  เพิ่มสินค้า
                </button>
              </a>
              <ExcelFile 
                element={
                  <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                  >
                  <i className="icon-download"></i> Export Excel
                  </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Preorder-Product" />
              </ExcelFile>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
