import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const ewalletAction = {
  createProblemEwalletTitle,
  getProblemEwalletTitle,
  updateProblemEwalletTitle,

  getAccountMerchant,
  getAccountEWallet,

  withdrawForAU,

  getDetailTransaction,
  deleteTransaction,

  getListChannelEwallet,
  getListStatusEwallet,

  getMemberByNumber,

  createTopup,
  createRefund,

  getRecentList,
  getSearchList,
  getVoidList,
  getRefundList,
  getTopupList,
  getHistoryList,
  getWithdrawList,

  reportDailySettlement,
  reportDailyRefund,
  reportMonthlySettlement,
  reportMonthlyRefund,

  exportExcelEwalletAccounts
};

function exportExcelEwalletAccounts(onDownloadProgress) {
  return axios.get(`${Base_API.backoffice}/api/export/ewallet/listAccount`,{}).then(res => {
    return res.data;
  });
}

function createProblemEwalletTitle(formData) {
  return axios.post(`${Base_API.ewallet}/api/v1/problem_ewallet_title`, formData).then(res => {
    return res.data;
  });
}

function getProblemEwalletTitle(id) {
  return axios.get(`${Base_API.ewallet}/api/v1/problem_ewallet_title/${id}`, "").then(res => {
    return res.data;
  });
}

function updateProblemEwalletTitle(formData, id) {
  return axios.put(`${Base_API.ewallet}/api/v1/problem_ewallet_title/${id}`, formData).then(res => {
    return res.data;
  });
}

function getAccountMerchant() {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/account_merchant`, "").then(res => {
    return res.data;
  });
}

function withdrawForAU(data) {
  return axios.post(`${Base_API.ewallet}/api/v1/ewallet/account_withdraw`, data).then(res => {
    return res.data;
  });
}

function getDetailTransaction(id) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/detail_transaction/${id}`, "").then(res => {
    return res.data;
  });
}

function getListChannelEwallet() {
  return axios.get(`${Base_API.ewallet}/api/ewallet/channel`, "").then(res => {
    return res.data;
  });
}

function getListStatusEwallet() {
  return axios.get(`${Base_API.ewallet}/api/ewallet/status`, "").then(res => {
    return res.data;
  });
}

function getMemberByNumber(id) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/account/${id}`, "").then(res => {
    return res.data;
  });
}

function createTopup(inputBody) {
  return axios.post(`${Base_API.ewallet}/api/v1/ewallet/topup/create`, inputBody).then(res => {
    return res.data;
  });
}

function createRefund(inputBody) {
  return axios.post(`${Base_API.ewallet}/api/v1/ewallet/refund/create`, inputBody).then(res => {
    return res.data;
  });
}

function getRecentList() {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/search/recent`, "").then(res => {
    return res.data;
  });
}

function getSearchList(dataSearch) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/search/transactions?searchTransactionID=${dataSearch.searchTransactionID}&searchMemNumber=${dataSearch.searchMemNumber}&searchCardholderName=${dataSearch.searchCardholderName}&searchPaymentChannelID=${dataSearch.searchPaymentChannelID}&searchTransactionStatus=${dataSearch.searchTransactionStatus}&searchTransactionFrom=${dataSearch.searchTransactionFrom}&searchTransactionTo=${dataSearch.searchTransactionTo}&searchTransactionType=${dataSearch.searchTransactionType}&searchInvoiceNo=${dataSearch.searchInvoiceNo}&searchPhoneNo=${dataSearch.searchPhoneNo}`, "").then(res => {
    return res.data;
  });
}

function getVoidList(dataSearch) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/search/void?searchTransactionID=${dataSearch.searchTransactionID}&searchApprovalCode=${dataSearch.searchApprovalCode}&searchMemNumber=${dataSearch.searchMemNumber}&searchCardholderName=${dataSearch.searchCardholderName}&searchPaymentChannelID=${dataSearch.searchPaymentChannelID}&searchTransactionFrom=${dataSearch.searchTransactionFrom}&searchTransactionTo=${dataSearch.searchTransactionTo}`, "").then(res => {
    return res.data;
  });
}

function getRefundList(dataSearch) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/search/refund?searchTransactionID=${dataSearch.searchTransactionID}&searchApprovalCode=${dataSearch.searchApprovalCode}&searchMemNumber=${dataSearch.searchMemNumber}&searchCardholderName=${dataSearch.searchCardholderName}&searchTransactionFrom=${dataSearch.searchTransactionFrom}&searchTransactionTo=${dataSearch.searchTransactionTo}`, "").then(res => {
    return res.data;
  });
}

function getTopupList(dataSearch) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/search/topup?searchTransactionID=${dataSearch.searchTransactionID}&searchMemNumber=${dataSearch.searchMemNumber}&searchTransactionFrom=${dataSearch.searchTransactionFrom}&searchTransactionTo=${dataSearch.searchTransactionTo}&searchCardholderName=${dataSearch.searchCardholderName}&searchTransactionChannel=${dataSearch.searchTransactionChannel}`, "").then(res => {
    return res.data;
  });
}

function getHistoryList(dataSearch, Type) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/history_transaction?searchType=${Type}&searchThisMonth=${dataSearch.s_This_Month}&searchMonth=${dataSearch.s_Month}&searchFrom=${dataSearch.s_From}&searchTo=${dataSearch.s_To}`, "").then(res => {
    return res.data;
  });
}

function getAccountEWallet(dataSearch) {
  // return axios.get(`${Base_API.ewallet}/api/v2/ewallet/account_users?searchRegisDate=${dataSearch.s_RegisDate}&searchMemberNumber=${dataSearch.s_MemberNumber}&searchFirstname=${dataSearch.s_Firstname}&searchLastname=${dataSearch.s_Lastname}&searchPhone=${dataSearch.s_Phone}&searchIDCard=${dataSearch.s_IDCard}&start=1&length=10000`).then(res => {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/account_users?searchRegisDate=${dataSearch.s_RegisDate}&searchMemberNumber=${dataSearch.s_MemberNumber}&searchFirstname=${dataSearch.s_Firstname}&searchLastname=${dataSearch.s_Lastname}&searchPhone=${dataSearch.s_Phone}&searchIDCard=${dataSearch.s_IDCard}&start=1`).then(res => {
    return res.data;
  });
}

function getWithdrawList() {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/account_withdraw`, "").then(res => {
    return res.data;
  });
}

function reportDailySettlement(dataSearch) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/report/daily?searchTransactionFrom=${dataSearch.searchTransactionFrom}&searchTransactionTo=${dataSearch.searchTransactionTo}`, "").then(res => {
    return res.data;
  });
}

function reportDailyRefund(dataSearch) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/report/daily/refund?searchTransactionFrom=${dataSearch.searchTransactionFrom}&searchTransactionTo=${dataSearch.searchTransactionTo}`, "").then(res => {
    return res.data;
  });
}

function reportMonthlySettlement(dataSearch) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/report/monthly?searchTransactionMonthFrom=${dataSearch.searchTransactionMonthFrom}&searchTransactionYearFrom=${dataSearch.searchTransactionYearFrom}&searchTransactionMonthTo=${dataSearch.searchTransactionMonthTo}&searchTransactionYearTo=${dataSearch.searchTransactionYearTo}`, "").then(res => {
    return res.data;
  });
}

function reportMonthlyRefund(dataSearch) {
  return axios.get(`${Base_API.ewallet}/api/v1/ewallet/report/monthly/refund?searchTransactionMonthFrom=${dataSearch.searchTransactionMonthFrom}&searchTransactionYearFrom=${dataSearch.searchTransactionYearFrom}&searchTransactionMonthTo=${dataSearch.searchTransactionMonthTo}&searchTransactionYearTo=${dataSearch.searchTransactionYearTo}`, "").then(res => {
    return res.data;
  });
}

function deleteTransaction(transaction_id) {
  return axios.delete(`${Base_API.ewallet}/api/v1/ewallet/delete_transaction/${transaction_id}`, "").then(res => {
    return res.data;
  });
}


