import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import { Redirect } from 'react-router'
import "./login.css";
import { adminAction } from "../../_actions";

let modalAlert;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      submitted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);

  }

  componentDidMount() {
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundImage = `linear-gradient(rgba(0,0,0), rgba(0,0,0,.5)),url(${process.env.PUBLIC_URL}/images/afteryou_bg.png)`;
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundSize = '100%';
    document.body.style.backgroundPosition = 'center';

  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    
  }

  handleSubmit(e) {
    console.log(e);
    e.preventDefault();
    this.setState({ submitted: true });
    this.handleLogin(this.state.username,this.state.password)
  }

  handleLogin(username,password) {
    let inputData = {
      ad_username : username,
      ad_password : password
    }
    
    adminAction.checkLogin(inputData).then(e => {      

      if (e.isSuccess === true && e.data[0].is_active === 1) {
        localStorage.setItem("admin_id", e.data[0].ad_id)
        localStorage.setItem("user", username)
        localStorage.setItem("level", e.data[0].ad_level)
        localStorage.setItem("ad_b_status", e.data[0].ad_b_status)
        localStorage.setItem("ad_b_id", e.data[0].ad_b_id)
        localStorage.setItem("login", "true")
        window.location.href = "/backoffice/homepage";

      } else {
        this.onModalError("Error", e.message);
      }
    });


    // if (this.state.username === "admin" && this.state.password === "nilecon-after") {
    //   localStorage.setItem("username", "admin")
    //   localStorage.setItem("login", "true")
    //   window.location.href = "homepage";
    // } else {
    //   return false
    // }
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  render() {
    const loginStatus = localStorage.getItem('login');
    const { username, password, submitted } = this.state;
    if (loginStatus === true) { return <Redirect to="/Emporium" push={true} /> }

    return (
      <div className="content">
        <div className="text-center">

          </div>
          <br />
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="panel panel-body login-form">
            <div className="text-center mb-4">
              <img src="../images/logo.png" alt="" className="logo-login" ></img>

            </div>

            <div className="form-group has-feedback has-feedback-left">
              <input
                type="text"
                className="form-control"
                name="username"
                value={username}
                placeholder="ชื่อผู้ใช้"
                onChange={this.handleChange}
              />
              <div className="form-control-feedback">
                <i className="icon-user text-muted" />
              </div>
            </div>

            <div className="form-group has-feedback has-feedback-left">
              <input
                type="password"
                className="form-control"
                name="password"
                value={password}
                placeholder="รหัสผ่าน"
                onChange={this.handleChange}
              />
              <div className="form-control-feedback">
                <i className="icon-lock2 text-muted" />
              </div>
            </div>

            <div className="form-group">
              <button
                type="submit"
                value={submitted}
                className="btn btn-add btn-block"
              >
                เข้าสู่ระบบ{" "}
                <i className="icon-circle-right2 position-right" />
              </button>
            </div>
          </div>
        </form>
        {this.state.modal}

      </div>
    );
  }
}

export default LoginPage;
