import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import { productAction } from "../../../../_actions";
import "../product.css";

$.DataTable = require("datatables.net");
let modalAlert;

export class Product_Online_Branch_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    let ad_b_status = localStorage.getItem('ad_b_status');
    let ad_b_id = localStorage.getItem('ad_b_id');    
    const columns = [
      {
        class: "details-control",
        orderable: false,
        data: null,
        defaultContent: ""
      },
      {
        title: "ชื่อสินค้า",
        class: "text-center",
        data: "pr_online_name"
      },
      {
        title: "รหัสสินค้า",
        class: "text-center",
        data: "pr_online_code"
      },
      {
        title: "หมวดหมู่สินค้า",
        class: "text-center",
        data: "pr_online_category_name"
      },
      {
        title: "สถานะสินค้า",
        class: "text-center",
        data: "is_active"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "pr_online_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class='text-left'>${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class='text-left'>${cellData}</div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            let isStatus = ''
            if(cellData==1){
              isStatus = '<span class="label label-success label-status">ใช้งาน</span>'
            }else{
              isStatus = '<span class="label label-danger label-status">ไม่ใช้งาน</span>'
            }

            $(td)
              .html(isStatus);
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/order-online/product/branch/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3"></i> แก้ไข</a>
                    </div>
                                        
                  </div>
                </div>
                `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.product}/product_online_by_branch?ad_b_status=${ad_b_status}&ad_b_id=${ad_b_id}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "ASC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
      
    });

    function format ( d ) {
      let branch_by_product = ""
      let color_status_branch_switch = ""
      
      let status_branch = ""
      let status_switch = (d.is_active != 1 ? "disabled" : "")
      let color_status_switch = (d.is_active != 1 ? "color_switch_disabled" : "")
      
      for(var i in d.branch){
        
        console.log('d.branch[i].pr_online_branch_type: ', d.branch[i].pr_online_branch_type);
        if(d.branch[i].b_status == 1 && d.branch[i].b_orderonline_status == 1){
          status_branch = "dot-green"
          color_status_branch_switch = ""
          status_switch = ""
        }else{
          if(d.branch[i].pr_online_branch_type == "ems" || d.branch[i].pr_online_branch_type == "gift"){
            status_branch = "dot-green"
            color_status_branch_switch = ""
            status_switch = ""
          }else{
            status_branch = "dot-red"
            color_status_branch_switch = "color_switch_disabled"
            status_switch = "disabled"
          }
        }

        branch_by_product += 
          '<tr>'+
            '<td>'+ `<div class=${status_branch}></div> ` + (d.branch[i].pr_online_branch_type == "delivery" ? "["+ d.branch[i].b_code +"] - " + d.branch[i].b_name : d.branch[i].pr_online_branch_type == "gift" ? 'gift' : 'ems') +'</td>'+
            '<td>'+d.branch[i].pr_online_branch_price+'</td>'+
            '<td>'+
              `<label class="switch">
                  <input name="status_soldout" class="activeBtn_soldout" data-id="${d.branch[i].pr_online_branch_id}" type="checkbox" ${d.branch[i].is_soldout==0 ? "" : "checked"} ${status_switch}/>
                  <div class="slider slider_2color ${color_status_switch} ${color_status_branch_switch}"></div>
                </label>`+
            '</td>'+
            '<td>'+
              `<label class="switch">
                  <input name="status_active" class="activeBtn_active" data-id="${d.branch[i].pr_online_branch_id}" type="checkbox" ${d.branch[i].is_active==0 ? "" : "checked"} ${status_switch}/>
                  <div class="slider ${color_status_switch} ${color_status_branch_switch}"></div>
                </label>`+
            '</td>'+
          '</tr>'
      }
      
      return '<table border="0" style="width:100%">'+
        '<thead class="header_table">'+
          '<tr>'+
            '<td style="width:33%">'+'การจัดส่ง/ชื่อสาขา'+'</td>'+
            '<td style="width:20%">'+'ราคา'+'</td>'+
            '<td style="width:20%">'+'สถานะ soldout'+'</td>'+
            '<td style="width:20%">'+'สถานะแสดงสินค้า'+'</td>'+
          '</tr>'+
        '</thead>'+
        '<tbody class="body_table">'+
          branch_by_product+
        '<tbody>'+
      '</table>';
    }

    $('#dataTable tbody').on('click', 'td.details-control', function () {
      var tr = $(this).closest('tr');
      var row = table.row( tr );

      if ( row.child.isShown() ) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('shown');
      }
      else {
          // Open this row
          row.child( format(row.data()) ).show();
          tr.addClass('shown');
      }
    });

    $(this.refs.main).on("click", ".activeBtn_soldout", function () {
      var id = $(this).data("id");
      var val = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("is_soldout", val);

      productAction.onlineUpdateProductByProductID(formData, id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", ".activeBtn_active", function () {
      var id = $(this).data("id");
      var val = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("is_active", val);

      productAction.onlineUpdateProductByProductID(formData, id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    var state = this;

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการสินค้าออน์ไลน์แต่ละสาขา</h4>
            <div className="heading-elements">
              <a href="/backoffice/order-online/product/branch/edit_by_code">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                  จัดการสินค้าแต่ละสาขาตามรหัสสินค้า
                </button>
              </a>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
