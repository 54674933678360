import React from "react";
import { newsAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { Base_API } from "../../../../_constants/matcher";
import Select from 'react-select';
import "../news.css";
import moment from "moment-timezone";

let modalAlert;

export class News_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      fields: {},
      errors: {},
      typeProgram: null,
      selectType: null,
      isStatus: '',
      isAutoNoti: '',
      status: '',
      autoNotiStatus: '',
      news_id: this.props.match.params.news_id
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.handleSubmitEditImage = this.handleSubmitEditImage.bind(this);
    this.onUpdateNews = this.onUpdateNews.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getNewsByID(params.news_id);
  }

  getNewsByID(news_id) {
    newsAction.getNewsId(news_id).then(e => {
      console.log('e: ', e.data);
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            newsTitle: e.data.news_title,
            newsDescription: e.data.news_description,
            newsTitle_en: e.data.news_title_en,
            newsLink: e.data.news_link,
            newsDescription_en: e.data.news_description_en,
            newsImage: e.data.news_image,
            isStatus: e.data.news_active,
            isAutoNoti: e.data.is_autonoti,
            autonoti_datetime: !!e.data.autonoti_datetime ? moment(e.data.autonoti_datetime).format("Y-MM-DD")+"T"+moment(e.data.autonoti_datetime).format("HH:mm:ss") : '',
            news_id: news_id,
          }
        });

        this.setState({isStatus: String(this.state.fields["isStatus"]) == "1"});
        this.setState({status: this.state.fields["isStatus"]});
        this.setState({isAutoNoti: this.state.fields["isAutoNoti"]});
        this.setState({autoNotiStatus: this.state.fields["isAutoNoti"]});

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/news/news";
  }

  validateForm() {
    let fields = this.state.fields;
    let {isAutoNoti} = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["newsTitle"]) {
      formIsValid = false;
      errors["newsTitle"] = "*กรุณากรอกหัวข้อ (TH)";
    }

    if (!fields["newsDescription"]) {
      formIsValid = false;
      errors["newsDescription"] = "*กรุณากรอกรายละเอียด (EN)";
    }

    if (!fields["newsTitle_en"]) {
      formIsValid = false;
      errors["newsTitle_en"] = "*กรุณากรอกหัวข้อ (EN)";
    }

    if (!fields["newsDescription_en"]) {
      formIsValid = false;
      errors["newsDescription_en"] = "*กรุณากรอกรายละเอียด (EN)";
    }

    // if (!fields["newsStart"]) {
    //   formIsValid = false;
    //   errors["newsStart"] = "*กรุณาเลือกวันที่เริ่มต้นข่าวสาร";
    // }

    if (!fields["newsImage"]) {
      formIsValid = false;
      errors["newsImage"] = "*กรุณาแนบไฟล์";
    }
    console.log('fields: ', fields);
    if (isAutoNoti) {
      if(!fields["autonoti_datetime"])
      {
        formIsValid = false;
        errors["autonoti_datetime"] = "*กรุณาระบุวันและเวลาแจ้งเตืือนอัตโนมัติ";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    formData.append("news_title", this.state.fields["newsTitle"]);
    formData.append("news_description", this.state.fields["newsDescription"]);
    formData.append("news_title_en", this.state.fields["newsTitle_en"]);
    formData.append("news_link", (this.state.fields["newsLink"] ? this.state.fields["newsLink"] : ""));
    formData.append("news_description_en", this.state.fields["newsDescription_en"]);
    formData.append("news_open_type", "home");
    formData.append("news_image", this.state.fields["newsImage"]);
    formData.append("news_active", this.state.status);
    formData.append("is_autonoti", this.state.autoNotiStatus);
    formData.append("autonoti_datetime", this.state.fields["autonoti_datetime"]);

    newsAction.updateNews(formData, this.state.fields["news_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onUpdateNews(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    formData.append("news_title", this.state.fields["newsTitle"]);
    formData.append("news_description", this.state.fields["newsDescription"]);
    formData.append("news_title_en", this.state.fields["newsTitle_en"]);
    formData.append("news_link", (this.state.fields["newsLink"] ? this.state.fields["newsLink"] : ""));
    formData.append("news_description_en", this.state.fields["newsDescription_en"]);
    formData.append("news_open_type", "home");
    formData.append("news_image", this.state.fields["newsImage_new"]);
    formData.append("news_active", this.state.status);

    newsAction.updateNews(formData, this.state.fields["news_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
      let { fields, errors } = this.state;

        fields[e.target.name] = e.target.value;
        this.state.errors[e.target.name] = null;

      this.setState({ fields, errors });

  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleInputChange = e => {
    const target = e.target;
    const name = target.name;

    if(name == "isStatus"){
      this.setState({ isStatus: !this.state.isStatus })

      if(this.state.isStatus == true){
        this.setState({ status: '2' })
      }else{
        this.setState({ status: '1' })
      }

    }
    if(name == "isAutoNoti"){
      this.setState({ isAutoNoti: !this.state.isAutoNoti })

      if(this.state.isAutoNoti == true){
        this.setState({ autoNotiStatus: '0' })
      }else{
        this.setState({ autoNotiStatus: '1' })
      }
    }
  }

  handleClickEdit = () => {
    window.$('#ModalAddImage').modal('show')
  };
  
  onConfirmDelete = () => {
    this.setState({ modal: null });

    newsAction.deleteNewsContentByID(this.state.fields["news_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  };

  validateFormAddImage() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["newsImage_new"]) {
      formIsValid = false;
      errors["newsImage_new"] = "*กรุณาแนบรูปภาพข่าวสาร";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onModalQuestionAddContent(head, body, type) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreateContent(type)}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleClickDelete = (id) => {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title="Delete"
        onConfirm={() => this.onConfirmDelete(id)}
        onCancel={() => this.onCancel()}
      >
        คุณต้องการลบข้อมูลนี้หรือไม่ ?
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestionEditContent(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onUpdateNews()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmitEditImage() {
    if (this.validateFormAddImage()) {
      this.onModalQuestionEditContent("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  render() {
    let { fields } = this.state;

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขแจ้งเตือนข่าวสารทั่วไป</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="row">
                  <div className="col-lg-6 col-md-6 my-3 text-right">
                    <img
                      width="100" height="100"
                      className="preview-img-promotion"
                      src={fields["newsImage"]}
                    />
                  </div>
                  <div className="col-lg-6 content-button text-left">
                  <input
                    type="button"
                    className={`btn btn-warning`}
                    onClick={()=>this.handleClickEdit()}
                    value="แก้ไข"
                  />
                  &nbsp;&nbsp;
                  <input
                    type="button"
                    className={`btn btn-danget`}
                    onClick={()=>this.handleClickDelete()}
                    value="ลบ"
                  />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">หัวข้อข่าวสาร (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="newsTitle"
                      type="text"
                      className="form-control"
                      placeholder="กรอกหัวข้อข่าวสาร (TH)"
                      value={this.state.fields["newsTitle"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsTitle}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">หัวข้อข่าวสาร (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="newsTitle_en"
                      type="text"
                      className="form-control"
                      placeholder="หัวข้อข่าวสาร (EN)"
                      value={this.state.fields["newsTitle_en"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsTitle_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียดข่าวสาร (TH)</label>
                  <div className="col-lg-10">
                  <textarea
                      className="form-control"
                      rows="3"
                      name="newsDescription"
                      placeholder="รายละเอียด (TH)"
                      value={this.state.fields["newsDescription"] || ""}
                      onChange={this.handleChange}
                  >
                  </textarea>
                    <div className="errorMsg">{this.state.errors.newsDescription}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียดข่าวสาร (EN)</label>
                  <div className="col-lg-10">
                  <textarea
                      className="form-control"
                      rows="3"
                      name="newsDescription_en"
                      placeholder="รายละเอียดข่าวสาร (EN)"
                      value={this.state.fields["newsDescription_en"] || ""}
                      onChange={this.handleChange}
                  >
                  </textarea>
                    <div className="errorMsg">{this.state.errors.newsDescription_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">Link ข่าวสารแจ้งเตือน <br /><span className="text-gray">(ขึ้นต้นด้วย http:// หรือ https:// Ex.https://afteryou.com)</span></label>
                  <div className="col-lg-10">
                  <input
                      name="newsLink"
                      type="text"
                      className="form-control"
                      placeholder="Link ข่าวสารแจ้งเตือน"
                      value={this.state.fields["newsLink"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsLink}</div>
                  </div>
                </div>

                {/* <div className="form-group">
                <label className="control-label col-lg-2">รูปภาพข่าวสาร</label>
                  <div className="col-lg-10">
                  <input
                    type="file"
                    className="file-input"
                    name="newsImage"
                    onChange={this.onChangeUploadHandler}/>
                  <div className="errorMsg">{this.state.errors.newsImage}</div>
                  <img
                    className="banner-preview-img"
                    src={this.state.fields["newsImage"] || ""}
                    alt={this.state.fields["newsTitle"] || ""}
                  />
                  </div>
                </div> */}

                {/*<div className="form-group">
                  <label className="control-label col-lg-2">วันที่แจ้งเตือน (เดือน/วัน/ปี)</label>
                  <div className="col-lg-4">
                  <input
                      name="newsStart"
                      type="date"
                      data-date-format="DD-MM-YYYY"
                      className="form-control"
                      value={this.state.fields["newsStart"] || ""}
                      placeholder="Please input Date Start"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.newsStart}</div>
                  </div>
                </div>
              */}

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการแจ้งเตือนอัตโนมัติ</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isAutoNoti" type="checkbox" onChange={this.handleInputChange} checked={this.state.isAutoNoti}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2">วันและเวลาที่แจ้งเตือน</label>
                  <div className="col-md-3">
                    <input
                      name="autonoti_datetime"
                      type="datetime-local"
                      className="form-control"
                      placeholder="วันและเวลาที่แจ้งเตือน"
                      value={ this.state.fields.autonoti_datetime}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.autonoti_datetime}</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}

        {/* modelAddImage */}
        <div class="modal fade" id="ModalAddImage" tabindex="-1" role="dialog" aria-labelledby="ModalAddImageLabel">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="ModalAddImageLabel">เปลี่ยนรูปข่าวสาร</h4>
              </div>
              <div class="modal-body">
                <div className="row mb-3">
                  <div class="col-md-3">
                    รูปภาพ <span className="text-danger">(.jpg) (500 x 500 px)</span>
                  </div>
                  <div class="col-md-8">
                    <input
                      type="file"
                      className="file-input"
                      name="newsImage_new"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">
                      {this.state.errors.newsImage_new}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" onClick={this.handleSubmitEditImage}>อัพเดต</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
