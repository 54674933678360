import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../account.css";
import ReactExport from 'react-data-export';
import { ewalletAction } from "../../../../_actions";


$.DataTable = require("datatables.net");
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd+mm+yyyy+hh+mn+ss+ms+'-transaction-withdraw'


export class Withdraw_Ewallet extends React.Component {
  constructor(props, context) {
    super(props, context);


    this.state = {

    };
  }

  componentDidMount() {
    this.showTable();
    this.exportExcel()

  }

  showTable(){
    const columns = [
      {
        title: "Withdraw Options",
        class: "text-center",
        data: "transaction_description"
      },
      {
        title: "Processing Time",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "Cost",
        class: "text-center",
        data: "transaction_amount"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData} ฿</div>`);
          }
        }
      ],

      ajax: {
        url: `${Base_API.ewallet}/api/v1/ewallet/account_withdraw`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[2, "DESC"]],
      columns,
      serverSide: true,
      ordering: false,
      searching: false,
      processing: true,
      bLengthChange: true,
      "language": {
        processing: '<i className="fa fa-spinner fa-spin fa-3x fa-fw"></i><span className="sr-only">Loading..n.</span> '
      },
    });

  }

  exportExcel = () => {
    ewalletAction.getWithdrawList()
    .then(res => {
        this.setState({
            dataTransSearch: res.data
        })
    })
  };


  render() {

    var dataExcel = []
    let data = this.state.dataTransSearch    
    for(var i in data){
      dataExcel.push(
        [
          {value: data[i].num},
          {value: data[i].transaction_description},
          {value: data[i].created_at},
          {value: data[i].transaction_amount},
        ],
      )
    }    
    
    multiDataSet = [
      {
        columns: [
            {title: "#"},//pixels width 
            {title: "Withdraw Options", width: {wch: 40}},//char width 
            {title: "Processing Time", width: {wch: 20}},
            {title: "Cost", width: {wch: 20}},
        ],
        data: dataExcel
      }
    ];

    return (
      <div>
        <ol className="breadcrumb">
          <li><a href="#">E-Wallet</a></li>
          <li><a href="/backoffice/ewallet/account/overview">บัญชี</a></li>
          <li className="active">Whitdraw</li>
        </ol>

        <ul className="nav nav-tabs">
          <li role="Overview"><a href="/backoffice/ewallet/account/overview">Overview</a></li>
          <li role="Withdraw" className="active"><a href="#">Withdraw</a></li>
          <li role="History"><a href="/backoffice/ewallet/account/history">History</a></li>
        </ul>
        <div className="panel panel-flat">
          <div className="panel-body">
            <div>
              <div className="row">
                <div className="col-md-6">
                  <h4>Withdraw Funds</h4>
                </div>
                <div className="col-md-6 text-right">
                  <a href="/backoffice/ewallet/account/withdraw_fund">
                    <button
                      type="button"
                      name="add_banner_btn"
                      data-content=""
                      className="btn btn-add mr-2"
                    >
                    ขอทำรายการ
                    </button>
                  </a>
                  <ExcelFile 
                  element={
                    <button
                    type="button"
                    name="add_banner_btn"
                    data-content=""
                    className="btn btn-add mr-2"
                    >
                    <i className="icon-download"></i> Export Excel
                    </button>
                  }
                  filename = {name_excel}
                >
                  <ExcelSheet dataSet={multiDataSet} name="Transaction-Withdraw" />
                </ExcelFile>
                </div>
              </div>
              <div className="table-responsive">
                <table id="dataTable" className="table table-hover" ref="main" />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
