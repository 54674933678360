import React, { Component } from "react";
import Routing from "../../routes";
import "./login.css"

export class LoginLayout extends Component {
  render() {
    return (
      <div className="login-container">
        {/* <div className="navbar navbar-inverse" style={{ backgroundColor: '#fff' }}>
          <div className="navbar-header">
          <img src="../images/logo.png" alt="" className="logo-login"></img>

            <ul className="nav navbar-nav pull-right visible-xs-block">
              <li>
                <a data-toggle="collapse" data-target="#navbar-mobile">
                  <i className="icon-tree5" />
                </a>
              </li>
            </ul>
          </div>
        </div> */}

        <div className="page-container">
          <div className="page-content">
            <div className="content-wrapper">
              <Routing></Routing>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
