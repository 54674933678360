import React from "react";
import { adminAction, branchAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import $ from 'jquery'
import "../admin.css";
let modalAlert;

export class Admin_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalResetPassword: null,
      modalOption: {},
      // messageCreate: "",
      modalReset: false,
      showPassword: false,
      resetPasswordText: "",
      fields: {},
      select: {},
      errors: {},
      // levelList: [],
      selectOptionLevel: [],
      selectOptionBranch: [],
      // level: null,
      // branch: null,
      admin_id: this.props.match.params.admin_id
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirmResetPassword = this.onConfirmResetPassword.bind(this);
  }

  componentDidMount() {
    let { selectOptionLevel, selectOptionBranch } = this.state;

    const {
      match: { params }
    } = this.props;
    this.getDataByID(params.admin_id);

    // adminAction.listLevelAdmin()
    // .then(res => {
    //     this.setState({
    //         levelList: res.data
    //     })
    // })

    adminAction.listLevelAdmin().then(res => {
      for (var i in res.data) {
        selectOptionLevel.push({
          label: res.data[i].level_name,
          value: res.data[i].level_id
        });
      }
      this.setState({ selectOptionLevel });
    });

    branchAction.listBranchAll().then(res_branch => {
      for (var j in res_branch.data) {
        selectOptionBranch.push({
          label: res_branch.data[j].b_name,
          value: res_branch.data[j].b_id
        });
      }
      this.setState({ selectOptionBranch });
    });
  }

  getDataByID(admin_id) {
    adminAction.getAdminId(admin_id).then(e => {
      if (e.isSuccess === true) {
          this.setState({
          fields: {
            ...this.state.fields,
            ad_firstname: e.data.ad_firstname,
            ad_lastname: e.data.ad_lastname,
            ad_phone: e.data.ad_phone,
            ad_email: e.data.ad_email,
            ad_username: e.data.ad_username,
            ad_password: e.data.ad_password,
            ad_b_status: e.data.ad_b_status,
            is_active: e.data.is_active,
            ad_id: admin_id
          }
        });
        this.setState({
          select: {
            level : {
              ...this.state.select.level,
              label: e.data.level_name,
              value: e.data.level_id,
            },
            branch : {
              ...this.state.select.branch,
              label: e.data.b_name,
              value: e.data.b_id,
            },
          }
        });

        // this.setState({select:
        //   {
        //     label: this.state.fields["level_name"],
        //     value: this.state.fields["level_id"]
        //   }
        // });

        // this.setState({branch:
        //   {
        //     label: this.state.fields["b_name"],
        //     value: this.state.fields["b_id"]
        //   }
        // });

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestionResetPassword(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmResetPassword()}
        onCancel={() => this.onCancelResetPassword()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modalResetPassword: modalAlert() });
  }

  onCancelResetPassword = () => {
    this.setState({
      modalResetPassword: null
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/admin";
  }

  validateForm() {
    let fields = this.state.fields;
    let select = this.state.select;
    let errors = {};
    let formIsValid = true;

    if (!fields["ad_firstname"]) {
      formIsValid = false;
      errors["ad_firstname"] = "*กรุณากรอกชื่อจริง";
    }
    if (!fields["ad_lastname"]) {
      formIsValid = false;
      errors["ad_lastname"] = "*กรุณากรอกนามสกุล";
    }
    if (!fields["ad_phone"]) {
      formIsValid = false;
      errors["ad_phone"] = "*กรุณากรอกเบอร์โทรศัพท์";
    }
    if (!fields["ad_email"]) {
      formIsValid = false;
      errors["ad_email"] = "*กรุณากรอก E-Mail";
    }
    if (!fields["ad_username"]) {
      formIsValid = false;
      errors["ad_username"] = "*กรุณากรอก Username";
    }
    if (!fields["ad_oldPassword"]) {
      formIsValid = false;
      errors["ad_oldPassword"] = "*กรุณากรอกรหัสผ่านเดิม";
    }

    if(fields["ad_newPassword"] !== "" && typeof fields["ad_newPassword"] !== "undefined"){
      if(fields["ad_newPassword"].length < 8){
        formIsValid = false;
        errors["ad_newPassword"] ="*กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว";
      }
    }
    if (fields["ad_b_status"] == 1 && !select.branch["value"]) {
      formIsValid = false;
      errors["branch"] = "*กรุณาเลือกสาขาที่สังกัด";
    }

    if (fields["ad_newPassword"] !== "" && typeof fields["ad_newPassword"] !== "undefined" && (fields["ad_cfNewPassword"] === ""  || typeof fields["ad_cfNewPassword"] === "undefined")) {
      formIsValid = false;
      errors["ad_cfNewPassword"] = "*กรุณากรอกยืนยันรหัสผ่าน";
    }else{
      if(fields["ad_cfNewPassword"] !== "" && typeof fields["ad_cfNewPassword"] && fields["ad_newPassword"] !== "" && typeof fields["ad_newPassword"] !== "undefined"){
        if (fields["ad_newPassword"] !== fields["ad_cfNewPassword"]) {
          formIsValid = false;
          errors["ad_cfNewPassword"] = "*ยืนยันรหัสผ่านไม่ถูกต้อง";
        }
      }

    }


    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    var md5 = require('md5');
    this.setState({ modal: null });
    var formData = {
      "ad_firstname" : this.state.fields["ad_firstname"],
      "ad_lastname" : this.state.fields["ad_lastname"],
      "ad_phone" : this.state.fields["ad_phone"],
      "ad_email" : this.state.fields["ad_email"],
      "ad_username" : this.state.fields["ad_username"],
      "ad_level" : this.state.select.level["value"],
      'ad_b_status' : this.state.fields["ad_b_status"],
      'ad_b_id' : this.state.select.branch["value"],
      "is_active" : this.state.fields["is_active"],
      "ad_password" : (this.state.fields["ad_newPassword"] ? md5(this.state.fields["ad_newPassword"]) : ''),
      "ad_oldPassword" : md5(this.state.fields["ad_oldPassword"])
    }

    adminAction.updateAdmin(formData, this.state.fields["ad_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }


  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleSelect = (e, action) => {
    let { select, fields, errors } = this.state;    
    select[action.name] = {
      label: e.label,
      value: e.value
    };
    this.state.errors[action.name] = null;
    this.setState({ select, fields, errors });
  };
  
  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active" || e.target.name === "ad_b_status") {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  onConfirmResetPassword = e => {
    var md5 = require('md5');
    let {resetPasswordText, admin_id} = this.state;

    var formData = {
      "new_password" : md5(resetPasswordText),
    }

    adminAction.resetPasswordAdmin(formData, admin_id)
    .then(res => {
      console.log('reset password: ', res);
      if(res.isSuccess)
      {
        // Close Modal & Popup Confirm
        $("#btn-closeresetpmodal").click();
        this.setState({ modalResetPassword: null });
        this.onModalSuccess("Success", res.message);
      }else
      {
        this.onModalError("Error", res.message);
      }
    })

  }

  handleShowPasswordChange = e => {
    let {showPassword} = this.state;
    this.setState({
      showPassword: !showPassword
    });  
  }

  handleInputResetPasswordChange = async(e) => {
    let value = e.target.value;
    this.setState({
      resetPasswordText: value
    })
    await this.validateFormResetPassword(value);
  }

  handleSubmitResetPassword = async e => {
    await this.validateFormResetPassword(this.state.resetPasswordText);
    this.onModalQuestionResetPassword("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
  }

  validateFormResetPassword = (msg) => {
    return new Promise(async resolve => {
      let {errors} = this.state; 
      let error = {}
      if(!!msg && msg.length >= 6)
      {
        resolve();
        error["reset_password"] = "";
      }else
      {
        error["reset_password"] = "*กรอกรหัสผ่านให้ครบถ้วน (อย่างน้อย 6 ตัวอักษร)";    
      }
      this.setState({
        errors: {...errors, ...error}
      })
    })
  }

  render() {
    let { select, showPassword } = this.state;

    let modalResetPassword = (
      <div className="modal-resetpw-form">
        {/* <!-- Modal --> */}
        <div class="modal fade" id="modal-reset-password" tabindex="-1" role="dialog" aria-labelledby="modal-reset-passwordTitle" aria-hidden="true" data-backdrop="static">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <h3 className="mt-0">รีเซทรหัสผ่าน</h3>
                <span className="resetp-input-wrap">
                  <input className="form-control" value={this.state.resetPasswordText} onChange={this.handleInputResetPasswordChange} placeholder="รหัสผ่านใหม่" type={showPassword ? 'text' : 'password'} />
                  <span onClick={this.handleShowPasswordChange}>
                    {showPassword ? 
                      // show password
                      <i class="bi bi-eye"></i>
                      : 
                      // hide password
                      <i class="bi bi-eye-slash"></i>
                    }
                  </span>
                </span>
                <p className="text-danger mt-2">{this.state.errors["reset_password"]}</p>
              </div>
              <div class="modal-footer">
                <button type="button" id="btn-closeresetpmodal" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" onClick={this.handleSubmitResetPassword}>Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขข้อมูลผู้ดูแลระบบ</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">

              <div className="form-group">
                <label className="control-label col-lg-2">ชื่อจริง</label>
                <div className="col-lg-4">
                <input
                    name="ad_firstname"
                    type="text"
                    className="form-control"
                    placeholder="กรอกชื่อจริง"
                    value={this.state.fields["ad_firstname"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.ad_firstname}</div>
                </div>

                <label className="control-label col-lg-2">นามสกุล</label>
                <div className="col-lg-4">
                <input
                    name="ad_lastname"
                    type="text"
                    className="form-control"
                    placeholder="กรอกนามสกุล"
                    value={this.state.fields["ad_lastname"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.ad_lastname}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">เบอร์โทรศัพท์</label>
                <div className="col-lg-4">
                <input
                    name="ad_phone"
                    type="text"
                    className="form-control"
                    placeholder="กรอกเบอร์โทรศัพท์"
                    value={this.state.fields["ad_phone"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.ad_phone}</div>
                </div>

                <label className="control-label col-lg-2">E-Mail</label>
                <div className="col-lg-4">
                <input
                    name="ad_email"
                    type="email"
                    className="form-control"
                    placeholder="กรอก e-mail"
                    value={this.state.fields["ad_email"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.ad_email}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">ระดับ</label>
                <div className="col-lg-4">
                  <Select
                      className="typeSelect"
                      classNamePrefix="typeSelect-menu"
                      placeholder="เลือกระดับ"
                      value={select.level}
                      name="level"
                      onChange={this.handleSelect}
                      options={this.state.selectOptionLevel}
                    />
                  <div className="errorMsg">{this.state.errors["level"]}</div>
                </div>
                <label className="control-label col-lg-2">Username</label>
                <div className="col-lg-4">
                  <input
                    name="ad_username"
                    type="text"
                    className="form-control"
                    placeholder="กรอก Username"
                    value={this.state.fields["ad_username"] || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.ad_username}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">
                  รหัสผ่านเดิม
                </label>
                <div className="col-lg-4">
                  <input
                    name="ad_oldPassword"
                    type="password"
                    className="form-control"
                    placeholder="กรอกรหัสผ่านเดิม"
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">
                    {this.state.errors["ad_oldPassword"]}
                  </div>
                </div>
                <label className="control-label col-lg-2">
                  รหัสผ่านใหม่
                </label>
                <div className="col-lg-4">
                  <input
                    name="ad_newPassword"
                    type="password"
                    className="form-control"
                    placeholder="กรอกรหัสผ่านใหม่"
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">
                    {this.state.errors["ad_newPassword"]}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">
                  ยืนยันรหัสผ่าน
                </label>
                <div className="col-lg-4">
                  <input
                    name="ad_cfNewPassword"
                    type="password"
                    className="form-control"
                    placeholder="กรอกยืนยันรหัสผ่าน"
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">
                    {this.state.errors["ad_cfNewPassword"]}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-lg-2">
                  {/* รีเซทรหัสผ่าน */}
                </label>
                <div className="col-lg-4">
                  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modal-reset-password">
                    รีเซทรหัสผ่าน
                  </button>
                </div>
              </div>

              <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเป็นพนักงานสาขา</label>
                  <div className="col-lg-4">
                    <label className="switch">
                      <input
                        name="ad_b_status"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["ad_b_status"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">สาขาที่สังกัด</label>
                  <div className="col-lg-4">
                    <Select
                      className="typeSelect"
                      classNamePrefix="typeSelect-menu"
                      placeholder="เลือกสาขาที่สังกัด"
                      value={select.branch}
                      name="branch"
                      onChange={this.handleSelect}
                      options={this.state.selectOptionBranch}
                    />
                    <div className="errorMsg">{this.state.errors["branch"]}</div>
                  </div>
                </div>

              <div className="form-group">
                <label className="control-label col-lg-2">
                  สถานะเปิดใช้งาน
                </label>
                <div className="col-lg-10">
                  <label className="switch">
                    <input
                      name="is_active"
                      type="checkbox"
                      onChange={this.handleChange}
                      checked={(this.state.fields["is_active"] === 1) ? true : false}
                    />
                    <div className="slider round" />
                  </label>
                </div>
              </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
        {this.state.modalResetPassword}
        {modalResetPassword}
      </div>
    );
  }
}
