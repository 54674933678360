import React from "react";
import { ewalletAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../topup.css";
import Select from "react-select";

let modalAlert;

export class Topup_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      errors: {},
      fields: {
        settlement_Initiated_by: 'backoffice',
        payment_channel_id: 5,
        approval_code: ''
      },
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let { selectOption } = this.state;

    const {
      match: { params }
    } = this.props;

    let mem_number = params.mem_number
    
    this.setState({
      admin_id: localStorage.getItem('ad_b_id'),
      mem_number: mem_number
    })

    this.getMemberByNumber(mem_number)
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }


  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/ewallet/account/overview"
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["transaction_amount"]) {
      formIsValid = false;
      errors["transaction_amount"] = "*กรุณากรอกจำนวนเงิน";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    var inputBody = ({
      'transaction_amount' : this.state.fields["transaction_amount"],
      'payment_channel_id' : this.state.fields["payment_channel_id"],
      'mem_number' : this.state.fields["mem_number"],
      'approval_code' : this.state.fields["approval_code"],
      'transaction_comment' : this.state.fields["transaction_comment"],
      'settlement_Initiated_by': this.state.fields['settlement_Initiated_by']

    });    
    ewalletAction.createTopup(inputBody).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  getMemberByNumber(mem_number) {    
    let { fields } = this.state;

    ewalletAction.getMemberByNumber(mem_number).then(res => {
      fields['mem_number'] = res.data.mem_number
      fields['ewallet_firstname_th'] = res.data.ewallet_firstname_th
      fields['ewallet_lastname_th'] = res.data.ewallet_lastname_th
      this.setState({ fields });
    })
  }


  render() {
    let { selectedOption } = this.state; 

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">Topup E-Wallet</h4>
          </div>
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
              <div className="form-group">
                <label className="control-label col-md-2">Member Number</label>
                <label className="control-label col-md-3">{this.state.mem_number}</label>
              </div>

                <div className="form-group">
                  <label className="control-label col-md-2">Member Name</label>
                  <label className="control-label col-md-3">
                    {this.state.fields.ewallet_firstname_th} &nbsp;&nbsp; {this.state.fields.ewallet_lastname_th}
                  </label>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">Amount</label>
                  <div className="col-md-5">
                  <input
                      name="transaction_amount"
                      type="number" 
                      pattern="[0-9]*"
                      step="any"
                      className="form-control"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.transaction_amount}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">Comment</label>
                  <div className="col-md-5">
                    <textarea
                      // rows="3"
                      name="transaction_comment"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.transaction_comment}</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
