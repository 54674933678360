import React from "react";
import $ from "jquery";
import { branchAction,orderAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../order.css";
import Select from "react-select";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-preorder-eatin'

var optionStatus = [
  { value: '1', label: 'รอชำระเงิน' },
  { value: '2', label: 'ชำระเงินสำเร็จ' },
  { value: '3', label: 'ยกเลิกการชำระเงิน' },
];

let dataSearch = {
  s_Order: '',
  s_Branch: '',
  s_Date: '',
  s_Status: '',
}

export class PreOrder_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false,
      inputBody: [],
      fields: {},
      branchList: [],
      searchStatus:'',
      searchBranch:'',
    };

  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    branchAction.listBranchAll()
    .then(res => {
        this.setState({
            branchList: res.data
        })
    })

    this.showTable(dataSearch);
    this.exportExcel(dataSearch);
    
  }

  showTable(dataSearch){
    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "od_id"
      },
      {
        title: "เลขที่ออเดอร์",
        class: "text-center",
        data: "od_number"
      },
      {
        title: "สาขา",
        class: "text-center",
        data: "b_name"
      },
      {
        title: "ชื่อลูกค้า",
        class: "text-center",
        data: "mem_firstname",
      },
      {
        title: "ราคารวม",
        class: "text-center",
        data: "sum_price"
      },
      {
        title: "วันที่สร้างรายการ",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "สถานะการชำระเงิน",
        class: "text-center",
        data: "status_payment_name"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "od_number"
      },
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left"><a href="/backoffice/member/edit/${rowData.mem_id}">${cellData} ${rowData.mem_lastname}</a></div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-right">${cellData} ฿</div>`);
          }
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            var status = '';
            if(rowData.status_payment == '1') status = `<span class="badge badge-warning">${cellData}</span>`
            else if(rowData.status_payment == '2') status = `<span class="badge badge-success">${cellData}</span>`
            else if(rowData.status_payment == '3') status = `<span class="badge badge-danger">${cellData}</span>`
            else status = `<span class="badge badge-info">ไม่ระบุสถานะ</span>`
            $(td).html(status);
          },
          orderable: false
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            var bt_manage = '';
            if(rowData.status_payment != '1'){
              bt_manage =
                `
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/preorder/order/info/${cellData}" class="dropdown-item"><i class="icon-clipboard3"></i> ดูรายละเอียด</a>
                      <a class="dropdown-item disabled"><i class="icon-gear"></i> จัดการออเดอร์</a>
                    </div>
                  </div>
                </div>
                `
            }else{
              bt_manage =
                `
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/preorder/order/info/${cellData}" class="dropdown-item"><i class="icon-clipboard3"></i> ดูรายละเอียด</a>
                      <a href="/backoffice/preorder/order/edit/${cellData}" class="dropdown-item"><i class="icon-gear"></i> จัดการออเดอร์</a>
                    </div>
                  </div>
                </div>
                `
            }

            $(td).html(bt_manage);
          },
          orderable: false
        }
      ],

      ajax: {
        url: `${Base_API.order}/list/order?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchStatus=${dataSearch.s_Status}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[5, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

  }


  handleSubmit(e) {
    var table = $('#dataTable').DataTable();
    table.destroy();

    e.preventDefault()
    var dataSearch = {
      s_Status: (this.state.fields["searchStatus"] ? this.state.fields["searchStatus"] : ''),
      s_Branch: (this.state.fields["searchBranch"] ? this.state.fields["searchBranch"] : ''),
      s_Order: (this.state.fields["searchOrder"] ? this.state.fields["searchOrder"] : ''),
      s_Date: (this.state.fields["searchDate"] ? this.state.fields["searchDate"] : '')
    }
    this.showTable(dataSearch);
    this.exportExcel(dataSearch)
    
  }

  handleSearchStatus = (selectedOption) => {
    if(selectedOption != null){
      let { fields } = this.state;
      fields["searchStatus"] = selectedOption.value;
      this.setState({ fields });
    }
  };

  handleSearchBranch = (selectedOption) => {
    if(selectedOption != null){
      let { fields } = this.state;
      fields["searchBranch"] = selectedOption.value;
      this.setState({ fields });
    }
  };

  handleChangeSearch = (e) => {
      let { fields } = this.state;
      fields[e.target.name] = e.target.value;
      this.setState({ fields });
  };

  clearSearch = (e) => {
    let { fields } = this.state;
    fields['searchOrder']='';
    fields['searchBranch']='';
    fields['searchDate']='';
    fields['searchStatus']='';
    
    this.selectRefBranch.select.clearValue();
    this.selectRefStatus.select.clearValue();

    this.setState({ fields });
  };

  exportExcel = (dataSearch) => {
    orderAction.getOrderEatinListExcel(dataSearch)
    .then(res => {
        this.setState({
            dataOrderEatin: res.data
        })
    })
  };

  render() {

    let optionBranch = this.state.branchList.map(function (branchList) {
      return { value: branchList.b_id, label: branchList.b_name };
    })

    var dataOrder = []
    let data = this.state.dataOrderEatin    
    
    for(var i in data){
      dataOrder.push(
        [
          {value: data[i].num},
          {value: data[i].od_number},
          {value: data[i].b_name},
          {value: data[i].mem_firstname + ' ' + data[i].mem_lastname},
          {value: data[i].sum_price},
          {value: data[i].created_at},
          {value: data[i].status_payment_name}
        ],
      )
    }    

    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "เลขที่ออเดอร์", width: {wch: 30}},//char width 
              {title: "สาขา", width: {wch: 30}},
              {title: "ชื่อลูกค้า", width: {wch: 40}},
              {title: "ราคารวม", width: {wch: 20}},
              {title: "วันที่สร้างรายการ", width: {wch: 30}},
              {title: "สถานะการชำระเงิน"},
          ],
          data: dataOrder
          
      }
    ];

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการสั่งซื้อหน้าร้าน (Eat In)</h4>
            <div className="heading-elements">
              <ExcelFile element={
                <button
                type="button"
                name="add_banner_btn"
                data-content=""
                className="btn btn-add mr-2"
                >
                <i className="icon-download"></i> Export Excel
                </button>
              }
              filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Preorder (Eatin)" />
              </ExcelFile>
            </div>
          </div>
          
          <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>

            <div className="row form-search">
              <div className="col-md-2">
                <input
                  type="text"
                  name="searchOrder"
                  placeholder="เลขที่ออเดอร์"
                  onChange={this.handleChangeSearch}
                  className="form-control"
                  value={this.state.fields.searchOrder}
                />
              </div>
              <div className="col-md-2">
                <Select
                  name="searchBranch"
                  placeholder="เลือกสาขา"
                  onChange={this.handleSearchBranch}
                  options={optionBranch}
                  className="Select-menu-outer"
                  ref={ref => {
                    this.selectRefBranch = ref;
                  }}
                />
              </div>
              <div className="col-md-2">
                <input
                  type="date"
                  name="searchDate"
                  placeholder="วันที่สร้างรายการ"
                  onChange={this.handleChangeSearch}
                  className="form-control"
                  value={this.state.fields.searchDate}
                />
              </div>
              <div className="col-md-2">
                <Select
                  name="searchStatus"
                  placeholder="เลือกสถานะออเดอร์"
                  onChange={this.handleSearchStatus}
                  options={optionStatus}
                  className="Select-menu-outer"
                  ref={ref => {
                    this.selectRefStatus = ref;
                  }}
                />
              </div>

              <div className="col-lg-2 text-left">
                <input
                  type="submit"
                  className="btn btn-add btn-md btn-banner-create mr-3"
                  value="ค้นหา"
                />
               
                <input
                  type="button"
                  className="btn btn-clear btn-md"
                  onClick={this.clearSearch}
                  value="เคลียร์ข้อมูลค้นหา"
                />
              </div>
            </div>

            {/*
            <div className="col-lg-6 text-right">
              <a href={`../create/${this.state.branch_id}`}>
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add"
                >
                  เพิ่มวันที่รับสินค้า
                </button>
              </a>
            </div>
            */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table id="dataTable" className="table table-hover" ref="main" />
              </div>
            </div>
          </div>
        </form>
      </div>
      {this.state.modal}
    </div>
    );
  }
}
