import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const adminAction = {
  createAdmin,
  updateAdmin,
  ActiveAdmin,
  getAdminId,
  deleteAdminById,
  resetPasswordAdmin,

  listLevelAdmin,
  getLevelById,
  createLevel,
  updateLevel,
  activateLevel,

  checkLogin,

  getCalc,
  getTop10,
};

function listLevelAdmin() {
  return axios.get(`${Base_API.admin}/level`, "").then(res => {
    return res.data;
  });
}

function getAdminId(id) {
  return axios.get(`${Base_API.admin}/users/${id}`, "").then(res => {
    return res.data;
  });
}

function ActiveAdmin(formData, id) {
  return axios.put(`${Base_API.admin}/users/${id}`, formData).then(res => {
    return res.data;
  });
}

function updateAdmin(formData, id) {
  return axios.put(`${Base_API.admin}/users/${id}`, formData).then(res => {
    return res.data;
  });
}

function resetPasswordAdmin(formData, id) {
  return axios.put(`${Base_API.admin}/users/resetpassword/${id}`, formData).then(res => {
    return res.data;
  });
}

function createAdmin(formData) {
  return axios.post(`${Base_API.admin}/users`, formData).then(res => {
    return res.data;
  });
}

function deleteAdminById(id) {
  return axios.delete(`${Base_API.admin}/users/${id}`, "").then(res => {
    return res.data;
  });
}

function getLevelById(id) {
  return axios.get(`${Base_API.admin}/level/${id}`, "").then(res => {
    return res.data;
  });
}

function createLevel(formData) {
  return axios.post(`${Base_API.admin}/level`, formData).then(res => {
    return res.data;
  });
}

function updateLevel(formData, id) {
  return axios.put(`${Base_API.admin}/level/${id}`, formData).then(res => {
    return res.data;
  });
}

function activateLevel(formData, id) {
  return axios.put(`${Base_API.admin}/activeLevel/${id}`, formData).then(res => {
    return res.data;
  });
}

function checkLogin(inputData) {
  return axios.post(`${Base_API.admin}/api/v1/admin/checkUser`, inputData).then(res => {
    return res.data;
  });
}

function getCalc() {
  return axios.get(`${Base_API.admin}/api/v1/deshbored`, "").then(res => {
    return res.data;
  });
}

function getTop10(datenow) {
  return axios.get(`${Base_API.admin}/api/v1/deshbored/topAll/${datenow}`, "").then(res => {
    return res.data;
  });
}
