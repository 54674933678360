import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const rewardAction = {
    getRewardCategoryList,
    createRewardCategory,
    activateRewardCategory,
    deleteRewardCategory,
    getRewardCategoryDataByID,
    updateRewardCategory,

    listReward,
    createReward,
    deleteReward,
    getRewardDataByID,
    updateReward,
    activateReward,

    listBurnHistory,
    deleteBurnHistory,
    getLogsHistoryByID,
    activeLogsHistoryByID,
    updateTransaction,

    getRewardStaffByID,
    updateRewardStaffByID,
    getRedeemTypeLists
};

function getRedeemTypeLists() {
  return axios.get(`${Base_API.backoffice}/api/redeem-types`).then(res => {
    return res.data;
  });
}

function getRewardCategoryList() {
  return axios.get(`${Base_API.reward}/reward_category`).then(res => {
    return res.data;
  });
}

function createRewardCategory(FormData) {
  return axios.post(`${Base_API.reward}/reward_category`, FormData).then(res => {
    return res.data;
  });
}

function activateRewardCategory(id, FormData) {
  return axios.put(`${Base_API.reward}/reward_category/${id}`, FormData).then(res => {
    return res.data;
  });
}

function deleteRewardCategory(id) {
  return axios.delete(`${Base_API.reward}/reward_category/${id}`).then(res => {
    return res.data;
  });
}

function getRewardCategoryDataByID(id) {
  return axios.get(`${Base_API.reward}/reward_category/${id}`).then(res => {
    return res.data;
  });
}

function deleteReward(id) {
  return axios.delete(`${Base_API.reward}/reward/${id}`).then(res => {
    return res.data;
  });
}

function listReward() {
  return axios.get(`${Base_API.reward}/reward`).then(res => {
    return res.data;
  });
}

function createReward(FormData) {
  return axios.post(`${Base_API.reward}/reward`, FormData).then(res => {
    return res.data;
  });
}

function getRewardDataByID(id) {
  return axios.get(`${Base_API.reward}/reward/${id}`).then(res => {
    return res.data;
  });
}

function updateReward(FormData, id) {
  return axios.put(`${Base_API.reward}/reward/${id}`, FormData).then(res => {
    return res.data;
  });
}

function activateReward(FormData, id) {
  return axios.put(`${Base_API.reward}/reward/${id}`, FormData).then(res => {
    return res.data;
  });
}

function updateRewardCategory(FormData, id) {
  return axios.put(`${Base_API.reward}/reward_category/${id}`, FormData).then(res => {
    return res.data;
  });
}

function listBurnHistory(dataSearch) {
  return axios.get(`${Base_API.reward}/burn?searchFirstName=${dataSearch.s_FirstName}&searchLastName=${dataSearch.s_LastName}&searchPhone=${dataSearch.s_Phone}&searchRewardsCode=${dataSearch.s_RewardsCode}&searchRewardsName=${dataSearch.s_RewardsName}&searchRedeemDate=${dataSearch.s_RedeemDate}&searchRedeemDateTo=${dataSearch.s_RedeemDateTo}&searchBurnDate=${dataSearch.s_BurnDate}&searchBurnDateTo=${dataSearch.s_BurnDateTo}&searchExpiredDate=${dataSearch.s_ExpiredDate}&searchExpiredDateTo=${dataSearch.s_ExpiredDateTo}`).then(res => {
    return res.data;
  });
}

function getLogsHistoryByID(id) {
  return axios.get(`${Base_API.reward}/burn/${id}`).then(res => {
    return res.data;
  });
}

function activeLogsHistoryByID(FormData, id) {
  return axios.put(`${Base_API.reward}/burn/${id}`, FormData).then(res => {
    return res.data;
  });
}

function deleteBurnHistory(id) {
  return axios.delete(`${Base_API.reward}/burn/${id}`).then(res => {
    return res.data;
  });
}

function updateTransaction(FormData, id) {;
  return axios.put(`${Base_API.reward}/api/transaction/${id}`, FormData).then(res => {
    return res.data;
  });
}

function getRewardStaffByID(id) {
  return axios.get(`${Base_API.reward}/api/reward_staff/get/${id}`).then(res => {
    return res.data;
  });
}

function updateRewardStaffByID(id, data) {
  return axios.put(`${Base_API.reward}/api/reward_staff/update/${id}`, data).then(res => {
    return res.data;
  });
}

