import React from "react";
import { contactAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../contact.css";

let modalAlert;

export class Contact_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      isStatus: '',
      status: '',

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getContactByID(params.contact_id);
  }

  getContactByID(contact_id) {

    contactAction.getContactByID(contact_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            contact_title_th: e.data.contact_title_th,
            contact_title_en: e.data.contact_title_en,
            contact_address_th: e.data.contact_address_th,
            contact_address_en: e.data.contact_address_en,
            contact_code_phone: e.data.contact_code_phone,
            contact_phone: e.data.contact_phone,
            contact_extend_phone: e.data.contact_extend_phone,
            contact_code_fax: e.data.contact_code_fax,
            contact_fax: e.data.contact_fax,
            contact_extend_phone_fax: e.data.contact_extend_phone_fax,
            contact_email: e.data.contact_email,
            is_active: e.data.is_active,
            contact_id: contact_id
          }
        });

        this.setState({isStatus: e.data.is_active});
        this.setState({status: e.data.is_active});


      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/contact";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["contact_title_th"]) {
      formIsValid = false;
      errors["contact_title_th"] = "*กรุณากรอกชื่อฝ่าย (TH)";
    }

    if (!fields["contact_title_en"]) {
      formIsValid = false;
      errors["contact_title_en"] = "*กรุณากรอกชื่อฝ่าย (EN)";
    }

    if (!fields["contact_phone"]) {
      formIsValid = false;
      errors["contact_phone"] = "*กรุณากรอกเบอร์โทรศัพท์";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var inputBody = ({
      'contact_title_th' : this.state.fields["contact_title_th"],
      'contact_title_en' : this.state.fields["contact_title_en"],
      'contact_address_th' : this.state.fields["contact_address_th"],
      'contact_address_en' : this.state.fields["contact_address_en"],
      'contact_code_phone' : this.state.fields["contact_code_phone"],
      'contact_phone' : this.state.fields["contact_phone"],
      'contact_extend_phone' : this.state.fields["contact_extend_phone"],
      'contact_code_fax' : this.state.fields["contact_code_fax"],
      'contact_fax' : this.state.fields["contact_fax"],
      'contact_extend_phone_fax' : this.state.fields["contact_extend_phone_fax"],
      'contact_email' : this.state.fields["contact_email"],
      'is_active' : this.state.fields["is_active"],
    });

    contactAction.updateContact(inputBody, this.state.fields["contact_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active") {
      fields[e.target.name] = e.target.checked === true ? 1 : 2;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขข้อมูลติดต่อ</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
            <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-md-2">ชื่อฝ่าย (TH)</label>
                  <div className="col-md-5">
                  <input
                      name="contact_title_th"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อฝ่าย"
                      value={this.state.fields['contact_title_th'] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.contact_title_th}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">ชื่อฝ่าย (EN)</label>
                  <div className="col-md-5">
                  <input
                      name="contact_title_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อฝ่าย"
                      value={this.state.fields['contact_title_en'] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.contact_title_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">ที่อยู่ (TH)</label>
                  <div className="col-md-5">
                    <textarea
                      name="contact_address_th"
                      className="form-control"
                      placeholder="กรอกที่อยู่ (TH)"
                      value={this.state.fields['contact_address_th'] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.contact_address_th}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">ที่อยู่ (EN)</label>
                  <div className="col-md-5">
                    <textarea
                      name="contact_address_en"
                      className="form-control"
                      placeholder="กรอกที่อยู่ (EN)"
                      value={this.state.fields['contact_address_en'] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.contact_address_en}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">เบอร์โทรศัพท์</label>
                  <div className="col-md-5 form-inline">
                  <input
                      name="contact_code_phone"
                      type="text"
                      className="form-control mr-2"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      value={this.state.fields['contact_code_phone']}
                      onChange={this.handleChange}
                      readOnly
                      style={{width: '70px'}}
                    />                  
                  
                  <input
                      name="contact_phone"
                      type="text"
                      className="form-control mr-2"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      value={this.state.fields['contact_phone'] || ""}
                      onChange={this.handleChange}
                      style={{width: '30%'}}

                    />

                    <span className="mr-2">ต่อ</span> 

                    <input
                      name="contact_extend_phone"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเบอร์ภายใน"
                      value={this.state.fields['contact_extend_phone'] || ""}
                      onChange={this.handleChange}
                      style={{width: '120px'}}
                    />

                    <div className="errorMsg">{this.state.errors.contact_phone}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">เบอร์โทรศัพท์ (Fax.)</label>
                  <div className="col-md-5 form-inline">
                  <input
                      name="contact_code_fax"
                      type="text"
                      className="form-control mr-2"
                      placeholder="ex. +66"
                      value={this.state.fields['contact_code_fax'] || ""}
                      onChange={this.handleChange}
                      style={{width: '70px'}}
                    />                  
                  
                  <input
                      name="contact_fax"
                      type="text"
                      className="form-control mr-2"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      onChange={this.handleChange}
                      value={this.state.fields['contact_fax'] || ""}
                      style={{width: '30%'}}

                    />

                    <span className="mr-2">ต่อ</span> 

                    <input
                      name="contact_extend_phone_fax"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเบอร์ภายใน"
                      value={this.state.fields['contact_extend_phone_fax'] || ""}
                      onChange={this.handleChange}
                      style={{width: '120px'}}

                    />

                    <div className="errorMsg">{this.state.errors.contact_fax}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">E-mail</label>
                  <div className="col-md-5">
                  <input
                      name="contact_email"
                      type="email"
                      className="form-control"
                      placeholder="กรอก e-mail"
                      value={this.state.fields['contact_email'] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.contact_email}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">สถานะใช้งาน</label>
                  <div className="col-md-5">
                    <label className="switch">
                      <input
                        name="is_active"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.fields["is_active"] === 1) ? true : false}

                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
