import React from 'react'
import $ from 'jquery'
import './style.css'

export const _OverlayLoadingSwitch = status => {
    if(status)
    {
        $('.ovl-loading-con')
        .css("display", "flex")
        .hide()
        .fadeIn();
    }else
    {
        $('.ovl-loading-con').fadeOut();
    }
}

function OverlayLoading() {
  return (
    <div className='ovl-loading-con'>
      <img src={`${process.env.PUBLIC_URL}/images/logo.png`} />
    </div>
  )
}

export default OverlayLoading