import React from "react";
import { rewardAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import moment from "moment-timezone"
import "../../reward.css";

let modalAlert;
let checkbox_reward_type_receive = []
var checkbox_rewards_showtype = []


export class Reward_Staff_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      inputBody: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmEdit = this.onConfirmEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let { selectOption } = this.state;
    var params = this.props.match.params
    console.log(params);
    this.setState({ staff_reward_id: params.staff_reward_id })
    this.getRewardStaffByID(params.staff_reward_id)
  }

  getRewardStaffByID(id) {
    console.log(id);
    var { 
      inputBody 
    } = this.state;
    rewardAction.getRewardStaffByID(id).then(e => {
      if(e.isSuccess === true) {
          var resp = e.data['0']
            inputBody = resp      
            this.setState({ 
              inputBody: inputBody
            })
            

        } else {
            this.onModalError('ผิดพลาด', e.message)
        }
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmEdit()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = `/backoffice/reward/staff/list/${this.state.inputBody["staff_idcard"]}`;
  }

  validateForm() {
    let inputBody = this.state.inputBody;
    let errors = {};
    let formIsValid = true;

    if (!inputBody["reward_count"]) {
      formIsValid = false;
      errors["reward_count"] = "*กรุณากรอกจำนวนรีวอร์ดที่แลกได้";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmEdit(ev) {
    this.setState({ modal: null });
    let data = {
      reward_count: this.state.inputBody["reward_count"]
    }

    rewardAction.updateRewardStaffByID(this.state.staff_reward_id, data).then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { inputBody, errors } = this.state;
    inputBody[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ inputBody, errors });
  };

  render() {    
    return (
      <div className="reward-container">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขรีวอร์ดสำหรับพนักงาน</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-3">ชื่อรางวัล (TH)</label>
                  <div className="col-lg-9">
                   ({this.state.inputBody["rewards_code"]}) {this.state.inputBody["rewards_name_th"]}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-3">จำนวนจำกัดการแลก</label>
                  <div className="col-lg-3">
                    <input
                      name="reward_count"
                      type="number"
                      className="form-control"
                      placeholder="กรอกจำนวนจำกัดการแลก"
                      onChange={this.handleChange}
                      value={this.state.inputBody["reward_count"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["reward_count"]}
                    </div>
                  </div>
                </div>
              
                {/* <div className="form-group">
                  <label className="control-label col-lg-3">
                    สถานะเปิดใช้งาน
                  </label>
                  <div className="col-lg-9">
                    <label className="switch">
                      <input
                        name="is_open"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.inputBody["is_open"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div> */}

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
