import React from "react";
import { withRouter } from 'react-router-dom';
import { LoginLayout, NormalLayout } from "./_layout";

class App extends React.Component {
  render() {
    var layoutPage;
    var login = localStorage.getItem("login")
    if (login === 'true') {
      layoutPage = <NormalLayout></NormalLayout>
    } else {
      layoutPage = <LoginLayout></LoginLayout>
    }
    return (
      <div className="my-app">
        {layoutPage}
      </div>
    );
  }
}

export default withRouter(App);
