import React from "react";
import $ from "jquery";
import { branchAction,orderAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../order.css";
import Select from "react-select";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) { dd='0'+dd; } 
if(mm<10) { mm='0'+mm; }

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-order-online'

var optionStatus = [
  { value: '1', label: 'รอชำระเงิน' },
  { value: '2', label: 'ชำระเงินสำเร็จ' },
  { value: '3', label: 'ยกเลิกการชำระเงิน' },
  { value: '9', label: 'รอตรวจสอบการชำระเงิน' },
  { value: '10', label: 'ชำระเงินไม่สำเร็จ' },
];
var optionType = [
  { value: 'ems', label: 'EMS' },
  { value: 'delivery', label: 'Delivery' },
  { value: 'pickup', label: 'Order & Pickup' },
  { value: 'snackbox', label: 'Snackbox' },
];

let dataSearch = {
  s_Order: '',
  s_Branch: '',
  s_Date: '',
  s_DateTo: '',
  s_Status: '',
  s_ReceiveChannel: '',
}

export class Order_Online_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false,
      inputBody: [],
      fields: {},
      errors: {},
      branchList: [],
      searchStatus:'',
      searchBranch:'',
      
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    branchAction.listBranchAll()
    .then(res => {
        this.setState({
            branchList: res.data
        })
    })

    this.showTable(dataSearch);
    this.exportExcel(dataSearch);
  }

  showTable(dataSearch){
    const columns = [
        {
          title: "ลำดับ",
          class: "text-center",
          data: "updated_at"
        },
        {
          title: "วันที่สั่งออเดอร์",
          class: "text-center",
          data: "created_at"
        },
        {
          title: "เลขที่ออเดอร์",
          class: "text-center",
          data: "od_online_number"
        },
        {
          title: "ประเภทการรับสินค้า",
          class: "text-center",
          data: "receive_channel"
        },
        {
          title: "สาขา",
          class: "text-center",
          data: "b_name"
        },
        {
          title: "ชื่อลูกค้า",
          class: "text-center",
          data: "mem_firstname",
        },
        {
          title: "เบอร์โทรศัพท์",
          class: "text-center",
          data: "mem_phone",
        },
        {
          title: "เลขบัตรประชาชน",
          class: "text-center",
          data: "mem_idcard",
        },
        {
          title: "ราคารวม",
          class: "text-center",
          data: "sum_price"
        },
        {
          title: "สถานะการชำระเงิน",
          class: "text-center",
          data: "payment_name"
        },
        {
          title: "หมายเหตุ",
          class: "text-center",
          data: "od_online_comment"
        },
        {
          title: "จัดการ",
          class: "text-center",
          data: "od_online_number"
        },
      ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`${row+1}`);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).html(`<div class="text-left">${cellData}</div>`);
          },
          orderable: false
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left"><a href="/backoffice/member/edit/${rowData.mem_id}">${cellData} ${rowData.mem_lastname}</a></div>`);
          }
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-right">${cellData} ฿</div>`);
          }
        },
        {
          targets: 9,
          createdCell: function(td, cellData, rowData, row, col) {
            var status = '';
            if((rowData.status_payment == '1') && (rowData.status_receive == '8'|| rowData.status_receive == '10'|| rowData.status_receive == '11')) status = `<span class="badge badge-danger">${rowData.status_receive_name}</span>`
            else if(rowData.status_payment == '1') status = `<span class="badge badge-warning">${cellData}</span>`
            else if(rowData.status_payment == '2' && (rowData.status_receive == '8' || rowData.status_receive == '10'|| rowData.status_receive == '11')) status = `<span class="badge badge-danger">${rowData.status_receive_name}</span>`
            else if(rowData.status_payment == '2') status = `<span class="badge badge-success">${cellData}</span>`
            else if(rowData.status_payment == '3') status = `<span class="badge badge-danger">${cellData}</span>`
            else if(rowData.status_payment == '9') status = `<span class="badge badge-warning">${cellData}</span>`
            else status = `<span class="badge badge-default">${cellData}</span>`
            $(td).html(status);
          },
          orderable: false
        },
        {
          targets: 11,
          createdCell: function(td, cellData, rowData, row, col) {
            var bt_manage = '';
            if(rowData.status_payment != '1' && rowData.status_payment != '9'){
              bt_manage =
                ` 
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/order-online/order/info/${cellData}" class="dropdown-item"><i class="icon-file-eye2 mr-3 mb-1"></i> ดูรายละเอียด</a>
                      <a href="/backoffice/order-online/order/edit/${cellData}" class="dropdown-item"><i class="icon-cogs mr-3 mb-1"></i> จัดการออเดอร์</a>
                  </div>
                  </div>
                </div>`
            }else{
              bt_manage =
                `
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/order-online/order/info/${cellData}" class="dropdown-item"><i class="icon-file-eye2 mr-3 mb-1"></i> ดูรายละเอียด</a>
                      <a href="/backoffice/order-online/order/edit/${cellData}" class="dropdown-item"><i class="icon-cogs mr-3 mb-1"></i> จัดการออเดอร์</a>
                    </div>
                  </div>
                </div>
                
                  `
            }

            $(td).html(bt_manage);
          },
          orderable: false
        }
      ],

      ajax: {
        url: `${Base_API.order_online}/order-online?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchDateTo=${dataSearch.s_DateTo}&searchStatus=${dataSearch.s_Status}&searchReceiveChannel=${dataSearch.s_ReceiveChannel}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[1, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

  }

  handleSubmit(e) {
    var table = $('#dataTable').DataTable();
    table.destroy();

    e.preventDefault()

    if (this.validateForm()) {
      var dataSearch = {
        s_Status: (this.state.fields["searchStatus"] ? this.state.fields["searchStatus"] : ''),
        s_Branch: (this.state.fields["searchBranch"] ? this.state.fields["searchBranch"] : ''),
        s_Order: (this.state.fields["searchOrder"] ? this.state.fields["searchOrder"] : ''),
        s_Date: (this.state.fields["searchDate"] ? this.state.fields["searchDate"] : ''),
        s_DateTo: (this.state.fields["searchDateTo"] ? this.state.fields["searchDateTo"] : ''),
        s_ReceiveChannel: (this.state.fields["searchReceiveChannel"] ? this.state.fields["searchReceiveChannel"] : '')
    }

      this.showTable(dataSearch);
      this.exportExcel(dataSearch)
    }



  }

  clearSearch = (e) => {
    let { fields } = this.state;
    fields['searchStatus']='';
    fields['searchBranch']='';
    fields['searchOrder']='';
    fields['searchDate']='';
    fields['searchDateTo']='';
    fields['searchReceiveChannel']='';
    
    this.selectRefBranch.select.clearValue();
    this.selectRefStatus.select.clearValue();
    this.selectRefReceiveChannel.select.clearValue();

    this.setState({ fields });
  };

  handleSearchStatus = (selectedOption) => {
    if(selectedOption != null){
      let { fields } = this.state;
      fields["searchStatus"] = selectedOption.value;
      this.setState({ fields });
    }
  };

  handleSearchBranch = (selectedOption) => {
    if(selectedOption != null){
      let { fields } = this.state;
      fields["searchBranch"] = selectedOption.value;
      this.setState({ fields });
    }
  };
  handlesearchReceiveChannel = (selectedOption) => {
    if(selectedOption != null){
      let { fields } = this.state;
      fields["searchReceiveChannel"] = selectedOption.value;
      this.setState({ fields });
    }
  };

  handleChangeSearch = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };
  
  exportExcel = (dataSearch) => {
    orderAction.getOrderOnlineListExcel(dataSearch).then(res => {
      this.setState({
          dataOrder: res.data
      })
    })
  };

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (fields["searchDate"] && !fields["searchDateTo"]) {
      formIsValid = false;
      errors["searchDateTo"] = "*กรุณากรอกวันที่สร้างออเดอร์";
    }

    if (!fields["searchDate"] && fields["searchDateTo"]) {
      formIsValid = false;
      errors["searchDate"] = "*กรุณากรอกวันที่สร้างออเดอร์";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  render() {
    let optionBranch = this.state.branchList.map(function (branchList) {
      return { value: branchList.b_id, label: branchList.b_name };
    })
    var dataOrder = []
    let data = this.state.dataOrder  
    var status = ""
    for(var i in data){
      if((data[i].status_payment == '1') && (data[i].status_receive == '8'|| data[i].status_receive == '10'|| data[i].status_receive == '11')) status = `${data[i].status_receive_name}`
      else if(data[i].status_payment == '1') status = `${data[i].payment_name}`
      else if(data[i].status_payment == '2' && (data[i].status_receive == '8' || data[i].status_receive == '10'|| data[i].status_receive == '11')) status = `${data[i].status_receive_name}`
      else if(data[i].status_payment == '2') status = `${data[i].payment_name}`
      else if(data[i].status_payment == '3') status = `${data[i].payment_name}`
      else if(data[i].status_payment == '9') status = `${data[i].payment_name}`
      else status = `${data[i].payment_name}`
      dataOrder.push(
        [
          {value: parseInt(i)+1},
          {value: data[i].created_at},
          {value: data[i].od_online_number},
          {value: data[i].receive_channel},
          {value: (data[i].b_name != "" && data[i].b_name != null && data[i].b_name != undefined ? data[i].b_name : '')},
          {value: data[i].mem_firstname + ' ' + data[i].mem_lastname},
          {value: data[i].mem_phone},
          {value: (data[i].mem_idcard != "" && data[i].mem_idcard != null && data[i].mem_idcard != undefined ? data[i].mem_idcard : '')},
          {value: data[i].sum_price},
          {value: status},
          {value: data[i].od_online_comment}
        ],
        
      )
    }    

    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "วันที่สั่งออเดอร์", width: {wch: 30}},//char width 
              {title: "เลขที่ออเดอร์", width: {wch: 30}},//char width 
              {title: "ประเภทการรับสินค้า", width: {wch: 30}},//char width 
              {title: "สาขา", width: {wch: 40}},
              {title: "ชื่อลูกค้า", width: {wch: 40}},
              {title: "เบอร์โทรศัพท์", width: {wch: 40}},
              {title: "เลขที่บัตรประชาชน", width: {wch: 40}},
              {title: "ราคารวม", width: {wch: 20}},
              {title: "สถานะการชำระเงิน"},
              {title: "หมายเหตุ"},
          ],
          data: dataOrder
          
      }
    ];

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการออเดอร์ (Order Online)</h4>

            <div className="heading-elements">
              <ExcelFile element={
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                <i className="icon-download"></i> Export Excel
                </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Order Online" />
              </ExcelFile>
            </div>
          </div>
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="panel-body panel-color-gray px-5 py-3">
                <div className="pl-3">
                  <div className="row my-2">
                    <div className="col-md-3">เลขที่ออเดอร์</div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="searchOrder"
                        placeholder="เลขที่ออเดอร์"
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchOrder']}
                      />
                    </div>
                    <div className="col-md-3">ประเภทการรับสินค้า</div>
                    <div className="col-md-3">
                      <Select
                        name="searchReceiveChannel"
                        placeholder="เลือกประเภทการรับสินค้า"
                        onChange={this.handlesearchReceiveChannel}
                        options={optionType}
                        className="Select-menu-outer"
                        ref={ref => {
                        this.selectRefReceiveChannel = ref;
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">สาขา</div>
                    <div className="col-md-3">
                      <Select
                        name="searchBranch"
                        placeholder="เลือกสาขา"
                        onChange={this.handleSearchBranch}
                        options={optionBranch}
                        className="Select-menu-outer"
                        ref={ref => {
                        this.selectRefBranch = ref;
                        }}
                      />
                    </div>
                    <div className="col-md-3">สถานะออเดอร์</div>
                    <div className="col-md-3">
                      <Select
                        name="searchStatus"
                        placeholder="เลือกสถานะออเดอร์"
                        onChange={this.handleSearchStatus}
                        options={optionStatus}
                        className="Select-menu-outer"
                        ref={ref => {
                        this.selectRefStatus = ref;
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">วันที่สร้างออเดอร์</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchDate"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchDate']}
                      />
                      <div className="errorMsg">{this.state.errors.searchDate}</div>
                    </div>

                    <div className="col-md-3">ถึง วันที่สร้างออเดอร์</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchDateTo"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchDateTo']}
                      />
                      <div className="errorMsg">{this.state.errors.searchDateTo}</div>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <input
                        type="submit"
                        className="btn btn-add btn-md btn-banner-create mr-3"
                        value="ค้นหา"
                    />
                    <input
                        type="button"
                        className="btn btn-clear btn-md"
                        onClick={this.clearSearch}
                        value="เคลียร์ข้อมูลค้นหา"
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12">
              <div className="table-responsive">
                  <table id="dataTable" className="table table-hover" ref="main" />
              </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
