import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const productAction = {
  updateEatinProduct,
  activateProductEatin,
  getEatinProductId,
  createEatinProduct,
  deleteEatinProduct,
  getCategoryProductListAll,
  getEatinProductList,

  getEatinBranchByProduct,
  getEatinBranchByProductID,
  updateEatinBranchByProductID,

  updatePreProduct,
  getPreProductId,
  createPreProduct,
  deletePreProduct,
  activateProductPre,

  getCategoryProductOnline,

  getProductOnlineById,
  updateProductOnline,
  getProductOnlineList,

  onlineListBranchByProduct,
  onlineGetProductByProductBranchID,
  onlineUpdateProductByProductID,

  updateProductByBranchCode,

  listProductIsactive,
  importExcel,

  createProductAttribute,
  deleteProductAttribute,
  updateProductAttribute,
  getProductAttributeByID,
  getProductAttributeList,
  getProductAttributeUsedByPrCode,
  updateProductAttributeDetail,
  createPreProductV2,
  deletePreProductV2,
  updateProductOnlineV2,
  importProductOnlineBySheet
};

function importProductOnlineBySheet(reqData, setProgress) {
  // Connect to WebSocket server
  const socket = new WebSocket(`${Base_API.product}/api/product/excel/upload`);
  // Update progress when receiving messages from WebSocket
  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.progress) {
      setProgress(data.progress);
    }
  };
  return axios.post(`${Base_API.product}/api/product/excel/upload`, reqData).then(res => {
    return res.data;
  });
}
function createProductAttribute(reqData) {
  return axios.post(`${Base_API.product_v2}/api/product/attribute`, reqData).then(res => {
    return res.data;
  });
}
function deleteProductAttribute(id) {
  return axios.delete(`${Base_API.product_v2}/api/product/attribute/${id}`).then(res => {
    return res.data;
  });
}
function updateProductAttribute(formData, id) {
  return axios.put(`${Base_API.product_v2}/api/product/attribute/${id}`, formData).then(res => {
    return res.data;
  });
}
function updateProductAttributeDetail(reqData, id) {
  return axios.put(`${Base_API.product_v2}/api/product/attribute-detail/${id}`, reqData).then(res => {
    return res.data;
  });
}
function getProductAttributeByID(id) {
  return axios.get(`${Base_API.product_v2}/api/product/attribute/${id}`).then(res => {
    return res.data;
  });
}
function getProductAttributeList() {
  return axios.get(`${Base_API.product_v2}/api/product/attributeset`).then(res => {
    return res.data;
  });
}
function getProductAttributeUsedByPrCode(product_code) {
  return axios.get(`${Base_API.product_v2}/api/product/attributeused/${product_code}`).then(res => {
    return res.data;
  });
}
function createPreProductV2(formData) {
  return axios.post(`${Base_API.product_v2}/v2/product_online`, formData).then(res => {
    return res.data;
  });
}
function deletePreProductV2(pid) {
  return axios.delete(`${Base_API.product_v2}/v2/product_online/${pid}`).then(res => {
    return res.data;
  });
}
function updateProductOnlineV2(formData, pid) {
  console.log(formData)
  return axios.put(`${Base_API.product_v2}/v2/product_online/${pid}`, formData).then(res => {
    return res.data;
  });
}


function getEatinProductId(pid) {
  return axios.get(`${Base_API.product}/product/${pid}`, "").then(res => {
    return res.data;
  });
}

function updateEatinProduct(formData, pid) {
  return axios.put(`${Base_API.product}/product/${pid}`, formData).then(res => {
    return res.data;
  });
}

function activateProductEatin(formData, pid) {
  return axios.put(`${Base_API.product}/product/${pid}`, formData).then(res => {
    return res.data;
  });
}

function createEatinProduct(formData) {
  return axios.post(`${Base_API.product}/product`, formData).then(res => {
    return res.data;
  });
}

function deleteEatinProduct(pid) {
  return axios.delete(`${Base_API.product}/product/${pid}`).then(res => {
    return res.data;
  });
}

function getCategoryProductListAll() {
  return axios.get(`${Base_API.category}/category_all`, "").then(res => {
    return res.data;
   })
}

function getEatinProductList() {
  return axios.get(`${Base_API.product}/product`, "").then(res => {
    return res.data;
  });
}

function getPreProductId(pid) {
  return axios.get(`${Base_API.product}/product_online/${pid}`, "").then(res => {
    return res.data;
  });
}

function updatePreProduct(formData, pid) {
  return axios.put(`${Base_API.product}/product_online/${pid}`, formData).then(res => {
    return res.data;
  });
}

function activateProductPre(formData, pid) {
  return axios.put(`${Base_API.product}/product_online/${pid}`, formData).then(res => {
    return res.data;
  });
}

function createPreProduct(formData) {
  return axios.post(`${Base_API.product}/product_online`, formData).then(res => {
    return res.data;
  });
}

function deletePreProduct(pid) {
  return axios.delete(`${Base_API.product}/product_online/${pid}`).then(res => {
    return res.data;
  });
}

function getEatinBranchByProduct(pr_id,ad_b_status,ad_b_id) {
  return axios.get(`${Base_API.product}/product_by_branch/list_branch/${pr_id}/${ad_b_status}/${ad_b_id}`, "").then(res => {
    return res.data;
  });
}

function getEatinBranchByProductID(pr_branch_id) {
  return axios.get(`${Base_API.product}/product_by_branch/branch/${pr_branch_id}`, "").then(res => {
    return res.data;
  });
}

function updateEatinBranchByProductID(formData,pr_branch_id) {
  return axios.put(`${Base_API.product}/product_by_branch/branch/${pr_branch_id}`, formData).then(res => {
    return res.data;
  });
}

function getCategoryProductOnline() {
  return axios.get(`${Base_API.category}/api/product_online_category`, "").then(res => {
    return res.data;
   })
}

function getProductOnlineList() {
  return axios.get(`${Base_API.product}/product_online_export`, "").then(res => {
    return res.data;
  });
}

function getProductOnlineById(pid) {
  return axios.get(`${Base_API.product}/product_online/${pid}`, "").then(res => {
    return res.data;
  });
}
function updateProductOnline(formData, pid) {
  console.log(formData)
  return axios.put(`${Base_API.product}/product_online/${pid}`, formData).then(res => {
    return res.data;
  });
}

function onlineListBranchByProduct(pr_online_id,ad_b_status,ad_b_id) {
  return axios.get(`${Base_API.product}/product_online_by_branch/list_branch/${pr_online_id}/${ad_b_status}/${ad_b_id}`, "").then(res => {
    return res.data;
  });
}

function onlineGetProductByProductBranchID(pr_online_branch_id) {
  return axios.get(`${Base_API.product}/product_online_by_branch/branch/${pr_online_branch_id}`, "").then(res => {
    return res.data;
  });
}

function onlineUpdateProductByProductID(formData,pr_online_branch_id) {
  return axios.put(`${Base_API.product}/product_online_by_branch/branch/${pr_online_branch_id}`, formData).then(res => {
    return res.data;
  });
}

function updateProductByBranchCode(formData) {
  return axios.put(`${Base_API.product}/product_online/update/branchcode`, formData).then(res => {
    return res.data;
  });
}

function listProductIsactive() {
  return axios.get(`${Base_API.product}/product_online/list/isactive`).then(res => {
    return res.data;
  });
}

function importExcel(inputBody) {
  return axios.post(`${Base_API.product}/api/product/excel/upload`, inputBody).then(res => {
    return res.data;
  });
}
