import React from "react";
import { pointAction } from "../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../point.css";

let modalAlert;

export class Point_Manage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {
        manage_point_id : '1'
      },
      errors: {},
      isStatus: '',
      status: '',

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getManagePoint(this.state.fields.manage_point_id);
  }

  getManagePoint(manage_point_id) {

    pointAction.getManagePoint(manage_point_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            manage_point_cost: e.data[0].manage_point_cost,
            manage_point_number: e.data[0].manage_point_number,
            manage_point_id: e.data[0].manage_point_id,
            updated_at: e.data[0].updated_at,

          }
        });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/point/manage";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["manage_point_cost"]) {
      formIsValid = false;
      errors["manage_point_cost"] = "*กรุณากรอกจำนวนเงินที่ต้องซื้อสินค้า";
    }

    if (!fields["manage_point_number"]) {
      formIsValid = false;
      errors["manage_point_number"] = "*กรุณากรอกจำนวนคะแนนที่ได้รับ";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var inputBody = ({
      'manage_point_cost' : this.state.fields["manage_point_cost"],
      'manage_point_number' : this.state.fields["manage_point_number"],
    });

    pointAction.updateManagePoint(inputBody, this.state.fields["manage_point_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };


  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขข้อมูลการได้รับคะแนน</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="control-label col-md-4">จำนวนเงินที่ต้องซื้อสินค้า (&#3647;)</label>
                      <div className="col-md-7">
                        <input
                          name="manage_point_cost"
                          type="number"
                          className="form-control"
                          placeholder="กรอกจำนวนเงินที่ต้องซื้อสินค้า"
                          value={this.state.fields['manage_point_cost'] || ""}
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{this.state.errors.manage_point_cost}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="control-label col-md-4">จำนวนคะแนนที่ได้รับ (P)</label>
                      <div className="col-md-7">
                        <input
                          name="manage_point_number"
                          type="number"
                          className="form-control"
                          placeholder="กรอกจำนวนคะแนนที่ได้รับ"
                          value={this.state.fields['manage_point_number'] || ""}
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{this.state.errors.manage_point_number}</div>
                      </div>
                    </div>
                  </div>
                </div>

                
                <div className="row">
                  <div className="col-md-2">ปรับปรุงล่าสุด</div>
                  <div className="col-md-10">{this.state.fields['updated_at']}</div>
                </div>
                  

                <div className="form-group">
                  <div className="col-md-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
