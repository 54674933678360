import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import { ewalletAction } from "../../../../_actions";
import "../account.css";
import ReactExport from 'react-data-export';

$.DataTable = require("datatables.net");
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-user-ewallet'

let newDate = new Date()
let month = newDate.getMonth() + 1;
if(month < 10) month = '0'+ month
let year = newDate.getFullYear();


let dataSearch = {
  s_MemberNumber: '-',
  s_Firstname: '-',
  s_Lastname: '-',
  s_Phone: '-',
  s_IDCard: '-',
  s_RegisDate: '-'
}


export class Overview_Ewallet extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fields: {},
      excelUrl: "",
      isExportBtn: true,
      exportBtnLabel: "Export Excel",
      errors: {},
    };
  }

  componentDidMount() {
    this.showTable(dataSearch);
    // this.exportExcel(dataSearch)
  }

  showTable(dataSearch){ 
    const columns = [
      {
        title: "Member Number",
        class: "text-center",
        data: "mem_number"
      },
      {
        title: "Firstname",
        class: "text-center",
        data: "ewallet_firstname_th"
      },
      {
        title: "Lastname",
        class: "text-center",
        data: "ewallet_lastname_th"
      },
      {
        title: "Phone no.",
        class: "text-center",
        data: "mem_phone"
      },
      {
        title: "ID Card",
        class: "text-center",
        data: "mem_idcard"
      },
      {
        title: "Net Amount",
        class: "text-center",
        data: "net_amount"
      },
      {
        title: "Register Date",
        class: "text-center",
        data: "created_at",
      },
      {
        title: "Status",
        class: "text-center",
        data: "ewallet_active"
      },
      {
        title: "Manage",
        class: "text-center",
        data: "mem_number"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(cellData);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).html(`<div class="text-center">${cellData}</div>`);
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).html(`<div class="text-center">${cellData}</div>`);
          }
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            let status=""
            if(cellData==1){
              status = `<span class="label label-success label-status">ปกติ</span>`
            }else{
              status = `<span class="label label-danger label-status">ถูกบล็อค</span>`
            }
            $(td).html(`<div class="text-center">${status}</div>`);
          }
        },
        {
          targets:8,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/ewallet/topup/create/${cellData}" class="dropdown-item"><img src="/../images/icons8-topup-payment-50.png" class="image-icon mr-3 mb-1"></i> Topup</a>
                  </div>
                </div>
              </div>
            `);
          },
          orderable: false
        }        
      ],
      ajax: {
        url: `${Base_API.ewallet}/api/v1/ewallet/account_users?searchRegisDate=${dataSearch.s_RegisDate}&searchMemberNumber=${dataSearch.s_MemberNumber}&searchFirstname=${dataSearch.s_Firstname}&searchLastname=${dataSearch.s_Lastname}&searchPhone=${dataSearch.s_Phone}&searchIDCard=${dataSearch.s_IDCard}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          console.log(data)
          return data;
        }
      },
      order: [[1, "DESC"]],
      columns,
      serverSide: true,
      ordering: false,
      searching: false,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i className="fa fa-spinner fa-spin fa-3x fa-fw"></i><span className="sr-only">Loading..n.</span> '
      },
    });    
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  handleSubmit(e) {
    e.preventDefault()
    if (this.validateForm()) {
      var table = $('#dataTable').DataTable();
      table.destroy();
      var dataSearch = {
          s_MemberNumber: (this.state.fields["searchMemberNumber"] ? this.state.fields["searchMemberNumber"] : ''),
          s_Firstname: (this.state.fields["searchFirstname"] ? this.state.fields["searchFirstname"] : ''),
          s_Lastname: (this.state.fields["searchLastname"] ? this.state.fields["searchLastname"] : ''),
          s_Phone: (this.state.fields["searchPhone"] ? this.state.fields["searchPhone"] : ''),
          s_IDCard: (this.state.fields["searchIDCard"] ? this.state.fields["searchIDCard"] : ''),
          s_RegisDate: (this.state.fields["searchRegisDate"] ? this.state.fields["searchRegisDate"] : ''),
      }

      this.showTable(dataSearch);
      this.exportExcel(dataSearch)
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if(!fields["searchMemberNumber"] && 
    !fields["searchFirstname"] && 
    !fields["searchLastname"] && 
    !fields["searchPhone"] && 
    !fields["searchIDCard"] && 
    !fields["searchRegisDate"]
    ){
      formIsValid = false;
      errors["searchRegisDate"] = "*กรุณากรอกข้อมูลค้นหาอย่างน้อย 1 ช่อง";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }


  handleSubmitAll(e) {
    var table = $('#dataTable').DataTable();
    table.destroy();
    e.preventDefault()
    var dataSearch = {
        s_MemberNumber: '',
        s_Firstname: '',
        s_Lastname: '',
        s_Phone: '',
        s_IDCard: '',
        s_RegisDate: '',
    }
    this.showTable(dataSearch);
    this.exportExcel(dataSearch)
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors['searchRegisDate'] = null;
    this.setState({ fields, errors });
  };

  clearSearch = (e) => {
    var table = $('#dataTable').DataTable();
    table.destroy();
    let { fields } = this.state;
    fields['searchMemberNumber']='-';
    fields['searchFirstname']='-';
    fields['searchLastname']='-';
    fields['searchPhone']='-';
    fields['searchIDCard']='-';
    fields['searchRegisDate']='-';

    let dataSearch = {
      s_MemberNumber: '-',
      s_Firstname: '-',
      s_Lastname: '-',
      s_Phone: '-',
      s_IDCard: '-',
      s_RegisDate: '-'
    }
    this.showTable(dataSearch);

    this.setState({ fields });

  };


  exportExcel = (dataSearch) => {
    console.log(dataSearch);
    ewalletAction.getAccountEWallet(dataSearch)
    .then(res => {
      console.log(res);
        this.setState({
            dataTransSearch: res.data
        })
    })
  };


  handleExportEwalletAccounts = () => {
    this.setState({
      isExportBtn: false,
      exportBtnLabel: "Loading..." 
    })

    ewalletAction.exportExcelEwalletAccounts()
    .then((res) => {
      console.log('export ewallet accounts: ', res);
      if(res.status === true)
      {
        this.setState({
          excelUrl: res.data,
          isExportBtn: true,
          exportBtnLabel: "Export Excel" 
        })
        window.location.href = res.data;
      }
    })
  }

  render() {

    var dataExcel = []
    let data = this.state.dataTransSearch    
    let ewallet_active = ""
    for(var i in data){
      if(data[i].ewallet_active == 1){ewallet_active = "ปกติ" }
      else {ewallet_active = "ปิดการใช้งาน" }

      dataExcel.push(
        [
          {value: data[i].mem_number},
          {value: data[i].ewallet_firstname_th},
          {value: data[i].ewallet_lastname_th},
          {value: data[i].mem_phone},
          {value: data[i].mem_idcard},
          {value: data[i].net_amount},
          {value: data[i].created_at},
          {value: ewallet_active}
        ],
      )
    }  
    
    console.log(dataExcel);
    
    multiDataSet = [
      {
        columns: [
            {title: "Member Number", width: {wch: 20}},//pixels width 
            {title: "Firstname", width: {wch: 20}},//char width 
            {title: "Lastname", width: {wch: 20}},//char width 
            {title: "Phone no.", width: {wch: 20}},//char width 
            {title: "ID Card", width: {wch: 20}},//char width 
            {title: "Net Amount", width: {wch: 10}},//char width 
            {title: "Register Date", width: {wch: 20}},//char width 
            {title: "Status	", width: {wch: 10}},
        ],
        data: dataExcel
      }
    ];

    return (
      <div>
        <ol className="breadcrumb">
          <li>E-Wallet</li>
          <li>บัญช</li>
          <li className="active">Overview</li>
        </ol>
        

        <ul className="nav nav-tabs">
          <li role="Overview" className="active"><a href="#">Overview</a></li>
          {/* <li role="Withdraw"><a href="/backoffice/ewallet/account/withdraw">Withdraw</a></li> */}
          {/* <li role="History"><a href="/backoffice/ewallet/account/history">History</a></li> */}
        </ul>
        <div className="panel panel-flat">
          <div className="panel-body">
            <form className="form-horizontal">
              <div>
                <h4>Merchant Account Overview</h4>
                
                <div className="panel-body panel-color-gray px-5 py-3">
                
                  <div className="pl-3">
                    <div className="row my-2">
                      <div className="col-md-3">Member Number</div>
                      <div className="col-md-3">
                        <input 
                          type="text" 
                          className="form-control" 
                          name="searchMemberNumber"
                          placeholder="" 
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-3">Firstname</div>
                      <div className="col-md-3">
                        <input 
                          type="text" 
                          className="form-control" 
                          name="searchFirstname"
                          placeholder="" 
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-3">Lastname</div>
                      <div className="col-md-3">
                        <input 
                          type="text" 
                          className="form-control" 
                          name="searchLastname"
                          placeholder="" 
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-3">Phone no.</div>
                      <div className="col-md-3">
                        <input 
                          type="text" 
                          className="form-control" 
                          name="searchPhone"
                          placeholder="" 
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-3">ID Card</div>
                      <div className="col-md-3">
                        <input 
                          type="text" 
                          className="form-control" 
                          name="searchIDCard"
                          placeholder="" 
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-3">Register Date</div>
                      <div className="col-md-3">
                        <input 
                          type="date" 
                          className="form-control" 
                          name="searchRegisDate"
                          placeholder="" 
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{this.state.errors.searchRegisDate}</div>

                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <div className="">
                        <input type="button" className="btn btn-info" value="Show Statement" onClick={(e) => this.handleSubmit(e)}/>&nbsp;&nbsp;
                        <input type="button" className="btn btn-success" value="Show All" onClick={(e) => this.handleSubmitAll(e)}/>&nbsp;&nbsp;
                        {/* <input type="button" className="btn btn-info" value="Clear" onClick={(e) => this.clearSearch(e)}/>&nbsp;&nbsp; */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="text-right my-3">
              {/* {this.state.excelUrl !== ""
                ? 
                  <a href={this.state.excelUrl}>
                    <button
                        type="button"
                        name="add_banner_btn"
                        data-content=""
                        className="btn btn-add mr-2"
                      >
                        <i className="icon-download"></i> Export Excel
                    </button>
                  </a>
                :
                  <button
                      type="button"
                      name="add_banner_btn"
                      data-content=""
                      className="btn mr-2 disabled"
                      style={{ cursor: 'not-allowed' }}
                    >
                      <i className="icon-download"></i> Export Excel
                  </button>
              } */}
              {this.state.isExportBtn ?
                <button
                    type="button"
                    name="add_banner_btn"
                    data-content=""
                    className="btn btn-add mr-2"
                    onClick={this.handleExportEwalletAccounts}
                  >
                    <i className="icon-download"></i> {this.state.exportBtnLabel}
                </button>
                : 
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn mr-2 disabled"
                  style={{ cursor: 'not-allowed' }}
                >
                  <i className="icon-download"></i> {this.state.exportBtnLabel}
              </button>
              }
                {/* <ExcelFile 
                  element={
                    <button
                    type="button"
                    name="add_banner_btn"
                    data-content=""
                    className="btn btn-add mr-2"
                    >
                    <i className="icon-download"></i> Export Excel
                    </button>
                  }
                  filename = {name_excel}
                >
                  <ExcelSheet dataSet={multiDataSet} name="บัญชี E-wallet" />
                </ExcelFile> */}
              </div>
            <div className="table-responsive">
              <table id="dataTable" className="table table-hover" ref="main" />
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
