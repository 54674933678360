import React from "react";
import { redeemAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../reward.css";

let modalAlert;

export class Redeem_Category_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      typeProgram: null,
      selectType: null,
      inputBody: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการเพิ่มข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/redeem/category";
  }

  validateForm() {
    let inputBody = this.state.inputBody;
    let errors = {};
    let formIsValid = true;

    if (!inputBody["redeem_category_name_th"]) {
      formIsValid = false;
      errors["redeem_category_name_th"] = "*กรุณากรอกชื่อหมวดหมู่รายการรับแต้ม ภาษาไทย";
    }

    if (!inputBody["redeem_category_name_en"]) {
      formIsValid = false;
      errors["redeem_category_name_en"] = "*กรุณากรอกชื่อหมวดหมู่รายการรับแต้ม ภาษาอังกฤษ";
    }


    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });
    var formData = new FormData();
    formData.append("redeem_category_name_th",this.state.inputBody["redeem_category_name_th"]);
    formData.append("redeem_category_name_en",this.state.inputBody["redeem_category_name_en"]);
    formData.append("redeem_category_subtitle_th","-");
    formData.append("redeem_category_subtitle_en","-");
    formData.append("is_open",this.state.inputBody["is_open"] ? this.state.inputBody["is_open"] : 2);
    formData.append("user_id", 1);

    redeemAction.createRedeemCategory(formData).then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { inputBody, errors } = this.state;
    if (e.target.name === "is_open") {
      inputBody[e.target.name] = e.target.checked === true ? 1 : 2;
    } else {
      inputBody[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ inputBody, errors });
  };

  onChangeUploadHandler = e => {
    let { inputBody, errors } = this.state;
    inputBody[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      inputBody,
      errors
    });
  };

  render() {
    return (
      <div className="reward-container">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มหมวดหมู่รายการรับแต้ม</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    ชื่อหมวดหมู่รายการรับแต้ม (TH)
                  </label>
                  <div className="col-lg-10">
                    <input
                      name="redeem_category_name_th"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อหมวดหมู่รายการรับแต้ม (TH)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors["redeem_category_name_th"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    ชื่อหมวดหมู่รายการรับแต้ม (EN)
                  </label>
                  <div className="col-lg-10">
                    <input
                      name="redeem_category_name_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อหมวดหมู่รายการรับแต้ม (EN)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors["redeem_category_name_en"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    สถานะเปิดใช้งาน
                  </label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="is_open"
                        type="checkbox"
                        onChange={this.handleChange}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
