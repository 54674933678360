import React from "react";
import $ from "jquery";
import { pointAction } from "../../../_actions";
import { Base_API } from "../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactExport from 'react-data-export';
import "../member.css";

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-historypoint'

export class Member_Point extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false,
      inputBody: [],
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {

    var params = this.props.match.params;

    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "redeemOrderNo"
      },
      {
        title: "ชื่อรายการ",
        class: "text-center",
        data: "name"
      },
      // {
      //   title: "วันที่รับคะแนน",
      //   class: "text-center",
      //   data: "createdOn"
      // },
      // {
      //   title: "รหัสรีวอร์ด",
      //   class: "text-center",
      //   data: "rewards_code"
      // },
      // {
      //   title: "ชื่อรีวอร์ด",
      //   class: "text-center",
      //   data: "rewards_name"
      // },
      {
        title: "รหัสรายการอ้างอิง",
        class: "text-center",
        data: "redeemOrderNo"
      },
      {
        title: "คะแนน",
        class: "text-center",
        data: "point"
      },
      // {
      //   title: "คะแนนที่ใช้",
      //   class: "text-center",
      //   data: "point"
      // },
      {
        title: "สาขา",
        class: "text-center",
        data: "branch"
      },
      {
        title: "วันที่ทำรายการ",
        class: "text-center",
        data: "createdOn"
      },
      // {
      //   title: "ลบ",
      //   class: "text-center",
      //   data: "p_id"
      // },

    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          },
          orderable: true
        },
        // {
        //   targets: 2,
        //   createdCell: function(td, cellData, rowData, row, col) {
        //     let list_code = '';
        //     if(cellData === null) list_code = `${rowData.earn_code}`
        //     else list_code = `${cellData}`

        //     $(td).html(list_code);
        //   },
        //   orderable: false
        // },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).html(`${!!cellData ? cellData : '-'}`);
          },
          orderable: false
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            let pointType = rowData.pointType;
            if(pointType === 'earn')
            {
              $(td).html(`<strong class="text-success">+${cellData}</strong>`);
            }else
            {
              $(td).html(`<strong class="text-danger">${cellData}</strong>`);
            }
          },
          orderable: false
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).html(`${!!cellData ? cellData : '-'}`);
          },
          orderable: false
        },
        // {
        //   targets: 4,
        //   createdCell: function(td, cellData, rowData, row, col) {
        //     $(td).html(`<strong class="text-danger">-${cellData}</strong>`);
        //   },
        //   orderable: false
        // },
        // {
        //   targets: 7,
        //   createdCell: function(td, cellData, rowData, row, col) {
        //     $(td)
        //       .html(`
        //         <div>
        //           <button type='button' class="icon-trash" name='delete_btn' data-content='${cellData}'></button>
        //         </div>
        //       `);
        //   }
        // }
      ],
      ajax: {
        url: `${Base_API.point}/api/point/v2/logPoint/${params.mem_id}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[1, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );
    
      state.setState({ show: true, modal: modalAlert() });
    });

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }


    function onConfirmDelete(cid) {
      pointAction.deleteManagePoint(cid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

    pointAction.exportExcelPointLog(params.mem_id)
    .then(res => {
        this.setState({
          dataExcel: res.data
        })
    })
  }

  render() {

    var dataExcel = []
    let data = this.state.dataExcel

    for(var i in data){
      dataExcel.push(
        [
          {value: i+1},
          {value: (data[i].p_receive_date ? data[i].p_receive_date : "")},
          {value: (data[i].earn_code ? data[i].earn_code : "")},
          {value: data[i].earn_name ? data[i].earn_name : ""},
          {value: data[i].order_ref ? data[i].order_ref : ""},
          {value: data[i].p_remain_point ? "+" + data[i].p_remain_point : "0"},
          {value: data[i].p_point ? "-" + data[i].p_point : "0"},
          {value: data[i].created_at ? data[i].created_at : ""}
        ],
      )
    }    
    
    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "วันที่รับคะนน", width: {wch: 20}},//char width 
              {title: "รหัสรีวอร์ด", width: {wch: 20}},
              {title: "ชื่อรีวอร์ด", width: {wch: 40}},
              {title: "รหัสรายการอ้างอิง", width: {wch: 20}},
              {title: "คะแนนที่ได้รับ", width: {wch: 10}},
              {title: "คะแนนที่ใช้", width: {wch: 10}},
              {title: "สาขา"},
              {title: "วันที่ทำรายการ", width: {wch: 20}},
          ],
          data: dataExcel
          
      }
    ];

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">ประวัติการได้รับ - การใช้คะแนน</h4>
            <div className="heading-elements">
              <ExcelFile 
                element={
                  <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                  >
                  <i className="icon-download"></i> Export Excel
                  </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="History Point" />
              </ExcelFile>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
