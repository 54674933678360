import React from "react";
import $ from "jquery";
import { queueAction,branchAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../queue.css";
import Select from "react-select";


$.DataTable = require("datatables.net");
let modalAlert;

let dataSearch = {
  s_Branch: ''
}

export class Queue_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      branchList: [],
      searchBranch: 'all',
      fields: {},
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };


  componentDidMount() {
    branchAction.listBranchAll()
    .then(res => {
        this.setState({
            branchList: res.data
        })
    })

    this.showTable(dataSearch);
  }

  showTable(dataSearch){    
    const columns = [
      // {
      //   title: "ลำดับ",
      //   class: "text-center",
      //   data: "b_code",
      // },
      {
        title: "สาขา",
        class: "text-center",
        data: "b_name",
      },
      {
        title: "คิวปัจจุบัน",
        class: "text-center",
        data: "queue",
      },
      {
        title: "จำนวนคิว",
        class: "text-center",
        data: "queue",

      },
      {
        title: "วันที่จองคิว",
        class: "text-center",
        data: "queue",
      },

    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        // {
        //   targets: 0,
        //   createdCell: function(td, cellData, rowData, row, col) {
        //     let row_id = 0
        //     $(td)
        //     .html(`<div class="text-center">${row+1}</div>`);
        //   }
        // },
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            let q_numA = (cellData[0] != undefined ? cellData[0].q_num  : 'ปิดชั่วคราว')
            let q_numB = (cellData[0] != undefined ? cellData[1].q_num  : 'ปิดชั่วคราว')
            $(td)
            .html(`
            <div class="text-center">${q_numA}</div>
            <div class="text-center">${q_numB}</div>
            `);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            let countA = (cellData[0] != undefined ? cellData[0].count  : '0')
            let countB = (cellData[0] != undefined ? cellData[1].count  : '0')

            $(td)
            .html(`
            <div class="text-center">${countA}</div>
            <div class="text-center">${countB}</div>
            `);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            let created_atA = (cellData[0] != undefined ? cellData[0].created_at  : '')
            let created_atB = (cellData[0] != undefined ? cellData[1].created_at  : '')

            $(td)
            .html(`
            <div class="text-center">${created_atA}</div>
            <div class="text-center">${created_atB}</div>
            `);
          }
        },
      ],
      ajax: {
        url: `${Base_API.queue}/api/v1/list_queue?b_code=${this.state.searchBranch}&page_no=0`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;          
        }
      },
      // order: [[1, "ASC"]],
      columns,
      serverSide: false,
      ordering: true,
      searching: false,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

  }

  handleSubmit(e) {
    var table = $('#dataTable').DataTable();
    table.destroy();

    e.preventDefault()
    var dataSearch = {
        s_Branch: (this.state["searchBranch"] ? this.state["searchBranch"] : 'all'),
    }

    this.showTable(dataSearch);
  }

  handleSearchBranch = (selectedOption) => {
      this.state["searchBranch"] = selectedOption.value;
  };

  render() {

    let optionBranch = this.state.branchList.map(function (branchList) {
      return { value: branchList.b_code, label: branchList.b_code+' - '+branchList.b_name };
    })

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการคิว (Queues)</h4>
          </div>
          <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>

            <div className="row form-search">
              <div className="col-md-4">
                <Select
                  name="searchBranch"
                  placeholder="เลือกสาขา"
                  onChange={this.handleSearchBranch}
                  options={optionBranch}
                  className="Select-menu-outer"
                />
              </div>

              <div className="col-lg-3 text-left">
                <input
                  type="submit"
                  className="btn btn-add btn-md btn-banner-create"
                  value="ค้นหา"
                />
              </div>
          </div>


          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table id="dataTable" className="table table-hover" ref="main" />
              </div>
            </div>
          </div>
        </form>
      </div>
      {this.state.modal}
    </div>
    );
  }
}
