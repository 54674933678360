import React from "react";
import Select from 'react-select';

import { productAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../product.css";
import $ from 'jquery'

let modalAlert;

export class Product_AddOn_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      // messageCreate: '',
      fields: {},
      errors: {},
      // typeProgram: null,2
      // selectType: null,
      isStatus: false,
      isRequired: false,
      status: 0,
      required: 0,
      attrID: 0,
      addonElem: [
        {
          id: 1,
          attribute_name_th: '',
          attribute_name_en: '',
          attribute_sort: 0,
          attribute_price: 0.00, 
        }
      ],
      attrDetail: null 
      // disabled:true
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleIncreaseAddOn = this.handleIncreaseAddOn.bind(this);
    this.handleDecreaseAddOn = this.handleDecreaseAddOn.bind(this);
  }

  componentDidMount() {
    let {id} = this.props.match.params;
    this.setState({attrID: id})

    productAction.getProductAttributeByID(id)
    .then(res => {
      if(res.isSuccess === true)
      {
        this.setState({attrDetail: res.data});
        this.pageInit();
      }
    })
  }

  pageInit(){
    let {attrDetail, fields} = this.state;
    let attrCateData = attrDetail[0];

    $('input[name="cate_name_th"]').val(attrCateData.cate_name_th);
    $('input[name="cate_name_en"]').val(attrCateData.cate_name_en);
    $('input[name="limit_select"]').val(attrCateData.limit_select);

    fields["cate_name_th"] = attrCateData.cate_name_th;
    fields["cate_name_en"] = attrCateData.cate_name_en;
    fields["limit_select"] = attrCateData.limit_select;
    this.setState({
      isStatus: attrCateData.is_active === 1 ? true : false,
      status: attrCateData.is_active,
      isRequired: attrCateData.is_required === 1 ? true : false,
      required: attrCateData.is_required,
    });

    let newAddonElem = [];
    if(attrDetail.length > 0)
    {
      attrDetail.map(async (item, i) => {
        let id = i+1;
        let newAttrSet = {
          id: id,
          attribute_name_th: item.attribute_name_th,
          attribute_name_en: item.attribute_name_en,
          attribute_sort: item.attribute_sort,
          attribute_price: item.attribute_price
        }
        newAddonElem.push(newAttrSet);

        if(Number(i) === attrDetail.length-1)
        {
          this.setState({
            addonElem: newAddonElem
          });

          await this.attributeSetInit();
        }
      })
    }
  }

  attributeSetInit()
  {
    return new Promise(async resolve => {
      let {attrDetail} = this.state;
      if(attrDetail.length > 0)
      {
        attrDetail.forEach(async (item, i) => {
          let id = i+1;
          $(`#attr_sort_${id}`).val(item.attribute_sort);
          $(`#attr_name_th_${id}`).val(item.attribute_name_th);
          $(`#attr_name_en_${id}`).val(item.attribute_name_en);
          $(`#attr_price_${id}`).val(item.attribute_price);
        })
      }
      resolve(); 
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }

  handleIncreaseAddOn()
  {
    let {addonElem}  = this.state;

    let newAddonElem = {
      id: addonElem[addonElem.length-1].id+1,
      attribute_name_th: '',
      attribute_name_en: '',
      attribute_price: 0.00,
      attribute_sort: addonElem[addonElem.length-1].id+1
    }
    addonElem.push(newAddonElem)
  
    this.setState({
      addonElem: addonElem
    })
  }

  handleDecreaseAddOn()
  {
    let {addonElem}  = this.state;

    if(addonElem.length > 1)
    {
      let newAddonElem = addonElem.slice(0, addonElem.length-1);
      this.setState({
        addonElem: newAddonElem
      })
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/order-online/product/add-on"
  }

  validateForm() {

    let fields = this.state.fields;
    let addonElem = this.state.addonElem;
    let errors = {};
    let formIsValid = true;

    console.log("fields: ", fields);
    console.log('addonElem: ', addonElem);

    if (!fields["cate_name_th"]) {
      formIsValid = false;
      errors["cate_name_th"] = "*ชื่อหมวดส่วนเสริม(TH)";
    }
    if (!fields["cate_name_en"]) {
      formIsValid = false;
      errors["cate_name_en"] = "**ชื่อหมวดส่วนเสริม(EN)";
    }
    if (!fields["limit_select"]) {
      formIsValid = false;
      errors["limit_select"] = "**จำนวนที่เลือกได้ / ส่วนเสริม";
    }

    addonElem.map((item) => {
      if(!item.attribute_name_en || !item.attribute_name_th || !item.attribute_sort)
      {
        formIsValid = false;
        errors[`attrset_${item.id}`] = "**กรอกให้ครบถ้วน";
      }
    })

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    let {fields, addonElem, status, required, attrID} = this.state;
    
    addonElem = addonElem.map((item, i) => {
      delete item.id;
      item.attribute_sort = i+1;
      return item
    })

    let reqData = {
      ...fields,
      is_active: status,
      is_required: required,
      attribute_set: addonElem
    }

    console.log('reqData: ', reqData);

    productAction.updateProductAttributeDetail(reqData, attrID).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };
  handleChangeAttr = (e, index) => {
    let { addonElem } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    
    if(name === 'attribute_name_th')
    {
      addonElem[index].attribute_name_th = value;
    }
    if(name === 'attribute_name_en')
    {
      addonElem[index].attribute_name_en = value;
    }
    if(name === 'attribute_price')
    {
      addonElem[index].attribute_price = Number(value);
    }
    if(name === 'attribute_sort')
    {
      addonElem[index].attribute_sort = Number(value);
    }

  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleInputChange = e => {

    const target = e.target;
    const name = target.name;

    if(name == "isStatus"){
      this.setState({ isStatus: !this.state.isStatus })
      if(this.state.isStatus == true){
        this.setState({ status: 0 })
      }else{
        this.setState({ status: 1 })
      }
    }
    if(name === "isRequired")
    {
      this.setState({ isRequired: !this.state.isRequired })
      if(this.state.isRequired == true){
        this.setState({ required: 0 })
      }else{
        this.setState({ required: 1 })
      }
    }
  }

  onDragStart = (e, index) => {
    e.dataTransfer.setData('index', index);
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDrop = (e, targetIndex) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData('index');
    const { addonElem } = this.state;
    let reorderedAddonElem = [...addonElem];
    const [draggedItem] = reorderedAddonElem.splice(sourceIndex, 1);
    reorderedAddonElem.splice(targetIndex, 0, draggedItem);

    this.setState({ addonElem: reorderedAddonElem });
  };

  render() {
    let {addonElem} = this.state;

    return (

      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มคุณสมบัติสินค้าออนไลน์</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">

                <div className="form-group">
                  <div class="col-lg-4">
                    <label className="control-label">ชื่อคุณสมบัติ (TH)</label>
                    <div className="">
                    <input
                        name="cate_name_th"
                        type="text"
                        className="form-control"
                        placeholder="กรอกชื่อคุณสมบัติ(TH)"
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.cate_name_th}</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label className="control-label">ชื่อคุณสมบัติ (EN)</label>
                    <div className="">
                    <input
                        name="cate_name_en"
                        type="text"
                        className="form-control"
                        placeholder="กรอกชื่อคุณสมบัติ(EN)"
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.cate_name_en}</div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label className="control-label">จำนวนที่เลือกได้</label>
                    <div className="">
                      <input
                        name="limit_select"
                        type="number"
                        className="form-control"
                        placeholder="กรอกจำนวนที่เลือกได้"
                        aria-valuemin={1}
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.limit_select}</div>
                    </div>
                  </div>
                </div>

                <div className="mb-5 py-3">
                  <h4 className="panel-title mb-3">รายละเอียดคุณสมบัติ <span className="text-danger">(drag & drop)</span></h4>

                  {addonElem.length > 0 && addonElem.map((item, i) => 
                    <div 
                      className="pt-2" 
                      key={item.id}
                      draggable
                      onDragStart={(e) => this.onDragStart(e, i)}
                      onDragOver={this.onDragOver}
                      onDrop={(e) => this.onDrop(e, i)}
                    >
                      {/* <h5 className="text-danger mt-0">#{item.id}</h5> */}
                      <div className="form-group mb-2">
                        <div className="col-lg-1">
                          <label className="control-label "></label>
                          <div className="drag-icon-wrap">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-move" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"/>
                            </svg>
                            {/* <input
                              name="attribute_sort"
                              id={`attr_sort_${item.id}`}
                              type="number"
                              className="form-control"
                              placeholder="กรอกลำดับการแสดง"
                              onChange={(e) => this.handleChangeAttr(e, i)}
                            /> */}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <label className="control-label ">ชื่อ (TH)</label>
                          <div className="">
                            <input
                              name="attribute_name_th"
                              id={`attr_name_th_${item.id}`}
                              type="text"
                              className="form-control"
                              placeholder="กรอกชื่อ(TH)"
                              onChange={(e) => this.handleChangeAttr(e, i)}
                            />
                          </div>
                          <div className="errorMsg">{this.state.errors[`attrset_${item.id}`]}</div>
                        </div>
                        <div className="col-lg-4">
                          <label className="control-label ">ชื่อ (EN)</label>
                          <div className="">
                            <input
                              name="attribute_name_en"
                              id={`attr_name_en_${item.id}`}
                              type="text"
                              className="form-control"
                              placeholder="กรอกชื่อ(EN)"
                              onChange={(e) => this.handleChangeAttr(e, i)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label className="control-label ">ราคา</label>
                          <div className="">
                            <input
                              name="attribute_price"
                              id={`attr_price_${item.id}`}
                              type="number"
                              className="form-control"
                              placeholder="ราคา"
                              defaultValue={0}
                              onChange={(e) => this.handleChangeAttr(e, i)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>   
                  )} 

                  <div className="form-group mb-2 mt-5">
                    <div className="col-lg-12 text-left">
                      <input
                        type="button"
                        className="btn btn-primary btn-md btn-banner-create mr-3"
                        value="เพ่ิม"
                        onClick={this.handleIncreaseAddOn}
                      />
                      <input
                        type="button"
                        className="btn btn-danger btn-md btn-banner-create"
                        value="ลบ"
                        onClick={this.handleDecreaseAddOn}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mb-0">
                  <label className="control-label col-lg-2">บังคับเลือก</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="isRequired" type="checkbox" onChange={this.handleInputChange} checked={this.state.isRequired}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>
                <p className="text-danger">(บังคับผู้ใช้เลือกอยากน้อย 1 รายการ)</p>

                <div className="form-group mt-4">
                  <label className="control-label col-lg-2">สถานะ</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
