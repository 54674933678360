import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const memberAction = {
  update_Member,
  getMemberId,
  createMember,
  deleteMember,
  deleteTokenMember,
  activateMember,
  sentNotificationAll,

  getMemberList,
  exportMemberList,

  getProvince,
  getAmphoe,
  getDistrict,
  getZipcode,

  exportProblemList,
  updateProblemEwallet,

  logoutMember,
  deleteMemberV2
};


function updateProblemEwallet(id, formData) {
  return axios.put(`${Base_API.member}/api/v1/problem_ewallet/${id}`, formData).then(res => {
    return res.data;
  });
} 

function exportMemberList() {
  return axios.get(`${Base_API.member}/api/export/member/listMember`, "").then(res => {
    return res.data;
  });
} 

function getMemberList() {
  return axios.get(`${Base_API.member}/api/member/v1/listMember`, "").then(res => {
    return res.data;
  });
}

function getMemberId(id) {
  return axios.get(`${Base_API.member}/api/member/v1/${id}`, "").then(res => {
    return res.data;
  });
}

function update_Member(formData, id) {
  return axios.put(`${Base_API.member}/api/member/v1/${id}`, formData).then(res => {
    return res.data;
  });
}

function activateMember(formData, id) {
  return axios.put(`${Base_API.member}/api/member/v1/${id}`, formData).then(res => {
    return res.data;
  });
}

function logoutMember(formData) {
  return axios.post(`${Base_API.member}/api/member/v1/logoutMember`, formData).then(res => {
    return res.data;
  });
}


function createMember(formData) {
  return axios.post(`${Base_API.member}/api/member/v1/regisMember`, formData).then(res => {
    return res.data;
  });
}

function deleteMember(id) {
  return axios.delete(`${Base_API.member}/api/member/v1/${id}`).then(res => {
    return res.data;
  });
}

function deleteMemberV2(id) {
  return axios.delete(`${Base_API.member}/api/member/v2/${id}`).then(res => {
    return res.data;
  });
}

function deleteTokenMember(id)
{
  return axios.delete(`${Base_API.member}/api/member/v1/clearToken/${id}`).then(res => {
    return res.data;
  });
}

function sentNotificationAll(inputData) {
  return axios.post(`${Base_API.member}/sentNotificationAll`, inputData).then(res => {
    return res.data;
  });
}

function getProvince() {
  return axios.get(`${Base_API.province}/province`, "").then(res => {
    return res.data;
  });
}

function getAmphoe(id) {
  return axios.get(`${Base_API.province}/amphoe/${id}`, "").then(res => {
    return res.data;
  });
}

function getDistrict(id) {
  return axios.get(`${Base_API.province}/districts/${id}`, "").then(res => {
    return res.data;
  });
}

function getZipcode(id) {
  return axios.get(`${Base_API.province}/getZipcode/${id}`, "").then(res => {
    return res.data;
  });
}

function exportProblemList() {
  return axios.get(`${Base_API.member}/api/v1/problem`, "").then(res => {
    return res.data;
  });
}
