import React from "react";
import $ from "jquery";
import { BackofficeAction, memberAction } from "../../../_actions";
import { Base_API } from "../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../member.css";
// import ReactExport from "react-export-excel";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd+mm+yyyy+hh+mn+ss+ms+'-member'

export class Member_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false,
      inputBody:[],
      fetching: true,
      excelUrl: "",
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    //! ไม่ได้ใช้แล้ว ไป Export ที่ API Backoffice แทน
    // memberAction.getMemberList().then(res => {
    //   // dataLength = res.recordsTotal;
    //   this.setState({
    //       dataMember: res.data
    //   })
    // })
    // memberAction.exportMemberList().then(res => {
    //   console.log('Export Member List: ', res.data);
    //   this.setState({
    //       dataExportMember: res.data,
    //       fetching: false
    //   })
    // }).catch(function (error) {
    //   console.log(error);
    //   this.setState({ fetching: false });
    // });

    //! Export Excel (ปิดใช้งาน)
    getExcelMembers()


    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "mem_firstname",
      },
      {
        title: "ชื่อสมาชิก",
        data: "mem_firstname"
      },
      {
        title: "เบอร์โทรศัพท์",
        data: "mem_phone"
      },
      {
        title: "รหัสบัตรประชาชน",
        class: "text-center",
        data: "mem_idcard"
      },
      {
        title: "e-mail",
        data: "mem_email"
      },
      {
        title: "คะแนนคงเหลือ (P)",
        class: "text-center",
        data: "total_point"
      },
      {
        title: "วันเวลาที่ลงทะเบียน",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "สถานะ",
        class: "text-center",
        data: "is_active"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "mem_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`${cellData} ${rowData.mem_lastname}`);
          }
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="isStatus" class="activeBtn" data-id="${rowData.mem_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: false
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/member/log_point/${cellData}" class="dropdown-item"><i class="icon-file-text mr-3 mb-1"></i> ประวัติการใช้-แลกแต้ม</a>
                    <a href="/backoffice/member/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                    <button name="cleartoken" class="dropdown-item" data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i> เคลียร์โทเคน</button>
                    <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                  </div>

                </div>
              </div>        
            `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.member}/api/member/v1/listMember`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[1, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      // lengthMenu: [[10, 25, 50, dataLength], [10, 25, 50, "All"]],
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 2;

      state.setState({
        inputBody : {
          is_active: fval,
        }
      });
      let formData = new FormData();      
      formData.append('mem_id', fid)

      memberAction.activateMember(state.state.inputBody, fid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          if(fval === 2)
          {
            memberAction.logoutMember(formData).then(res => {
              if(res.isSuccess === true)
              {
                onModalSuccess("Success", e.message);
              }else
              {
                onModalSuccess("Success", e.message);
              }
            })
          }else
          {
            onModalSuccess("Success", e.message);
          }
        }
      });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var mcid = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(mcid)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    $(this.refs.main).on("click", 'button[name="cleartoken"]', function() {
      var mcid = $(this).data("content");
      console.log(mcid)
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDeleteToken(mcid)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบโทเคนนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(id) {
      memberAction.deleteMemberV2(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }
    function onConfirmDeleteToken(id) {
      memberAction.deleteTokenMember(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      })
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
    // Get Excel Members
    function getExcelMembers() {
      console.log('test export')
      BackofficeAction.getExcelMemberList()
      .then(res => {
        console.log(res)
        if(res.status === true)
        {
          state.setState({ excelUrl: res.data })
        }
      })
    }
  }



  render() {
    const { fetching } = this.state;
    if (fetching) {
      console.log(fetching);
    }else{
      console.log(fetching);
    }
    var dataExportMember = []
    let data = this.state.dataExportMember   
    for(var i in data){
      dataExportMember.push(
        [
          {value: parseInt(i)+1},
          {value: data[i].mem_firstname + ' ' + data[i].mem_lastname},
          {value: (data[i].mem_phone ? data[i].mem_phone : "")},
          {value: (data[i].mem_idcard ? data[i].mem_idcard : "")},
          {value: (data[i].mem_address ? data[i].mem_address : "")},
          {value: (data[i].districts ? data[i].districts : "")},
          {value: (data[i].amphoe ? data[i].amphoe : "")},
          {value: (data[i].provinces ? data[i].provinces : "")},
          {value: (data[i].mem_zipcode ? data[i].mem_zipcode : "")},
          {value: data[i].total_point},
          {value: data[i].created_at},
          {value: (data[i].is_active == 1 ? 'active' : 'inactive'), style: {fill: {fgColor: {rgb: (data[i].is_active == 1 ? "FF00FF00" : "FFFF0000")}}}},
        ],
      )
    }    
    
    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "ชื่อสมาชิก", width: {wch: 40}},//char width 
              {title: "เบอร์โทรศัพท์", width: {wch: 20}},
              {title: "รหัสบัตรประชาชน", width: {wch: 20}},
              {title: "ที่อยู่", width: {wch: 50}},
              {title: "แขวง/ตำบล", width: {wch: 20}},
              {title: "เขต/อำเภอ", width: {wch: 20}},
              {title: "จังหวัด", width: {wch: 20}},
              {title: "รหัสไปรษณีย์", width: {wch: 10}},
              {title: "คะแนนคงเหลือ", width: {wch: 15}},
              {title: "วันเวลาที่ลงทะเบียน", width: {wch: 15}},
              {title: "สถานะ"},
          ],
          data: dataExportMember
          
      }
    ];
    console.log(multiDataSet);
    
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายชื่อข้อมูลสมาชิก</h4>
            <div className="heading-elements">
              <a href="/backoffice/member/create">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                  <i className="icon-plus2"></i> เพิ่มสมาชิก
                </button>
              </a>

              {this.state.excelUrl !== ""
                ? 
                  <a href={this.state.excelUrl}>
                    <button
                        type="button"
                        name="add_banner_btn"
                        data-content=""
                        className="btn btn-add mr-2"
                      >
                        <i className="icon-download"></i> Export Excel
                    </button>
                  </a>
                :
                  <button
                      type="button"
                      name="add_banner_btn"
                      data-content=""
                      className="btn mr-2 disabled"
                      style={{ cursor: 'not-allowed' }}
                    >
                      <i className="icon-download"></i> Export Excel
                  </button>

              }
              {/*//! Disabled */}
              {/* <ExcelFile 
                element={
                  <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                  >
                  <i className="icon-download"></i> Export Excel
                  </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Member" />
              </ExcelFile> */}
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
          
        </div>
        {this.state.modal}
      </div>
    );
  }
}
