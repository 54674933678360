import React from "react";
import { branchAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../branch.css";

let modalAlert;

export class Branch_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      isStatus: false,
      isStatus_preorder: false,
      isStatus_orderonline: false,

      inputCheck: {
        branch_time_monday_status: 0,
        branch_time_tuesday_status: 0,
        branch_time_wednesday_status: 0,
        branch_time_thursday_status: 0,
        branch_time_friday_status: 0,
        branch_time_saturday_status: 0,
        branch_time_sunday_status: 0
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการเพิ่มข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/preorder/branch/";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["branchCode"]) {
      formIsValid = false;
      errors["branchCode"] = "*กรุณากรอกรหัสสาขา";
    }

    if (!fields["branchName"]) {
      formIsValid = false;
      errors["branchName"] = "*กรุณากรอกชื่อสาขา (TH)";
    }

    if (!fields["branchNameEN"]) {
      formIsValid = false;
      errors["branchNameEN"] = "*กรุณากรอกชื่อสาขา (EN)";
    }

    if (!fields["branchImage"]) {
      formIsValid = false;
      errors["branchImage"] = "*กรุณาแนบไฟล์รูปภาพ";
    }

    if (!fields["branchImagePin"]) {
      formIsValid = false;
      errors["branchImagePin"] = "*กรุณาแนบไฟล์รูปภาพ Pin";
    }

    if (!fields["branchAddress"]) {
      formIsValid = false;
      errors["branchAddress"] = "*กรุณากรอกที่ตั้งสาขา (TH)";
    }

    if (!fields["branchAddressEN"]) {
      formIsValid = false;
      errors["branchAddressEN"] = "*กรุณากรอกที่ตั้งสาขา (EN)";
    }

    if (!fields["branchTelephone"]) {
      formIsValid = false;
      errors["branchTelephone"] = "*กรุณากรอกเบอร์โทรศัพท์";
    }

    if (!fields["latName"]) {
      formIsValid = false;
      errors["latName"] = "*กรุณากรอกละติจูด";
    }

    if (!fields["longName"]) {
      formIsValid = false;
      errors["longName"] = "*กรุณากรอกลองติจูด";
    }

    if (!fields["time_open"]) {
      formIsValid = false;
      errors["time_open"] = "*กรุณากรอกเวลาทำการ (TH)";
    }

    if (!fields["time_openEN"]) {
      formIsValid = false;
      errors["time_openEN"] = "*กรุณากรอกเวลาทำการ (EN)";
    }

    if (!fields["branch_time_monday_open"]) {
      formIsValid = false;
      errors["branch_time_monday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันจันทร์)";
    }

    if (!fields["branch_time_monday_close"]) {
      formIsValid = false;
      errors["branch_time_monday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันจันทร์)";
    }

    if (!fields["branch_time_tuesday_open"]) {
      formIsValid = false;
      errors["branch_time_tuesday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันอังคาร)";
    }

    if (!fields["branch_time_tuesday_close"]) {
      formIsValid = false;
      errors["branch_time_tuesday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันอังคาร)";
    }

    if (!fields["branch_time_wednesday_open"]) {
      formIsValid = false;
      errors["branch_time_wednesday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันพุธ)";
    }

    if (!fields["branch_time_wednesday_close"]) {
      formIsValid = false;
      errors["branch_time_wednesday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันพุธ)";
    }

    if (!fields["branch_time_thursday_open"]) {
      formIsValid = false;
      errors["branch_time_thursday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันพฤหัสบดี)";
    }

    if (!fields["branch_time_thursday_close"]) {
      formIsValid = false;
      errors["branch_time_thursday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันพฤหัสบดี)";
    }

    if (!fields["branch_time_friday_open"]) {
      formIsValid = false;
      errors["branch_time_friday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันศุกร์)";
    }

    if (!fields["branch_time_friday_close"]) {
      formIsValid = false;
      errors["branch_time_friday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันศุกร์)";
    }

    if (!fields["branch_time_saturday_open"]) {
      formIsValid = false;
      errors["branch_time_saturday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันเสาร์)";
    }

    if (!fields["branch_time_saturday_close"]) {
      formIsValid = false;
      errors["branch_time_saturday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันเสาร์)";
    }

    if (!fields["branch_time_sunday_open"]) {
      formIsValid = false;
      errors["branch_time_sunday_open"] = "*กรุณากรอกเวลาเปิดทำการ (วันอาทิตย์)";
    }

    if (!fields["branch_time_sunday_close"]) {
      formIsValid = false;
      errors["branch_time_sunday_close"] = "*กรุณากรอกเวลาปิดทำการ (วันอาทิตย์)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });
    var formData = new FormData();
    if(this.state.fields["branchImage"]) {
    formData.append("b_image", this.state.fields["branchImage"]); }

    if(this.state.fields["branchImagePin"]) {
      formData.append("b_image_pin", this.state.fields["branchImagePin"]); }
  
    formData.append("b_code", this.state.fields["branchCode"]);
    formData.append("b_name", this.state.fields["branchName"]);
    formData.append("b_name_en", this.state.fields["branchNameEN"]);
    formData.append("b_address", this.state.fields["branchAddress"]);
    formData.append("b_address_en", this.state.fields["branchAddressEN"]);
    formData.append("b_telephone", this.state.fields["branchTelephone"]);
    formData.append("b_lat", this.state.fields["latName"]);
    formData.append("b_long", this.state.fields["longName"]);
    formData.append("time_open", this.state.fields["time_open"]);
    formData.append("time_open_en", this.state.fields["time_openEN"]);

    formData.append("branch_time_monday_status", this.state.inputCheck["branch_time_monday_status"]);
    formData.append("branch_time_monday_open", this.state.fields["branch_time_monday_open"]);
    formData.append("branch_time_monday_close", this.state.fields["branch_time_monday_close"]);

    formData.append("branch_time_tuesday_status", this.state.inputCheck["branch_time_tuesday_status"]);
    formData.append("branch_time_tuesday_open", this.state.fields["branch_time_tuesday_open"]);
    formData.append("branch_time_tuesday_close", this.state.fields["branch_time_tuesday_close"]);

    formData.append("branch_time_wednesday_status", this.state.inputCheck["branch_time_wednesday_status"]);
    formData.append("branch_time_wednesday_open", this.state.fields["branch_time_wednesday_open"]);
    formData.append("branch_time_wednesday_close", this.state.fields["branch_time_wednesday_close"]);

    formData.append("branch_time_thursday_status", this.state.inputCheck["branch_time_thursday_status"]);
    formData.append("branch_time_thursday_open", this.state.fields["branch_time_thursday_open"]);
    formData.append("branch_time_thursday_close", this.state.fields["branch_time_thursday_close"]);

    formData.append("branch_time_friday_status", this.state.inputCheck["branch_time_friday_status"]);
    formData.append("branch_time_friday_open", this.state.fields["branch_time_friday_open"]);
    formData.append("branch_time_friday_close", this.state.fields["branch_time_friday_close"]);

    formData.append("branch_time_saturday_status", this.state.inputCheck["branch_time_saturday_status"]);
    formData.append("branch_time_saturday_open", this.state.fields["branch_time_saturday_open"]);
    formData.append("branch_time_saturday_close", this.state.fields["branch_time_saturday_close"]);

    formData.append("branch_time_sunday_status", this.state.inputCheck["branch_time_sunday_status"]);
    formData.append("branch_time_sunday_open", this.state.fields["branch_time_sunday_open"]);
    formData.append("branch_time_sunday_close", this.state.fields["branch_time_sunday_close"]);

    formData.append("b_status", (this.state["isStatus"] == true ? 1 : 0));
    formData.append("b_preorder_status", (this.state["isStatus_preorder"] == true ? 1 : 0));
    formData.append("b_orderonline_status", (this.state["isStatus_orderonline"] == true ? 1 : 0));
    branchAction.createBranchEatin(formData).then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleInputChange = e => {
    let { inputCheck } = this.state;
    const target = e.target;
    const name = target.name;

    if (name === "isStatus") {
      this.setState({ isStatus: !this.state.isStatus });
    }else if(name === "isStatus_preorder"){
      this.setState({ isStatus_preorder: !this.state.isStatus_preorder });
    }else if(name === "isStatus_orderonline"){
      this.setState({ isStatus_orderonline: !this.state.isStatus_orderonline });
    }else if(
      name === "branch_time_monday_status" || 
      name === "branch_time_tuesday_status" ||
      name === "branch_time_wednesday_status" ||
      name === "branch_time_thursday_status" ||
      name === "branch_time_friday_status" ||
      name === "branch_time_saturday_status" ||
      name === "branch_time_sunday_status"
      ){
      inputCheck[name] = e.target.checked === true ? 1 : 0;
      this.setState({ inputCheck });
    }

  };

  render() {
    console.log(this.state.inputCheck);
    return (
      <div className="eatin-branch-container">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มสาขา</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รูปภาพประจำสาขา
                    <div className="text-danger"> (1200 x 750 px)</div>
                  </label>
                  <div className="col-lg-10">
                    <input
                      type="file"
                      className="file-input"
                      name="branchImage"
                      id="file"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchImage}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รูปภาพ Pin
                    <div className="text-danger"> (500 x 500 px)</div>
                  </label>
                  <div className="col-lg-10">
                    <input
                      type="file"
                      className="file-input"
                      name="branchImagePin"
                      id="file"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchImagePin}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสาขา</label>
                  <div className="col-lg-10">
                    <input
                      name="branchCode"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสสาขา"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchCode}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสาขา (TH)</label>
                  <div className="col-lg-10">
                    <input
                      name="branchName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสาขา (TH)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchName}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสาขา (EN)</label>
                  <div className="col-lg-10">
                    <input
                      name="branchNameEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสาขา"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchNameEN}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ที่ตั้งสาขา (TH)</label>
                  <div className="col-lg-10">
                    <textarea
                      name="branchAddress"
                      className="form-control"
                      placeholder="กรอกที่ตั้งสาขา (TH)"
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchAddress}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ที่ตั้งสาขา (EN)</label>
                  <div className="col-lg-10">
                    <textarea
                      name="branchAddressEN"
                      className="form-control"
                      placeholder="กรอกที่ตั้งสาขา (EN)"
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchAddressEN}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เบอร์โทรศัพท์</label>
                  <div className="col-lg-10">
                    <input
                      name="branchTelephone"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchTelephone}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ละติจูด</label>
                  <div className="col-lg-10">
                    <input
                      name="latName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกละติจูด"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.latName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ลองติจูด</label>
                  <div className="col-lg-10">
                    <input
                      name="longName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกลองติจูด"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.longName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เวลาทำการ (TH)</label>
                  <div className="col-lg-10">
                    <input
                      name="time_open"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเวลาทำการ เช่น 10:00 น. - 22:00 น. (ทุกวัน) หรือ จันทร์ - พฤหัสบดี เปิด 11.00 น. - 21.30 น."
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.time_open}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เวลาทำการ (EN)</label>
                  <div className="col-lg-10">
                  <input
                    name="time_openEN"
                    type="text"
                    className="form-control"
                    placeholder="กรอกเวลาทำการ เช่น 10:00 am. - 10:00 pm. (everyday) หรือ monday - friday เปิด 11.00 am. - 09.30 pm."
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.time_openEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันจันทร์)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_monday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_monday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันจันทร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_monday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันจันทร์)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_monday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันจันทร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_monday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันจันทร์)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_monday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันอังคาร)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_tuesday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_tuesday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันอังคาร)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_tuesday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันอังคาร)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_tuesday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันอังคาร)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_tuesday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันอังคาร)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_tuesday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันพุธ)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_wednesday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_wednesday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันพุธ)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_wednesday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันพุธ)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_wednesday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันพุธ)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_wednesday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันพุธ)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_wednesday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันพฤหัสบดี)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_thursday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_thursday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันพฤหัสบดี)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_thursday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันพฤหัสบดี)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_thursday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันพฤหัสบดี)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_thursday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันพฤหัสบดี)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_thursday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันศุกร์)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_friday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_friday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันศุกร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_friday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันศุกร์)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_friday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันศุกร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_friday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันศุกร์)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_friday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันเสาร์)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_saturday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_saturday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันเสาร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_saturday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันเสาร์)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_saturday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันเสาร์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_saturday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันเสาร์)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_saturday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการเปิดทำการ (ปิด/เปิด) (วันอาทิตย์)</label>
                  <div className="col-lg-1">
                    <label className="switch">
                      <input
                        name="branch_time_sunday_status"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.inputCheck['branch_time_sunday_status']}
                      />
                      <div className="slider" />
                    </label>
                  </div>

                  <label className="control-label col-lg-2">เวลาเปิดทำการ (วันอาทิตย์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_sunday_open"
                      type="time"
                      className="form-control"
                      placeholder="เวลาเปิดทำการ (วันอาทิตย์)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_sunday_open}</div>
                  </div>

                  <label className="control-label col-lg-2">เวลาปิดทำการ (วันอาทิตย์)</label>
                  <div className="col-lg-2">
                    <input
                      name="branch_time_sunday_close"
                      type="time"
                      className="form-control"
                      placeholder="เวลาปิดทำการ (วันอาทิตย์)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.branch_time_sunday_close}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสาขา</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="isStatus"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.isStatus}
                      />
                      <div className="slider" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสาขาพรีออเดอร์</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="isStatus_preorder"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.isStatus_preorder}
                      />
                      <div className="slider" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสาขาออนไลน์</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="isStatus_orderonline"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.isStatus_orderonline}
                      />
                      <div className="slider" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
