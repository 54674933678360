import React from "react";
import { staffAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../staff.css";
import Select from "react-select";

let modalAlert;

export class Staff_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      errors: {},
      fields: {
        is_active: 0
      },
      typeJobs: [
        {
          label: 'Full Time',
          value: 'full_time'
        },
        {
          label: 'Part Time',
          value: 'part_time'
        }
      ]
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/staff"
  }

  validateForm() {

    let fields = this.state.fields;
    let select = this.state.select;
    let errors = {};
    let formIsValid = true;

    if (!fields["staff_number"]) {
      formIsValid = false;
      errors["staff_number"] = "*กรุณากรอกรหัสพนักงาน";
    }

    if (!fields["staff_firstname"]) {
      formIsValid = false;
      errors["staff_firstname"] = "*กรุณากรอกชื่อ";
    }

    if (!fields["staff_lastname"]) {
      formIsValid = false;
      errors["staff_lastname"] = "*กรุณากรอกนามสกุล";
    }

    if (!fields["staff_department"]) {
      formIsValid = false;
      errors["staff_department"] = "*กรุณากรอกแผนก";
    }

    if (!fields["staff_position"]) {
      formIsValid = false;
      errors["staff_position"] = "*กรุณากรอกตำแหน่ง";
    }

    if (!fields["staff_idcard"]) {
      formIsValid = false;
      errors["staff_idcard"] = "*กรุณากรอกรหัสบัตรประชาชน";
    }

    if (!fields["staff_birthdate"]) {
      formIsValid = false;
      errors["staff_birthdate"] = "*กรุณากรอกวันเดือนปีเกิด";
    }

    if (!fields["start_date"]) {
      formIsValid = false;
      errors["start_date"] = "*กรุณากรอกวันที่เริ่มงาน";
    }

    if (!fields["job_type"]) {
      formIsValid = false;
      errors["job_type"] = "*กรุณาระบุประเภทงาน";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    var formData = ({
      'staff_number' : this.state.fields["staff_number"],
      'staff_firstname' : this.state.fields["staff_firstname"],
      'staff_lastname' : this.state.fields["staff_lastname"],
      'staff_department' : this.state.fields["staff_department"],
      'staff_position' : this.state.fields["staff_position"],
      'staff_idcard' : this.state.fields["staff_idcard"],
      'staff_birthdate' : this.state.fields["staff_birthdate"],
      'is_active' : this.state.fields["is_active"],
      'job_type' : this.state.fields["job_type"].value,
      'start_date' : this.state.fields["start_date"]
    });

    staffAction.createStaff(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
  })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }



  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active") {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });

  };

  onSelectChange = (res, e) => {
    let name = e.name;
    let value = res.value;
    let { fields, errors } = this.state;
    if(name === 'job_type')
    {
      fields[name] = res;
      this.state.errors[name] = null;
      this.setState({ fields, errors });   
    }
  }

  render() {
    let { select } = this.state;

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มข้อมูลพนักงาน</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
              <div className="form-group">
                  <label className="control-label col-lg-2">รหัสพนักงาน</label>
                  <div className="col-lg-4">
                  <input
                      name="staff_number"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสพนักงาน"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.staff_number}</div>
                  </div>

                  <label className="control-label col-lg-2">รหัสบัตรประชาชน</label>
                  <div className="col-lg-4">
                  <input
                      name="staff_idcard"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสบัตรประชาชน"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.staff_idcard}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อจริง</label>
                  <div className="col-lg-4">
                  <input
                      name="staff_firstname"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อจริง"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.staff_firstname}</div>
                  </div>

                  <label className="control-label col-lg-2">นามสกุล</label>
                  <div className="col-lg-4">
                  <input
                      name="staff_lastname"
                      type="text"
                      className="form-control"
                      placeholder="กรอกนามสกุล"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.staff_lastname}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">วันเดือนปีเกิด</label>
                  <div className="col-lg-4">
                  <input
                      name="staff_birthdate"
                      type="date"
                      className="form-control"
                      placeholder="กรอกวันเดือนปีเกิด"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.staff_birthdate}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">แผนก</label>
                  <div className="col-lg-4">
                  <input
                      name="staff_department"
                      type="text"
                      className="form-control"
                      placeholder="กรอกแผนก"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.staff_department}</div>
                  </div>

                  <label className="control-label col-lg-2">ตำแหน่ง</label>
                  <div className="col-lg-4">
                  <input
                      name="staff_position"
                      type="text"
                      className="form-control"
                      placeholder="กรอกตำแหน่ง"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.staff_position}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">วันที่เริ่มงาน</label>
                  <div className="col-lg-4">
                    <input                       
                      name="start_date"
                      type="date"
                      className="form-control"
                      placeholder="กรอกวันที่เริ่มงาน"
                      onChange={this.handleChange}
                      value={this.state.fields["start_date"]} 
                    /> 
                    <div className="errorMsg">{this.state.errors.start_date}</div>
                  </div>

                  <label className="control-label col-lg-2">ประเภทพนักงาน</label>
                  <div className="col-lg-4">
                  {/* <input
                      name="job_type"
                      type="text"
                      className="form-control"
                      placeholder="กรอกประเภทงาน"
                      onChange={this.handleChange}
                      value={this.state.fields["job_type"] || ""}
                    /> */}
                    <Select
                      name="job_type"
                      placeholder="เลือกประเภทพนักงาน"
                      onChange={this.onSelectChange} 
                      options={this.state.typeJobs}
                      value={this.state.fields["job_type"] || null}
                      // styles={customStyles}
                    />
                    <div className="errorMsg">{this.state.errors.job_type}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะเปิดใช้งาน</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="is_active"
                        type="checkbox"
                        onChange={this.handleChange}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
