import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import { productAction } from "../../../../_actions";
import "../product.css";

$.DataTable = require("datatables.net");
let modalAlert;

export class Product_AddOn_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "pratr_cat_id"
      },
      {
        title: "ชื่อ",
        class: "text-center",
        data: "cate_name_th"
      },
      {
        title: "สถานะ",
        class: "text-center",
        data: "is_active"
      },
      {
        title: "วันที่แก้ไข",
        class: "text-center",
        data: "updated_at"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "pratr_cat_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="isStatus" class="activeBtn" data-id="${rowData.pratr_cat_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: true
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/order-online/product/add-on/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                    <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                  </div>
                </div>
              </div>
              `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.product_v2}/api/product/attribute`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[3, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
      
    });

    $(this.refs.main).on("click", ".activeBtn", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 2;

      var formData = new FormData();
      formData.append("is_active", fval);

      productAction.updateProductAttribute(formData, fid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var attrcate_id = $(this).data("content");

      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(attrcate_id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(attrcate_id) {
      productAction.deleteProductAttribute(attrcate_id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    $(this.refs.main).on("click", ".activeBtn_soldout", function () {
      var id = $(this).data("id");
      var val = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("is_soldout", val);

      productAction.onlineUpdateProductByProductID(formData, id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", ".activeBtn_active", function () {
      var id = $(this).data("id");
      var val = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("is_active", val);

      productAction.onlineUpdateProductByProductID(formData, id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    // $(this.refs.main).on("click", 'button[name="add_addon_btn"]', function() {
    //   window.location.href = `/backoffice/order-online/product/add-on/create`;

    //   state.setState({ show: true, modal: modalAlert() });
    // });

    var state = this;

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการคุณสมบัตรสินค้าออนไลน์</h4>
            <div className="heading-elements">
              <a href="/backoffice/order-online/product/add-on/create">
                <button
                  type="button"
                  name="add_addon_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                  เพิ่มคุณสมบัติ
                </button>
              </a>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
