import React from 'react'
export default () => (
  <div>      
    <div className="text-center content-group">
      <h1 className="error-title">404</h1>
      <h5>Oops, an error has occurred. Page not found!</h5>
    </div>

    <div className="row">
      <div className="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3">
        <form action="#" className="main-search">
          <div className="input-group content-group">
            <input type="text" className="form-control input-lg" placeholder="Search" />

            <div className="input-group-btn">
              <button type="submit" className="btn bg-slate-600 btn-icon btn-lg"><i className="icon-search4"></i></button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <a to="#" className="btn btn-primary btn-block content-group"><i className="icon-circle-left2 position-left"></i> Go to dashboard</a>
            </div>

            <div className="col-sm-6">
              <a to="#" className="btn btn-default btn-block content-group"><i className="icon-menu7 position-left"></i> Advanced search</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
)
