import React from "react";
import { Base_API } from "../../../../_constants/matcher";
import { orderAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../timelogs.css";
import Select from "react-select";

let modalAlert;


export class Order_Online_Timelogs_Info extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      inputBody: [],
      searchMonth:'',
      searchYear:'',
      fields: {},
      branch_id: '',
    };

  }

  componentDidMount() {
    var params = this.props.match.params;
    // this.getDateTimeOnline(params.branch_code);
    this.getDateOnline(params.branch_code);
  }

  getDateTimeOnline(branch_code) {
    orderAction.getTimelogsByBranch(branch_code).then(e => {
      if (e.isSuccess === true) {

        console.log(e.data.data);
        

        var newObjectMonth = Object.assign(e.data.data, this.state.newObjectMonth);
        var newObjectYear = Object.assign(e.data.data, this.state.newObjectYear);
        
        for(var i in newObjectMonth){
          newObjectMonth[i] = { value : newObjectMonth[i].month, label: newObjectMonth[i].month}
        }

        for(var i in newObjectYear){
          newObjectYear[i] = { value : newObjectYear[i].year, label: newObjectYear[i].year}
        }

        this.setState({
          optionsMonth: newObjectMonth ,
          optionsYear: newObjectYear
        });
      } else {
        this.setState({ 
          optionsMonth: [] ,
          optionsYear: []
        });
      }
    });
  }

  handleSubmit(e) {

    this.setState({ inputBody: '' });

    e.preventDefault()
    var params = this.props.match.params;

    var dataSearch = {
        b_id : params.branchid,
        month: (this.state.fields["searchMonth"] ? this.state.fields["searchMonth"] : ''),
        year: (this.state.fields["searchYear"] ? this.state.fields["searchYear"] : ''),
    }

    orderAction.getTimelogsByMonth(dataSearch).then(e => {

      if(e.isSuccess === true) {


        var newObject = Object.assign(e.data, this.state.inputBody);


        for(var i in newObject){
          let avai_text = newObject[i].available_status;

          if(avai_text === 1){
            newObject[i].available_status = <strong className="text-success">ว่าง</strong>
          }else if(avai_text === 2){
            newObject[i].available_status = <strong className="text-danger">เต็ม</strong>
          }else{
            newObject[i].available_status = <strong className="text-info">ไม่ระบุสถานะ</strong>
          }
        }

        this.setState({ inputBody: newObject });
      } else {
        this.setState({ inputBody: [] });
        // alert("ไม่พบข้อมูล");
      }
    });
  }

  render() {


    return (
      <div className="reward-container row">
        <div className="col-12">
          <div className="panel panel-flat">
            <div className="panel-heading panel-heading-wline">
              <h4 className="panel-title">ตารางแสดงวันที่รับสินค้า</h4>
            </div>
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="panel-body pt-4">
                <div className="row pt-3 m-1">
                  
                  <div className="col-md-2 pb-4">
                    <Select
                      name="searchMonth"
                      placeholder="เลือกเดือน"
                      // onChange={this.handleChangeMonth}
                      options={this.state.optionsMonth}
                      className="Select-menu-outer"
                    />
                  </div>
                  <div className="col-md-2 pb-4">
                    <Select
                      name="searchMonth"
                      placeholder="เลือกปี"
                      // onChange={this.handleChangeMonth}
                      options={this.state.optionsYear}
                      className="Select-menu-outer"
                    />
                  </div>

                  <div className="col-lg-2 text-left">
                    <input
                      type="submit"
                      className="btn btn-add btn-md btn-banner-create"
                      value="ค้นหา"
                    />
                  </div>

                </div>
              </div>
                
            </form>
          </div>
        </div>

        {this.state.modal}
      </div>
    );
  }
}
