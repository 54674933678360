import React from "react";
import { redeemAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import moment from "moment-timezone"
import "../../reward.css";

let modalAlert;

export class Redeem_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      typeProgram: null,
      selectType: null,
      inputBody: {
        redeem_category_id: 0
      },
      selectOption: [],
      selectedOption: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmEdit = this.onConfirmEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let { selectOption } = this.state;
    var params = this.props.match.params

    redeemAction.getRedeemCategoryList().then(res => {
      for (var i in res.data) {
        selectOption.push({
          label: res.data[i].redeem_category_name_th,
          value: res.data[i].redeem_category_id
        });
      }
      this.setState({ selectOption });
    });
    this.setState({ redeemid: params.redeem_id })
    this.getRedeemDataByID(params.redeem_id)
  }

  getRedeemDataByID(id) {
    var { selectedOption, inputBody } = this.state;
    redeemAction.getRedeemDataByID(id).then(e => {
        if(e.isSuccess === true) {
          var resp = e.data['0']
            inputBody = resp

            if(inputBody['redeem_image'] === Base_API.reward_category_image) {
                inputBody['oldImage'] =  `${Base_API.reward}/images/default.png`
            } else {
                inputBody['oldImage'] =  inputBody['redeem_image']
            }
            selectedOption['label'] = inputBody['redeem_category_name_th']
            selectedOption['value'] = inputBody['redeem_category_id']
            this.setState({ selectedOption, inputBody: inputBody })
        } else {
            this.onModalError('ผิดพลาด', e.message)
        }
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmEdit()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการเพิ่มข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/redeem/list";
  }

  validateForm() {
    let inputBody = this.state.inputBody;
    let errors = {};
    let formIsValid = true;

    if (!inputBody["redeem_code"]) {
      formIsValid = false;
      errors["redeem_code"] = "*กรุณากรอกรหัสรายการรับแต้ม";
    }

    if (!inputBody["redeem_name_th"]) {
      formIsValid = false;
      errors["redeem_name_th"] = "*กรุณากรอกชื่อรายการรับแต้ม (TH)";
    }

    if (!inputBody["redeem_name_en"]) {
      formIsValid = false;
      errors["redeem_name_en"] = "*กรุณากรอกชื่อรายการรับแต้ม (EN)";
    }

    if (!inputBody["redeem_description_th"]) {
      formIsValid = false;
      errors["redeem_description_th"] = "*กรุณากรอกรายละเอียดรายการรับแต้ม (TH)";
    }

    if (!inputBody["redeem_description_en"]) {
      formIsValid = false;
      errors["redeem_description_en"] = "*กรุณากรอกรายละเอียดรายการรับแต้ม (EN)";
    }

    if (!inputBody["redeem_point"]) {
      formIsValid = false;
      errors["redeem_point"] = "*กรุณากรอกคะแนนที่ได้รับ";
    }
    if (!inputBody["redeem_category_id"]) {
      formIsValid = false;
      errors["redeem_category_id"] = "*กรุณาเลือกประเภทของรายการ";
    }
    if (!inputBody["redeem_count"]) {
      formIsValid = false;
      errors["redeem_count"] = "*กรุณากรอกจำนวนของรายการ";
    }
    if (!inputBody["redeem_image"]) {
      formIsValid = false;
      errors["redeem_image"] = "*กรุณาแนบไฟล์รูปภาพ";
    }
    if (!inputBody["redeem_date_start"]) {
      formIsValid = false;
      errors["redeem_date_start"] = "*กรุณากรอกวันที่เริ่มต้น";
    }
    if (!inputBody["redeem_date_end"]) {
      formIsValid = false;
      errors["redeem_date_end"] = "*กรุณาวันที่สิ้นสุด";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmEdit(ev) {
    this.setState({ modal: null });
    var formData = new FormData();
    if(typeof(this.state.inputBody["redeem_image"]) === 'object') {
      formData.append("redeem_image", this.state.inputBody["redeem_image"]);
    }
    formData.append("redeem_code", this.state.inputBody["redeem_code"]);
    formData.append("redeem_name_th", this.state.inputBody["redeem_name_th"]);
    formData.append("redeem_description_th",this.state.inputBody["redeem_description_th"]);
    formData.append("redeem_name_en", this.state.inputBody["redeem_name_en"]);
    formData.append("redeem_description_en",this.state.inputBody["redeem_description_en"]);
    formData.append("redeem_point", this.state.inputBody["redeem_point"]);
    formData.append("redeem_category_id",this.state.inputBody["redeem_category_id"]);
    formData.append("redeem_count",this.state.inputBody["redeem_count"]);
    formData.append("redeem_note","-");
    formData.append("redeem_date_start", this.state.inputBody["redeem_date_start"]);
    formData.append("redeem_date_end", this.state.inputBody["redeem_date_end"]);
    formData.append("is_open",this.state.inputBody["is_open"] ? this.state.inputBody["is_open"] : 2);

    redeemAction.updateRedeem(formData, this.state.redeemid).then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleSelect = selectedOption => {
    let { inputBody, errors } = this.state;
    inputBody["redeem_category_id"] = selectedOption.value;
    this.state.errors["redeem_category_id"] = null;
    this.setState({ selectedOption, inputBody, errors });
  };

  handleChange = e => {
    let { inputBody, errors } = this.state;
    if (e.target.name === "is_open") {
      inputBody[e.target.name] = e.target.checked === true ? 1 : 2;
    } else {
      inputBody[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ inputBody, errors });
  };

  onChangeUploadHandler = e => {
    let { inputBody, errors } = this.state;
    inputBody[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      inputBody,
      errors
    });
  };

  render() {
    let { selectedOption } = this.state;
    return (
      <div className="reward-container">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขรางวัล - {this.state.inputBody["redeem_name_th"]}</h4>
          </div>
          <br />
          <div className="panel-body">
            <div className="text-center mb-3">
              <img src={this.state.inputBody['oldImage']} style={{ maxWidth: "100%",maxHeight: "200px" }} alt={this.state.inputBody["redeem_name_th"]} />
            </div>
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสรายการรับแต้ม</label>
                  <div className="col-lg-10">
                    <input
                      name="redeem_code"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสรายการรับแต้ม"
                      onChange={this.handleChange}
                      value={this.state.inputBody["redeem_code"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["redeem_code"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อรายการรับแต้ม (TH)</label>
                  <div className="col-lg-10">
                    <input
                      name="redeem_name_th"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อรายการรับแต้ม (TH)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["redeem_name_th"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["redeem_name_th"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อรายการรับแต้ม (EN)</label>
                  <div className="col-lg-10">
                    <input
                      name="redeem_name_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อรายการรับแต้ม (EN)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["redeem_name_en"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["redeem_name_en"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รายละเอียดของรายการรับแต้ม (TH)
                  </label>
                  <div className="col-lg-10">
                    <textarea
                      name="redeem_description_th"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรายละเอียดของรายการรับแต้ม (TH)"
                      onChange={this.handleChange}
                      rows={6}
                      value={this.state.inputBody["redeem_description_th"]}
                    />
                    <div className="errorMsg">{this.state.errors["redeem_description_th"]}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รายละเอียดของรายการรับแต้ม (EN)
                  </label>
                  <div className="col-lg-10">
                    <textarea
                      name="redeem_description_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรายละเอียดของรายการรับแต้ม (EN)"
                      onChange={this.handleChange}
                      rows={6}
                      value={this.state.inputBody["redeem_description_en"]}
                    />
                    <div className="errorMsg">{this.state.errors["redeem_description_en"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    คะแนนที่ได้รับ (P)
                  </label>
                  <div className="col-lg-2">
                    <input
                      name="redeem_point"
                      type="number"
                      className="form-control"
                      placeholder="กรอกคะแนนที่ได้รับ"
                      onChange={this.handleChange}
                      value={this.state.inputBody["redeem_point"]}
                    />
                    <div className="errorMsg">{this.state.errors["redeem_point"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    ประเภทรางวัล
                  </label>
                  <div className="col-lg-10">
                    <Select
                      className="typeSelect"
                      classNamePrefix="typeSelect-menu"
                      placeholder="ประเภทรางวัล"
                      value={selectedOption}
                      name="redeem_category_id"
                      onChange={this.handleSelect}
                      options={this.state.selectOption}
                    />
                    <div className="errorMsg">
                      {this.state.errors["redeem_category_id"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">จำนวน</label>
                  <div className="col-lg-10">
                    <input
                      name="redeem_count"
                      type="number"
                      className="form-control"
                      placeholder="กรอกจำนวนของรางวัล"
                      onChange={this.handleChange}
                      value={this.state.inputBody["redeem_count"]}
                    />
                    <div className="errorMsg">{this.state.errors["redeem_count"]}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">วันที่เริ่มต้น</label>
                  <div className="col-lg-2">
                    <input
                      name="redeem_date_start"
                      type="date"
                      className="form-control"
                      placeholder="กรอกวันที่เริ่มต้น"
                      onChange={this.handleChange}
                      value={this.state.inputBody["redeem_date_start"]}
                    />
                    <div className="errorMsg">{this.state.errors["redeem_date_start"]}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">วันที่สิ้นสุด</label>
                  <div className="col-lg-2">
                    <input
                      name="redeem_date_end"
                      type="date"
                      className="form-control"
                      placeholder="กรอกวันที่สิ้นสุด"
                      onChange={this.handleChange}
                      value={this.state.inputBody["redeem_date_end"]}
                    />
                    <div className="errorMsg">{this.state.errors["redeem_date_end"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รูปของรางวัล
                    <span className="text-danger"> (1920 x 1920 px)</span>
                  </label>
                  <div className="col-lg-10">
                    <input
                      type="file"
                      className="file-input"
                      name="redeem_image"
                      onChange={this.onChangeUploadHandler}
                      data-allowed-file-extensions='["jpg", "jpeg", "png", "gif"]'
                      // value={this.state.inputBody["rewards_image"]}
                    />
                    <div className="errorMsg">
                      {this.state.errors["redeem_image"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
