import React from "react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { productAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../product.css";
import moment from "moment-timezone";

const animatedComponents = makeAnimated();
let modalAlert;

export class Product_Online_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      // messageCreate: '',
      fields: {
        pr_online_sort: 0,
        pr_online_discount_price: 0,
        pr_online_dayreceive: 0,
        shipping_type_id: "",
        pr_online_keywords: "",
        pr_online_typebadge: "normal",
        is_recommend: 0,
        pr_online_promotion_type: ''
      },
      errors: {},
      // typeProgram: null,2
      // selectType: null,
      isStatus: false,
      isRecommend: false,
      is_status_give_point: false,
      is_status_type_ems: false,
      is_status_type_delivery: false,
      is_status_type_gift: false,
      status_give_point: 0,
      status_type_ems: 0,
      status_type_delivery: 0,
      status_type_gift: 0,
      status: 0,
      cateName:[],
      disabled_shipping_type: true,
      attrOption: [],
      attrData: [],
      attrSelected: [],
      prPromotionTypeOptions: [
        {label: 'สินค้าทั่วไป', value: 0},
        {label: 'สินค้าโปรโมชั่น', value: 1}
      ], 
      prPromotionTypeSelected: null
      // disabled:true
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleSelectAttribute = this.handleSelectAttribute.bind(this);
  }

  componentDidMount() {
    productAction.getCategoryProductOnline()
    .then(res => {
        this.setState({
            cateName: res.data
        })
    })

    productAction.getProductAttributeList()
    .then(res => {
      console.log('Product Attribute Set: ', res);
      if(res.isSuccess === true && res.data.length > 0)
      {
        let data = res.data;
        let option = [];
        data.forEach(item => {
          option.push({
            value: item.pratr_cat_id,
            label: item.cate_name_th
          })
        })
  
  
        this.setState({
          attrData: res.data,
          attrOption: option
        })
      }
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/order-online/product"
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["productCode"]) {
      formIsValid = false;
      errors["productCode"] = "*กรุณากรอกรหัสสินค้า";
    }

    if (!fields["productName"]) {
      formIsValid = false;
      errors["productName"] = "*กรุณากรอกชื่อสินค้า (TH)";
    }

    if (!fields["productNameEN"]) {
      formIsValid = false;
      errors["productNameEN"] = "*กรุณากรอกชื่อสินค้า (EN)";
    }

    if (!fields["productImage"]) {
      formIsValid = false;
      errors["productImage"] = "*กรุณาแนบไฟล์รูปภาพ";
    }

    if (!fields["priceProduct"]) {
      formIsValid = false;
      errors["priceProduct"] = "*กรุณากรอกราคาเริ่มต้นสินค้า";
    }

    if (!fields["cate_id"]) {
      formIsValid = false;
      errors["cate_id"] = "*กรุณาเลือกหมวดหมู่สินค้า";
    }

    if (fields["pr_online_promotion_type"] === null || fields["pr_online_promotion_type"] === '' || fields["pr_online_promotion_type"] === undefined) {
      formIsValid = false;
      errors["pr_online_promotion_type"] = "*กรุณาระบุประเภทสินค้า";
    }

    // if (!fields["discountProduct"]) {
    //   formIsValid = false;
    //   errors["discountProduct"] = "*กรุณากรอกส่วนลดสินค้า";
    // }

    if (!fields["descriptProduct"]) {
      formIsValid = false;
      errors["descriptProduct"] = "*กรุณากรอกรายละเอียดสินค้า (TH)";
    }

    if (!fields["descriptProductEN"]) {
      formIsValid = false;
      errors["descriptProductEN"] = "*กรุณากรอกรายละเอียดสินค้า (EN)";
    }

    if (!fields["productHeight"]) {
      formIsValid = false;
      errors["productHeight"] = "*กรุณากรอกความสูงของบรรจุภัณฑ์ (ซม.)";
    }

    if (!fields["productWidth"]) {
      formIsValid = false;
      errors["productWidth"] = "*กรุณากรอกความกว้างของบรรจุภัณฑ์ (ซม.)";
    }

    if (!fields["productDepth"]) {
      formIsValid = false;
      errors["productDepth"] = "*กรุณากรอกความลึกของบรรจุภัณฑ์ (ซม.)";
    }

    if (!fields["productWeight"]) {
      formIsValid = false;
      errors["productWeight"] = "*กรุณากรอกน้ำหนักของสินค้า (กรัม)";
    }

    if (!fields["shipping_type_id"] && this.state.status_type_ems === 1) {
      formIsValid = false;
      errors["shipping_type_id"] = "*กรุณาเลือกประเภทการจัดส่งสินค้า";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  async onConfirmCreate(ev) {
    this.setState({ modal: null })
    let dataAttr = await this.reNewDataAttr();

    console.log(`Re-New data Attribute: `, dataAttr);

    var formData = new FormData();
    formData.append("pr_online_code", this.state.fields["productCode"]);
    formData.append("pr_online_name", this.state.fields["productName"]);
    formData.append("pr_online_name_en", this.state.fields["productNameEN"]);
    formData.append("pr_online_description", this.state.fields["descriptProduct"]);
    formData.append("pr_online_description_en", this.state.fields["descriptProductEN"]);
    formData.append("pr_online_height", this.state.fields["productHeight"]);
    formData.append("pr_online_width", this.state.fields["productWidth"]);
    formData.append("pr_online_depth", this.state.fields["productDepth"]);
    formData.append("pr_online_weight", this.state.fields["productWeight"]);
    formData.append("pr_online_image", this.state.fields["productImage"]);
    formData.append("pr_online_price", this.state.fields["priceProduct"]);
    formData.append("pr_online_discount_price", this.state.fields["pr_online_discount_price"]);
    formData.append("pr_online_category_id", this.state.fields["cate_id"]);
    formData.append("pr_online_sort", this.state.fields["pr_online_sort"]);
    formData.append("pr_online_give_point", this.state.status_give_point);
    formData.append("pr_online_type_ems", this.state.status_type_ems);
    formData.append("pr_online_type_delivery", this.state.status_type_delivery);
    formData.append("pr_online_type_gift", this.state.status_type_gift);
    formData.append("pr_online_shipping_type_id", this.state.fields["shipping_type_id"]);
    formData.append("pr_online_typebadge", this.state.fields["pr_online_typebadge"]);
    formData.append("pr_online_keywords", this.state.fields["pr_online_keywords"]);
    formData.append("pr_online_dayreceive", this.state.fields["pr_online_dayreceive"]);
    formData.append("pr_online_promotion_type", this.state.fields["pr_online_promotion_type"]);
    formData.append("is_active", this.state.status);
    formData.append("is_recommend", this.state.fields.is_recommend);
    formData.append("attribute_set", JSON.stringify(dataAttr));

    // ( V1 )
    // productAction.createPreProduct(formData).then(e => {
    //   if(e.isSuccess === true) {
    //     this.onModalSuccess('Success', e.message)
    //   } else {
    //     this.onModalError('Error', e.message)
    //   }
    // })

    // ( V2 )
    productAction.createPreProductV2(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  reNewDataAttr() {
    return new Promise(async resolve => {
      let {attrSelected, fields} = this.state;  

      if(attrSelected.length > 0)
      {
        attrSelected.forEach(item => {
          item.created_at = moment().format("Y-MM-DD HH:mm:ss");
          item.pr_online_code = fields["productCode"];
        })
        resolve(attrSelected);
      }else
      {
        resolve([]);
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
      let { fields, errors } = this.state;
      fields[e.target.name] = e.target.value;
      this.state.errors[e.target.name] = null;
      this.setState({ fields, errors });
  };

  handleSelectAttribute = (list, action) => {
    let actionName = action.name;

    if(actionName === 'product_attribute')
    {
      let newAttrSelected = [];

      if(!!list)
      {
        for(var i in list)
        {
          let index = Number(i);
          let itemAttr = {
            pratr_cat_id: list[index].value
          }
          newAttrSelected.push(itemAttr);
  
          if(index === list.length-1)
          {
            this.setState({
              attrSelected: newAttrSelected
            })
          }
        }
      }else
      {
        this.setState({
          attrSelected: []
        })
      }
    }
  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });

    if(action.name === "pr_online_promotion_type")
    {
      this.setState({
        prPromotionTypeSelected: {
          label: e.label,
          value: e.value
        }
      })
    }
  };


handleInputChange = e => {

  const target = e.target;
  const name = target.name;

  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })
    if(this.state.isStatus == true){
      this.setState({ status: 0 })
    }else{
      this.setState({ status: 1 })
    }
  }

  if(name === "isRecommend")
  {
    if(this.state.isRecommend === true)
    {
      this.setState({fields: {...this.state.fields, is_recommend: 0 }})
    }else
    {
      this.setState({fields: {...this.state.fields, is_recommend: 1 }})
    }
    this.setState({ isRecommend: !this.state.isRecommend })
  }

  if(name == "is_status_give_point"){
    this.setState({ is_status_give_point: !this.state.is_status_give_point })
    if(this.state.is_status_give_point == true){
      this.setState({ status_give_point: 0 })
    }else{
      this.setState({ status_give_point: 1 })
    }
  }

  if(name == "is_status_type_ems"){
    this.setState({ is_status_type_ems: !this.state.is_status_type_ems })
    if(this.state.is_status_type_ems == true){
      this.setState({ 
        status_type_ems: 0,
        disabled_shipping_type: true
      })
    }else{
      this.setState({ 
        status_type_ems: 1 ,
        disabled_shipping_type: false
      })
    }
  }

  if(name == "is_status_type_delivery"){
    this.setState({ is_status_type_delivery: !this.state.is_status_type_delivery })
    if(this.state.is_status_type_delivery == true){
      this.setState({ status_type_delivery: 0 })
    }else{
      this.setState({ status_type_delivery: 1 })
    }
  }

  if(name == "is_status_type_gift"){
    this.setState({ is_status_type_gift: !this.state.is_status_type_gift })
    if(this.state.is_status_type_gift == true){
      this.setState({ status_type_gift: 0 })
    }else{
      this.setState({ status_type_gift: 1 })
    }
  }
}


  render() {
    let {attrOption} = this.state;
    let optionsCate = this.state.cateName.map(function (cateName) {
      return { value: cateName.pr_online_category_id, label: cateName.pr_online_category_name };
    })

    let optionsShipping = [
      { value: 1, label: "สินค้าประเภทของแห้ง" },
      { value: 2, label: "สินค้าประเภทของแช่เย็น" },
      { value: 3, label: "สินค้าประเภทของแช่แข็ง" }
    ]

    let optionsTypebadge = [
      { value: "normal", label: "normal" },
      { value: "recommend", label: "recommend" },
      { value: "hot", label: "hot" },
      { value: "promotion", label: "promotion" },
      { value: "new", label: "new" }
    ]

    return (

      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มสินค้าสั่งซื้อออนไลน์</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">

              <div className="form-group">
                <label className="control-label col-lg-2">รูปภาพสินค้า
                  <div className="text-danger"> (1280 x 1280 px)</div>
                </label>
                  <div className="col-lg-10">
                  <input type="file" className="file-input" name="productImage" accept="image/*" onChange={this.onChangeUploadHandler}/>
                  <div className="errorMsg">{this.state.errors.productImage}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสินค้า</label>
                  <div className="col-lg-10">
                  <input
                      name="productCode"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productCode}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสินค้า (TH)</label>
                  <div className="col-lg-4">
                  <input
                      name="productName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสิินค้า (TH)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productName}</div>
                  </div>

                  <label className="control-label col-lg-2">ชื่อสินค้า (EN)</label>
                  <div className="col-lg-4">
                  <input
                      name="productNameEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสิินค้า (EN)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productNameEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">หมวดหมู่สินค้า</label>
                  <div className="col-lg-4">
                    <Select
                      name="cate_id"
                      placeholder="เลือกหมวดหมู่สินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsCate}
                    />
                    <div className="errorMsg">{this.state.errors.cate_id}</div>
                  </div>

                  <label className="control-label col-lg-2">ป้ายกำกับสินค้า</label>
                  <div className="col-lg-4">
                    <Select
                      name="pr_online_typebadge"
                      placeholder="เลือกป้ายกำกับสินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsTypebadge}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_typebadge}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เลือกประเภทสินค้า</label>
                    <div className="col-lg-4">
                    <Select
                      name="pr_online_promotion_type"
                      placeholder="เลือกประเภทสินค้า"
                      onChange={this.handleSelectChange}
                      options={this.state.prPromotionTypeOptions}
                      value={this.state.prPromotionTypeSelected}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_promotion_type}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสินค้า (Recommend)</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="isRecommend" type="checkbox" onChange={this.handleInputChange} checked={this.state.isRecommend}/>
                    <div className="slider"></div>
                  </label>
                  </div>

                  <div></div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ความสูงของบรรจุภัณฑ์ (ซม.)</label>
                  <div className="col-lg-4">
                  <input
                      name="productHeight"
                      type="text"
                      className="form-control"
                      placeholder="ความสูงของบรรจุภัณฑ์ (ซม.)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productHeight}</div>
                  </div>

                  <label className="control-label col-lg-2">ความกว้างของบรรจุภัณฑ์ (ซม.)</label>
                  <div className="col-lg-4">
                  <input
                      name="productWidth"
                      type="text"
                      className="form-control"
                      placeholder="ความกว้างของบรรจุภัณฑ์ (ซม.)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productWidth}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ความลึกของบรรจุภัณฑ์ (ซม.)</label>
                  <div className="col-lg-4">
                  <input
                      name="productDepth"
                      type="text"
                      className="form-control"
                      placeholder="ความลึกของบรรจุภัณฑ์ (ซม.)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productDepth}</div>
                  </div>

                  <label className="control-label col-lg-2">น้ำหนักของสินค้า (กรัม)</label>
                  <div className="col-lg-4">
                  <input
                      name="productWeight"
                      type="text"
                      className="form-control"
                      placeholder="น้ำหนักของบรรจุภัณฑ์ (กรัม)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productWeight}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียด (TH)</label>
                  <div className="col-lg-10">
                  <textarea className="form-control"
                      name="descriptProduct"
                      placeholder="กรอกรายละเอียดสินค้า (TH)"
                      onChange={this.handleChange}
                      rows="3">
                  </textarea>
                    <div className="errorMsg">{this.state.errors.descriptProduct}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียด (EN)</label>
                  <div className="col-lg-10">
                  <textarea className="form-control"
                      name="descriptProductEN"
                      placeholder="กรอกรายละเอียดสินค้า (EN)"
                      onChange={this.handleChange}
                      rows="3">
                  </textarea>
                    <div className="errorMsg">{this.state.errors.descriptProductEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ราคาเริ่มต้น (฿)</label>
                  <div className="col-lg-4">
                    <input
                      name="priceProduct"
                      type="number"
                      className="form-control"
                      placeholder="กรอกราคาสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.priceProduct}</div>
                  </div>

                  <label className="control-label col-lg-2">ลำดับที่แสดง</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_sort"
                      type="number"
                      step="0"
                      className="form-control"
                      placeholder="กรอกลำดับที่แสดง"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_sort}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะแสดงสินค้าตอนค้นหา Delivery & Pickup & Snackbox & Eatin</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="is_status_type_delivery" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_type_delivery}/>
                    <div className="slider"></div>
                  </label>
                  </div>

                  <label className="control-label col-lg-2">สถานะแสดงสินค้าตอนค้นหา EMS</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="is_status_type_ems" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_type_ems}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะแสดงสินค้าตอนค้นหา Gift</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="is_status_type_gift" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_type_gift}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ประเภทการจัดส่งสินค้า <br /> (เฉพาะสินค้า EMS)</label>
                  <div className="col-lg-4">
                    <Select
                      name="shipping_type_id"
                      placeholder="เลือกประเภทการจัดส่งสินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsShipping}
                      isDisabled={this.state.disabled_shipping_type}
                    />
                    <div className="errorMsg">{this.state.errors.shipping_type_id}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">จำนวนวันขั้นต่ำของวันที่รับสินค้า <br /> (เฉพาะสินค้า Snackbox)</label>
                  <div className="col-lg-4">
                    <input
                      name="pr_online_dayreceive"
                      type="number"
                      className="form-control"
                      placeholder="กรอกจำนวนวันที่รับสินค้าขั้นต่ำ"
                      onChange={this.handleChange}
                      value={this.state.fields["pr_online_dayreceive"]}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_dayreceive}</div>
                  </div>
                </div>

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">คะแนนสินค้า (P)</label>
                  <div className="col-lg-4">
                  <input
                      name="productPoint"
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="กรอกคะแนนสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.productPoint}</div>
                  </div>
                </div> */}

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">ส่วนลด (บาท)</label>
                  <div className="col-lg-4">
                  <input
                      name="discountProduct"
                      type="number"
                      className="form-control"
                      placeholder="กรอกส่วนลดสินค้า"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.discountProduct}</div>
                  </div>
                </div> */}

                <div className="form-group">
                  <label className="control-label col-lg-2">keyword สินค้า (มีผลต่อการค้นหา)
                  <div className="text-danger"> (ขั่นคำด้วย "," เช่น ขนม,afteryou)</div></label>
                  
                  <div className="col-lg-10">
                  <input
                      name="pr_online_keywords"
                      type="text"
                      className="form-control"
                      placeholder="keyword สินค้า"
                      value={this.state.fields["pr_online_keywords"]}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_keywords}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">คุณลักษะสินค้า <div className="text-danger"> (สามารถพิมพ์ค้นหาได้)</div></label>
                  <div className="col-lg-4">
                    <Select
                      name="product_attribute"
                      placeholder="เลือกคุณลักษณะสินค้า"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      onChange={this.handleSelectAttribute}
                      isMulti
                      options={attrOption}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการให้คะแนน</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="is_status_give_point" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_give_point}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสินค้า</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
