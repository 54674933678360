import React from "react";
import { Base_API } from "../../../../_constants/matcher";
import { rewardAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../reward.css";

let modalAlert;

export class Reward_Category_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      typeProgram: null,
      selectType: null,
      inputBody: {},
      rewardid: 0
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmUpdate = this.onConfirmUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
      var params = this.props.match.params
      this.setState({ rewardid: params.rewards_id })
      this.getRewardDataByID(params.rewards_id)
  }

  getRewardDataByID(id) {
    rewardAction.getRewardCategoryDataByID(id).then(e => {
        if(e.isSuccess === true) {
            var newObject = Object.assign(e.data, this.state.inputBody)
            // if(newObject['rewards_category_image'] === Base_API.rewards_category_image) {
            //     newObject['oldImage'] =  `${process.env.PUBLIC_URL}/images/default.png`
            // } else {
            //     newObject['oldImage'] =  newObject['rewards_category_image']
            // }
            newObject['rewards_category_type_1'] = (newObject['rewards_category_type'] == '1' ? true : false)
            newObject['rewards_category_type_2'] = (newObject['rewards_category_type'] == '2' ? true : false)
            this.setState({ inputBody: newObject })
        } else {
            this.onModalError('ผิดพลาด', e.message)
        }
    })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmUpdate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/reward/category";
  }

  validateForm() {
    let inputBody = this.state.inputBody;
    let errors = {};
    let formIsValid = true;

    if (!inputBody["rewards_category_name"]) {
      formIsValid = false;
      errors["rewards_category_name"] = "*กรุณากรอกชื่อหมวดหมู่รางวัล (TH)";    }

    if (!inputBody["rewards_category_name_en"]) {
      formIsValid = false;
      errors["rewards_category_name_en"] = "*กรุณากรอกชื่อหมวดหมู่รางวัล (EN)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmUpdate(ev) {
    this.setState({ modal: null });
    var formData = new FormData();
    // if(typeof(this.state.inputBody["rewards_category_image"]) === 'object') {
    //     formData.append("rewards_category_image", this.state.inputBody["rewards_category_image"]);
    // }
    formData.append("rewards_category_name",this.state.inputBody["rewards_category_name"]);
    formData.append("rewards_category_subtitle","-");
    formData.append("rewards_category_name_en",this.state.inputBody["rewards_category_name_en"]);
    formData.append("rewards_category_subtitle_en","-");
    formData.append("rewards_category_type",this.state.inputBody["rewards_category_type"]);
    formData.append("is_open",this.state.inputBody["is_open"] ? this.state.inputBody["is_open"] : 2);
    formData.append("user_id", 1);

    rewardAction.updateRewardCategory(formData, this.state.rewardid).then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { inputBody, errors } = this.state;
    if (e.target.name === "is_open") {
      inputBody[e.target.name] = e.target.checked === true ? 1 : 2;
    } else {
      inputBody[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ inputBody, errors });
  };

  onChangeUploadHandler = e => {
    let { inputBody, errors } = this.state;
    inputBody[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      inputBody,
      errors
    });
  };

  onImageError(e) {
    e.target.setAttribute("src", `${process.env.PUBLIC_URL}/images/default.png`);
  }

  render() {
    return (
      <div className="reward-container">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขหมวดหมู่รางวัล</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    ชื่อหมวดหมู่รางวัล (TH)
                  </label>
                  <div className="col-lg-10">
                    <input
                      name="rewards_category_name"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อหมวดหมู่รางวัล (TH)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_category_name"] || ''}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_category_name"]}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    ชื่อหมวดหมู่รางวัล (EN)
                  </label>
                  <div className="col-lg-10">
                    <input
                      name="rewards_category_name_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อหมวดหมู่รางวัล (EN)"
                      onChange={this.handleChange}
                      value={this.state.inputBody["rewards_category_name_en"] || ''}
                    />
                    <div className="errorMsg">
                      {this.state.errors["rewards_category_name_en"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รูปแบบการแลกคูปอง
                  </label>
                  <div className="col-lg-10">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="rewards_category_type" id="rewards_category_type_1" defaultChecked={this.state.inputBody['rewards_category_type_1']} onChange={this.handleChange} value="1" />
                      <label class="form-check-label" for="rewards_category_type_1">&nbsp;แตกคูปอง</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="rewards_category_type" id="rewards_category_type_2" defaultChecked={this.state.inputBody['rewards_category_type_2']} onChange={this.handleChange} value="2" />
                      <label class="form-check-label" for="rewards_category_type_2">&nbsp;รวมคูปอง</label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    สถานะเปิดใช้งาน
                  </label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="is_open"
                        type="checkbox"
                        onChange={this.handleChange}
                        checked={(this.state.inputBody["is_open"] === 1) ? true : false}
                      />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
