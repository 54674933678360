import React from "react";
import $ from "jquery";
// import { adminAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import { NavLink } from "react-router-dom";
import "../../activity.css";

const urlPath = window.location.hostname
var urlAU = ""
console.log(urlPath);
if (
  urlPath === "https://afteryou-backoffice.podsable.com" 
  || 
  urlPath === "afteryou-backoffice.podsable.com" 
  || 
  urlPath === "grabapi.afteryoudessertcafe.com" 
  || 
  urlPath === "podsable.com"
  ||
  urlPath === "afteryoumobileprod.com"
  ||
  urlPath === "afteryoumobileuat.com"
  ||
  urlPath === "afteryoumobileuat.mmm2007.net"
) 
{
  urlAU = "/backoffice"  
}

export class Activity_All extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat pb-4">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">กิจกรรมทั้งหมด</h4>
          </div>
          
          <div class="row activity m-4">
            <div class="col-lg-2">
              <NavLink to={"/backoffice/activity/flashsale/time"}>
                <div className="card text-center">
                  <div className="div-image-activity">
                    <img src={urlAU + `/images/flash-sale.png`} class="card-img-activity" alt="Flash Sale" />
                  </div>
                  <div class="card-body">
                    <h4 class="card-title">Flash Sale</h4>
                  </div>
                </div>
              </NavLink>
            </div>
            <div class="col-lg-2">
              <NavLink to={"/backoffice/activity/friendgetfriend/list"}>
                <div className="card text-center">
                  <img src={urlAU + `/images/friends.png`} class="card-img-activity" alt="Friend Get Friend" />
                  <div class="card-body">
                    <h4 class="card-title">Friend Get Friend</h4>
                  </div>
                </div>
              </NavLink>
            </div>
            <div class="col-lg-2">
              <NavLink to={"/backoffice/activity/discount_delivery/list"}>
                <div className="card text-center">
                  <img src={urlAU + `/images/free-delivery.png`} class="card-img-activity" alt="Free Delivery" />
                  <div class="card-body">
                    <h4 class="card-title">Discount Delivery</h4>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
