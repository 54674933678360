import React from "react";
import { activityAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../activity.css";
import Select from 'react-select';
import moment from "moment-timezone";

let modalAlert;

export class DiscountDelivery_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: '',
      fields: {
        status_delivery_cost_free: 0,
        discount_delivery_cost: 0
      },
      errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {

  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }

  handleSubmit(event) {
    console.log('1');
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/activity/discount_delivery/list"
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["total_amount_product_au"]) {
      formIsValid = false;
      errors["total_amount_product_au"] = "*กรุณากรอกราคาที่ซื้อขั้นต่ำ";
    }

    if (!fields["distance_min_delivery_free"]) {
      formIsValid = false;
      errors["distance_min_delivery_free"] = "*กรุณากรอกระยะทางขั้นต่ำ";
    }

    if (!fields["distance_max_delivery_free"]) {
      formIsValid = false;
      errors["distance_max_delivery_free"] = "*กรุณากรอกระยะทางสูงสุด";
    }    

    if (fields["discount_delivery_time_start"] == undefined) {
      formIsValid = false;
      errors["discount_delivery_time_start"] = "*กรุณาระบุวันที่เริ่มต้น";
    }

    if (fields["discount_delivery_time_end"] == undefined) {
      formIsValid = false;
      errors["discount_delivery_time_end"] = "กรุณาระบุวันที่สิ้นสุด";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    let inputBody = {
      total_amount_product_au: this.state.fields["total_amount_product_au"],
      distance_min_delivery_free: this.state.fields["distance_min_delivery_free"],
      distance_max_delivery_free: this.state.fields["distance_max_delivery_free"],
      status_delivery_cost_free: this.state.fields["status_delivery_cost_free"],
      discount_delivery_cost: this.state.fields["discount_delivery_cost"],
      discount_delivery_time_start: moment(this.state.fields["discount_delivery_time_start"]).format('Y-MM-DD HH:mm:ss'),
      discount_delivery_time_end: moment(this.state.fields["discount_delivery_time_end"]).format('Y-MM-DD HH:mm:ss'),
    }
    activityAction.DiscountDeliveryCreate(inputBody).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })
  }

  render() {

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มข้อมูลกิจกรรม Discount Delivery</h4>
          </div>
          <br />
          <div className="panel-body">
            
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              
              <div className="form-group">
                <label className="control-label col-md-2">ราคาที่ซื้อขั้นต่ำ</label>
                <div className="col-md-2">
                  <input
                    name="total_amount_product_au"
                    type="number"
                    className="form-control"
                    placeholder="กรอกราคาที่ซื้อขั้นต่ำ"
                    value={this.state.fields.total_amount_product_au || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.total_amount_product_au}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-2">ระยะทางขั้นต่ำ</label>
                <div className="col-md-2">
                  <input
                    name="distance_min_delivery_free"
                    type="number"
                    className="form-control"
                    placeholder="กรอกระยะทางขั้นต่ำ"
                    value={this.state.fields.distance_min_delivery_free || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.distance_min_delivery_free}</div>
                </div>

                <label className="control-label col-md-2">ระยะทางสูงสุด</label>
                <div className="col-md-2">
                  <input
                    name="distance_max_delivery_free"
                    type="number"
                    className="form-control"
                    placeholder="กรอกระยะทางสูงสุด"
                    value={this.state.fields.distance_max_delivery_free || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.distance_max_delivery_free}</div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-2">สถานะส่งฟรี</label>
                <div className="col-md-2">
                  <label className="switch">
                    <input name="status_delivery_cost_free" type="checkbox" onChange={this.handleInputChange} checked={this.state.status_delivery_cost_free}/>
                    <div className="slider"></div>
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-2">
                  ส่วนลดค่าส่ง
                  <span className="text-danger"> <br />*หากส่งฟรีไม่ต้องกรอกส่วนนี้ </span>

                </label>
                <div className="col-md-2">
                  <input
                    name="discount_delivery_cost"
                    type="number"
                    className="form-control"
                    placeholder="กรอกส่วนลดค่าส่ง"
                    value={this.state.fields.discount_delivery_cost || ""}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
  
              <div className={`form-group`}>
                <label className="control-label col-md-2">วันเวลาเริ่ม</label>
                <div className="col-md-3">
                  <input
                    name="discount_delivery_time_start"
                    type="datetime-local"
                    className="form-control"
                    value={this.state.fields.discount_delivery_time_start || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.discount_delivery_time_start}</div>
                </div>

                <label className="control-label col-md-2">วันเวลาสิ้นสุด</label>
                <div className="col-md-3">
                  <input
                    name="discount_delivery_time_end"
                    type="datetime-local"
                    className="form-control"
                    value={this.state.fields.discount_delivery_time_end || ""}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.discount_delivery_time_end}</div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-lg-12 text-right">
                  <input
                    type="submit"
                    className="btn btn-success btn-md btn-banner-create"
                    value="บันทึก"
                  />
                </div>
              </div>
            </form>  
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
