import React from "react";
import $ from "jquery";
import { BackofficeAction, staffAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactExport from 'react-data-export';

import "../staff.css";
import moment from "moment-timezone";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

$.DataTable = require("datatables.net");
var multiDataSet

let modalAlert;
let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd+mm+yyyy+hh+mn+ss+ms+'-staff'

export class Staff_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false,
      excelUrl: ""
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    getExcelStaffList();

    // Get Excel Members
    function getExcelStaffList() {
      BackofficeAction.getExcelStaffList()
      .then(res => {
        console.log(res)
        if(res.status === true)
        {
          state.setState({ excelUrl: res.data })
        }
      })
    }

    const columns = [
      {
        title: "รหัสพนักงาน",
        class: "text-center",
        data: "staff_number"
      },
      {
        title: "ขื่อจริง",
        class: "text-left",
        data: "staff_firstname"
      },
      {
        title: "นามสกุล",
        class: "text-left",
        data: "staff_lastname"
      },
      {
        title: "วันเดือนปีเกิด",
        class: "text-center",
        data: "staff_birthdate"
      },
      {
        title: "แผนก",
        class: "text-left",
        data: "staff_department"
      },
      {
        title: "ตำแหน่ง",
        class: "text-left",
        data: "staff_position"
      },
      {
        title: "ประเภท",
        class: "text-center",
        data: "job_type"
      },
      {
        title: "เบอร์โทรศัพท์",
        class: "text-left",
        data: "mem_phone"
      },
      {
        title: "รหัสบัตรประชาชน",
        class: "text-left",
        data: "staff_idcard"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "staff_id"
      },
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets:3,
          createdCell: function(td, cellData, rowData, row, col) {
            if(!!cellData)
            {
              $(td)
                .html(moment(cellData).format('DD-MM-YYYY'));
            }else
            {
              $(td)
                .html('-');
            }
          },
          orderable: false
        },
        {
          targets:6,
          createdCell: function(td, cellData, rowData, row, col) {
            if(!!cellData)
            {
              $(td)
                .html(cellData === 'full_time' ? 'Full Time' : 'Part Time');
            }else
            {
              $(td)
                .html('-');
            }
          },
          orderable: false
        },
        {
          targets:9,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/staff/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                    <a href="/backoffice/ewallet/staff/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไขจำนวน e-wallet</a>
                    <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                  </div>
                </div>
              </div>
            `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.admin}/staff`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[8, "asc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(id) {
      staffAction.deleteStaffById(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    var dataExport = []
    let data = this.state.dataExport   
    for(var i in data){
      dataExport.push(
        [
          {value: (data[i].staff_number ? data[i].staff_number : "")},
          {value: (data[i].staff_firstname ? data[i].staff_firstname : "")},
          {value: (data[i].staff_lastname ? data[i].staff_lastname : "")},
          {value: (data[i].staff_department ? data[i].staff_department : "")},
          {value: (data[i].staff_position ? data[i].staff_position : "")},
          {value: (data[i].job_type ? data[i].job_type : "")},
          {value: (data[i].mem_phone ? data[i].mem_phone : "")},
          {value: (data[i].staff_idcard ? data[i].staff_idcard : "")}
          // {value: (data[i].is_active == 1 ? 'active' : 'inactive'), style: {fill: {fgColor: {rgb: (data[i].is_active == 1 ? "FF00FF00" : "FFFF0000")}}}},
        ],
      )
    }    
    
    multiDataSet = [
      {
          columns: [
              {title: "รหัสพนักงาน"},
              {title: "ชื่อจริง", width: {wch: 30}},//char width 
              {title: "นามสกุล", width: {wch: 30}},
              {title: "แผนก", width: {wch: 30}},
              {title: "ตำแหน่ง", width: {wch: 30}},
              {title: "ประเภทพนักงาน", width: {wch: 30}},
              {title: "เบอร์โทรศัพท์", width: {wch: 20}},
              {title: "รหัสบัตรประชาชน", width: {wch: 20}}
          ],
          data: dataExport
      }
    ];
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายชื่อพนักงาน ( สำหรับสิทธิ์ในการแลกรีวอร์ด )</h4>

            <div className="heading-elements">
              <a href="/backoffice/staff/create">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                  <i className="icon-plus2"></i>
                  เพิ่มรายชื่อพนักงาน
                </button>
              </a>

              {/* <ExcelFile 
                element={
                  <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                  >
                  <i className="icon-download"></i> Export Excel
                  </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Staff" />
              </ExcelFile> */}
              {this.state.excelUrl !== ""
                ? 
                  <a href={this.state.excelUrl}>
                    <button
                        type="button"
                        name="add_banner_btn"
                        data-content=""
                        className="btn btn-add mr-2"
                      >
                        <i className="icon-download"></i> Export Excel
                    </button>
                  </a>
                :
                  <button
                      type="button"
                      name="add_banner_btn"
                      data-content=""
                      className="btn mr-2 disabled"
                      style={{ cursor: 'not-allowed' }}
                    >
                      <i className="icon-download"></i> Export Excel
                  </button>
              }
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
