import React from "react";
import { branchAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../branch.css";

let modalAlert;

export class Order_Online_Branch_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      isStatus: false,
      status: 2,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการเพิ่มข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/order-online/branch/";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["branchCode"]) {
      formIsValid = false;
      errors["branchCode"] = "*กรุณากรอกรหัสสาขา";
    }

    if (!fields["branchName"]) {
      formIsValid = false;
      errors["branchName"] = "*กรุณากรอกชื่อสาขา (TH)";
    }

    if (!fields["branchNameEN"]) {
      formIsValid = false;
      errors["branchNameEN"] = "*กรุณากรอกชื่อสาขา (EN)";
    }

    if (!fields["branchImage"]) {
      formIsValid = false;
      errors["branchImage"] = "*กรุณาแนบไฟล์รูปภาพ";
    }

    if (!fields["branchAddress"]) {
      formIsValid = false;
      errors["branchAddress"] = "*กรุณากรอกที่ตั้งสาขา (TH)";
    }

    if (!fields["branchAddressEN"]) {
      formIsValid = false;
      errors["branchAddressEN"] = "*กรุณากรอกที่ตั้งสาขา (EN)";
    }

    if (!fields["branchTelephone"]) {
      formIsValid = false;
      errors["branchTelephone"] = "*กรุณากรอกเบอร์โทรศัพท์";
    }

    if (!fields["latName"]) {
      formIsValid = false;
      errors["latName"] = "*กรุณากรอกละติจูดสาขา";
    }

    if (!fields["longName"]) {
      formIsValid = false;
      errors["longName"] = "*กรุณากรอกลองติจูดสาขา";
    }

    if (!fields["time_open"]) {
      formIsValid = false;
      errors["time_open"] = "*กรุณากรอกเวลาทำการ (TH)";
    }

    if (!fields["time_open_en"]) {
      formIsValid = false;
      errors["time_open_en"] = "*กรุณากรอกเวลาทำการ (EN)";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });
    var formData = new FormData();
    if(this.state.fields["branchImage"]) { formData.append("b_image", this.state.fields["branchImage"]); }
    formData.append("b_code", this.state.fields["branchCode"]);
    formData.append("b_name", this.state.fields["branchName"]);
    formData.append("b_name_en", this.state.fields["branchNameEN"]);
    formData.append("b_address", this.state.fields["branchAddress"]);
    formData.append("b_address_en", this.state.fields["branchAddressEN"]);
    formData.append("b_telephone", this.state.fields["branchTelephone"]);
    formData.append("b_lat", this.state.fields["latName"]);
    formData.append("b_long", this.state.fields["longName"]);
    formData.append("time_open", this.state.fields["time_open"]);
    formData.append("time_open_en", this.state.fields["time_open_en"]);
    formData.append("b_status", this.state["status"]);

    branchAction.createBranchPreorder(formData).then(e => {
      if (e.isSuccess === true) {

        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleInputChange = e => {
    const target = e.target;
    const name = target.name;
    let status;

    if (name == "isStatus") {
      this.setState({ isStatus: !this.state.isStatus });
      if (this.state.isStatus == true) {
        this.setState({ status: "2" });
      } else {
        this.setState({ status: "1" });
      }
    }

  };

  render() {
    return (
      <div className="preorder-branch-container">
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มสาขาที่รับสินค้าออนไลน์</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
              <div className="form-group">
                  <label className="control-label col-lg-2">
                    รูปภาพประจำสาขา
                  </label>
                  <div className="col-lg-10">
                    <input
                      type="file"
                      className="file-input"
                      name="branchImage"
                      id="file"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchImage}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสาขา</label>
                  <div className="col-lg-10">
                    <input
                      name="branchCode"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสสาขา"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchCode}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสาขา (TH)</label>
                  <div className="col-lg-10">
                    <input
                      name="branchName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสาขา (TH)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchName}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสาขา (EN)</label>
                  <div className="col-lg-10">
                    <input
                      name="branchNameEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสาขา (EN)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchNameEN}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ที่ตั้งสาขา (TH)</label>
                  <div className="col-lg-10">
                    <textarea
                      name="branchAddress"
                      className="form-control"
                      placeholder="กรอกที่ตั้งสาขา"
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchAddress}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ที่ตั้งสาขา (EN)</label>
                  <div className="col-lg-10">
                    <textarea
                      name="branchAddressEN"
                      className="form-control"
                      placeholder="กรอกที่ตั้งสาขา (EN)"
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchAddressEN}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เบอร์โทรศัพท์</label>
                  <div className="col-lg-10">
                    <input
                      name="branchTelephone"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.branchTelephone}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ละติจูด</label>
                  <div className="col-lg-10">
                    <input
                      name="latName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกละติจูด"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.latName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ลองติจูด</label>
                  <div className="col-lg-10">
                    <input
                      name="longName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกลองติจูด"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.longName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ</label>
                  <div className="col-lg-10">
                    <label className="switch">
                      <input
                        name="isStatus"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.isStatus}
                      />
                      <div className="slider" />
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เวลาทำการ (TH)</label>
                  <div className="col-lg-10">
                    <input
                      name="time_open"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเวลาทำการ เช่น 10:00 น. - 10:00 น. (ทุกวัน) หรือ จันทร์ - พฤหัสบดี เปิด 11.00 น. - 21.30 น."
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.time_open}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">เวลาทำการ (EN)</label>
                  <div className="col-lg-10">
                    <input
                      name="time_open_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเวลาทำการ เช่น 10:00 am. - 10:00 pm. (everyday) หรือ mon. - fri. open 11.00 am. - 10.00 pm."
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.time_open_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
