import React from 'react';
import $ from "jquery";
import { activityAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../activity.css";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone"

var urlAU = ""
const urlPath = window.location.hostname
if (urlPath === "https://podsable.com" || urlPath === "podsable.com") {
  // urlAU = "/afteryou"  
}

$.DataTable = require("datatables.net");
let modalAlert;

export class FriendGetFriend_List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      modal: null,
      isModal: false,
      activity_id: "1",
      fields: {}
    };
  }

  async componentDidMount() {
    await activityAction.ActivityFriendGetByID(this.state.activity_id).then(e => {
      this.setState({

        fields: {
          ...this.state.fields,
          activity_name: e.data.activity_name,
          datetime_start: moment(e.data.datetime_start).format("Y-MM-DD HH:mm:ss"),
          datetime_stop: moment(e.data.datetime_stop).format("Y-MM-DD HH:mm:ss"),
          activity_desc_th: e.data.activity_desc_th,
          invite_condition_name: e.data.invite_condition_name,
          is_used: e.data.is_used
        }
      })
    })

    const columns = [
      {
        title: "ชื่อกิจกรรม",
        class: "text-left",
        data: "invite_condition_name"
      },
      {
        title: "ให้สิทธิพิเศษแก่สมาชิกเก่า",
        class: "text-center",
        data: "invite_condition_is_member"
      },
      {
        title: "ให้สิทธิพิเศษแก่สมาชิกใหม่",
        class: "text-center",
        data: "invite_condition_is_friend"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "invite_condition_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [ 
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div>
                  ${cellData===1 ? "<i class='fa fa-check-circle' style='color:green !important;'></i>" : "<i class='fa fa-times-circle' style='color:red !important;'></i>"}
                </div>
              `);
          },
          orderable: false
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div>
                ${cellData===1 ? "<i class='fa fa-check-circle' style='color:green !important;'></i>" : "<i class='fa fa-times-circle' style='color:red !important;'></i>"}
                </div>
              `);
          },
          orderable: false
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/activity/friendgetfriend/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                      <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                    </div>
                  </div>
                    
                  </div>
                </div>
                `);

          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.member}/api/friendgetfriend/list`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "ASC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 2;


      var formData = new FormData();
      formData.append("news_active", fval);
      formData.append("news_image", '-');

      // newsAction.activatePromotion(formData, fid).then(e => {
      //   if (e.isSuccess === false) {
      //     onModalError("Error", e.message);
      //   } else {
      //     onModalSuccess("Success", e.message);
      //   }
      // });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(id) {
      console.log(id);
      activityAction.FriendGetFriendDelete(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
           onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  onCancel(ev) {
    this.setState({ modal: null });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onReload}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">ข้อมูลกิจกรรม Friend Get Friend</h4>
            <div className="heading-elements">
              <NavLink to={urlAU + "/backoffice/activity/friend/edit/" + this.state.activity_id}>
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add"
                >
                  แก้ไข
                </button>
              </NavLink>

            </div>
          </div>
          <div className="panel-body">
            <div className='row'>
              <div className="col-md-2">
                เวลาที่เริ่มกิจกรรม
              </div>
              <div className="col-md-2">
                {this.state.fields['datetime_start']}
              </div>
              <div className="col-md-2">
                เวลาที่สิ้นสุดกิจกรรม
              </div>
              <div className="col-md-2">
                {this.state.fields['datetime_stop']}
              </div>
            </div>

            <div className='row'>
              <div className="col-md-2">
                กิจกรรมที่ถูกใช้งาน
              </div>
              <div className="col-md-10 color-after">
                {this.state.fields['invite_condition_name']}
              </div>
            </div>

            <div className='row'>
              <div className="col-md-2">
                รายละเอียดกิจกรรม (TH)
              </div>
              <div className="col-md-10">
                {this.state.fields['activity_desc_th']}
              </div>
            </div>

            <div className='row'>
              <div className="col-md-2">
                สถานะการใช้กิจกรรม
              </div>
              <div className={this.state.fields['is_used'] == 1 ? "text-success col-md-9" : "text-danger col-md-9"}>
                {this.state.fields['is_used'] == 1 ? "ใช้งาน" : "ไม่ใช้งาน"}
              </div>
            </div>
          </div>
        </div>

        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการกิจกรรม Friend Get Friend</h4>
            <div className="heading-elements">
              <NavLink to={urlAU + "/backoffice/activity/friendgetfriend/create"}>
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add"
                >
                  เพิ่มรายการ
                </button>
              </NavLink>

              {/* <a href="">
                
              </a> */}
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
