import React from "react";
import { memberAction } from '../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../member.css";
import Select from 'react-select';

let modalAlert;

export class Member_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: '',
      fields: {},
      errors: {},
      isStatus: false,
      status: 2,
      delete: 2,
      provinceName:[],
      amphoeName:[],
      districtName:[],
      zip_code:'',
      mem_password: '',
      cf_password: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
   memberAction.getProvince()
       .then(res => {
           this.setState({
               provinceName: res.data
           })
       })
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/member"
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["mem_firstname"]) {
      formIsValid = false;
      errors["mem_firstname"] = "*กรุณากรอกชื่อจริง.";
    }

    if (!fields["mem_lastname"]) {
      formIsValid = false;
      errors["mem_lastname"] = "*กรุณากรอกนามสกุลจริง.";
    }

    if (!fields["mem_phone"]) {
      formIsValid = false;
      errors["mem_phone"] = "*กรุณากรอกเบอร์โทรศัพท์.";
    }

    if (!fields["mem_email"]) {
      formIsValid = false;
      errors["mem_email"] = "*กรุณากรอก e-mail.";
    }

    if (!fields["mem_password"]) {
      formIsValid = false;
      errors["mem_password"] = "*หรุณากรอกรหัสผ่าน";
    }

    if (!fields["cf_password"]) {
      formIsValid = false;
      errors["cf_password"] = "*กรุณากรอกยืนยันรหัสผ่าน";
    }else{
      if (fields['mem_password'] !== fields['cf_password']) {
          formIsValid = false;
          errors["cf_password"] = "*กรุณากรอกให้ตรงกับรหัสผ่าน.";
      }
    }

    if (!fields["mem_birthdate"]) {
      formIsValid = false;
      errors["mem_birthdate"] = "*กรุณากรอก วัน/เดือน/ปี เกิด.";
    }

    if (!fields["mem_idcard"]) {
      formIsValid = false;
      errors["mem_idcard"] = "*กรุณากรอกรหัสบัตรประชาชน.";
    }

    


    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    const inputBody = {
      mem_firstname: this.state.fields["mem_firstname"],
      mem_lastname: this.state.fields["mem_lastname"],
      mem_phone: this.state.fields["mem_phone"],
      mem_email: this.state.fields["mem_email"],
      mem_password: this.state.fields["mem_password"],
      mem_birthdate: this.state.fields["mem_birthdate"],
      mem_idcard: this.state.fields["mem_idcard"],
      mem_address: this.state.fields["mem_address"],
      mem_district: this.state.fields["mem_district"],
      mem_country: this.state.fields["mem_country"],
      mem_province: this.state.fields["mem_province"],
      mem_zipcode: this.state.zip_code,
      is_active: this.state.status,
      is_delete: this.state["delete"],
    }
    memberAction.createMember(inputBody).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {

    const target = e.target;
    const name = target.name;
    let status;

    if(name == "isStatus"){
      this.setState({ isStatus: !this.state.isStatus })
      if(this.state.isStatus == true){
        this.setState({ status: '1' })
      }else{
        this.setState({ status: '2' })
      }
    }
  }

  handleProvinceChange = (selectedOption) => {

    let { fields, errors } = this.state;

    if(selectedOption != null){
      fields["mem_province"] = selectedOption.value;
      this.state.errors["mem_province"] = null;
    }else{
      fields["mem_province"] = null
    }

    this.setState({
      selectedOptionAmphoe: null,
      selectedOptionDistrict: null,
    })


    this.setState({ fields, errors });

    memberAction.getAmphoe(this.state.fields["mem_province"])
      .then(res => {
          this.setState({
              amphoeName: res.data
          })
      })

    memberAction.getDistrict()
      .then(res => {
          this.setState({
              districtName: res.data
          })
      })
  }

  handleAmphoeChange = (selectedOptionAmphoe) => {
    let { fields, errors } = this.state;

    if(selectedOptionAmphoe != null){
      fields["mem_country"] = selectedOptionAmphoe.value;
      this.state.errors["mem_country"] = null;
    }else{
      fields["mem_country"] = null
    }

    this.setState({selectedOptionAmphoe, fields, errors });

    memberAction.getDistrict(this.state.fields["mem_country"])
      .then(res => {
          this.setState({
              districtName: res.data
          })
      })
  }

  handleDistrictChange = (selectedOptionDistrict) => {
    let { fields, errors } = this.state;

    if(selectedOptionDistrict != null){
      fields["mem_district"] = selectedOptionDistrict.value;
      this.state.errors["mem_district"] = null;
      this.state.errors["mem_zipcode"] = null;
    }else{
      fields["mem_district"] = null
    }

    this.setState({ selectedOptionDistrict, fields, errors });
    this.getZipcodeByID(fields["mem_district"]);
  }

  getZipcodeByID(id_district) {

    memberAction.getZipcode(id_district).then(e => {
      if (e.isSuccess === true) {
        this.setState({
            zip_code: e.data.zip_code
        });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });

  }



  render() {

    let optionsProvince = this.state.provinceName.map(function (provinceName) {
      return { value: provinceName.id, label: provinceName.name_th };
    })

    let optionsAmphoe = this.state.amphoeName.map(function (amphoe) {
      return { value: amphoe.id, label: amphoe.name_th };
    })

    let optionsDistrict = this.state.districtName.map(function (district) {
      return { value: district.id, label: district.name_th };
    })

    const {
      selectedOptionAmphoe,
      selectedOptionDistrict
    } = this.state;




    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มสมาชิก</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-1">ชื่อจริง</label>
                  <div className="col-lg-5">
                  <input
                      name="mem_firstname"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อจริง"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.mem_firstname}</div>
                  </div>

                  <label className="control-label col-lg-1">นามสกุล</label>
                  <div className="col-lg-5">
                  <input
                      name="mem_lastname"
                      type="text"
                      className="form-control"
                      placeholder="กรอกนามสกุล"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.mem_lastname}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-1">เบอร์โทรศัพท์</label>
                  <div className="col-lg-5">
                  <input
                      name="mem_phone"
                      type="text"
                      className="form-control"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.mem_phone}</div>
                  </div>

                  <label className="control-label col-lg-1">E-Mail</label>
                  <div className="col-lg-5">
                  <input
                      name="mem_email"
                      type="email"
                      className="form-control"
                      placeholder="กรอก E-amil"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.mem_email}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-1">รหัสผ่าน</label>
                  <div className="col-lg-5">
                  <input
                      name="mem_password"
                      type="password"
                      className="form-control"
                      placeholder="กรอกรหัสผ่าน"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.mem_password}</div>
                  </div>

                  <label className="control-label col-lg-1"> ยืนยันรหัสผ่าน</label>
                  <div className="col-lg-5">
                  <input
                      name="cf_password"
                      type="password"
                      className="form-control"
                      placeholder="กรอกยืนยันรหัสผ่าน"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.cf_password}</div>
                  </div>


                </div>

                <div className="form-group">
                  <label className="control-label col-lg-1">วัน/เดือน/ปี เกิด</label>
                  <div className="col-lg-5">
                    <input
                        name="mem_birthdate"
                        type="date"
                        className="form-control"
                        placeholder="Please input Birthdate"
                        onChange={this.handleChange}
                      />
                    <div className="errorMsg">{this.state.errors.mem_birthdate}</div>
                  </div>

                  <label className="control-label col-lg-1">รหัสบัตรประชาชน</label>
                  <div className="col-lg-5">
                    <input
                        name="mem_idcard"
                        type="text"
                        className="form-control"
                        placeholder="กรอกรหัสบัตรประชาชน"
                        onChange={this.handleChange}
                      />
                    <div className="errorMsg">{this.state.errors.mem_idcard}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-1">ที่อยู่</label>
                  <div className="col-lg-5">
                    <textarea
                        name="mem_address"
                        className="form-control"
                        placeholder="กรอกที่อยู่"
                        onChange={this.handleChange}></textarea>
                    <div className="errorMsg">{this.state.errors.mem_address}</div>
                  </div>

                  <label className="control-label col-lg-1">จังหวัด</label>
                  <div className="col-lg-5">
                    <Select
                      name="mem_province"
                      onChange={this.handleProvinceChange}
                      placeholder="เลือกจังหวัด"
                      options={optionsProvince}
                    />
                    <div className="errorMsg">{this.state.errors.mem_province}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-1">เขต/อำเภอ</label>
                  <div className="col-lg-5">
                  <Select
                    name="mem_country"
                    placeholder="เลือกเขต/อำเภอ"
                    options={optionsAmphoe}
                    onChange={this.handleAmphoeChange}
                    value={selectedOptionAmphoe}
                  />
                    <div className="errorMsg">{this.state.errors.mem_country}</div>
                  </div>

                  <label className="control-label col-lg-1">แขวง/ตำบล</label>
                  <div className="col-lg-5">
                    <Select
                      name="mem_district"
                      placeholder="เลือกแขวง/ตำบล"
                      options={optionsDistrict}
                      onChange={this.handleDistrictChange}
                      value={selectedOptionDistrict}
                    />
                    <div className="errorMsg">{this.state.errors.mem_district}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-1">รหัสไปรษณีย์</label>
                  <div className="col-lg-5">
                    <input
                        name="mem_zipcode"
                        type="text"
                        className="form-control"
                        placeholder="กรอกรหัสไปรษณีย์"
                        onChange={this.handleChange}
                        value={this.state.zip_code}
                      />
                    <div className="errorMsg">{this.state.errors.mem_zipcode}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-1">สถานะการใช้งาน</label>
                  <div className="col-lg-5">
                  <label className="switch">
                    <input name="isStatus" type="checkbox" onChange={this.handleInputChange}/>
                    <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
