import React from 'react'
import './style.css'
import $ from 'jquery'

export const OverlayEvents_Switch = status => {
    if(status)
    {
        $('.evlevents-con')
        .css("display", "flex")
        .hide()
        .fadeIn();
    }else
    {
        $('.evlevents-con').fadeOut();
    } 
}

function OverlayEvents({ data = [] }) {
    return (
        <div className='evlevents-con'>
            <div className="content">
                <div className="head">
                    <button onClick={() => OverlayEvents_Switch(false)}>
                        <i class="bi bi-x-circle-fill"></i>
                    </button>
                </div>
                <div className="table-wrap">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ประเภท</th>
                                <th scope="col">คำขยายความ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, i) =>
                                <tr key={i}>
                                    <th scope="row">{i + 1}</th>
                                    <td>{item.event_type}</td>
                                    <td>{item.event_desc}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default OverlayEvents