import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const activityAction = {
  listOrderType,

  flashsaleListTime,
  flashsaleGetTime,
  flashsaleUpdateTime,
  flashsaleInsertTime,
  flashsaleDeleteTime,

  flashsaleDeleteProduct,
  flashsaleInsertProduct,
  flashsaleUpdateProduct,
  flashsaleGetProduct,

  ActivityFriendGetByID,
  ActivityUpdate,
  
  FriendGetFriendList,
  FriendGetFriendGet,
  FriendGetFriendCreate,
  FriendGetFriendUpdate,
  FriendGetFriendDelete,

  DiscountDeliveryCreate,
  DiscountDeliveryGet,
  DiscountDeliveryUpdate,
  DiscountDeliveryDelete,

  flashsaleInsertTimeV2,
};

function listOrderType() {
  return axios.get(`${Base_API.product}/api/getTypeOrder/th`).then(res => {
    return res.data;
  });
} 

function flashsaleListTime() {
  return axios.get(`${Base_API.product}/flashsale/list_time`).then(res => {
    return res.data;
  });
} 

function flashsaleGetTime(id) {
  return axios.get(`${Base_API.product}/flashsale/get_time/${id}`).then(res => {
    return res.data;
  });
}

function flashsaleUpdateTime(formData, id) {
  return axios.put(`${Base_API.product}/flashsale/update_time/${id}`, formData).then(res => {
    return res.data;
  });
}

function flashsaleInsertTime(formData) {
  return axios.post(`${Base_API.product}/flashsale/insert_time`, formData).then(res => {
    return res.data;
  });
}

function flashsaleInsertTimeV2(formData) {
  return axios.post(`${Base_API.product_v2}/v2/flashsale/insert_time`, formData).then(res => {
    return res.data;
  });
}

function flashsaleDeleteTime(id) {
  return axios.delete(`${Base_API.product}/flashsale/delete_time/${id}`).then(res => {
    return res.data;
  });
}

function flashsaleInsertProduct(formData) {
  return axios.post(`${Base_API.product}/flashsale/insert_product`, formData).then(res => {
    return res.data;
  });
}

function flashsaleGetProduct(id) {
  return axios.get(`${Base_API.product}/flashsale/get_product/${id}`).then(res => {
    return res.data;
  });
}

function flashsaleUpdateProduct(formData, id) {
  return axios.put(`${Base_API.product}/flashsale/update_product/${id}`, formData).then(res => {
    return res.data;
  });
}

function flashsaleDeleteProduct(id) {
  return axios.delete(`${Base_API.product}/flashsale/delete_product/${id}`).then(res => {
    return res.data;
  });
}

function ActivityFriendGetByID(id) {
  return axios.get(`${Base_API.member}/api/activity/friendgetfriend/get/${id}`).then(res => {
    return res.data;
  });
}

function ActivityUpdate(formData, id) {
  return axios.put(`${Base_API.member}/api/activity/update/${id}`, formData).then(res => {
    return res.data;
  });
}

function FriendGetFriendList() {
  return axios.get(`${Base_API.member}/api/friendgetfriend/list`).then(res => {
    return res.data;
  });
} 


function FriendGetFriendGet(id) {
  return axios.get(`${Base_API.member}/api/friendgetfriend/get/${id}`).then(res => {
    return res.data;
  });
} 

function FriendGetFriendCreate(formData) {
  return axios.post(`${Base_API.member}/api/friendgetfriend/insert`, formData).then(res => {
    return res.data;
  });
} 

function FriendGetFriendUpdate(formData, id) {
  return axios.put(`${Base_API.member}/api/friendgetfriend/update/${id}`, formData).then(res => {
    return res.data;
  });
} 

function FriendGetFriendDelete(id) {
  return axios.delete(`${Base_API.member}/api/friendgetfriend/delete/${id}`).then(res => {
    return res.data;
  });
}

function DiscountDeliveryCreate(inputData) {
  return axios.post(`${Base_API.order_online}/api/DiscountDelivery/insert`,inputData).then(res => {
    return res.data;
  });
}

function DiscountDeliveryGet(id) {
  return axios.get(`${Base_API.order_online}/api/DiscountDelivery/get/${id}`).then(res => {
    return res.data;
  });
}

function DiscountDeliveryUpdate(inputBody, id) {
  return axios.put(`${Base_API.order_online}/api/DiscountDelivery/update/${id}`, inputBody).then(res => {
    return res.data;
  });
}

function DiscountDeliveryDelete(id) {
  return axios.delete(`${Base_API.order_online}/api/DiscountDelivery/delete/${id}`).then(res => {
    return res.data;
  });
}