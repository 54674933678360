import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const staffAction = {
  createStaff,
  listStaff,
  getStaff,
  updateStaff,
  deleteStaffById,

  getEwalletStaff,
  updateEwalletStaff,
};

function createStaff(formData) {
  return axios.post(`${Base_API.admin}/staff`, formData).then(res => {
    return res.data;
  });
}
function listStaff() {
  return axios.get(`${Base_API.admin}/staff`).then(res => {
    return res.data;
  });
}

function getStaff(id) {
  return axios.get(`${Base_API.admin}/staff/${id}`, "").then(res => {
    return res.data;
  });
}

function updateStaff(formData, id) {
  return axios.put(`${Base_API.admin}/staff/${id}`, formData).then(res => {
    return res.data;
  });
}

function deleteStaffById(id) {
  return axios.delete(`${Base_API.admin}/staff/${id}`).then(res => {
    return res.data;
  });
}

function getEwalletStaff(id) {
  return axios.get(`${Base_API.admin}/staff/ewallet/${id}`, "").then(res => {
    return res.data;
  });
}

function updateEwalletStaff(id, formData) {
  return axios.put(`${Base_API.admin}/staff/ewallet/${id}`, formData).then(res => {
    return res.data;
  });
}
