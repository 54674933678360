import React from "react";
import { Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import "./header.css";

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false
    };
  }

  componentDidMount() {
    var admin_id = localStorage.getItem('admin_id')
    this.setState({ admin_id: admin_id })
  }

  render() {
    const { logout } = this.state;
    if (logout) {
      localStorage.clear();
      window.location.href='/backoffice'
    }

    return (
      <div className="navbar navbar-default header-highlight">
        <div className="navbar-header">
          <div className="navbar-brand">
          <img src={process.env.PUBLIC_URL+'/images/logo.png'} alt="" className="logo-login"></img>
          </div>
          <ul className="nav navbar-nav visible-xs-block">
            <li>
              <a
                to="/posts"
                data-toggle="collapse"
                data-target="#navbar-mobile"
              >
                <i className="icon-tree5" />
              </a>
            </li>
            <li>
              <a to="/posts" className="sidebar-mobile-main-toggle">
                <i className="icon-paragraph-justify3" />
              </a>
            </li>
          </ul>
        </div>

        <div className="navbar-collapse collapse" id="navbar-mobile">
          <ul className="nav navbar-nav">
            <li>
              <a
                to="/posts"
                className="sidebar-control sidebar-main-toggle hidden-xs"
              >
                <i className="icon-paragraph-justify3" />
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li className="dropdown dropdown-user">
              <a to="/posts" className="dropdown-toggle" data-toggle="dropdown">
                <span>Admin</span>
                <i className="caret" />
              </a>

              <ul className="dropdown-menu dropdown-menu-right">
              <li>
                  <NavLink to={'/backoffice/admin/editpassword/'+this.state.admin_id}>
                    <i className="icon-pencil3" /> แก้ไขรหัสผ่าน
                  </NavLink>
                </li>
                <li>
                  <a onClick={() => this.setState({ logout: true })}>
                    <i className="icon-switch2" /> Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
