import React from "react";
import { activityAction, rewardAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../activity.css";
import Select from 'react-select';
const moment = require('moment-timezone');

let modalAlert;

export class FriendGetFriend_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      optionsRewards: [],
      rewards: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmUpdate = this.onConfirmUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.FriendGetFriendGet(params.invite_condition_id);

    await rewardAction.listReward().then(res => {
      this.setState({
          rewards: res.data
      })
    })
  }

  FriendGetFriendGet(invite_condition_id) {
    activityAction.FriendGetFriendGet(invite_condition_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            invite_condition_id: e.data.invite_condition_id,
            invite_condition_name: e.data.invite_condition_name,
            invite_condition_is_friend: e.data.invite_condition_is_friend,
            invite_condition_privilege_friend: e.data.invite_condition_privilege_friend,
            invite_condition_ewallet_friend: e.data.invite_condition_ewallet_friend,
            invite_condition_rewards_id_friend: e.data.invite_condition_rewards_id_friend,
            invite_condition_rewards_amount_friend: e.data.invite_condition_rewards_amount_friend,
            invite_condition_is_member: e.data.invite_condition_is_member,
            invite_condition_privilege_member: e.data.invite_condition_privilege_member,
            invite_condition_ewallet_member: e.data.invite_condition_ewallet_member,
            invite_condition_rewards_id_member: e.data.invite_condition_rewards_id_member,
            invite_condition_rewards_amount_member: e.data.invite_condition_rewards_amount_member,
            invite_condition_max_friend_per_month: e.data.invite_condition_max_friend_per_month,
          }
        });
        this.setState({
          defaultPrivilegeFriend:
          {
            label: e.data.invite_condition_privilege_friend,
            value: e.data.invite_condition_privilege_friend
          },
          defaultPrivilegeMember:
          {
            label: e.data.invite_condition_privilege_member,
            value: e.data.invite_condition_privilege_member
          },
          defaultRewardFriend:
          {
            label: e.data.reward_name_friend,
            value: e.data.invite_condition_rewards_id_friend
          },
          defaultRewardMember:
          {
            label: e.data.reward_name_member,
            value: e.data.invite_condition_rewards_id_member
          },
        });

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmUpdate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/activity/friendgetfriend/list";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["invite_condition_name"]) {
      formIsValid = false;
      errors["invite_condition_name"] = "*กรุณากรอกชื่อกิจกรรม";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmUpdate(ev) {
    this.setState({ modal: null });

    let inputBody = {
      invite_condition_name: this.state.fields["invite_condition_name"],
      invite_condition_is_friend: this.state.fields["invite_condition_is_friend"],
      invite_condition_privilege_friend: this.state.fields["invite_condition_privilege_friend"],
      invite_condition_ewallet_friend: this.state.fields["invite_condition_ewallet_friend"],
      invite_condition_rewards_id_friend: this.state.fields["invite_condition_rewards_id_friend"],
      invite_condition_rewards_amount_friend: this.state.fields["invite_condition_rewards_amount_friend"],
      invite_condition_is_member: this.state.fields["invite_condition_is_member"],
      invite_condition_privilege_member: this.state.fields["invite_condition_privilege_member"],
      invite_condition_ewallet_member: this.state.fields["invite_condition_ewallet_member"],
      invite_condition_rewards_id_member: this.state.fields["invite_condition_rewards_id_member"],
      invite_condition_rewards_amount_member: this.state.fields["invite_condition_rewards_amount_member"],
      invite_condition_max_friend_per_month: this.state.fields["invite_condition_max_friend_per_month"]
    }

    activityAction.FriendGetFriendUpdate(inputBody, this.state.fields["invite_condition_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;

    if(action.name == "invite_condition_privilege_member"){
      this.setState({
        defaultPrivilegeMember:
        {
          label: e.label,
          value: e.value
        }
      })
    }else if(action.name == "invite_condition_privilege_friend"){
      this.setState({
        defaultPrivilegeFriend:
        {
          label: e.label,
          value: e.value
        }
      })
    }else if(action.name == "invite_condition_rewards_id_friend"){
      this.setState({
        defaultRewardFriend:
        {
          label: e.label,
          value: e.value
        }
      })
    }else if(action.name == "invite_condition_rewards_id_member"){
      this.setState({
        defaultRewardMember:
        {
          label: e.label,
          value: e.value
        }
      })
    }
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })
  }


  render() {

    let optionsRewards = this.state.rewards.map(function (reward) {
      return { value: reward.rewards_id, label: reward.rewards_name_th };
    })

    let optionsPrivilege = [
      { value: "", label: "กรุณาเลือกสิทธิพิเศษ" },
      { value: "Reward", label: "Reward" }
      // { value: "E-Wallet", label: "E-Wallet" }
    ]

    console.log(this.state.defaultPrivilegeMember);

    return (
      <div>
      <div className="panel panel-flat">
        <div className="panel-heading panel-heading-wline">
          <h4 className="panel-title">แก้ไขกิจกรรม Friend Get Friend</h4>
        </div>
        <br />
        <div className="panel-body">
          
          <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="form-group">
              <label className="control-label col-md-3">ชื่อกิจกรรม</label>
              <div className="col-md-9">
                <input
                  name="invite_condition_name"
                  type="text"
                  className="form-control"
                  placeholder="กรอกชื่อกิจกรรม"
                  value={this.state.fields.invite_condition_name || ""}
                  onChange={this.handleChange}
                />
                <div className="errorMsg">{this.state.errors.invite_condition_name}</div>
              </div>
            </div>

            <hr className="mx-0"/>

            <div className="mb-2">
              <u className="control-label">สำหรับสมาชิกเก่า</u>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">สถานะการให้สิทธิพิเศษ</label>
              <div className="col-md-3">
                <label className="switch">
                  <input name="invite_condition_is_member" type="checkbox" onChange={this.handleInputChange} checked={this.state.fields.invite_condition_is_member}/>
                  <div className="slider"></div>
                </label>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">สิทธิพิเศษ</label>
              <div className="col-md-3">
                <Select
                  name="invite_condition_privilege_member"
                  placeholder="เลือกสิทธิพิเศษสำหรับสมาชิกเก่า"
                  onChange={this.handleSelectChange}
                  options={optionsPrivilege}
                  value={this.state.defaultPrivilegeMember}
                />
                <div className="errorMsg">{this.state.errors.invite_condition_privilege_member}</div>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">รีวอร์ด</label>
              <div className="col-md-5">
                <Select
                  name="invite_condition_rewards_id_member"
                  placeholder="เลือกรีวอร์ดสำหรับสมาชิกเก่า"
                  onChange={this.handleSelectChange}
                  options={optionsRewards}
                  value={this.state.defaultRewardMember}
                />
                <div className="errorMsg">{this.state.errors.invite_condition_rewards_id_member}</div>
              </div>

              <label className="control-label col-md-2">จำนวนรีวอร์ด</label>
              <div className="col-md-2">
                <input
                  name="invite_condition_rewards_amount_member"
                  type="number"
                  className="form-control"
                  placeholder="กรอกจำนวนรีวอร์ด"
                  value={this.state.fields.invite_condition_rewards_amount_member || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <hr className="mx-0"/>

            <div className="mb-2">
              <u className="control-label">สำหรับสมาชิกใหม่</u>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">สถานะการให้สิทธิพิเศษ</label>
              <div className="col-md-3">
                <label className="switch">
                  <input name="invite_condition_is_friend" type="checkbox" onChange={this.handleInputChange} checked={this.state.fields.invite_condition_is_friend}/>
                  <div className="slider"></div>
                </label>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">สิทธิพิเศษ</label>
              <div className="col-md-3">
                <Select
                  name="invite_condition_privilege_friend"
                  placeholder="เลือกสิทธิพิเศษสำหรับสมาชิกใหม่"
                  onChange={this.handleSelectChange}
                  options={optionsPrivilege}
                  value={this.state.defaultPrivilegeFriend}
                />
                <div className="errorMsg">{this.state.errors.invite_condition_privilege_friend}</div>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">รีวอร์ด</label>
              <div className="col-md-5">
                <Select
                  name="invite_condition_rewards_id_friend"
                  placeholder="เลือกรีวอร์ดสำหรับสมาชิกใหม่"
                  onChange={this.handleSelectChange}
                  options={optionsRewards}
                  value={this.state.defaultRewardFriend}
                />
                <div className="errorMsg">{this.state.errors.invite_condition_rewards_id_friend}</div>
              </div>

              <label className="control-label col-md-2">จำนวนรีวอร์ด</label>
              <div className="col-md-2">
                <input
                  name="invite_condition_rewards_amount_friend"
                  type="number"
                  className="form-control"
                  placeholder="กรอกจำนวนรีวอร์ด"
                  value={this.state.fields.invite_condition_rewards_amount_friend || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <hr className="mx-0"/>

            <div className="form-group">
              <div className="col-lg-12 text-right">
                <input
                  type="submit"
                  className="btn btn-success btn-md btn-banner-create"
                  value="บันทึก"
                />
              </div>
            </div>
          </form>  
        </div>
      </div>
      {this.state.modal}
    </div>
    );
  }
}
