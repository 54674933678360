import React from "react";
import { Base_API } from "../../../../_constants/matcher";
import { rewardAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../point.css";

let modalAlert;

export class Point_Burn_Info extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      typeProgram: null,
      selectType: null,
      inputBody: {},
      rewardid: 0
    };
  }

  componentDidMount() {
    var params = this.props.match.params;
    this.setState({ rewardid: params.logsid });
    this.getRewardDataByID(params.logsid);
  }

  getRewardDataByID(id) {
    rewardAction.getLogsHistoryByID(id).then(e => {
      if (e.isSuccess === true) {
        var newObject = Object.assign(e.data, this.state.inputBody);
        if(newObject['rw_rewardstatus'] === 'N') newObject['rw_rewardstatus'] = <strong class="text-warning">ยังไม่ได้ใช้งาน</strong>
        else if(newObject['rw_rewardstatus'] === 'Y') newObject['rw_rewardstatus'] = <strong class="text-success">ใช้งานแล้ว</strong>
        else if(newObject['rw_rewardstatus'] === 'C') newObject['rw_rewardstatus'] = <strong class="text-danger">ยกเลิก</strong>
        this.setState({ inputBody: newObject });
      } else {
        this.onModalError("ผิดพลาด", e.message);
      }
    });
  }

  onImageError(e) {
    e.target.setAttribute(
      "src",
      `${process.env.PUBLIC_URL}/images/default.png`
    );
  }

  render() {
    console.log(this.state.inputBody);
    return (
      <div className="reward-container row">
        <div className="col-xs-4 col-sm-4 col-lg-4">
          <div className="panel panel-flat">
            <div className="panel-heading panel-heading-wline">
              <h4 className="panel-title">ข้อมูลผู้ใช้งาน</h4>
            </div>
            <div className="">
              <table className="table">
                <thead>
                  <tr><td><strong>ชื่อผู้แลกของรางวัล</strong></td><td>{this.state.inputBody['mem_firstname']} {this.state.inputBody['mem_lastname']}</td></tr>
                  <tr><td><strong>เบอร์ติดต่อ</strong></td><td>{this.state.inputBody['mem_phone']}</td></tr>
                  <tr><td><strong>อีเมลติดต่อ</strong></td><td>{this.state.inputBody['mem_email']}</td></tr>
                  <tr><td><strong>ที่อยู่</strong></td><td>{this.state.inputBody['mem_address']}</td></tr>
                  <tr><td><strong>เขต/ตำบล</strong></td><td>{this.state.inputBody['mem_district']}</td></tr>
                  <tr><td><strong>แขวง/อำเภอ</strong></td><td>{this.state.inputBody['mem_country']}</td></tr>
                  <tr><td><strong>จังหวัด</strong></td><td>{this.state.inputBody['mem_province']}</td></tr>
                  <tr><td><strong>รหัสไปรษณีย์</strong></td><td>{this.state.inputBody['mem_zipcode']}</td></tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div className="col-xs-8 col-sm-8 col-lg-8">
          <div className="panel panel-flat">
            <div className="panel-heading panel-heading-wline">
              <h4 className="panel-title">ข้อมูลของรางวัลที่แลก</h4>
            </div>
            <div className="">
            <div className="">
              <table className="table">
                <thead>
                  <tr><td><strong>รูปของรางวัล</strong></td><td><img style={{ maxHeight: '150px' }} src={this.state.inputBody['reward_image']} alt={this.state.inputBody['rw_reward']} /></td></tr>
                  <tr><td><strong>ชื่อผู้แลกของรางวัล</strong></td><td>{this.state.inputBody['rw_username']} {this.state.inputBody['mem_lastname']}</td></tr>
                  <tr><td><strong>รางวัลที่แลก</strong></td><td>{this.state.inputBody['rw_reward']}</td></tr>
                  <tr><td><strong>ข้อมูลของรางวัล</strong></td><td>{this.state.inputBody['reward_description']}</td></tr>
                  <tr><td><strong>ประเภทของรางวัล</strong></td><td>{this.state.inputBody['rw_reward_category']}</td></tr>
                  <tr><td><strong>คะแนนที่ใช้แลก</strong></td><td>{this.state.inputBody['rw_pointperunit']}</td></tr>
                  <tr><td><strong>รหัสของรางวัล</strong></td><td>{this.state.inputBody['rw_rewardcode']}</td></tr>
                  <tr><td><strong>สถานะการใช้รางวัล</strong></td><td>{this.state.inputBody['rw_rewardstatus']}</td></tr>
                  <tr><td><strong>วันที่ใช้งาน</strong></td><td>{this.state.inputBody['rw_burn_date']}</td></tr>
                  <tr><td><strong>วันหมดอายุของรางวัล</strong></td><td>{this.state.inputBody['rw_expired_date']}</td></tr>
                </thead>
              </table>
            </div>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
