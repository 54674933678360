import React from "react";
import { adminAction, branchAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import "../admin.css";
let modalAlert;

export class Admin_EditPassword extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      // messageCreate: "",
      fields: {},
      select: {},
      errors: {},
      // levelList: [],
      selectOptionLevel: [],
      selectOptionBranch: [],
      // level: null,
      // branch: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let { selectOptionLevel, selectOptionBranch } = this.state;

    const {
      match: { params }
    } = this.props;
    this.getDataByID(params.admin_id);
  }

  getDataByID(admin_id) {
    adminAction.getAdminId(admin_id).then(e => {
      if (e.isSuccess === true) {
          this.setState({
          fields: {
            ...this.state.fields,
            ad_firstname: e.data.ad_firstname,
            ad_lastname: e.data.ad_lastname,
            ad_phone: e.data.ad_phone,
            ad_email: e.data.ad_email,
            ad_username: e.data.ad_username,
            ad_password: e.data.ad_password,
            ad_b_status: e.data.ad_b_status,
            is_active: e.data.is_active,
            ad_id: admin_id
          }
        });
        this.setState({
          select: {
            level : {
              ...this.state.select.level,
              label: e.data.level_name,
              value: e.data.level_id,
            },
            branch : {
              ...this.state.select.branch,
              label: e.data.b_name,
              value: e.data.b_id,
            },
          }
        });

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/homepage";
  }

  validateForm() {
    let fields = this.state.fields;
    let select = this.state.select;
    let errors = {};
    let formIsValid = true;

  
    if (!fields["ad_oldPassword"]) {
      formIsValid = false;
      errors["ad_oldPassword"] = "*กรุณากรอกรหัสผ่านเดิม";
    }

    if(fields["ad_newPassword"] !== "" && typeof fields["ad_newPassword"] !== "undefined"){
      if(fields["ad_newPassword"].length < 8){
        formIsValid = false;
        errors["ad_newPassword"] ="*กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว";
      }
    }
    if (fields["ad_newPassword"] !== "" && typeof fields["ad_newPassword"] !== "undefined" && (fields["ad_cfNewPassword"] === ""  || typeof fields["ad_cfNewPassword"] === "undefined")) {
      formIsValid = false;
      errors["ad_cfNewPassword"] = "*กรุณากรอกยืนยันรหัสผ่าน";
    }else{
      if(fields["ad_cfNewPassword"] !== "" && typeof fields["ad_cfNewPassword"] && fields["ad_newPassword"] !== "" && typeof fields["ad_newPassword"] !== "undefined"){
        if (fields["ad_newPassword"] !== fields["ad_cfNewPassword"]) {
          formIsValid = false;
          errors["ad_cfNewPassword"] = "*ยืนยันรหัสผ่านไม่ถูกต้อง";
        }
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });
    var md5 = require('md5');
    var formData = {
      "ad_password" : md5(this.state.fields["ad_newPassword"]),
      "ad_oldPassword" : md5(this.state.fields["ad_oldPassword"])
    }

    adminAction.updateAdmin(formData, this.state.fields["ad_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }


  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleSelect = (e, action) => {
    let { select, fields, errors } = this.state;    
    select[action.name] = {
      label: e.label,
      value: e.value
    };
    this.state.errors[action.name] = null;
    this.setState({ select, fields, errors });
  };
  
  handleChange = e => {
    let { fields, errors } = this.state;
    if (e.target.name === "is_active" || e.target.name === "ad_b_status") {
      fields[e.target.name] = e.target.checked === true ? 1 : 0;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  render() {
    let { select } = this.state;

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขรหัสผ่าน</h4>
          </div>
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อ/สาขา</label>
                  <div className="col-lg-10">
                    <label className="control-label">{this.state.fields["ad_firstname"]} ({this.state.fields["ad_username"]})</label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">
                    รหัสผ่านเดิม
                  </label>
                  <div className="col-lg-4">
                    <input
                      name="ad_oldPassword"
                      type="password"
                      className="form-control"
                      placeholder="กรอกรหัสผ่านเดิม"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors["ad_oldPassword"]}
                    </div>
                  </div>
                  <label className="control-label col-lg-2">
                    รหัสผ่านใหม่
                  </label>
                  <div className="col-lg-4">
                    <input
                      name="ad_newPassword"
                      type="password"
                      className="form-control"
                      placeholder="กรอกรหัสผ่านใหม่"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors["ad_newPassword"]}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">
                    ยืนยันรหัสผ่าน
                  </label>
                  <div className="col-lg-4">
                    <input
                      name="ad_cfNewPassword"
                      type="password"
                      className="form-control"
                      placeholder="กรอกยืนยันรหัสผ่าน"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors["ad_cfNewPassword"]}</div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
