import React,  { Component }from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";

import { categoryAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../category.css";
$.DataTable = require("datatables.net");
let modalAlert;

var listCateShow = [];


export class Category_Edit_Show extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      inputBody: [],
  
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.onConfirm = this.onConfirm.bind(this);
    // this.onConfirmCreate = this.onConfirmCreate.bind(this);
    // this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getCategoryDataByID();
  }

  getCategoryDataByID() {
    let { fields } = this.state;
    listCateShow = []
    categoryAction.getEatinCategory().then(e => {
      if (e.isSuccess === true) {
        var newObject = Object.assign(e.data, this.state.inputBody);
        this.setState({ inputBody: newObject , fields });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }


  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value
    this.setState({ fields })
    // this.setState({ name : e.target.value});
    // this.state.errors[e.target.name] = null;
    // this.setState({ fields, errors });
  };

  render() {

    let listCate = this.state.inputBody.map(( item, cate ) => {
      return (
        <tr key={cate}>
          <td>1</td>
          <td>{item.pr_category_name}</td>
          <td><input type="text" id="sort" name={'sort' + item.sort} value={this.state.fields['sort']} className="form-control" onChange={(e) => this.handleChange(e)} /></td>
        </tr>
      );
    })
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">จัดการลำดับการแสดงหมวดหมู่สินค้า</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group"> 

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td><strong>ลำดับ</strong></td>
                    <td width="50%"><strong>ชื่อหมวดหมู่</strong></td>
                    <td><strong>ลำดับที่แสดง</strong></td>
                  </tr>
                </thead>
                <tbody>
                  {listCateShow}
                </tbody>
              </table>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
