import React from "react";
import { categoryAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../category.css";

let modalAlert;

export class Category_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getCategoryDataByID(params.categoryid);
  }

  getCategoryDataByID(cid) {
    categoryAction.getEatinCategoryId(cid).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            cateName: e.data.pr_category_name,
            cateNameEN: e.data.pr_category_name_en,
            // cateImage: e.data.pr_category_image,
            sort: e.data.sort,
            cateId: cid
          }
        });
      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/preorder/product/category";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["cateName"]) {
      formIsValid = false;
      errors["cateName"] = "*กรุณากรอกชื่อหมวดหมู่สินค้า (TH)";
    }

    if (!fields["cateNameEN"]) {
      formIsValid = false;
      errors["cateNameEN"] = "*กรุณากรอกชื่อหมวดหมู่สินค้า (EN)";
    }

    if (!fields["sort"]) {
      formIsValid = false;
      errors["sort"] = "*กรุณากรอกลำดับการแสดง";
    }

    // if (!fields["cateImage"]) {
    //   formIsValid = false;
    //   errors["cateImage"] = "*กรุณาแนบไฟล์รูปภาพ";
    // }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    var formData = new FormData();
    // formData.append("pr_category_image", this.state.fields["cateImage"]);
    formData.append("pr_category_name", this.state.fields["cateName"]);
    formData.append("pr_category_name_en", this.state.fields["cateNameEN"]);
    formData.append("sort", this.state.fields["sort"]);
    formData.append("user_id", 1);

    categoryAction.updateEatinCategory(formData, this.state.fields["cateId"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขหมวดหมู่สินค้าพรีออเดอร์</h4>
          </div>
          <br />
          <div className="panel-body">
            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อหมวดหมู่ (TH)</label>
                  <div className="col-lg-10">
                    <input
                      name="cateName"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อหมวดหมู่สินค้า (TH)"
                      value={this.state.fields["cateName"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.cateName}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อหมวดหมู่ (EN)</label>
                  <div className="col-lg-10">
                    <input
                      name="cateNameEN"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อหมวดหมู่สินค้า (EN)"
                      value={this.state.fields["cateNameEN"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.cateNameEN}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ลำดับการแสดง</label>
                  <div className="col-lg-10">
                  <input
                      name="sort"
                      type="number"
                      className="form-control"
                      placeholder="กรอกลำดับการแสดง"
                      value={this.state.fields["sort"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.sort}</div>
                  </div>
                </div>

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">รูปภาพ</label>
                  <div className="col-lg-10">
                    <input
                      type="file"
                      className="file-input"
                      name="cateImage"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">{this.state.errors.cateImage}</div>
                    <img
                      className="banner-preview-img"
                      src={this.state.fields["cateImage"] || ""}
                      alt={this.state.fields["cateName"] || ""}
                    />
                  </div>
                </div> */}

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
