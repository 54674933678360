import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <div className="footer text-center text-muted">
        &copy; 2019 Nilecon (T)Thailand. Ltd. All Rights
        Copyright © 2019 by Nilecon : Develop By Chalisa
      </div>
    );
  }
}
