import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const redeemAction = {
    getRedeemCategoryList,
    createRedeemCategory,
    activateRedeemCategory,
    deleteRedeemCategory,
    getRedeemCategoryDataByID,
    updateRedeemCategory,

    createRedeem,
    deleteRedeem,
    getRedeemDataByID,
    updateRedeem,
    activateRedeem,
    deleteBurnHistory,
    getLogsHistoryByID,

};

function getRedeemCategoryList() {
  return axios.get(`${Base_API.reward}/redeem_category`).then(res => {
    return res.data;
  });
}

function createRedeemCategory(FormData) {
  return axios.post(`${Base_API.reward}/redeem_category`, FormData).then(res => {
    return res.data;
  });
}

function createRedeem(FormData) {
  return axios.post(`${Base_API.reward}/redeem`, FormData).then(res => {
    return res.data;
  });
}

function activateRedeemCategory(id, FormData) {
  return axios.put(`${Base_API.reward}/redeem_category/${id}`, FormData).then(res => {
    return res.data;
  });
}

function deleteRedeemCategory(id) {
  return axios.delete(`${Base_API.reward}/redeem_category/${id}`).then(res => {
    return res.data;
  });
}

function deleteRedeem(id) {
  return axios.delete(`${Base_API.reward}/redeem/${id}`).then(res => {
    return res.data;
  });
}

function deleteBurnHistory(id) {
  return axios.delete(`${Base_API.reward}/burn/${id}`).then(res => {
    return res.data;
  });
}

function getRedeemCategoryDataByID(id) {
  return axios.get(`${Base_API.reward}/redeem_category/${id}`).then(res => {
    return res.data;
  });
}

function getLogsHistoryByID(id) {
  return axios.get(`${Base_API.reward}/burn/${id}`).then(res => {
    return res.data;
  });
}

function getRedeemDataByID(id) {
  return axios.get(`${Base_API.reward}/redeem/${id}`).then(res => {
    return res.data;
  });
}

function updateRedeemCategory(FormData, id) {
  return axios.put(`${Base_API.reward}/redeem_category/${id}`, FormData).then(res => {
    return res.data;
  });
}


function updateRedeem(FormData, id) {
  return axios.put(`${Base_API.reward}/redeem/${id}`, FormData).then(res => {
    return res.data;
  });
}

function activateRedeem(id, FormData) {
  return axios.put(`${Base_API.reward}/redeem/${id}`, FormData).then(res => {
    return res.data;
  });
}
