import React from "react";
import $ from "jquery";
import Select from 'react-select';
import { ewalletAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../account.css";
import ReactExport from 'react-data-export';

$.DataTable = require("datatables.net");
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-transaction-history'

let newDate = new Date()
let month = newDate.getMonth() + 1;
if(month < 10) month = '0'+ month
let year = newDate.getFullYear();


let dataSearch = {
  s_This_Month: '',
  s_Month: '',
  s_From: '',
  s_To: ''
}


export class History_Ewallet extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      fields: {},
      s_Type: 'option_month'
    };
  }

  componentDidMount() {
    this.showTable(dataSearch);
  }

  showTable(dataSearch){    
    const columns = [
      {
        title: "#",
        class: "text-center",
        data: "num"
      },
      {
        title: "Report Date",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "Transaction No.",
        class: "text-center",
        data: "invoice_no"
      },
      {
        title: "Member Number",
        class: "text-center",
        data: "mem_number",
      },
      {
        title: "Firstname",
        class: "text-center",
        data: "mem_firstname",
      },
      {
        title: "Lastname",
        class: "text-center",
        data: "mem_lastname",
      },
      {
        title: "Transaction Type",
        class: "text-center",
        data: "transaction_type"
      },
      {
        title: "Description",
        class: "text-center",
        data: "transaction_description"
      },
      {
        title: "Payment Channel",
        class: "text-center",
        data: "payment_channel_id"
      },
      {
        title: "Transaction Amount (฿)",
        class: "text-center",
        data: "transaction_amount"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(cellData);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            let data = '';
            if(cellData == 'T') data = `Topup`;
            else if(cellData == 'W') data = `Pay`;
            else if(cellData == 'V') data = `Void`;
            else if(cellData == 'R') data = `Refund`;
            else if(cellData == 'RT') data = `Refund Topup`;
            else if(cellData == 'VT') data = `Void Topup`;
            $(td)
              .html(`<div className="text-right">${data}</div>`);
          }
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            let data = '';
            if(cellData == '1') data = `E-Wallet`;
            else if(cellData == '2') data = `Credit/Debit`;
            else if(cellData == '3') data = `POS`;
            else if(cellData == '5') data = `Backoffice`;
            $(td)
              .html(`<div className="text-right">${data}</div>`);
          }
        },
        {
          targets: 9,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div className="text-right">${cellData}</div>`);
          }
        },
      ],

      ajax: {
        url: `${Base_API.ewallet}/api/v1/ewallet/history_transaction?searchType=${this.state.s_Type}&searchThisMonth=${dataSearch.s_This_Month}&searchMonth=${dataSearch.s_Month}&searchFrom=${dataSearch.s_From}&searchTo=${dataSearch.s_To}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[1, "DESC"]],
      columns,
      serverSide: true,
      ordering: false,
      searching: false,
      processing: true,
      bLengthChange: true,
      "language": {
        processing: '<i className="fa fa-spinner fa-spin fa-3x fa-fw"></i><span className="sr-only">Loading..n.</span> '
      },
    });    
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  handleSubmit(e) {
    
    var table = $('#dataTable').DataTable();
    table.destroy();

    e.preventDefault()
    var dataSearch = {
        s_This_Month: year+'-'+month,
        s_Month: (this.state.fields["searchMonth"] ? this.state.fields["searchMonth"] : ''),
        s_From: (this.state.fields["searchFrom"] ? this.state.fields["searchFrom"] : ''),
        s_To: (this.state.fields["searchTo"] ? this.state.fields["searchTo"] : ''),
    }
    this.showTable(dataSearch);
    this.exportExcel(dataSearch)

  }

  handleSelectChange = (e, action) => {
    let { fields } = this.state;
    fields[action.name] = e.value;
    // this.state.errors[action.name] = null;
    this.setState({ fields });
  };

  handleChange = e => {
    let { fields } = this.state;
    fields[e.target.name] = e.target.value;
    // this.state.errors[e.target.name] = null;
    this.setState({ fields });
  };

  handleOptionChange = (e) => {
    this.setState({
      s_Type: e.target.value
    });
  }

  exportExcel = (dataSearch) => {
    ewalletAction.getHistoryList(dataSearch, this.state.s_Type)
    .then(res => {
        this.setState({
            dataTransSearch: res.data
        })
    })
  };


  render() {

    var dateNow = new Date();

    let arrPreviousMonth = []

    for(var i = 0; i < 5; i++){
      dateNow.setMonth(dateNow.getMonth() -1);

      let monthNow = dateNow.getMonth()+1;
      if(monthNow < 10) monthNow = '0'+ monthNow

      let yearNow = dateNow.getFullYear();

      arrPreviousMonth.push(
        {value: yearNow+'-'+monthNow, label: monthNow+'/'+yearNow}
      )
      
    }

    let optionsPreviousMonth = arrPreviousMonth.map(function (item) {
      return { value: item.value, label: item.label };
    })

    var dataExcel = []
    let data = this.state.dataTransSearch    
    let transaction_type_name = ""
    let payment_channel_name = ""
    for(var i in data){
      if(data[i].transaction_type == 'T'){transaction_type_name = "Topup" }
      else if(data[i].transaction_type == 'W'){transaction_type_name = "Pay" }
      else if(data[i].transaction_type == 'V'){transaction_type_name = "Void" }
      else if(data[i].transaction_type == 'R'){transaction_type_name = "Refund" }
      else if(data[i].transaction_type == 'VT'){transaction_type_name = "Void Topup" }
      else if(data[i].transaction_type == 'RT'){transaction_type_name = "Refund Topup" }
      
      if(data[i].payment_channel_id == '1'){payment_channel_name = "E-Wallet" }
      else if(data[i].payment_channel_id == '2'){payment_channel_name = "Credit/debit" }
      else if(data[i].payment_channel_id == '3'){payment_channel_name = "POS" }
      else if(data[i].payment_channel_id == '5'){payment_channel_name = "Backoffice" }

      dataExcel.push(
        [
          {value: data[i].num},
          {value: data[i].created_at},
          {value: data[i].invoice_no},
          {value: data[i].mem_number},
          {value: data[i].mem_firstname},
          {value: data[i].mem_lastname},
          {value: transaction_type_name},
          {value: data[i].transaction_description},
          {value: payment_channel_name},
          {value: data[i].transaction_amount},

        ],
      )
    }    
    
    multiDataSet = [
      {
        columns: [
            {title: "#"},//pixels width 
            {title: "Report Date", width: {wch: 40}},//char width 
            {title: "Transaction No.", width: {wch: 40}},//char width 
            {title: "Member Number", width: {wch: 40}},//char width 
            {title: "Firstname", width: {wch: 40}},//char width 
            {title: "Lastname", width: {wch: 40}},//char width 
            {title: "Transaction Type", width: {wch: 40}},//char width 
            {title: "Description", width: {wch: 40}},
            {title: "Payment Channel", width: {wch: 20}},
            {title: "Transaction Amont (฿)", width: {wch: 20}}
        ],
        data: dataExcel
      }
    ];

    return (
      <div>
        <ol className="breadcrumb">
          <li>E-Wallet</li>
          <li>History</li>
          <li className="active">Search</li>
        </ol> 

        <ul className="nav nav-tabs">
          <li role="Transactoin" className="active"><a href="#">Transactoin</a></li>
          {/* <li role="History" className="active"><a href="/backoffice/ewallet/transaction/list">History</a></li> */}
        </ul>
        <div className="panel panel-flat">
          <div className="panel-body">

            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>

              <div>
                <h4>Account History</h4>
                <div className="panel-body panel-color-gray px-5 py-3">
                  <h4 className="">Statement Period</h4>
                  <div className="pl-3">
                    
                    <div className="radio">
                      <label>
                        <input type="radio" name="optionSearch" id="optionSearch-option_month" value="option_month" onChange={this.handleOptionChange} defaultChecked />
                        This month
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" name="optionSearch" id="optionSearch-option_previous" value="option_previous" onChange={this.handleOptionChange}/>
                        Previous month
                      </label>
                      <div className="inline-block ml-3">
                        <Select
                          className="select-input-month"
                          name="searchMonth"
                          placeholder="mm/yyyy"
                          onChange={this.handleSelectChange}
                          options={optionsPreviousMonth}
                        />
    
                      </div>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" name="optionSearch" id="optionSearch-option_date" value="option_date" onChange={this.handleOptionChange}/>
                        From
                      </label>
                      <div className="inline-block ml-3">
                        <input 
                        type="date" 
                        className="form-control" 
                        name="searchFrom"
                        placeholder="" 
                        onChange={this.handleChange}
                        />
                      </div>
                      <span className="ml-2">To</span>
                      <div className="inline-block ml-3">
                        <input 
                        type="date" 
                        className="form-control" 
                        name="searchTo"
                        placeholder="" 
                        onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <div className="">
                      <input type="submit" className="btn btn-success" value="Show Statement"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="text-right my-3">
                <ExcelFile 
                  element={
                    <button
                    type="button"
                    name="add_banner_btn"
                    data-content=""
                    className="btn btn-add mr-2"
                    >
                    <i className="icon-download"></i> Export Excel
                    </button>
                  }
                  filename = {name_excel}
                >
                  <ExcelSheet dataSet={multiDataSet} name="Transaction-History" />
                </ExcelFile>
              </div>
            <div className="table-responsive">
              <table id="dataTable" className="table table-hover" ref="main" />
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
