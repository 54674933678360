import React from "react";
import { categoryAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../category.css";

let modalAlert;

export class Order_Online_Category_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {
        category_delivery: 0,
        category_ems: 0,
        category_pickup: 0,
        category_snackbox: 0
      },
      errors: {},
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการเพิ่มข้อมูล ?')
    }
  }

  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/order-online/product/category"
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["pr_online_category_name"]) {
      formIsValid = false;
      errors["pr_online_category_name"] = "*กรุณากรอกชื่อหมวดหมู่สินค้า (TH)";
    }

    if (!fields["pr_online_category_name_en"]) {
      formIsValid = false;
      errors["pr_online_category_name_en"] = "*กรุณากรอกชื่อหมวดหมู่สินค้า (EN)";
    }

    if (!fields["sort"]) {
      formIsValid = false;
      errors["sort"] = "*กรุณากรอกลำดับการแสดง";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    var formData = new FormData();
    // formData.append("pr_category_image", this.state.fields["cateImage"]);
    formData.append("pr_online_category_name", this.state.fields["pr_online_category_name"]);
    formData.append("pr_online_category_name_en", this.state.fields["pr_online_category_name_en"]);
    formData.append("sort", this.state.fields["sort"]);
    formData.append("category_delivery", this.state.fields["category_delivery"]);
    formData.append("category_ems", this.state.fields["category_ems"]);
    formData.append("category_pickup", this.state.fields["category_pickup"]);
    formData.append("category_snackbox", this.state.fields["category_snackbox"]);
    formData.append("user_id", 1);

    categoryAction.createOnlineCategory(formData).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
  })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleInputChange = e => {
    let fields = this.state.fields
    let name = e.target.name;
    fields[name] = e.target.checked == true ? 1 : 0
    this.setState({ fields })
  }


  render() {
    console.log(this.state.fields);
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">เพิ่มหมวดหมู่สินค้าออนไลน์</h4>
          </div>
          <br />
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อหมวดหมู่ (TH)</label>
                  <div className="col-lg-10">
                  <input
                      name="pr_online_category_name"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อหมวดหมู่สินค้า (TH)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_category_name}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อหมวดหมู่ (EN)</label>
                  <div className="col-lg-10">
                  <input
                      name="pr_online_category_name_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อหมวดหมู่สินค้า (EN)"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_category_name_en}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-2">ลำดับการแสดง</label>
                  <div className="col-lg-10">
                  <input
                      name="sort"
                      type="number"
                      className="form-control"
                      placeholder="กรอกลำดับการแสดง"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.sort}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-3">แสดงหมวดหมู่ในเมนู Delivery</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="category_delivery" type="checkbox" onChange={this.handleInputChange} checked={this.state.fields['category_delivery']}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-3">แสดงหมวดหมู่ในเมนู EMS</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="category_ems" type="checkbox" onChange={this.handleInputChange} checked={this.state.fields['category_ems']}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-3">แสดงหมวดหมู่ในเมนู Order&Pickup</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="category_pickup" type="checkbox" onChange={this.handleInputChange} checked={this.state.fields['category_pickup']}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-lg-3">แสดงหมวดหมู่ในเมนู Snackbox</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="category_snackbox" type="checkbox" onChange={this.handleInputChange} checked={this.state.fields['category_snackbox']}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                {/* <div className="form-group">
                <label className="control-label col-lg-2">รูปภาพหมวดหมู่</label>
                  <div className="col-lg-10">
                  <input type="file" className="file-input" name="cateImage" onChange={this.onChangeUploadHandler}/>
                  <div className="errorMsg">{this.state.errors.cateImage}</div>
                  </div>
                </div> */}

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
