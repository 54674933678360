import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const contactAction = {
  deleteContact,
  getContactByID,
  updateContact,
  activeContact,
  createContact
};

function getContactByID(contact_id) {
  return axios.get(`${Base_API.general}/api/contact/${contact_id}`).then(res => {
    return res.data;
  });
}

function updateContact(inputBody, contact_id) {
  return axios.put(`${Base_API.general}/api/contact/${contact_id}`, inputBody).then(res => {
    return res.data;
  });
}

function activeContact(inputBody, contact_id) {
  return axios.put(`${Base_API.general}/api/contact/active/${contact_id}`, inputBody).then(res => {
    return res.data;
  });
}

function createContact(inputBody) {
  return axios.post(`${Base_API.general}/api/contact`, inputBody).then(res => {
    return res.data;
  });
}

function deleteContact(contact_id) {
  return axios({
    method: "DELETE",
    url: `${Base_API.general}/api/contact/${contact_id}`
  })
    .then(function(response) {
      return response.data;
    })
    .catch(function(response) {
      console.log(response);
    });
}
