import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../problem.css";

$.DataTable = require("datatables.net");

export class List_Problem extends React.Component {
  constructor(props, context) {
    super(props, context);


    this.state = {

    };
  }

  componentDidMount() {
    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "problem_id"
      },
      {
        title: "หัวข้อ",
        data: "problem_title"
      },
      {
        title: "รายละเอียด",
        data: "problem_description"
      },
      {
        title: "วันที่แจ้งปัญหา",
        class: "text-center",
        data: "created_at"
      }

    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          },
          orderable: false
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`${cellData}`);
          },
          orderable: false
        }
    
      ],
      ajax: {
        url: `${Base_API.member}/problem/listsProblem`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

  }


  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการแจ้งปัญหาการใช้งาน</h4>
            <div className="heading-elements">
              
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
