import React from "react";
import { productAction } from "../../../../_actions";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import SweetAlert from "react-bootstrap-sweetalert";
import "../product.css";
import Select from 'react-select';
import ReactModal from 'react-modal';

let modalAlert;
const customStyles = {
  content : {
    top: '40px',
    left: '20%',
    width: '60%',
    height: '68%',
    padding: '0px'
  }
};

export class Product_Branch_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      status: '',
      data_product:{},
      showModal: false,
      pr_branch_eatin: '',
      pr_branch_takehome: '',
      is_soldout: '',
      pr_branch_status: '',


    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    let ad_b_status = localStorage.getItem('ad_b_status');
    let ad_b_id = localStorage.getItem('ad_b_id');
    const {
      match: { params }
    } = this.props;
    this.getBranchByProduct(params.pr_id,ad_b_status,ad_b_id);
    this.setState({
      pr_id: params.pr_id
    })
  }

  getBranchByProduct(pr_id,ad_b_status,ad_b_id) {
    var data_table = []
    productAction.getEatinBranchByProduct(pr_id,ad_b_status,ad_b_id).then(e => {
      if (e.isSuccess === true) {
        
        for(var i in e.data[0].data_branch){
          var btn_edit = ''
          let isStatus_b_status = ''
          let isStatus_pr_branch_eatin = ''
          let isStatus_pr_branch_takehome = ''
          var isStatus_b_preorder_status = ''

          var isStatus_pr_branch_status = ''
          var isStatus_is_soldout = ''
          // let isStatus_pr_branch_status = ''

          //สถานะสาขา
          if(e.data[0].data_branch[i].b_status==1){
            isStatus_b_status = <span className="label label-success label-status-long">สาขาเปิดปกติ</span>
            if(e.data[0].data_branch[i].b_preorder_status==1){
              btn_edit = <button name="btn-edit" className="btn btn-warning" type="button" value={e.data[0].data_branch[i].pr_branch_id}  onClick={this.handleOpenModal}>แก้ไข</button>
            }else{
              btn_edit = <button name="btn-edit" className="btn btn-warning" type="button" value=""  onClick="" disabled>แก้ไข</button>
            }
          }else{
            isStatus_b_status = <span className="label label-danger label-status">สาขาปิด</span>
            btn_edit = <button name="btn-edit" className="btn btn-warning" type="button" value=""  onClick="" disabled>แก้ไข</button>
          }
          
          if(e.data[0].data_branch[i].pr_branch_eatin==1){
            isStatus_pr_branch_eatin = <span className="label label-success label-status">เปิดใช้งาน</span>
          }else{
            isStatus_pr_branch_eatin = <span className="label label-danger label-status">ปิดใช้งาน</span>
          }

          // if(e.data[0].data_branch[i].pr_branch_takehome==1){
          //   isStatus_pr_branch_takehome = <span className="label label-success label-status">เปิดใช้งาน</span>
          // }else{
          //   isStatus_pr_branch_takehome = <span className="label label-danger label-status">ปิดใช้งาน</span>
          // }

          // if(e.data[0].data_branch[i].b_preorder_status==1){
          //   isStatus_b_preorder_status = <span className="label label-success label-status">เปิดใช้งาน</span>
          // }else{
          //   isStatus_b_preorder_status = <span className="label label-danger label-status">ปิดใช้งาน</span>
          // }

          if(e.data[0].data_branch[i].is_soldout==0){
            isStatus_is_soldout = <span className="label label-success label-status">มีสินค้า</span>
          }else{
            isStatus_is_soldout = <span className="label label-danger label-status">สินค้าหมด</span>
          }

          
          if(e.data[0].data_branch[i].pr_branch_status==1){
            isStatus_pr_branch_status = <span className="label label-success label-status">ใช้งาน</span>
          }else{
            isStatus_pr_branch_status = <span className="label label-danger label-status">ไม่ใช้งาน</span>
          }
          data_table.push(
            <tr className="text-center">
              <th scope="row">{parseInt(i)+1}</th>
              <td className="text-left">{e.data[0].data_branch[i].b_name}</td>
              <td className="text-price">{e.data[0].data_branch[i].pr_branch_price}</td>
              <td className="">{isStatus_b_status}</td>
              <td>
                {isStatus_is_soldout}
              </td>
              <td>
                {isStatus_pr_branch_status}
              </td>
              <td>
              </td>
              <td>{btn_edit}</td>
            </tr>
          )
          
        }

        this.setState({
          data_product : e.data[0],
          data_table_branch: data_table,
        });

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = `/backoffice/preorder/product/branch/edit/${this.state.pr_id}`;
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;      

    if (!fields["pr_branch_price"]) {
      formIsValid = false;
      errors["pr_branch_price"] = "*กรุณากรอกราคาสินค้า";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null });

    const inputBody = {
      pr_branch_price: this.state.fields["pr_branch_price"],
      // pr_branch_eatin: this.state["status_eatin"],
      // pr_branch_takehome: this.state["status_takehome"],
      is_soldout: this.state["is_soldout"],
      pr_branch_status: this.state["pr_branch_status"],
    }

    productAction.updateEatinBranchByProductID(inputBody, this.state.fields["pr_branch_id"])
      .then(e => {
        if (e.isSuccess === true) {
          this.onModalSuccess("Success", e.message);
        } else {
          this.onModalError("Error", e.message);
        }
      });
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
      let { fields, errors } = this.state;
      fields[e.target.name] = e.target.value;
      this.state.errors[e.target.name] = null;
      this.setState({ fields, errors });
  };

  handleInputChange = e => {

    const target = e.target;
    const name = target.name;

    // if(name == "pr_branch_eatin"){
    //   this.setState({ pr_branch_eatin: !this.state.pr_branch_eatin })

    //   if(this.state.pr_branch_eatin == true){
    //     this.setState({ status_eatin: '0' })
    //   }else{
    //     this.setState({ status_eatin: '1' })
    //   }
    // }else if(name == "pr_branch_takehome"){
    //   this.setState({ pr_branch_takehome: !this.state.pr_branch_takehome })

    //   if(this.state.pr_branch_takehome == true){
    //     this.setState({ status_takehome: '0' })
    //   }else{
    //     this.setState({ status_takehome: '1' })
    //   }
    // }
    if(name == "is_soldout"){
      this.setState({ is_soldout: !this.state.is_soldout })

      if(this.state.is_soldout == true){
        this.setState({ status: '0' })
      }else{
        this.setState({ status: '1' })
      }
    }

    if(name == "pr_branch_status"){
      this.setState({ pr_branch_status: !this.state.pr_branch_status })

      if(this.state.pr_branch_status == true){
        this.setState({ status: '0' })
      }else{
        this.setState({ status: '1' })
      }
    }

  }

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  handleOpenModal (e) {
    this.getBranchByProductID(e.target.value)
    this.setState({ showModal: true, });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  getBranchByProductID(pr_branch_id) {
    productAction.getEatinBranchByProductID(pr_branch_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            pr_branch_id : e.data.pr_branch_id,
            pr_branch_price: e.data.pr_branch_price,
            // pr_branch_eatin: e.data.pr_branch_eatin,
            // pr_branch_takehome: e.data.pr_branch_takehome,
            // b_preorder_status: e.data.b_preorder_status,
            is_soldout: e.data.is_soldout,
            pr_branch_status: e.data.pr_branch_status,
            b_name: e.data.b_name,
            b_code: e.data.b_code,
            pr_name: e.data.pr_name,
            pr_code: e.data.pr_code
          }

        });

        this.setState({
          // pr_branch_eatin: String(this.state.fields.pr_branch_eatin) == "1",
          // pr_branch_takehome: String(this.state.fields.pr_branch_takehome) == "1",
          pr_branch_status: String(this.state.fields.pr_branch_status) == "1",
          is_soldout: String(this.state.fields.is_soldout) == "1",

          // status_eatin: this.state.fields.pr_branch_eatin,
          // status_takehome: this.state.fields.pr_branch_takehome,
          pr_branch_status: this.state.fields.pr_branch_status,
          is_soldout: this.state.fields.is_soldout
        });


      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  render() {
    var label_b_preorder_status
    if(this.state.fields.b_preorder_status == 1){
      label_b_preorder_status = <span className="label label-success label-status">ใช้งาน</span> 
    }else{
      label_b_preorder_status = <span className="label label-danger label-status">ไม่ใช้งาน</span>
    }
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขสินค้าพรีออเดอร์แต่ละสาขา</h4>
          </div>
          <div className="panel-body">
            <div className="row mt-3">
              <div className="col-md-2">
                รหัสสินค้า:
              </div>
              <div className="col-md-2">
                {this.state.data_product.pr_code}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                ชื่อสินค้า:
              </div>
              <div className="col-md-2">
                {this.state.data_product.pr_name}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                สถานะสินค้า
              </div>
              <div className="col-md-2">
                {(this.state.data_product.pr_status == 1 ? <div className="text-success">ใช้งาน</div> : <div className="text-danger">ไม่ใช้งาน</div>)}
              </div>
            </div>

            <hr className="mx-0"/>
              {/* <BootstrapTable data={this.state.data_table_branch} version='3' striped hover>
                <TableHeaderColumn isKey dataField='pr_branch_id'>#</TableHeaderColumn>
                <TableHeaderColumn dataField='b_name'>ชื่อสาขา</TableHeaderColumn>
                <TableHeaderColumn dataField='pr_branch_price'>ราคา</TableHeaderColumn>
                <TableHeaderColumn dataField='pr_branch_eatin'>สถานะ Eatin</TableHeaderColumn>
                <TableHeaderColumn dataField='pr_branch_takehome'>สถานะ Takehome</TableHeaderColumn>
                <TableHeaderColumn dataField='pr_branch_status'>สถานะสินค้า</TableHeaderColumn>
              </BootstrapTable> */}

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">ชื่อสาขา</th>
                    <th scope="col">ราคา</th>
                    <th scope="col">สถานะสาขา</th>
                    <th scope="col">สถานะ soldout</th>
                    <th scope="col">สถานะแสดงสินค้า</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data_table_branch}
                </tbody>
              </table>

              
          </div>
        </div>

        
        <ReactModal 
            isOpen={this.state.showModal}
            contentLabel="form-edit-price"
            style={customStyles}
        >
          <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >
              <div className="modal-header modal-header-form">
                <button type="button" className="close" onClick={this.handleCloseModal}><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">แก้ไขข้อมูลสินค้าแต่ละสาขา</h4>
              </div>
              <div className="modal-body modal-body-form">
                <div className="form-group">
                  <label className="control-label col-md-3">ชื่อสินค้า</label>
                  <div className="col-md-2">
                    <p className="form-control-static">({this.state.fields.pr_code}) {this.state.fields.pr_name}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3 ">ชื่อสาขา</label>
                  <div className="col-md-2">
                    <p className="form-control-static">({this.state.fields.b_code}) {this.state.fields.b_name}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">ราคาสินค้า (฿)</label>
                  <div className="col-md-2">
                    <input
                      name="pr_branch_price"
                      type="number"
                      className="form-control"
                      placeholder="กรอกราคาสินค้า"
                      value={this.state.fields.pr_branch_price || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_branch_price}</div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">สถานะ soldout</label>
                  <div className="col-md-2">
                    <label className="switch">
                      <input name="is_soldout" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_soldout}/>
                      <div className="slider slider_2color"></div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">สถานะแสดงสินค้า</label>
                  <div className="col-md-2">
                    <label className="switch">
                      <input name="pr_branch_status" type="checkbox" onChange={this.handleInputChange} checked={this.state.pr_branch_status}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>
                {/* <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสินค้า</label>
                  <div className="col-md-2">
                    <label className="switch">
                      <input name="pr_branch_status" type="checkbox" onChange={this.handleInputChange} checked={this.state.pr_branch_status}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div> */}
              </div>
              <div className="modal-footer modal-footer-form">
                <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>Close</button>
                <button type="submit" className="btn btn-success">Save changes</button>
              </div>
            </form>          
          </ReactModal>
        {this.state.modal}
      </div>
    );
  }
}
